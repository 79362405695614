import { uuid } from 'vue-uuid';

export const centersMarrakech = [
  {id:uuid.v1(),name:"Centre Sanitaire El Fekhkhara",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.636562",lng:"-7.976982",tel:"212677295095"},
{id:uuid.v1(),name:"Centre St Mellah",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.691965",lng:"-7.932359",tel:"212661622282"},
{id:uuid.v1(),name:"CS Adassil",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.227194",lng:"-8.443176",tel:"212662110682"},
{id:uuid.v1(),name:"CS Mejjat",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.353735",lng:"-8.510239",tel:"212662110682"},
{id:uuid.v1(),name:"Center Nariz",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.188251",lng:"-8.856507",tel:"212662110682"},
{id:uuid.v1(),name:"CS Ahdil",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.678981",lng:"-8.819866",tel:"212661921581"},
{id:uuid.v1(),name:"CS Ait Hadi",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.397146",lng:"-8.770606",tel:"212662110682"},
{id:uuid.v1(),name:"CS Moulay Ibrahim Roha",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.502819",lng:"-8.791138",tel:"212706725667"},
{id:uuid.v1(),name:"CS Sidi Ghanem",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.205531",lng:"-8.804062",tel:"212662110682"},
{id:uuid.v1(),name:"CSC  Sidi Bouzide ",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.499604",lng:"-8.783631",tel:"212662110682"},
{id:uuid.v1(),name:"CS Aitourir",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.564244",lng:"-7.665271",tel:"212661736130"},
{id:uuid.v1(),name:"CS Takhsifte",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.405582",lng:"-7.715275",tel:"212661736130"},
{id:uuid.v1(),name:"Allal Ben Ahmed Amkik Tets Spotnik",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.5163132",lng:"-9.7593345",tel:"212667264506"},
{id:uuid.v1(),name:"Commune Attaouia Chaiba",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"31.792442",lng:"-7.311185",tel:"212668489567"},
{id:uuid.v1(),name:"Ait Bayoud",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.322649",lng:"-9.322746",tel:"212662112783"},
{id:uuid.v1(),name:"Bizdad",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.286509",lng:"-9.338765",tel:"212662112783"},
{id:uuid.v1(),name:"CENTRE COMMUNE AIT TALEB ",address:"Marrakech-Safi, Rehamna",province:"Rehamna",lat:"32.377338",lng:"-8.172818",tel:"212661673493"},
{id:uuid.v1(),name:"Centre Commune Labrahla",address:"Marrakech-Safi, Rehamna",province:"Rehamna",lat:"32.239097",lng:"-8.021318",tel:"212661673493"},
{id:uuid.v1(),name:"Centre Commune Labriyikinne",address:"Marrakech-Safi, Rehamna",province:"Rehamna",lat:"32.295695",lng:"-8.071344",tel:"212661673493"},
{id:uuid.v1(),name:"Centre Commune Lamhara",address:"Marrakech-Safi, Rehamna",province:"Rehamna",lat:"32.068708",lng:"-7.684344",tel:"212661673493"},
{id:uuid.v1(),name:"CS Sidi Aissa",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.415192",lng:"-8.898006",tel:"212656334570"},
{id:uuid.v1(),name:"CS Gzoula",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.134476",lng:"-9.083547",tel:"212656334570"},
{id:uuid.v1(),name:"CS Biada ",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.308119",lng:"-9.234678",tel:"212656334570"},
{id:uuid.v1(),name:"CS Bouguedra",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.257889",lng:"-8.981468",tel:"212662068600"},
{id:uuid.v1(),name:"cs ibnou rochd ",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.231764",lng:"-9.252681",tel:"212656334570"},
{id:uuid.v1(),name:"CS Sbiat",address:"Marrakech-Safi, Youssoufia",province:"Youssoufia",lat:"32.20537657836742",lng:"-8.578939457672117",tel:"212678817878"},
{id:uuid.v1(),name:"CSC Zaouiat Sidi Boutiab",address:"Marrakech-Safi, Youssoufia",province:"Youssoufia",lat:"32.1808618",lng:"-8.5926472",tel:"212632686328"},
{id:uuid.v1(),name:"CS Ain Itti ",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.648297",lng:"-7.983239",tel:"212615936445"},
{id:uuid.v1(),name:"CS Ait Daoud",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.4888676",lng:"-8.2003887",tel:"212668248662"},
{id:uuid.v1(),name:"CS Arsat Moha",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.623893",lng:"-7.988789",tel:"212661622282"},
{id:uuid.v1(),name:"CS Centre 44",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.955762",lng:"-8.207543",tel:"212661293118"},
{id:uuid.v1(),name:"CS Chouiter",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.578407",lng:"-7.827061",tel:"212680280785"},
{id:uuid.v1(),name:"CS Kadi Ayad",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.652534",lng:"-7.996547",tel:"212671160094"},
{id:uuid.v1(),name:"CS Kobba",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.619787",lng:"-7.971303",tel:"212661211359"},
{id:uuid.v1(),name:"CSCA HANCHANE",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.525796",lng:"-9.441439",tel:"212662112783"},
{id:uuid.v1(),name:"CS Sidi Bouaalam",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.676513",lng:"-9.273248",tel:"212662112783"},
{id:uuid.v1(),name:"CS Tigoudar",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.628630",lng:"-9.628630",tel:"212662112783"},
{id:uuid.v1(),name:"CS Ghazoua",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.446558",lng:"-9.731759",tel:"212677443955"},
{id:uuid.v1(),name:"CSC El Hakkat",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.562572",lng:"-8.894403",tel:"212662110682"},
{id:uuid.v1(),name:"CS Jbilat",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.717129",lng:"-9.433433",tel:"212662112783"},
{id:uuid.v1(),name:"CS Talmest",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.813992",lng:"-9.340624",tel:"212662112783"},
{id:uuid.v1(),name:"CSR  Ait Faska",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.566287",lng:"-7.728852",tel:"212661736130"},
{id:uuid.v1(),name:"CS Sraghna",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.6784408",lng:"-8.0155249",tel:"212661462940"},
{id:uuid.v1(),name:"CSC Et Maison D Accouchement Loudaya",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.637220",lng:"-8.249555",tel:"212651240800"},
{id:uuid.v1(),name:"CSR  Anougal",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.138375",lng:"-8.265931",tel:"212615593654"},
{id:uuid.v1(),name:"CSR  Ouirgane",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.174494",lng:"-8.081148",tel:"212661736130"},
{id:uuid.v1(),name:"CSR Abaddou",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.605484",lng:"-7.411290",tel:"212661736130"},
{id:uuid.v1(),name:"CSR Ahmed Chefquaoui",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.312396",lng:"-7.743836",tel:"212661736130"},
{id:uuid.v1(),name:"CSR Ait Aadel",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.649823",lng:"-7.265133",tel:"212661736130"},
{id:uuid.v1(),name:"CSR Ait Aadel ",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.649825",lng:"-7.265135",tel:"212661736130"},
{id:uuid.v1(),name:"CSR Ait Hkim",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.485386",lng:"-7.204467",tel:"212661736130"},
{id:uuid.v1(),name:"CSR Ait Ouanga",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.419557",lng:"-7.685551",tel:"212661736130"},
{id:uuid.v1(),name:"CSR Amaghrass",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.210605",lng:"-8.182371",tel:"212661736130"},
{id:uuid.v1(),name:"CSR Asni",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.251413",lng:"-7.978295",tel:"212661736130"},
{id:uuid.v1(),name:"CSR Azgour",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.146977",lng:"-8.308301",tel:"212661736130"},
{id:uuid.v1(),name:"CSR Bencheikh",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.426841",lng:"-7.959320",tel:"212661736130"},
{id:uuid.v1(),name:"CSR Boukhoubza",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.628562",lng:"-7.675583",tel:"212661736130"},
{id:uuid.v1(),name:"CSR Dar Jamaa",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.253406",lng:"-8.373169",tel:"212661736130"},
{id:uuid.v1(),name:"CSR Ghmat ",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.423940",lng:"-7.801999",tel:"212661736130"},
{id:uuid.v1(),name:"CSR Ighil",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"30.984365",lng:"-8.291272",tel:"212661736130"},
{id:uuid.v1(),name:"CSR Igoudar",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.267981",lng:"-8.255414",tel:"212661736130"},
{id:uuid.v1(),name:"CSR Ijoukak",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"30.996801",lng:"-8.155533",tel:"212661736130"},
{id:uuid.v1(),name:"CSR Imegdal",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.120567",lng:"-8.121239",tel:"212661736130"},
{id:uuid.v1(),name:"CSR lalla Takerkoust",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.359694",lng:"-8.135164",tel:"212661736130"},
{id:uuid.v1(),name:"CSR Moulay Brahim",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.285906",lng:"-7.968785",tel:"212661736130"},
{id:uuid.v1(),name:"CSR Oulad Yahia",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.538433",lng:"-8.031082",tel:"212661736130"},
{id:uuid.v1(),name:"CSR Ourika",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.374070",lng:"-7.779429",tel:"212661736130"},
{id:uuid.v1(),name:"CSR Ourika ",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.374073",lng:"-7.779430",tel:"212661736130"},
{id:uuid.v1(),name:"CSR Ouzghita",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.289415",lng:"-8.103587",tel:"212664437903"},
{id:uuid.v1(),name:"CSR Sidi Abdalleh Ghiat ",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.519402",lng:"-7.848991",tel:"212661736130"},
{id:uuid.v1(),name:"CSR Sidi abdellah ghiat ",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.513329",lng:"-7.842246",tel:"212661227162"},
{id:uuid.v1(),name:"CSR Sidi Badhaj",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.301786",lng:"-8.207881",tel:"212661736130"},
{id:uuid.v1(),name:"CSR TAGUARTE",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.472266",lng:"-7.534326",tel:"212661736130"},
{id:uuid.v1(),name:"CSR Talat N'Yacoub",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"30.989788882995597",lng:"-8.183261169311528",tel:"212661736130"},
{id:uuid.v1(),name:"CSC El Hougate",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.562485",lng:"-8.894503",tel:"212662110682"},
{id:uuid.v1(),name:"CSR Tamazouzt",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.474635",lng:"-7.767096",tel:"212661736130"},
{id:uuid.v1(),name:"CSR Tamesloht",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.497284",lng:"-8.098043",tel:"212661736130"},
{id:uuid.v1(),name:"CSR Tazart",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.662914",lng:"-7.412554",tel:"212661736130"},
{id:uuid.v1(),name:"CSR Tazlida",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.439172",lng:"-7.404368",tel:"212661736130"},
{id:uuid.v1(),name:"CSR Tidili Mesfioua",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.459",lng:"-7.61031",tel:"212661736130"},
{id:uuid.v1(),name:"CSR TIGANZIOUINE ",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.250841",lng:"-8.218211",tel:"212615593654"},
{id:uuid.v1(),name:"CSR Tighedouine",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.426835",lng:"-7.527518",tel:"212661736130"},
{id:uuid.v1(),name:"CSR Tighedouine ",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.426838",lng:"-7.527520",tel:"212661736130"},
{id:uuid.v1(),name:"CSR Tizguine",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.252815",lng:"-8.355664",tel:"212664437903"},
{id:uuid.v1(),name:"CSR TOUAMA ",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.531200",lng:"-7.485402",tel:"212664437903"},
{id:uuid.v1(),name:"CSR TWART ISLA",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.495966",lng:"-7.484289",tel:"212661736130"},
{id:uuid.v1(),name:"csu ait ourir ",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.564602",lng:"-7.663094",tel:"212661736130"},
{id:uuid.v1(),name:"CSU Amzmiz",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.218896",lng:"-8.233630",tel:"212661736130"},
{id:uuid.v1(),name:"CSU REGRAGA",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.21943037243621",lng:"-8.243918431877136",tel:"212661736130"},
{id:uuid.v1(),name:"CSU Tahannaout ",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.348860",lng:"-7.951658",tel:"212661736130"},
{id:uuid.v1(),name:"Délégation Provinciale Al Haouz",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.361785",lng:"-7.933927",tel:"212670029601"},
{id:uuid.v1(),name:"CSC Souhla",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.631493",lng:"-8.164602",tel:"212661938301"},
{id:uuid.v1(),name:"Commune Eddachra",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.226880",lng:"-7.248533",tel:"212668489567"},
{id:uuid.v1(),name:"Commune El Kelaa (cs el hana)",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.058596",lng:"-7.411118",tel:"212611191807"},
{id:uuid.v1(),name:"Commune El Kelaa (CS El Hana) test spotnik",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.059045",lng:"-7.410904",tel:"212611191807"},
{id:uuid.v1(),name:"Commune El Kelaa (CSU Alqods)",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.060317",lng:"-7.378495",tel:"212661549131"},
{id:uuid.v1(),name:"Commune El Kelaa(CS El Hana)",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.058598",lng:"-7.411120",tel:"212611191807"},
{id:uuid.v1(),name:"Commune El Kelaa(Hôpital Des Maladies Psy)",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.060620",lng:"-7.378652",tel:"212668489567"},
{id:uuid.v1(),name:"Commune Hiadna",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.189696",lng:"-7.6315499",tel:"212668489567"},
{id:uuid.v1(),name:"Commune Jbil",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.048412",lng:"-7.394368",tel:"212668489567"},
{id:uuid.v1(),name:"Commune Joualla ",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"31.828983",lng:"-7.412113",tel:"212668489567"},
{id:uuid.v1(),name:"Commune Oulad Aarad",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"31.820964",lng:"-7.414792",tel:"212668489567"},
{id:uuid.v1(),name:"Commune Ouled Ali Joumoua",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"31.890803",lng:"-7.195261",tel:"212668489567"},
{id:uuid.v1(),name:"Commune Ouled Lgarn",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.060404",lng:"-7.378567",tel:"212668489567"},
{id:uuid.v1(),name:"Commune Ouled Msabbel",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.273228",lng:"-7.231689",tel:"212668489567"},
{id:uuid.v1(),name:"Commune Sidi Moussa",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.273700",lng:"-7.232508",tel:"212668489567"},
{id:uuid.v1(),name:"Commune Sidi Moussa ",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.273694",lng:"-7.232505",tel:"212668489567"},
{id:uuid.v1(),name:"Commune Zenada",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.060317",lng:"-7.378724",tel:"212668489567"},
{id:uuid.v1(),name:"CRS Reproductive",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.045605",lng:"-7.407973",tel:"212628430658"},
{id:uuid.v1(),name:"CS Alamria ",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.2257462",lng:"-7.2495389",tel:"212667971772"},
{id:uuid.v1(),name:"CS Choara ",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"31.792057",lng:"-7.310631",tel:"212668489567"},
{id:uuid.v1(),name:"CS Collectif Ouled Sebih ",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.066935",lng:"-7.510264",tel:"212668489567"},
{id:uuid.v1(),name:"CS Communal Sidi Ali Nouiti",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.548946",lng:"-7.500765",tel:"212668489567"},
{id:uuid.v1(),name:"CS Lamzam",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.006660",lng:"-7.130824",tel:"212668489567"},
{id:uuid.v1(),name:"CS Marbouh",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.06003615866806",lng:"-7.37924543320466",tel:"212668489567"},
{id:uuid.v1(),name:"CS Oulad Bou Ali",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.225746",lng:"-7.247350",tel:"212668489567"},
{id:uuid.v1(),name:"CS Ouled Abdellah",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"31.819522",lng:"-7.220753",tel:"212668489567"},
{id:uuid.v1(),name:"CS Ouled Zerrad",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.078055",lng:"-7.612799",tel:"212651796601"},
{id:uuid.v1(),name:"CS RAFIA",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.157833",lng:"-7.295501",tel:"212671065344"},
{id:uuid.v1(),name:"CS Tamelalt",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"31.732507",lng:"-7.496247",tel:"212673262384"},
{id:uuid.v1(),name:"CSC Aoulad Masoud ",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.2257462",lng:"-7.2495389",tel:"212668489567"},
{id:uuid.v1(),name:"CSC Chtaiba",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.226034",lng:"-7.247650",tel:"212622586463"},
{id:uuid.v1(),name:"CSC El Fraita",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"31.932464",lng:"-7.259770",tel:"212668489567"},
{id:uuid.v1(),name:"CSC Ouargui",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"31.815455",lng:"-7.203770",tel:"212668489567"},
{id:uuid.v1(),name:"CSC Ouled Khalouf (Zouitina)",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"31.782991",lng:"-7.086481",tel:"212668489567"},
{id:uuid.v1(),name:"CSC Ouled Yaagoub",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.025092",lng:"-7.272113",tel:"212668489567"},
{id:uuid.v1(),name:"CSC Sahrij",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"31.802302",lng:"-7.147460",tel:"212668489567"},
{id:uuid.v1(),name:"CSC Sidi Aissa ",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"31.826509",lng:"-7.263723",tel:"212668489567"},
{id:uuid.v1(),name:"CSC Sidi Rahal",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"31.650890",lng:"-7.474905",tel:"212623188286"},
{id:uuid.v1(),name:"CSC Zemran Lgharbia",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"31.731915",lng:"-7.496842",tel:"212668489567"},
{id:uuid.v1(),name:"CSR Bouya Omar",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"31.787955",lng:"-7.261108",tel:"212668489567"},
{id:uuid.v1(),name:"CSR Lounasda",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.060196",lng:"-7.378609",tel:"212668489567"},
{id:uuid.v1(),name:"CSR Mayat Oulad Saber",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.271954",lng:"-7.524991",tel:"212668489567"},
{id:uuid.v1(),name:"CSR Ouad Lakhdar",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"31.815881",lng:"-7.030412",tel:"212668489567"},
{id:uuid.v1(),name:"CSR Oulad Cherki ",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.2309647",lng:"-7.4472714",tel:"212668489567"},
{id:uuid.v1(),name:"CSR Ouled Amer",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.090679",lng:"-7.343473",tel:"212668489567"},
{id:uuid.v1(),name:"CSR Sidi El Hetab",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.38484207481874",lng:"-7.215667592590333",tel:"212668489567"},
{id:uuid.v1(),name:"CSR Sidi El Hetab ",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.385132",lng:" -7.215968",tel:"212668489567"},
{id:uuid.v1(),name:"CSR Sour Laaz",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"31.833594",lng:"-7.025667",tel:"212668489567"},
{id:uuid.v1(),name:"CSR Taouzint",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.076088",lng:"-7.373280",tel:"212668489567"},
{id:uuid.v1(),name:"CSU Adzouz",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"31.889674",lng:"-7.317738",tel:"212668489567"},
{id:uuid.v1(),name:"CSU District Administratif",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.051999999999985",lng:"-7.4040000000000035",tel:"212668489567"},
{id:uuid.v1(),name:"CSU El Attaouia",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"31.835270",lng:"-7.312797",tel:"212673262366"},
{id:uuid.v1(),name:"CSU El Attaouia (Dar Taliba )",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"31.835530",lng:"-7.312595",tel:"212673262366"},
{id:uuid.v1(),name:"CS Lahdar",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.459388",lng:"-8.792631",tel:"212656334570"},
{id:uuid.v1(),name:"CS Lahdar\t",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.476034",lng:"-8.828806",tel:"212656334570"},
{id:uuid.v1(),name:"CS Medina ",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.297978",lng:"-9.238259",tel:"212661188902"},
{id:uuid.v1(),name:"CS Moul El Bergui",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.519663",lng:"-8.998329",tel:"212661436535"},
{id:uuid.v1(),name:"CSC Beddouza",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.543960",lng:"-9.277317",tel:"212667799473"},
{id:uuid.v1(),name:"CSC Dar El Caid",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.434500",lng:"-9.049352",tel:"212666568979"},
{id:uuid.v1(),name:"CSC Dar Lamine",address:"Marrakech-Safi, Safi",province:"Safi",lat:"31.972321",lng:"-9.292704",tel:"212655048980"},
{id:uuid.v1(),name:"CSC Elgouraani",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.3206070",lng:"-8.7777360",tel:"212656334570"},
{id:uuid.v1(),name:"CSC Et Maison D'accouchement Nega\t",address:"Marrakech-Safi, Safi",province:"Safi",lat:"31.999531",lng:"-9.034478",tel:"212656334570"},
{id:uuid.v1(),name:"CSC Had Hrara",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.438970",lng:"-9.134996",tel:"212661210311"},
{id:uuid.v1(),name:"CSC Laakarta (Souk Ayir)",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.676814",lng:"-9.086138",tel:"212661520190"},
{id:uuid.v1(),name:"CSC Labkhati",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.435501",lng:"-8.649006",tel:"212656334570"},
{id:uuid.v1(),name:"CSC Laghiat  ",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.040175",lng:"-9.161247",tel:"212661174084"},
{id:uuid.v1(),name:"CSC Lemrasla",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.040813",lng:"-8.877767",tel:"212644661269"},
{id:uuid.v1(),name:"CSC Lemsabih",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.2837749",lng:"-8.7555385",tel:"212656334570"},
{id:uuid.v1(),name:"CSC Souiria Kdima ",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.043945",lng:"-9.338638",tel:"212656334570"},
{id:uuid.v1(),name:"CSR Khat Azzakanne",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.215055",lng:"-9.134573",tel:"212614711095"},
{id:uuid.v1(),name:"CSR Laamamra ",address:"Marrakech-Safi, Safi",province:"Safi",lat:"31.909191",lng:"-8.980323",tel:"212661435523"},
{id:uuid.v1(),name:"CSR Sidi Tiji",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.162337",lng:"-8.854898",tel:"212656334570"},
{id:uuid.v1(),name:"CSR Boughedra",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.257612",lng:"-8.981328",tel:"212656334570"},
{id:uuid.v1(),name:"CSU kariat Chams",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.287880",lng:"-9.203160",tel:"212656334570"},
{id:uuid.v1(),name:"Csu Al Matar ",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.266334533",lng:"-9.234575271",tel:"212662068600"},
{id:uuid.v1(),name:"CSU ANAS",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.289815",lng:"-9.236542",tel:"212662068600"},
{id:uuid.v1(),name:"CSU AZIB DERAI",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.294521",lng:"-9.219394",tel:"212656334570"},
{id:uuid.v1(),name:"CSU BIADA",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.308134",lng:"-9.235057",tel:"212662068600"},
{id:uuid.v1(),name:"CSU Gzoula\t",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.114567",lng:"-9.079227",tel:"212662068600"},
{id:uuid.v1(),name:"CSU KOLEA",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.262020",lng:"-9.249285",tel:"212656334570"},
{id:uuid.v1(),name:"csu matar",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.267355",lng:"-9.234293",tel:"212661222478"},
{id:uuid.v1(),name:"CSU SFA",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.289263",lng:"-9.217872",tel:"212656334570"},
{id:uuid.v1(),name:"csu sidi abdelkrim",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.313759",lng:"-9.227770",tel:"212656334570"},
{id:uuid.v1(),name:"csu sidi bouzid",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.307193",lng:"-9.235159",tel:"212656334570"},
{id:uuid.v1(),name:"csu zine el abidine",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.277842",lng:"-9.235534",tel:"212656334570"},
{id:uuid.v1(),name:"CSU-1 JRIFAT",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.284633",lng:"-9.227114",tel:"212662068600"},
{id:uuid.v1(),name:"csu-1 kawki",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.257077",lng:"-9.248618",tel:"212656334570"},
{id:uuid.v1(),name:"csu-1 sidi kawki",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.257075",lng:"-9.248616",tel:"212656334570"},
{id:uuid.v1(),name:"Dar Caid Si Issa",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.418196",lng:"-9.011785",tel:"212656334570"},
{id:uuid.v1(),name:"Hopital Med V",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.287886",lng:"-9.237423",tel:"212640309809"},
{id:uuid.v1(),name:"Jemaa ",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.354180",lng:"-8.840212",tel:"212662068600"},
{id:uuid.v1(),name:"Kariat Chems",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.291388",lng:"-9.209033",tel:"212690782414"},
{id:uuid.v1(),name:"Labo Epedimio",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.281863",lng:"-9.228988",tel:"212640309809"},
{id:uuid.v1(),name:"Lycée Fkih El Kanouni",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.3109783",lng:"-9.223658",tel:"212605114918"},
{id:uuid.v1(),name:"Lycee Ibno Sina",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.354162",lng:"-8.840628",tel:"212662068600"},
{id:uuid.v1(),name:"Lycee Idrissi",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.2927035",lng:"-9.2367775",tel:"212663582628"},
{id:uuid.v1(),name:"Maison D'accouchement Jamaa Shaim ",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.346222",lng:"-8.843599",tel:"212661158413"},
{id:uuid.v1(),name:"Ouled Selmane ",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.120143",lng:"-9.163553",tel:"212656334570"},
{id:uuid.v1(),name:"Salle Couverte Plateau",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.297133",lng:"-9.240788",tel:"212662068600"},
{id:uuid.v1(),name:"Salle Omnisport Laarissa",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.2659361",lng:"-9.2414904",tel:"212656334570"},
{id:uuid.v1(),name:"SCU SFA",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.289262",lng:"-9.218023",tel:"212656334570"},
{id:uuid.v1(),name:"CSC Irohalen",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.084975",lng:"-8.937027",tel:"212662110682"},
{id:uuid.v1(),name:"CSC Lalla Aziza",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.112751",lng:"-8.717162",tel:"212662110682"},
{id:uuid.v1(),name:"CSC Ouled Moumna",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.581425",lng:"-9.013727",tel:"212662110682"},
{id:uuid.v1(),name:"CSC S Mhamed Dalil Rouafia",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.448004",lng:"-8.625064",tel:"212674738467"},
{id:uuid.v1(),name:"SIDI Ouassel",address:"Marrakech-Safi, Safi",province:"Safi",lat:"32.275449",lng:"-9.245261",tel:"212662068600"},
{id:uuid.v1(),name:"CSC Saidate",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.343552",lng:"-8.7057999",tel:"212674738467"},
{id:uuid.v1(),name:"CSC Zaouiat Nhila",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.265050",lng:"-8.655295",tel:"212662110682"},
{id:uuid.v1(),name:"CSR Nfifa",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.227688",lng:"-8.856083",tel:"212661593086"},
{id:uuid.v1(),name:"CSR Ait Haddou Youssef",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"30.938301",lng:"-8.723963",tel:"212656334570"},
{id:uuid.v1(),name:"CSR De Douirane",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.213381",lng:"-8.779302",tel:"212662110682"},
{id:uuid.v1(),name:"CSR Niveau 2 Guemassa",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.4159394",lng:"-8.4128368",tel:"212662110682"},
{id:uuid.v1(),name:"CSR Timlilt",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.0289927",lng:"-9.1329808",tel:"212674738467"},
{id:uuid.v1(),name:"CSR Ain Tazitounte",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.124823",lng:"-8.931805",tel:"212662110682"},
{id:uuid.v1(),name:"CSR Kouzamet",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.096644",lng:"-9.289503",tel:"212662110682"},
{id:uuid.v1(),name:"CSR Ouad L'bour Taba3 Nfifa",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.198244",lng:"-8.888309",tel:"212608921924"},
{id:uuid.v1(),name:"CSR Rahhala",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.369727",lng:"-9.013233",tel:"212662110682"},
{id:uuid.v1(),name:"CSR Souk Anzamer",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.179059",lng:"-9.225157",tel:"212662110682"},
{id:uuid.v1(),name:"CSR Abdel Moumen",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.265242",lng:"-8.937927",tel:"212662110682"},
{id:uuid.v1(),name:"CSR Afelaissen",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.045456",lng:"-9.023336",tel:"212662110682"},
{id:uuid.v1(),name:"CSR Agouidir",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.3881174",lng:"-8.807378",tel:"212662110682"},
{id:uuid.v1(),name:"CSR Assif El Mal",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.227194 ",lng:"-8.443176",tel:"212662110682"},
{id:uuid.v1(),name:"CSR Bouabout",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.267066",lng:"-9.177787",tel:"212662110682"},
{id:uuid.v1(),name:"CSR Ichamraren",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.096956",lng:"-9.168362",tel:"212662110682"},
{id:uuid.v1(),name:"CSR Lamzoudia",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.582666",lng:"-8.490850",tel:"212662110682"},
{id:uuid.v1(),name:"CSR amzoudia",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.582496",lng:"-8.490980",tel:"212662110682"},
{id:uuid.v1(),name:"CSR Timzgadouine",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"30.887712",lng:"-9.044484",tel:"212662110682"},
{id:uuid.v1(),name:"CSR ouloukoult",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.219253",lng:"-9.082935",tel:"212662110682"},
{id:uuid.v1(),name:"CSU Chichaoua",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.541808",lng:"-8.766589",tel:"212662110682"},
{id:uuid.v1(),name:"Centre Commune Mzouda",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.2901949",lng:"-8.5957507",tel:"212662110682"},
{id:uuid.v1(),name:"CSR Ait Imour",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.5532945",lng:"-8.3059356",tel:"212680280785"},
{id:uuid.v1(),name:"CSR Ouahat Sidi Brahim",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.6462333",lng:"-7.9877273",tel:"212661498546"},
{id:uuid.v1(),name:"Delegation Provinciale Chichaoua",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.546464",lng:"-8.761491",tel:"212662110682"},
{id:uuid.v1(),name:"DR Imintanoute ",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.177867",lng:"-8.847285",tel:"212662110682"},
{id:uuid.v1(),name:"DR Laadilat",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.692148",lng:"-8.911644",tel:"212662110682"},
{id:uuid.v1(),name:"DR Laghsassia",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.2887829",lng:"-8.8231757",tel:"212674738467"},
{id:uuid.v1(),name:"DR Lguer N'alla",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.232667",lng:"-8.597212",tel:"212662110682"},
{id:uuid.v1(),name:"DR El Mzara ",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.561014",lng:"-9.126302",tel:"212662110682"},
{id:uuid.v1(),name:"DR Sidi Mokhtar ",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.581388",lng:"-9.014185",tel:"212662110682"},
{id:uuid.v1(),name:"DP Chichaoua ",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.546478",lng:"-8.761504",tel:"212662110682"},
{id:uuid.v1(),name:"DR  Liarf",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.380573",lng:"-8.2956587",tel:"212610617723"},
{id:uuid.v1(),name:"DR  Lkhnig",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.383062",lng:"-8.966957",tel:"212674738467"},
{id:uuid.v1(),name:"DR Amnas",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.416203",lng:"-8.78222",tel:"212662110682"},
{id:uuid.v1(),name:"DR Bouzouga",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.254766",lng:"-8.509950",tel:"212662110682"},
{id:uuid.v1(),name:"DR Lmaisser",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.5373061",lng:"-8.6063667",tel:"212606555321"},
{id:uuid.v1(),name:"DR Tirkhat",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.343895",lng:"-9.116306",tel:"212662110682"},
{id:uuid.v1(),name:"hopital med 6",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.539399",lng:"-8.768449",tel:"212662154939"},
{id:uuid.v1(),name:"CSC Ait Aissa",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"30.914745",lng:"-9.429840",tel:"212662112783"},
{id:uuid.v1(),name:"CSC Brakt Radi",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.882709",lng:"-9.259922",tel:"212662112783"},
{id:uuid.v1(),name:"CSC Commune Adaghas",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"30.886741",lng:"-9.297108",tel:"212662112783"},
{id:uuid.v1(),name:"CSC Lahssinate",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.533472",lng:"-9.474417",tel:"212662112783"},
{id:uuid.v1(),name:"CSC M'zilat",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.696785",lng:"-9.137622",tel:"212662112783"},
{id:uuid.v1(),name:"CSC Sidi Abdeljalil",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.832822",lng:"-9.332502",tel:"212662112783"},
{id:uuid.v1(),name:"CSC Tafdna",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.098099",lng:"-9.822438",tel:"212667264506"},
{id:uuid.v1(),name:"CSR  Aglif",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.120389",lng:"-9.392667",tel:"212662112783"},
{id:uuid.v1(),name:"CSR  Sidi Ahmed Sayeh",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.244404",lng:"-9.769301",tel:"212662112783"},
{id:uuid.v1(),name:"CSR Ait Lhaj ",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.444235",lng:"-9.440315",tel:"212662112783"},
{id:uuid.v1(),name:"CSR Meskala",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.393792",lng:"-9.414210",tel:"212662112783"},
{id:uuid.v1(),name:"CSR Sidi Kaouki",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.406943",lng:"-9.716089",tel:"212662112783"},
{id:uuid.v1(),name:"CSR Targante",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.087747",lng:"-9.661124",tel:"212662112783"},
{id:uuid.v1(),name:"CSR Tizougate",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.075959",lng:"-9.523790",tel:"212662112783"},
{id:uuid.v1(),name:"CSR Zaouiat Skiat",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.778144",lng:"-9.226890",tel:"212662112783"},
{id:uuid.v1(),name:"CSR Ait Tahrya",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.657228",lng:"-9.639545",tel:"212662112783"},
{id:uuid.v1(),name:"CSR Ait Zelten",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.284178",lng:"-9.445009",tel:"212677410652"},
{id:uuid.v1(),name:"CSR Akermoud",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.764272",lng:"-9.534767",tel:"212662112783"},
{id:uuid.v1(),name:"CSR Bouzemour",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.005862",lng:"-9.404483",tel:"212662112783"},
{id:uuid.v1(),name:"CSR Dhar",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.157314",lng:"-9.534268",tel:"212662112783"},
{id:uuid.v1(),name:"CSR Had Draa",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.577382",lng:"-9.538358",tel:"212662112783"},
{id:uuid.v1(),name:"CSR Ida Ouazza",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.139277",lng:"-9.734278",tel:"212662112783"},
{id:uuid.v1(),name:"CSR Ida Outghouma",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"30.891318",lng:"-9.695955",tel:"212662112783"},
{id:uuid.v1(),name:"CSR Imi N Tlit",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.214730",lng:"-9.548282",tel:"212662112783"},
{id:uuid.v1(),name:"CSR Kachoula",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.503170",lng:"-9.297764",tel:"212662112783"},
{id:uuid.v1(),name:"CSR Lagdadra",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.551160",lng:"-9.423741",tel:"212677415906"},
{id:uuid.v1(),name:"CSR Mejji",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.548487",lng:"-9.346214",tel:"212662112783"},
{id:uuid.v1(),name:"CSR M'rameur ",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.660438",lng:"-9.163664",tel:"212662112783"},
{id:uuid.v1(),name:"CSR Nairat",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.704649",lng:"-9.314969",tel:"212662112783"},
{id:uuid.v1(),name:"CSR Neknafa",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.297345",lng:"-9.563344",tel:"212662112783"},
{id:uuid.v1(),name:"CSR Oulad Said",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.563615",lng:"-9.176887",tel:"212662112783"},
{id:uuid.v1(),name:"CSR Ounagha",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.533481",lng:"-9.544502",tel:"212677444755"},
{id:uuid.v1(),name:"CSR Sidi Ahmed Ou Hamed",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.339454",lng:"-9.710115",tel:"212662112783"},
{id:uuid.v1(),name:"CSR Sidi Laarousssi",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.846345",lng:"-9.160401",tel:"212662112783"},
{id:uuid.v1(),name:"CSR Sidi Med Merzouk",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.765032",lng:"-9.012342",tel:"212662112783"},
{id:uuid.v1(),name:"CSR Souk Aam",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.866242",lng:"-9.449146",tel:"212662112783"},
{id:uuid.v1(),name:"CSR Takat",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.650478",lng:"-9.468913",tel:"212662112783"},
{id:uuid.v1(),name:"CSR Takouchte",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.187789",lng:"-9.449161",tel:"212662112783"},
{id:uuid.v1(),name:"CSR Assais Akrarou",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"30.9190860",lng:"-9.2198490",tel:"212662112783"},
{id:uuid.v1(),name:"CSR Smimo",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.212245",lng:"-9.704864",tel:"212662112783"},
{id:uuid.v1(),name:"CSR Tafettachet",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.582823",lng:"-9.254177",tel:"212662112783"},
{id:uuid.v1(),name:"CSR Tamanar",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"30.999310",lng:"-9.677940",tel:"212663121656"},
{id:uuid.v1(),name:"CSR Zaouit Benhmida",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.753820",lng:"-9.373120",tel:"212662112783"},
{id:uuid.v1(),name:"CSR Ait Daoud ",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.074274",lng:"-9.359736",tel:"212667264506"},
{id:uuid.v1(),name:"CSU La Lagune",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.512480",lng:"-9.754235",tel:"212670419845"},
{id:uuid.v1(),name:"CSU Ghazoua",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.445468",lng:"-9.730999",tel:"212677443955"},
{id:uuid.v1(),name:"CSU Rahal Ben Ahmed",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.510579",lng:"-9.766639",tel:"212662174446"},
{id:uuid.v1(),name:"csu1-Allal Ben Ahmed Amkik",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.516379",lng:"-9.759174",tel:"212661927081"},
{id:uuid.v1(),name:"Centre Commune Ida o Guelloul",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"30.952495",lng:"-9.696392",tel:"212662112783"},
{id:uuid.v1(),name:"DR Birmzouri",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.506332",lng:"-9.615250",tel:"212662112783"},
{id:uuid.v1(),name:"DR Artnan",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"30.934142",lng:"-9.363410",tel:"212662112783"},
{id:uuid.v1(),name:"Ezzaouit",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.348597",lng:"-9.405259",tel:"212662112783"},
{id:uuid.v1(),name:"Had Draa",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.571738",lng:"-9.535933",tel:"212667264506"},
{id:uuid.v1(),name:"Hopital Sidi Mohamed Ben Abdellah",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.511837",lng:"-9.763539",tel:"212661865196"},
{id:uuid.v1(),name:"Ida Ougourd",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.423875",lng:"-9.636941",tel:"212662112783"},
{id:uuid.v1(),name:"Ida Oukazzou",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"30.881103",lng:"-9.635685",tel:"212662112783"},
{id:uuid.v1(),name:"Imerditssen",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"30.958732",lng:"-9.790707",tel:"212662112783"},
{id:uuid.v1(),name:"Kourimat",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.455195",lng:"-9.314194",tel:"212662112783"},
{id:uuid.v1(),name:"Mouarid",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.392834",lng:"-9.165920",tel:"212662112783"},
{id:uuid.v1(),name:"Riad Mogador",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.495158",lng:"-9.760062",tel:"212662112783"},
{id:uuid.v1(),name:"Salle De Sport D'Essaouira",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.509856",lng:"-9.763835",tel:"212667264506"},
{id:uuid.v1(),name:"Sidi Ali Elkrati",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.803120",lng:"-9.4005184",tel:"212662112783"},
{id:uuid.v1(),name:"DR Idou Zmzam",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.286641",lng:"-9.338811",tel:"212662112783"},
{id:uuid.v1(),name:"CSR Oued Lahjer",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.6504175",lng:"-7.8301031",tel:"212632686328"},
{id:uuid.v1(),name:"CSR Saada",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.629803",lng:"-8.111058",tel:"212662294463"},
{id:uuid.v1(),name:"CSR Sidi Moussa",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.537503",lng:"-7.968257",tel:"212661622282"},
{id:uuid.v1(),name:"CSR Sidi Zouine",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.6660435",lng:"-8.3522286",tel:"212701028007"},
{id:uuid.v1(),name:"CSU  Dar Al Bacha",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.631607",lng:"-7.991861",tel:"212680280785"},
{id:uuid.v1(),name:"CSU Abboussy",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.641095",lng:"-7.984005",tel:"212673109487"},
{id:uuid.v1(),name:"CSU Agdal Bahmad ",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.625441",lng:"-7.976401",tel:"212661622282"},
{id:uuid.v1(),name:"CSU Akiod",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.6499707",lng:"-8.0249266",tel:"212660789762"},
{id:uuid.v1(),name:"CSU Al Bahia",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.622548",lng:"-7.985168",tel:"212661622282"},
{id:uuid.v1(),name:"CSU Azli",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.621053",lng:"-8.047205",tel:"212666398687"},
{id:uuid.v1(),name:"CSU Azzouzia",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.6879836",lng:"-8.0640867",tel:"212642143106"},
{id:uuid.v1(),name:"CSU Bab Doukala",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.637849",lng:"-8.001055",tel:"212661448355"},
{id:uuid.v1(),name:"CSU Bab Taghzout",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.6361248",lng:"-7.9907935",tel:"212680280785"},
{id:uuid.v1(),name:"CSU Castor",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.632171",lng:"-8.050109",tel:"212661622282"},
{id:uuid.v1(),name:"DR  Ighi",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.466501",lng:"-7.297436",tel:"212661736130"},
{id:uuid.v1(),name:"DR Agoujgal",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.577192",lng:"-7.554946",tel:"212661736130"},
{id:uuid.v1(),name:"DR Ait Abdesslam",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.492286",lng:"-7.669983",tel:"212661736130"},
{id:uuid.v1(),name:"DR Ait Ouahlil",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.574633",lng:"-7.481059",tel:"212661736130"},
{id:uuid.v1(),name:"DR Ait Rbaa",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.5627",lng:"-7.13507",tel:"212661736130"},
{id:uuid.v1(),name:"CSU Harbil",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.7449215",lng:"-8.1098428",tel:"212666706200"},
{id:uuid.v1(),name:"CSU Hay El Hassany",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.6329895",lng:"-8.0444047",tel:"212661337692"},
{id:uuid.v1(),name:"CSU Hay Mohammadi",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.651022",lng:"-7.995132",tel:"212661622282"},
{id:uuid.v1(),name:"CSU Houmane Fetouaki",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.618724",lng:"-7.978430",tel:"212661622282"},
{id:uuid.v1(),name:"CSU Iziki",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.625450",lng:"-8.066759",tel:"212680280785"},
{id:uuid.v1(),name:"CSU Kasabah",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.615913",lng:"-7.987880",tel:"212661622282"},
{id:uuid.v1(),name:"csu maatallah",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.596046",lng:"-8.037341",tel:"212661622282"},
{id:uuid.v1(),name:"CSU Massira",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.631447",lng:"-8.057486",tel:"212661622282"},
{id:uuid.v1(),name:"csu massira 2",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.637343",lng:"-8.061854",tel:"212661622282"},
{id:uuid.v1(),name:"CSU Mhamid",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.588919",lng:"-8.039007",tel:"212661622282"},
{id:uuid.v1(),name:"CSU Mohammadia",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.650068",lng:"-7.991312",tel:"212680280785"},
{id:uuid.v1(),name:"CSU Mokef",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.633438",lng:"-7.982421",tel:"212661622282"},
{id:uuid.v1(),name:"CSU Mouassine",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.628940",lng:"-7.993004",tel:"212661622282"},
{id:uuid.v1(),name:"CSU OUSSEF BEN TACHEFINE",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.613491",lng:"-7.969802",tel:"212642017476"},
{id:uuid.v1(),name:"CSU Riad Laarouss",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.6356425",lng:"-7.9928377",tel:"212673118409"},
{id:uuid.v1(),name:"CSU Sidi Amara",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.607528",lng:"-7.988266",tel:"212661366279"},
{id:uuid.v1(),name:"CSU Sidi Ghanem",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.679914",lng:"-8.058283",tel:"212662193469"},
{id:uuid.v1(),name:"CSU Tamensourt",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.747070",lng:"-8.104620",tel:"212667507777"},
{id:uuid.v1(),name:"DR Bab Aylane",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.628850",lng:"-7.972293",tel:"212661622282"},
{id:uuid.v1(),name:"Lycee Dar Tounssi",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.645483016967773",lng:"-7.941953182220459",tel:"212643818751"},
{id:uuid.v1(),name:"Lycée Fatima Mernissi",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.65376091003418",lng:"-8.078263282775879",tel:"212661237299"},
{id:uuid.v1(),name:"Lycée Hassan 2",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.6293011",lng:"-8.0139736",tel:"212661911112"},
{id:uuid.v1(),name:"Lycée Sidi Youssef Ben Ali",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.6032215",lng:"-7.9618404",tel:"212662813001"},
{id:uuid.v1(),name:"Maison D'accouchement Kettara",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.866583",lng:"-8.182367",tel:"212661356669"},
{id:uuid.v1(),name:"Pharmacie Préfectorale Marrakech",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.63684523933217",lng:"-8.0015599852848",tel:"212680280785"},
{id:uuid.v1(),name:"DR Anzal",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.576026",lng:"-7.427989",tel:"212661736130"},
{id:uuid.v1(),name:"DR Azzaden",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.142659",lng:"-7.978837",tel:"212661736130"},
{id:uuid.v1(),name:"DR Benkabour",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.427891",lng:"-7.907205",tel:"212661736130"},
{id:uuid.v1(),name:"Centre Commune Ouled Hassoun Hamri",address:"Marrakech-Safi, Rehamna",province:"Rehamna",lat:"32.356595",lng:"-7.938358",tel:"212661673493"},
{id:uuid.v1(),name:"Centre Commune Sidi Mansour",address:"Marrakech-Safi, Rehamna",province:"Rehamna",lat:"32.498515",lng:"-8.098510",tel:"212661673493"},
{id:uuid.v1(),name:"Centre Ct Sidi Ghanem Commune Sidi Ghanem",address:"Marrakech-Safi, Rehamna",province:"Rehamna",lat:"32.680716",lng:"-8.002137",tel:"212661673493"},
{id:uuid.v1(),name:"Centre De Diagnostic Jnane El Khair",address:"Marrakech-Safi, Rehamna",province:"Rehamna",lat:"32.241262",lng:"-7.946514",tel:"212661673493"},
{id:uuid.v1(),name:"CF3-CHP Rhamna",address:"Marrakech-Safi, Rehamna",province:"Rehamna",lat:"32.241317",lng:"-7.958821",tel:"212663290834"},
{id:uuid.v1(),name:"Complex Culturel ",address:"Marrakech-Safi, Rehamna",province:"Rehamna",lat:"32.238813",lng:"-7.954517",tel:"212661673493"},
{id:uuid.v1(),name:"Complex Sportif Bouchane",address:"Marrakech-Safi, Rehamna",province:"Rehamna",lat:"32.288597",lng:"-8.315948",tel:"212649429371"},
{id:uuid.v1(),name:"CS  Cummunal Tlauh",address:"Marrakech-Safi, Rehamna",province:"Rehamna",lat:"31.809576",lng:"-7.558767",tel:"212661673493"},
{id:uuid.v1(),name:"CS Ait Hammou",address:"Marrakech-Safi, Rehamna",province:"Rehamna",lat:"32.245563",lng:"-8.283014",tel:"212668561950"},
{id:uuid.v1(),name:"CS Borouss",address:"Marrakech-Safi, Rehamna",province:"Rehamna",lat:"31.758363",lng:"-7.851816",tel:"212661673493"},
{id:uuid.v1(),name:"CS Communal Akarma",address:"Marrakech-Safi, Rehamna",province:"Rehamna",lat:"31.873259",lng:"-7.644515",tel:"212661673493"},
{id:uuid.v1(),name:"CS Communal Ouled Ameur Tazmrine",address:"Marrakech-Safi, Rehamna",province:"Rehamna",lat:"32.291421",lng:"-8.437040",tel:"212661673493"},
{id:uuid.v1(),name:"CS Jbilate",address:"Marrakech-Safi, Rehamna",province:"Rehamna",lat:"31.838144",lng:"-7.846535",tel:"212661673493"},
{id:uuid.v1(),name:"CS Rass El Ain",address:"Marrakech-Safi, Rehamna",province:"Rehamna",lat:"31.753212",lng:" -7.618425",tel:"212661673493"},
{id:uuid.v1(),name:"CS Sidi Bouothmane Rhamna",address:"Marrakech-Safi, Rehamna",province:"Rehamna",lat:"31.900244",lng:"-7.941805",tel:"212668561950"},
{id:uuid.v1(),name:"CSC Avec Unite D Accouchement Sidi Ghanem",address:"Marrakech-Safi, Rehamna",province:"Rehamna",lat:"32.6794700",lng:"-8.0022080",tel:"212663013005"},
{id:uuid.v1(),name:"CSC Bouchane",address:"Marrakech-Safi, Rehamna",province:"Rehamna",lat:"32.293581",lng:"-8.328193",tel:"212661673493"},
{id:uuid.v1(),name:"CSC Nzalet Ladem",address:"Marrakech-Safi, Rehamna",province:"Rehamna",lat:"32.101530",lng:"-7.951518",tel:"212661673493"},
{id:uuid.v1(),name:"CSC Sidi Abdellah",address:"Marrakech-Safi, Rehamna",province:"Rehamna",lat:"32.592302",lng:"-7.815493",tel:"212661673493"},
{id:uuid.v1(),name:"CSR  Jaafra ",address:"Marrakech-Safi, Rehamna",province:"Rehamna",lat:"32.37552642",lng:"-7.727455139",tel:"212661673493"},
{id:uuid.v1(),name:"CSR  Skoura Laananate ",address:"Marrakech-Safi, Rehamna",province:"Rehamna",lat:"32.472985",lng:"-7.802594",tel:"212661673493"},
{id:uuid.v1(),name:"CSR Sidi Boubker",address:"Marrakech-Safi, Rehamna",province:"Rehamna",lat:"31.831468",lng:"-7.736340",tel:"212661673493"},
{id:uuid.v1(),name:"CSR Sokhour Rhamna",address:"Marrakech-Safi, Rehamna",province:"Rehamna",lat:"32.479944",lng:"-7.912144",tel:"212663013005"},
{id:uuid.v1(),name:"CSU Jaaidat ",address:"Marrakech-Safi, Rehamna",province:"Rehamna",lat:"31.70265754",lng:"-7.6842737",tel:"212663290834"},
{id:uuid.v1(),name:"CSU Riaden ",address:"Marrakech-Safi, Rehamna",province:"Rehamna",lat:"32.190089",lng:"-7.964146",tel:"212661673493"},
{id:uuid.v1(),name:"CSU Takadoum",address:"Marrakech-Safi, Rehamna",province:"Rehamna",lat:"32.256947",lng:"-7.949636",tel:"212661673493"},
{id:uuid.v1(),name:"Delegation Provinciale Rhamna",address:"Marrakech-Safi, Rehamna",province:"Rehamna",lat:"32.2412447",lng:"-7.961622",tel:"212661673493"},
{id:uuid.v1(),name:"Douar Kadour Ben Brik Commune Ouled Imloul",address:"Marrakech-Safi, Rehamna",province:"Rehamna",lat:"32.036620",lng:"-7.748100",tel:"212661673493"},
{id:uuid.v1(),name:"DP Rhamna CF ",address:"Marrakech-Safi, Rehamna",province:"Rehamna",lat:"32.240916",lng:"-7.961421",tel:"212663290834"},
{id:uuid.v1(),name:"DR Ouled Abid Hmad",address:"Marrakech-Safi, Rehamna",province:"Rehamna",lat:"32.221722",lng:"-8.200037",tel:"212661673493"},
{id:uuid.v1(),name:"DR Ouled Lhaj",address:"Marrakech-Safi, Rehamna",province:"Rehamna",lat:"32.302982",lng:"-8.184181",tel:"212661673493"},
{id:uuid.v1(),name:"DR Skoura Elhadra",address:"Marrakech-Safi, Rehamna",province:"Rehamna",lat:"32.470432",lng:"-7.724072",tel:"212661673493"},
{id:uuid.v1(),name:"Lycée Ghazal Adam",address:"Marrakech-Safi, Rehamna",province:"Rehamna",lat:"31.904046",lng:"-7.941959",tel:"212706193521"},
{id:uuid.v1(),name:"CSR  Jdour",address:"Marrakech-Safi, Youssoufia",province:"Youssoufia",lat:"32.111110",lng:"-8.782182",tel:"212678817878"},
{id:uuid.v1(),name:"Imindounit",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.065716",lng:"-8.531315",tel:"212662110682"},
{id:uuid.v1(),name:"Lycée Al Khawarizmi",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.3639583",lng:"-8.50389",tel:"212674738467"},
{id:uuid.v1(),name:"Lycée Iben Arabi",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.5400378",lng:"-8.7704097",tel:"212674738467"},
{id:uuid.v1(),name:"Lycée Ibno L'haitam ",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.188030242919922",lng:"-8.844861030578613",tel:"212673086889"},
{id:uuid.v1(),name:"lycée Ibno Rochd ",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.576056",lng:"-8.983026",tel:"212673086891"},
{id:uuid.v1(),name:"Lycée Taouloukolt",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.225016",lng:"-9.098065",tel:"212655273767"},
{id:uuid.v1(),name:"CSR  Ras El Ain  ",address:"Marrakech-Safi, Youssoufia",province:"Youssoufia",lat:"32.02286713269036",lng:"-8.488968967745912",tel:"212678817878"},
{id:uuid.v1(),name:"CSR  Sidi Ahmed",address:"Marrakech-Safi, Youssoufia",province:"Youssoufia",lat:"32.212940",lng:"-8.493346",tel:"212678817878"},
{id:uuid.v1(),name:"CSR  Tiamime",address:"Marrakech-Safi, Youssoufia",province:"Youssoufia",lat:"32.057866",lng:"-8.5669980",tel:"212678817878"},
{id:uuid.v1(),name:"CSR Ighoud",address:"Marrakech-Safi, Youssoufia",province:"Youssoufia",lat:"31.864452",lng:"-8.881024",tel:"212678817878"},
{id:uuid.v1(),name:"CSR Jdour",address:"Marrakech-Safi, Youssoufia",province:"Youssoufia",lat:"32.111112",lng:"-8.782184",tel:"212678817878"},
{id:uuid.v1(),name:"CSR Lkhwalka",address:"Marrakech-Safi, Youssoufia",province:"Youssoufia",lat:"32.048170",lng:"-8.354424",tel:"212678817878"},
{id:uuid.v1(),name:"CSR Ras El Ain  ",address:"Marrakech-Safi, Youssoufia",province:"Youssoufia",lat:"32.02303314",lng:"-8.488985061",tel:"212678817878"},
{id:uuid.v1(),name:"CSR Sidi Ahmed",address:"Marrakech-Safi, Youssoufia",province:"Youssoufia",lat:"32.212879",lng:"-8.493446",tel:"212678817878"},
{id:uuid.v1(),name:"CSR Sidi Chiker",address:"Marrakech-Safi, Youssoufia",province:"Youssoufia",lat:"31.764944",lng:"-8.693343",tel:"212678817878"},
{id:uuid.v1(),name:"CSR Tiamime",address:"Marrakech-Safi, Youssoufia",province:"Youssoufia",lat:"32.057864",lng:"-8.566978",tel:"212678817878"},
{id:uuid.v1(),name:"CSu  Chemaia",address:"Marrakech-Safi, Youssoufia",province:"Youssoufia",lat:"32.091513",lng:"-8.634014",tel:"212678817878"},
{id:uuid.v1(),name:"CSU  Chemaia ",address:"Marrakech-Safi, Youssoufia",province:"Youssoufia",lat:"32.078077",lng:"-8.625738",tel:"212678817878"},
{id:uuid.v1(),name:"DR Labdadgha ",address:"Marrakech-Safi, Youssoufia",province:"Youssoufia",lat:"31.941627",lng:"-8.552457",tel:"212678817878"},
{id:uuid.v1(),name:"Hôpital Princesse Lalla Hasna ",address:"Marrakech-Safi, Youssoufia",province:"Youssoufia",lat:"32.250084",lng:"-8.522471",tel:"212659562504"},
{id:uuid.v1(),name:"CSU Laarsa",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.059689",lng:"-7.378768",tel:"212604424399"},
{id:uuid.v1(),name:"CSU Laatamna",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"31.912042",lng:"-7.334889",tel:"212668489567"},
{id:uuid.v1(),name:"CSU Zemran Charquia ",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"31.598339",lng:"-7.528402",tel:"212668489567"},
{id:uuid.v1(),name:"CSU Zerman Charquia ",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"31.598351",lng:"-7.528317",tel:"212668489567"},
{id:uuid.v1(),name:"Delegation Test Sputnik",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.05716232248633",lng:"-7.282420517578123",tel:"212762903302"},
{id:uuid.v1(),name:"Delegation Test Sputnik ",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.050593",lng:"-7.407383",tel:"212762903302"},
{id:uuid.v1(),name:"DR Bouida",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"31.722901",lng:"-7.333202",tel:"212668489567"},
{id:uuid.v1(),name:"DR Hrawa ",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"31.598497",lng:"-7.528631",tel:"212668489567"},
{id:uuid.v1(),name:"DR Lahmadna",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"31.777175",lng:"-7.121553",tel:"212668489567"},
{id:uuid.v1(),name:"DR Lamnassir",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"31.814521",lng:"-7.200564",tel:"212668489567"},
{id:uuid.v1(),name:"DR Lkhnafena",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.478642",lng:"-7.204070",tel:"212668489567"},
{id:uuid.v1(),name:"DR Oulad Abbou",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"31.838287",lng:"-7.321811",tel:"212668489567"},
{id:uuid.v1(),name:"DR Oulad Hammou Mayaat",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.048713",lng:"-7.394738",tel:"212668489567"},
{id:uuid.v1(),name:"DR Oulad Tarraf",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.283139",lng:"-7.210368",tel:"212604424399"},
{id:uuid.v1(),name:"DR Ouled Chaikhe Lgarni",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.1063150",lng:"-76004790",tel:"212668489567"},
{id:uuid.v1(),name:"DR Ouled Gaid",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"31.723558",lng:"-7.333359",tel:"212668489567"},
{id:uuid.v1(),name:"DR Ouled Khalouf",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"31.783112",lng:"-7.086023",tel:"212668489567"},
{id:uuid.v1(),name:"DR Sidi Driss ",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"31.794654",lng:"-7.030140",tel:"212668489567"},
{id:uuid.v1(),name:"DR Sidi Med Toumi",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.017443",lng:"-7.222438",tel:"212668489567"},
{id:uuid.v1(),name:"Hôpital Essalama",address:"Marrakech-Safi, El Kelaâ des Sraghna",province:"El Kelaâ des Sraghna",lat:"32.060475",lng:"-7.378896",tel:"212668489567"},
{id:uuid.v1(),name:"Salle Couverte",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.546358",lng:"-8.761511",tel:"212662110682"},
{id:uuid.v1(),name:"Lycée Imame Boukhari ",address:"Marrakech-Safi, Youssoufia",province:"Youssoufia",lat:"32.258670",lng:"-8.534225",tel:"212655242112"},
{id:uuid.v1(),name:"Maison De Jeunes Al Amal ",address:"Marrakech-Safi, Youssoufia",province:"Youssoufia",lat:"32.257145",lng:"-8.527718",tel:"212678817878"},
{id:uuid.v1(),name:"Salle Omnisports  Test Spotnik",address:"Marrakech-Safi, Youssoufia",province:"Youssoufia",lat:"32.240972",lng:"-8.533871",tel:"212632686328"},
{id:uuid.v1(),name:"DR Chouiter",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.575670",lng:"-7.816272",tel:"212661736130"},
{id:uuid.v1(),name:"DR Imlil",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.136290",lng:"-7.918009",tel:"212661736130"},
{id:uuid.v1(),name:"DR Lamdinate",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.110362",lng:"-8.370002",tel:"212661736130"},
{id:uuid.v1(),name:"DR lhrissane",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.600379",lng:"-7.377976",tel:"212661736130"},
{id:uuid.v1(),name:"DR Marigha",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.196234",lng:"-8.047193",tel:"212661736130"},
{id:uuid.v1(),name:"DR Mouldikht",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"30.937014",lng:"-8.269256",tel:"212661736130"},
{id:uuid.v1(),name:"DR Sahrij",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.650601",lng:"-7.330421",tel:"212661736130"},
{id:uuid.v1(),name:"DR Taddart",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.357428",lng:"-7.391865",tel:"212661736130"},
{id:uuid.v1(),name:"DR Tassaout",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.379213",lng:"-8.024842",tel:"212661736130"},
{id:uuid.v1(),name:"DR Tidrara",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.347524",lng:"-7.895733",tel:"212661736130"},
{id:uuid.v1(),name:"DR Tiouli N'kik",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.308360",lng:"-7.961922",tel:"212661736130"},
{id:uuid.v1(),name:"DR Tizal Ait Abdellah",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.554191",lng:"-7.364607",tel:"212661736130"},
{id:uuid.v1(),name:"DR Toukhribine",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.507997",lng:"-7.564398",tel:"212687856081"},
{id:uuid.v1(),name:"Salle Bab Igheli",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.612939834594727",lng:"-7.9943318367004395",tel:"212666264136"},
{id:uuid.v1(),name:"Vaccindrome Maatallah ",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.589611053466797",lng:"-8.053496360778809",tel:"212762684464"},
{id:uuid.v1(),name:"Vaccinodrome  Youssef Ben Tachefine",address:"Marrakech-Safi, Marrakech",province:"Marrakech",lat:"31.6122282",lng:"-7.9693825",tel:"212661211359"},
{id:uuid.v1(),name:"Sid Lmokhtar",address:"Marrakech-Safi, Chichaoua",province:"Chichaoua",lat:"31.5805476",lng:"-9.0143903",tel:"212662110682"},
{id:uuid.v1(),name:"DR Toulkine",address:"Marrakech-Safi, Al Haouz",province:"Al Haouz",lat:"31.160415",lng:"-8.335828",tel:"212661736130"},
{id:uuid.v1(),name:"Tabarda",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.372326",lng:"-9.240459",tel:"212662112783"},
{id:uuid.v1(),name:"Tahlointe",address:"Marrakech-Safi, Essaouira",province:"Essaouira",lat:"31.200246",lng:"-9.306087",tel:"212662112783"},
{id:uuid.v1(),name:"Salle Couvertes Omnisports",address:"Marrakech-Safi, Rehamna",province:"Rehamna",lat:"32.223918",lng:"-7.936925",tel:"212661673493"},
{id:uuid.v1(),name:"Vaccindrome Lycée Salame",address:"Marrakech-Safi, Rehamna",province:"Rehamna",lat:"32.2458488",lng:"-7.9502624",tel:"212660635858"}]

export const healthCenters = [
  {
    id: uuid.v1(),
    name: "CENTRE DE PROCREATION MEDICALEMENT ASSISTEE",
    tel: "05 22 26 10 14",
    address: "13 RUE DE NEUFCHETEAU QUARTIER CENTRE VILLE 20140 CASABLANCA Casablanca 20140",
    type: 2,
    lng: -7.583333,
    lat: 33.533333,province:"Marrakech"
  },

  {
    id: uuid.v1(),
    name: "Clinique Chifaa",

    tel: "05 28 83 26 24",


    address: "AV MOKHTAR SOUSSI IMM OUBERKA Inezgane -",

    type: 2,
    lng: -7.6381404,
    province:"Marrakech", lat: 33.5732406
  },







  {
    id: uuid.v1(),
    name: "Clinique CAPITAL",

    tel: "05 38 00 44 00",


    etat: "En cours",


    note: "Resp non-disponible / Rappel",


    address: "avenue 46 Du Chellah ex Abderrahmane Annegay Rabat 10010",

    type: 2,
    lng: -6.8282054,
    province:"Marrakech", lat: 34.0167856
  },







  {
    id: uuid.v1(),
    name: "Clinique LE LITORAL",

    tel: "06 61 15 52 23",


    address: "Salaj 3, Bv la grande ceinture Ain Diab 20180 Casablanca Casa-Anfa 20180",

    type: 2,
    lng: -7.6749999,
    province:"Marrakech", lat: 33.5810151
  },







  {
    id: uuid.v1(),
    name: "Clinique BNI SNASSEN - Berkane",

    tel: "05 36 61 98 23",


    address: "route de Saïdia bd de l&apos;Istiqlal , km 2، Bd de l’indépendance, Berkane 63320 Berkane 63320",

    type: 2,
    lng: -2.3245268,
    province:"Marrakech", lat: 34.9344167
  },







  {
    id: uuid.v1(),
    name: "Clinique CLINIQ. DU MAROC - Oujda Angad",

    tel: "05 36 68 20 20",


    address: "Rue Allal Bin Abdullah, Oujda 60000 Oujda Angad 60000",

    type: 2,
    lng: -1.918549,
    province:"Marrakech", lat: 34.6725282
  },







  {
    id: uuid.v1(),
    name: "Polyclinique Illigh",

    tel: "05 28 82 14 91",


    address: "LOT ILLIGH AGADIR Agadir 80030",

    type: 2,
    lng: -9.5900789,
    province:"Marrakech", lat: 30.440022
  },







  {
    id: uuid.v1(),
    name: "Polyclinique du Souss",

    tel: "05 28 22 40 40",


    address: "AV ABDERRAHIM BOUABID Agadir 80000",

    type: 2,
    lng: -9.5833321,
    province:"Marrakech", lat: 30.4098065
  },







  {
    id: uuid.v1(),
    name: "Polyclinique de la CNSS Agadir",

    statut: "CNSS",


    tel: "05 28 84 66 21",


    address: "BD MOULAY YOUSSEF EXTENSION Agadir 80000",

    type: 2,
    lng: -9.5894251,
    province:"Marrakech", lat: 30.4282618
  },







  {
    id: uuid.v1(),
    name: "Clinique spécialisée Moulay Youssef",

    tel: "05 28 84 75 55",


    address: "6 BD MY YOUSSEF EXTENTION Agadir 80000",

    type: 2,
    lng: -9.5910594,
    province:"Marrakech", lat: 30.4270812
  },







  {
    id: uuid.v1(),
    name: "Clinique Cheikh Maelainin",

    tel: "05 28 82 16 66",


    address: "RUE DU SOUVENIR TALBORJT Agadir 80000",

    type: 2,
    lng: -9.595714,
    province:"Marrakech", lat: 30.4207953
  },







  {
    id: uuid.v1(),
    name: "Clinique Argana",

    tel: "05 28 84 60 00",


    address: "RUE DE LA FOIRE Agadir 80000",

    type: 2,
    lng: -9.5941685,
    province:"Marrakech", lat: 30.4167064
  },







  {
    id: uuid.v1(),
    name: "Clinique Agadir des spécialités",

    tel: "05 28 84 84 84",


    address: "AV HASSAN II GALERIES ALINBIAET Agadir 80000",

    type: 2,
    lng: -9.594087,
    province:"Marrakech", lat: 30.414798
  },







  {
    id: uuid.v1(),
    name: "Clinique Azrou",

    tel: "05 35 56 24 32",


    address: "ROUTE DE MIDELT AJELLAB AZROU Azrou -",

    type: 2,
    lng: -5.211685,
    province:"Marrakech", lat: 33.4381116
  },







  {
    id: uuid.v1(),
    name: "Polyclinique Atlas",

    tel: "05 23 48 87 25",


    address: "AV HASSAN II CITE BELLE VUE BENI MELLAL Béni-Mellal 23030",

    type: 2,
    lng: -6.3607861,
    province:"Marrakech", lat: 32.3306622
  },







  {
    id: uuid.v1(),
    name: "Clinique Multidisciplinaire Jabrane",

    tel: "05 23 48 68 67",


    address: "QUARTIER SOUMIA Béni-Mellal 23000",

    type: 2,
    lng: -6.3741526,
    province:"Marrakech", lat: 32.3271178
  },







  {
    id: uuid.v1(),
    name: "Clinique Multidisciplinaire Benhessou",

    tel: "05 23 48 56 30",


    address: "LOTISS CHEMS 1 LOT 4 HAY CHARAF Béni-Mellal 23040",

    type: 2,
    lng: -6.3797082,
    province:"Marrakech", lat: 32.3280704
  },







  {
    id: uuid.v1(),
    name: "Clinique Alamal",

    tel: "05 23 48 98 88",


    address: "98 RUE ATTAIF Q MODERNE Béni-Mellal 23000",

    type: 2,
    lng: -6.359686421623,
    province:"Marrakech", lat: 32.333033324293
  },







  {
    id: uuid.v1(),
    name: "Clinique Al Adarissa",

    tel: "05 23 42 02 42",


    address: "10 RUE DE FES QUARTIER AL ADARISSA Béni-Mellal 23040",

    type: 2,
    lng: -6.384412,
    province:"Marrakech", lat: 32.3283928
  },







  {
    id: uuid.v1(),
    name: "Polyclinique de Benslimane",

    tel: "05 23 29 85 94",


    address: "2 AV PRINCE MLY ABDELLAH Benslimane 13000",

    type: 2,
    lng: -7.6259975,
    province:"Marrakech", lat: 33.57932
  },







  {
    id: uuid.v1(),
    name: "Polyclinique Hatim",

    tel: "05 36 61 11 44",


    address: "1 RUE ZEGZEL BERKANE Berkane 63323",

    type: 2,
    lng: -6.1116372,
    province:"Marrakech", lat: 34.2348454
  },







  {
    id: uuid.v1(),
    name: "Polyclinique Notre Dame",

    tel: "05 22 27 44 34",


    address: "206 AVENUE MERS SULTAN QUARTIER CENTRE VILLE Casablanca 20140",

    type: 2,
    lng: -7.6157409,
    province:"Marrakech", lat: 33.5841974
  },







  {
    id: uuid.v1(),
    name: "Polyclinique Mutualiste Benkiran",

    tel: "05 22 20 32 98",


    address: "7 RUE EL HOUCINE BEN ALI EX MURDOCH QUARTIER LUSITANIA Casablanca 20070",

    type: 2,
    lng: -7.6244905,
    province:"Marrakech", lat: 33.5933115
  },







  {
    id: uuid.v1(),
    name: "Polyclinique Firdaous",

    tel: "05 22 76 73 73",


    address: "BD MOHAMED EL BAAMRANI EX M CITE CHABAB QUARTIER SIDI BERNOUSSI Casablanca 20605",

    type: 2,
    lng: -7.519446,
    province:"Marrakech", lat: 33.6050304
  },







  {
    id: uuid.v1(),
    name: "Polyclinique de la CNSS Ziraoui",

    statut: "CNSS",


    tel: "05 22 20 38 56",


    address: "BD ZIRAOUI ANGRUE GENERAL HENRYS QUARTIER BOURGOGNE Casablanca 20040",

    type: 2,
    lng: -7.6380998,
    province:"Marrakech", lat: 33.594662
  },







  {
    id: uuid.v1(),
    name: "Polyclinique de la CNSS Sidi Bernoussi",

    statut: "CNSS",


    tel: "05 22 73 16 20",


    address: "HAY EL QODS QUARTIER SIDI BERNOUSSI Casablanca 20600",

    type: 2,
    lng: -7.5030564,
    province:"Marrakech", lat: 33.610795
  },







  {
    id: uuid.v1(),
    name: "Polyclinique de la CNSS Inara",

    statut: "CNSS",


    tel: "05 22 21 30 90",


    address: "BD EL QODS INARA II QUARTIER AEN CHOK Casablanca 20480",

    type: 2,
    lng: -7.5992903,
    province:"Marrakech", lat: 33.5406611
  },







  {
    id: uuid.v1(),
    name: "Polyclinique de la CNSS Hay El Hassani",

    statut: "CNSS",


    tel: "05 22 90 83 63",


    address: "BD OUED OUM ERRABIA QUARTIER OULFA Casablanca 20220",

    type: 2,
    lng: -7.6704754,
    province:"Marrakech", lat: 33.5671468
  },







  {
    id: uuid.v1(),
    name: "Polyclinique de la CNSS Derb Ghalef",

    statut: "CNSS",


    tel: "05 22 86 30 21",


    address: "RUE DE LA SECURITE SOCIALE EX JULES GREVY QUARTIER ERMITAGE HEPITAUX Casablanca 20360",

    type: 2,
    lng: -7.6235544,
    province:"Marrakech", lat: 33.5707252
  },







  {
    id: uuid.v1(),
    name: "Orthopedie Rabatrome",

    tel: "06 26 41 06 06",


    address: "2LT MADINATI GH 05 NR04 AHL LGHLAM AL AZHAR SIDI BERNOUSSI Casablanca 20605",

    type: 2,
    lng: -7.496303,
    province:"Marrakech", lat: 33.5931525
  },







  {
    id: uuid.v1(),
    name: "Oncologie Zerktouni",

    tel: "05 22 29 97 39",


    address: "296 BD MOHAMED ZERKTOUNI 2AET QUARTIER BOURGOGNE Casablanca 20040",

    type: 2,
    lng: -7.6229125,
    province:"Marrakech", lat: 33.5824944
  },







  {
    id: uuid.v1(),
    name: "Neuroclinique de Casablanca",

    tel: "05 22 82 11 11",


    address: "RUE DE L ISERE ANG RUE NA2 Q BAGATELLE QUARTIER POLO Casablanca 20420",

    type: 2,
    lng: -7.6260014,
    province:"Marrakech", lat: 33.5576087
  },







  {
    id: uuid.v1(),
    name: "Clinique Zerktouni d&apos;Orthopédie Traumatologie",

    tel: "05 22 25 33 00",


    address: "BD DU 9 AVRIL EX CL PERRAULT ANG BD MED ABDOU QUARTIER PALMIER Casablanca 20340",

    type: 2,
    lng: -7.6303167,
    province:"Marrakech", lat: 33.5821068
  },







  {
    id: uuid.v1(),
    name: "Clinique Yasmine",

    tel: "05 22 39 69 60",


    address: "BD SIDI ABDERRAHMAN HAY ELHANA QUARTIER HAY EL HANA Casablanca 20200",

    type: 2,
    lng: -7.6742311,
    province:"Marrakech", lat: 33.5757564
  },







  {
    id: uuid.v1(),
    name: "Clinique Villa Des Lilas",

    tel: "05 22 77 66 65",


    address: "88 ROUTE DE L OASIS QUARTIER OASIS Casablanca 20103",

    type: 2,
    lng: -7.6546445,
    province:"Marrakech", lat: 33.5603157
  },







  {
    id: uuid.v1(),
    name: "Clinique Spécialisée Le Littoral",

    tel: "05 20 66 68 90",


    address: "BD SIDI ABDERRAHMAN EX GDE CEINTURE SALAJ 3 QUARTIER AEN DIAB Casablanca 20180",

    type: 2,
    lng: -7.6771886,
    province:"Marrakech", lat: 33.5810195
  },







  {
    id: uuid.v1(),
    name: "Clinique Alfarabi",

    tel: "05 22 47 30 30",


    address: "7 RUE JEAN JAURES QUARTIER GAUTIER Casablanca 20000",

    type: 2,
    lng: -7.629236,
    province:"Marrakech", lat: 33.5892119
  },







  {
    id: uuid.v1(),
    name: "Clinique Sidi Othman",

    tel: "05 22 59 75 75",


    address: "AVENUE DU DIX MARS PLACE DE LA PREFECTURE QUARTIER SIDI OTHMAN Casablanca 20670",

    type: 2,
    lng: -7.5637999,
    province:"Marrakech", lat: 33.5598819
  },







  {
    id: uuid.v1(),
    name: "Clinique Sarah",

    tel: "05 22 94 51 60",


    address: "255 BD SIDI ABDERRAHMAN QUARTIER CIL Casablanca 20210",

    type: 2,
    lng: -7.6690909,
    province:"Marrakech", lat: 33.570395
  },







  {
    id: uuid.v1(),
    name: "Clinique Racine",

    tel: "05 22 94 22 66",


    address: "RUE EL JIHANI ANG BD EL MASSIRA EL KHADRA QUARTIER RACINE Casablanca 20100",

    type: 2,
    lng: -7.6391106,
    province:"Marrakech", lat: 33.5869231
  },







  {
    id: uuid.v1(),
    name: "Clinique Rachidi",

    tel: "05 22 29 48 36",


    address: "43 BD RACHIDI QUARTIER LUSITANIA Casablanca 20000",

    type: 2,
    lng: -7.6302007,
    province:"Marrakech", lat: 33.5934137
  },







  {
    id: uuid.v1(),
    name: "Clinique Mansouri",

    tel: "05 22 20 30 43",


    address: "105 BD D ANFA QUARTIER GAUTIER Casablanca 20060",

    type: 2,
    lng: -7.6406988,
    province:"Marrakech", lat: 33.5795831
  },







  {
    id: uuid.v1(),
    name: "Clinique Maghreb",

    tel: "05 22 99 09 90",


    address: "70 RUE CADI IASS EX MONT CENIS MAERIF QUARTIER MAARIF Casablanca 20100",

    type: 2,
    lng: -7.6448857,
    province:"Marrakech", lat: 33.5849273
  },







  {
    id: uuid.v1(),
    name: "Clinique l&apos;Hermitage",

    tel: "05 22 86 55 66",


    address: "35 BD ANOUAL EX FELIX FAURE QUARTIER ERMITAGE HEPITAUX Casablanca 20360",

    type: 2,
    lng: -7.6163759,
    province:"Marrakech", lat: 33.568914
  },







  {
    id: uuid.v1(),
    name: "Clinique Ghandi",

    tel: "05 22 36 74 05",


    address: "54 BD GHANDI QUARTIER HAY EL HANA Casablanca 20200",

    type: 2,
    lng: -7.6590834,
    province:"Marrakech", lat: 33.5797831
  },







  {
    id: uuid.v1(),
    name: "Clinique France Ville",

    tel: "08 02 00 32 32",


    address: "393 BD GHANDI ANG RUE MED FOUITEH QUARTIER DERB GHALEF FRANCE VILLE Casablanca 20340",

    type: 2,
    lng: -7.6332815,
    province:"Marrakech", lat: 33.5640276
  },







  {
    id: uuid.v1(),
    name: "Clinique du Val D&apos;anfa",

    tel: "05 22 36 87 87",


    address: "19 BD FRANKLIN ROOSEVELT QUARTIER ANFA SUPERIEUR Casablanca 20050",

    type: 2,
    lng: -7.655047,
    province:"Marrakech", lat: 33.5900336
  },







  {
    id: uuid.v1(),
    name: "Clinique du Palmier",

    tel: "05 22 25 27 24",


    address: "6 RUE SARIA BEN ZOUNAIM EX LOUIS DAVID QUARTIER PALMIER Casablanca 20340",

    type: 2,
    lng: -7.6285189,
    province:"Marrakech", lat: 33.5811326
  },







  {
    id: uuid.v1(),
    name: "Clinique des Princes",

    tel: "05 22 27 52 50",


    address: "14 RUE OMAR SLAOUI QUARTIER CENTRE VILL Casablanca 20130",

    type: 2,
    lng: -7.6217921,
    province:"Marrakech", lat: 33.5879028
  },







  {
    id: uuid.v1(),
    name: "Clinique de l&apos;Oeil de Casablanca",

    tel: "05 22 86 46 18",


    address: "34 RUE MOHAMED EL YAZIDI EX RHODES QUARTIER ERMITAGE HEPITAUX Casablanca 20360",

    type: 2,
    lng: -7.6289618,
    province:"Marrakech", lat: 33.5483427
  },







  {
    id: uuid.v1(),
    name: "Clinique Dar Salam",

    tel: "05 22 85 14 14",


    address: "728 BD MODIBO KEITA QUARTIER POLO Casablanca 20550",

    type: 2,
    lng: -7.6508788,
    province:"Marrakech", lat: 33.5549047
  },







  {
    id: uuid.v1(),
    name: "Clinique CIL",

    tel: "05 22 36 00 06",


    address: "367 BD SIDI ABDERRAHMAN QUARTIER CIL Casablanca 20210",

    type: 2,
    lng: -7.6690832,
    province:"Marrakech", lat: 33.5704491
  },







  {
    id: uuid.v1(),
    name: "Clinique Cardiologie Californie",

    tel: "05 22 52 55 55",


    address: "545 BD PANORAMIQUE QUARTIER CALIFORNIE Casablanca 20150",

    type: 2,
    lng: -7.6331513,
    province:"Marrakech", lat: 33.546735
  },







  {
    id: uuid.v1(),
    name: "Clinique Anoual",

    tel: "05 22 86 02 07",


    address: "14 RUE ZAKI EDDINE TAOUSSI EX CHEOPS QUARTIER ERMITAGE HOPITAUX Casablanca 20100",

    type: 2,
    lng: -7.6196021,
    province:"Marrakech", lat: 33.5698356
  },







  {
    id: uuid.v1(),
    name: "Clinique Andalouss",

    tel: "05 22 36 27 27",


    address: "19 BD DRISS SLAOUI EX TEMARA QUARTIER BOURGOGNE Casablanca 20160",

    type: 2,
    lng: -7.6568701,
    province:"Marrakech", lat: 33.596264
  },







  {
    id: uuid.v1(),
    name: "Clinique Alamine",

    tel: "05 22 86 36 36",


    address: "73 RUE IBN JALJAL EX GENERAL FERRIE QUARTIER ERMITAGE HOPITAUX Casablanca 20360",

    type: 2,
    lng: -7.6204021,
    province:"Marrakech", lat: 33.5703928
  },







  {
    id: uuid.v1(),
    name: "Clinique Al Massira",

    tel: "05 22 39 40 41",


    address: "5 RUE AHMED EL MOKRI EX AUTEUIL QUARTIER RACINE Casablanca 20050",

    type: 2,
    lng: -7.6384961,
    province:"Marrakech", lat: 33.5906839
  },







  {
    id: uuid.v1(),
    name: "Clinique Ain Chock",

    tel: "05 22 21 21 88",


    address: "510 AVENUE DU DEUX MARS QUARTIER AIN CHOCK Casablanca 20460",

    type: 2,
    lng: -7.6024396,
    province:"Marrakech", lat: 33.5491014
  },







  {
    id: uuid.v1(),
    name: "Clinique Adoha",

    tel: "05 22 66 42 65",


    address: "QUARTIER BEAUSITE LOT KAMALIA NA1 QUARTIER AIN SEBAA Casablanca 20250",

    type: 2,
    lng: -7.5387063,
    province:"Marrakech", lat: 33.5994517
  },







  {
    id: uuid.v1(),
    name: "Centre Laser Dermatologique Puvatherapie",

    tel: "05 22 48 49 50",


    address: "35 RUE DE L IMAM EL ALOUSSI CO CLINIQUE BADR QUARTIER RACINE Casablanca 20050",

    type: 2,
    lng: -7.6412059,
    province:"Marrakech", lat: 33.5953615
  },







  {
    id: uuid.v1(),
    name: "Centre de Radiologie Sidi Mâarouf",

    tel: "05 22 97 74 00",


    address: "LOTISS LINA NA147 SIDI MAEROUF QUARTIER SIDI MAAROUF Casablanca 20280",

    type: 2,
    lng: -7.6469355,
    province:"Marrakech", lat: 33.521939
  },







  {
    id: uuid.v1(),
    name: "Centre de Nerphrologie Hémodialyse Avicenne",

    tel: "05 22 99 49 92",


    address: "BD DU 9 AVRIL EX CL PERRAULT ANG RUE MANSARD QUARTIER PALMIER Casablanca 20340",

    type: 2,
    lng: -7.629223,
    province:"Marrakech", lat: 33.581469
  },







  {
    id: uuid.v1(),
    name: "CENTRE INTERNE DE MEDECINE DU TRAVAIL CIMT",

    tel: "05 22 31 48 99",


    address: "13 RUE MELOUIA EX LAPEBIE QUARTIER CENTRE VILLE 20000 CASABLANCA Casablanca 20000",

    type: 2,
    lng: -7.6200321,
    province:"Marrakech", lat: 33.5882854
  },







  {
    id: uuid.v1(),
    name: "Centre de Lithotripsie Romandie",

    tel: "05 22 94 06 56",


    address: "19 BD IBN SINA EX AVICENNE QUARTIER MAARIF Casablanca 20370",

    type: 2,
    lng: -7.6504621,
    province:"Marrakech", lat: 33.584548
  },







  {
    id: uuid.v1(),
    name: "Centre de Fertilité de Casablanca",

    tel: "05 22 25 13 29",


    address: "21 RUE ABOU ABDELLAH NAFII EX BOURBON RESID BEN OMAR 2AET QUARTIER MAARIF Casablanca 20330",

    type: 2,
    lng: -7.6412355,
    province:"Marrakech", lat: 33.5835751
  },







  {
    id: uuid.v1(),
    name: "Centre de Chirurgie Oculaire Ambulatoire",province:"Marrakech",

    tel: "05 22 22 36 31",


    address: "3 RUE CHERIF AMEZIANE EX BRANLY 3AET QUARTIER CENTRE VILLE Casablanca 20080",

    type: 2,
    lng: -7.6564517,
    lat: 33.5894966
  },







  {
    id: uuid.v1(),
    name: "Centre d&apos;Ophtalmologie Bir Anzarane",

    tel: "05 22 25 26 20",


    address: "1 BD BIR ANZARANE ANG RUE IBNOU SOURAIJ RESID GHITA QUARTIER MAARIF Casablanca 20330",

    type: 2,
    lng: -7.6380506,
    province:"Marrakech", lat: 33.5811261
  },







  {
    id: uuid.v1(),
    name: "Centre d&apos;Hemodialyse et Nephrologie Hosni",

    tel: "05 22 40 07 56",


    address: "126 BD D OUJDA QUARTIER BELVEDERE Casablanca 20300",

    type: 2,
    lng: -7.5935601,
    province:"Marrakech", lat: 33.5896887
  },







  {
    id: uuid.v1(),
    name: "Centre d&apos;explorations Cardio Vasculaires",

    tel: "05 22 94 38 96",


    address: "237 BD MOHAMED ZERKTOUNI ANG BD D ANFA RESID KHEIR QUARTIER RACINE Casablanca 20100",

    type: 2,
    lng: -7.6359078,
    province:"Marrakech", lat: 33.5906251
  },







  {
    id: uuid.v1(),
    name: "Centre d&apos;explorations Cario Vasculaire Les Hôpitaux",

    tel: "05 22 86 33 66",


    address: "4 RUE TARIK BNOU ZIAD Q DES HEPITAUX QUARTIER HEPITAUX Casablanca 20360",

    type: 2,
    lng: -7.615466,
    province:"Marrakech", lat: 33.577068
  },







  {
    id: uuid.v1(),
    name: "CABINET D ONCOLOGIE PASTEUR",

    tel: "05 22 48 66 73",


    address: "173 BD ABDELMOUMEN RESID ALYAMAMA QUARTIER HEPITAUX 20100 CASABLANC Casablanca 20100",

    type: 2,
    lng: -7.6279241,
    province:"Marrakech", lat: 33.574466
  },







  {
    id: uuid.v1(),
    name: "Polyclinique de la CNSS El Jadida",

    statut: "CNSS",


    tel: "05 23 39 35 80",


    address: "RTE SIDI BOUZID El Jadida 24030",

    type: 2,
    lng: -8.5193758,
    province:"Marrakech", lat: 33.2464885
  },







  {
    id: uuid.v1(),
    name: "Clinique Oum Errabia",

    tel: "05 23 35 17 00",


    address: "28 AV ELMOUKAOUAMA El Jadida 24000",

    type: 2,
    lng: -8.5016465,
    province:"Marrakech", lat: 33.2435687
  },







  {
    id: uuid.v1(),
    name: "Clinique Doukkala",

    tel: "05 23 34 31 72",


    address: "146 AV HASSAN II El Jadida 24000",

    type: 2,
    lng: -8.507809,
    province:"Marrakech", lat: 33.24916
  },







  {
    id: uuid.v1(),
    name: "Polyclinique de Fés",

    tel: "05 35 62 44 68",


    address: "BD CHEFCHAOUNI Fés 30000",

    type: 2,
    lng: -7.443549,
    province:"Marrakech", lat: 33.4795895
  },







  {
    id: uuid.v1(),
    name: "Clinique Zalagh",

    tel: "05 35 62 30 30",


    address: "26 RUE MOUSSA IBN NOUSSAIR Fés 30000",

    type: 2,
    lng: -7.6414976,
    province:"Marrakech", lat: 33.5478784
  },







  {
    id: uuid.v1(),
    name: "Clinique Ryad Fés",

    tel: "05 35 96 00 00",


    address: "2 RUE BENZAKOUR Fés 30100",

    type: 2,
    lng: -4.9988994,
    province:"Marrakech", lat: 34.0318756
  },







  {
    id: uuid.v1(),
    name: "Clinique Alkawtar",

    tel: "05 35 61 19 00",


    address: "AV MOHAMED ELFASSI RTE D IMOUZZER Fés 30050",

    type: 2,
    lng: -4.9998378,
    province:"Marrakech", lat: 34.0143997
  },







  {
    id: uuid.v1(),
    name: "Clinique Agdal",

    tel: "05 35 93 16 33",


    address: "AV PRINCE HERITIER JNANE MY ELKAMEL Fés 30010",

    type: 2,
    lng: -5.0092206,
    province:"Marrakech", lat: 34.035532
  },







  {
    id: uuid.v1(),
    name: "Polyclinique du Gharb",

    tel: "05 37 37 65 65",


    address: "148 AV MOHAMMED DIOURI Kénitra 14000",

    type: 2,
    lng: -6.5826957,
    province:"Marrakech", lat: 34.2560639
  },







  {
    id: uuid.v1(),
    name: "Polyclinique de la CNSS Kénitra",

    statut: "CNSS",


    tel: "05 37 37 87 38",


    address: "PLACE MOULAY YOUSSEF Kénitra 14010",

    type: 2,
    lng: -6.57094,
    province:"Marrakech", lat: 34.2653299
  },







  {
    id: uuid.v1(),
    name: "Polycljnique de Kénitra",

    tel: "05 37 37 36 35",


    address: "RUE DE L HOPITAL IDRISSI Kénitra -",

    type: 2,
    lng: -6.5745625,
    province:"Marrakech", lat: 34.2520625
  },







  {
    id: uuid.v1(),
    name: "Polyclinique à Kénitra",

    tel: "05 37 36 03 31",


    address: "1 RUE DE LA MOSQUEE Kénitra 14000",

    type: 2,
    lng: -6.5720564,
    province:"Marrakech", lat: 34.2626407
  },







  {
    id: uuid.v1(),
    name: "Clinique Ibn Sina",

    tel: "06 50 08 97 97",


    address: "AVENUE 2 MARS BIR RAMI Kénitra -",

    type: 2,
    lng: -6.6019773,
    province:"Marrakech", lat: 34.2485298
  },







  {
    id: uuid.v1(),
    name: "Polyclinique Al Amana",

    tel: "05 37 55 22 11",


    address: "AV KHALID IBN EL WALID Q HAKAMAT Khémissat 15000",

    type: 2,
    lng: -6.0622502,
    province:"Marrakech", lat: 33.830882
  },







  {
    id: uuid.v1(),
    name: "Clinique du Croissant Rouge Marocain",

    tel: "05 39 91 58 53",


    address: "1 AV DU PORT Larache -",

    type: 2,
    lng: -6.1482859,
    province:"Marrakech", lat: 35.1961481
  },







  {
    id: uuid.v1(),
    name: "Clinique Sabae Massira",

    tel: "05 28 89 17 00",


    address: "38 BD MEKKA HAY ESSAEDA Lâayoune 70010",

    type: 2,
    lng: -13.2133224,
    province:"Marrakech", lat: 27.1567625
  },







  {
    id: uuid.v1(),
    name: "Polyclinique Marrakesh Narcisses",

    tel: "05 24 44 75 75",


    address: "112 QUARTIER OLM CAMP EL GHOUL ROUTE DE TARGA Marrakesh 40084",

    type: 2,
    lng: -8.01991,
    province:"Marrakech", lat: 31.640223
  },







  {
    id: uuid.v1(),
    name: "Polyclinique Marrakesh El Massira",

    tel: "05 24 49 82 88",


    address: "LOTISSEMENT MASSIRA 1D Marrakesh 40000",

    type: 2,
    lng: -8.0567738,
    province:"Marrakech", lat: 31.637317
  },







  {
    id: uuid.v1(),
    name: "Polyclinique La Koutoubia",

    tel: "05 24 43 85 86",


    address: "RUE DE PARIS RESID KOUTOUBIA HIVERNAGE Marrakesh 40020",

    type: 2,
    lng: -8.0041408,
    province:"Marrakech", lat: 31.6257779
  },







  {
    id: uuid.v1(),
    name: "Polyclinique du Sud SCP",

    tel: "05 24 44 76 19",


    address: "2 RUE YOUGOSLAVIE ANG RUE IBN AECHA GUELIZ Marrakesh 40000",

    type: 2,
    lng: -8.0172317,
    province:"Marrakech", lat: 31.6381711
  },







  {
    id: uuid.v1(),
    name: "Clinique Sabae Massira",

    tel: "05 24 34 70 51",


    address: "RTE DE TARGA Marrakesh 40000",

    type: 2,
    lng: -8.0279974,
    province:"Marrakech", lat: 31.6529125
  },







  {
    id: uuid.v1(),
    name: "Clinique l&apos;Aéroport Marrakesh",

    tel: "05 29 80 38 03",


    address: "394 AVENUE GMASSA ROUTE DE L AEROPORT Marrakesh 41223",

    type: 2,
    lng: -7.996907,
    province:"Marrakech", lat: 31.5955703
  },







  {
    id: uuid.v1(),
    name: "Clinique Internationale de Marrakesh",

    tel: "05 24 44 40 40",


    address: "RTE DE L AEROPORT BAB IGHLI Marrakesh 40160",

    type: 2,
    lng: -7.6439717,
    province:"Marrakech", lat: 31.61311
  },







  {
    id: uuid.v1(),
    name: "Clinique Dalil",

    tel: "05 24 43 69 59",


    address: "5 RUE SOURYA IMM RACHADI GUELIZ Marrakesh 40000",

    type: 2,
    lng: -7.617592,
    province:"Marrakech", lat: 33.5493191
  },







  {
    id: uuid.v1(),
    name: "Clinique Chifa",

    tel: "05 24 43 30 06",


    address: "4 RUE IBN TOUMERT GUELIZ Marrakesh 40000",

    type: 2,
    lng: -9.2430587,
    province:"Marrakech", lat: 32.2938045
  },







  {
    id: uuid.v1(),
    name: "Centre Ophtalmologique Al Mouna",

    tel: "05 24 43 97 42",


    address: "16 RUE ABDELWAHAB DERRAQ Q DE L HEPITAL IBNOU TOFAIL Marrakesh 40000",

    type: 2,
    lng: -8.0174319,
    province:"Marrakech", lat: 31.6406879
  },







  {
    id: uuid.v1(),
    name: "Centre de Fertilité Injab",

    tel: "05 24 43 96 94",


    address: "RUE IBN TOUMERT CO CLINIQUE YASMINE Marrakesh 40000",

    type: 2,
    lng: -8.0084273606031,
    province:"Marrakech", lat: 31.638522928117
  },







  {
    id: uuid.v1(),
    name: "Polyclinique Zerhoune",

    tel: "05 35 52 02 62",


    address: "22 ESPLANADE DU DOCTEUR GIGUET Meknès 50000",

    type: 2,
    lng: -5.5523631,
    province:"Marrakech", lat: 33.9055193
  },







  {
    id: uuid.v1(),
    name: "Polyclinique Saiss",

    tel: "05 35 51 07 06",


    address: "56 AV BIR ANZARANE Q ABBAS LAMSAEDE RTE ALHAJEB Meknès 80650",

    type: 2,
    lng: -5.5424469,
    province:"Marrakech", lat: 33.8878557
  },







  {
    id: uuid.v1(),
    name: "Polyclinique Kendouci",

    tel: "05 35 52 12 48",


    address: "RUE DE BANDOENG ANG RUE NEHRU Meknès 50000",

    type: 2,
    lng: -5.52885,
    province:"Marrakech", lat: 33.88802
  },







  {
    id: uuid.v1(),
    name: "Polyclinique Ibn Rochd",

    tel: "05 35 51 52 62",


    address: "BD BIR ANZARANE ROUTE D ELHAJEB Meknès 50000",

    type: 2,
    lng: -5.5471011,
    province:"Marrakech", lat: 33.8914742
  },







  {
    id: uuid.v1(),
    name: "Polyclinique Ibn Nafis",

    tel: "05 35 52 48 18",


    address: "12 RUE FERHAT HACHAD Meknès 50000",

    type: 2,
    lng: -5.5561839117923,
    province:"Marrakech", lat: 33.902045093316
  },







  {
    id: uuid.v1(),
    name: "Polyclinique ElMenzeh",

    tel: "05 35 40 03 30",


    address: "10 AV NATIONS UNIES ROUTE DE MY DRISS Meknès -",

    type: 2,
    lng: -5.5540989,
    province:"Marrakech", lat: 33.8990955
  },







  {
    id: uuid.v1(),
    name: "Polyclinique Beauséjour",

    tel: "05 35 40 00 40",


    address: "QUARTIER ABBAS LAMSSADI RUE 1 NA56 RTE EL HAJEB Meknès 50000",

    type: 2,
    lng: -5.542453,
    province:"Marrakech", lat: 33.8874652
  },







  {
    id: uuid.v1(),
    name: "Polyclinique Alaoui",

    tel: "05 35 40 02 20",


    address: "11 RUE EL MAARI Meknès -",

    type: 2,
    lng: -5.5473259,
    province:"Marrakech", lat: 33.9016218
  },







  {
    id: uuid.v1(),
    name: "Polyclinique de la CNSS Mohammédia",

    statut: "CNSS",


    tel: "05 23 32 47 60",


    address: "HAY INARA 1 Mohammédia 28810",

    type: 2,
    lng: -7.3983541,
    province:"Marrakech", lat: 33.7010211
  },







  {
    id: uuid.v1(),
    name: "Clinique spécialisée Mohammédia Ophtalmologie",

    tel: "05 23 32 90 02",


    address: "AV HASSAN II LOT SANIA NA33 Mohammédia 28800",

    type: 2,
    lng: -7.3864709,
    province:"Marrakech", lat: 33.6994452
  },







  {
    id: uuid.v1(),
    name: "Polyclinique Taouima",

    tel: "05 36 60 59 07",


    address: "LOTISS AL BOUSTANE RTE TAOUIMA Nador 62026",

    type: 2,
    lng: -2.9289199,
    province:"Marrakech", lat: 35.1386332
  },







  {
    id: uuid.v1(),
    name: "Polyclinique du Rif",

    tel: "05 36 60 02 03",


    address: "AV SAKIA ELHAMRA Nador 11040",

    type: 2,
    lng: -2.921873,
    province:"Marrakech", lat: 35.1712383
  },







  {
    id: uuid.v1(),
    name: "Polyclinique du Nord",

    tel: "05 36 60 74 90",


    address: "RTE DE TAOUIMA Nador 62026",

    type: 2,
    lng: -2.933335,
    province:"Marrakech", lat: 35.1610784
  },







  {
    id: uuid.v1(),
    name: "Polyclinique Alwahda",

    tel: "06 91 57 57 94",


    address: "OULAD BOUTAYEB RTE ZGHANGHAN Nador 62650",

    type: 2,
    lng: -2.9474277,
    province:"Marrakech", lat: 35.1682824
  },







  {
    id: uuid.v1(),
    name: "Polyclinique Al Mataar",

    tel: "05 36 60 63 63",


    address: "LOTISSEMENT ONDA Nador 62000",

    type: 2,
    lng: -2.9251682,
    province:"Marrakech", lat: 35.1808336
  },







  {
    id: uuid.v1(),
    name: "Polyclinique de la CNSS Oujda",

    statut: "CNSS",


    tel: "05 36 68 60 13",


    address: "BD ECHOUHADA Oujda 60000",

    type: 2,
    lng: -1.9138372,
    province:"Marrakech", lat: 34.6703418
  },







  {
    id: uuid.v1(),
    name: "Clinique Isly",

    tel: "05 36 70 07 02",


    address: "12 BD ABDELAZIZ TAALABI OUED NACHEF Oujda 60040",

    type: 2,
    lng: -1.9278595,
    province:"Marrakech", lat: 34.6731737
  },







  {
    id: uuid.v1(),
    name: "Polyclinique Internationale de Rabat",

    tel: "05 37 20 61 61",


    etat: "En cours",


    note: "Polyclinique Internationale de Raba",


    address: "8 RUE DE TUNIS Rabat 10020",

    type: 2,
    lng: -6.8230434,
    province:"Marrakech", lat: 34.020594
  },







  {
    id: uuid.v1(),
    name: "Hôpital Universitaire International Cheikh Zeid",

    tel: "05 37 68 68 68",


    address: "CITE AL IRFANE HAY RYAD AV ALLAL ALFASSI BP 6533 Rabat 10000",

    type: 2,
    lng: -6.8689711,
    province:"Marrakech", lat: 33.9778722
  },







  {
    id: uuid.v1(),
    name: "Clinique Nations Unies",

    tel: "05 37 67 05 05",


    etat: "Validation créneau",


    note: "Demande rappel Lundi 19 Avril pour fixer le RDV",


    address: "RUE IBN HANBAL AV NATIONS UNIES Rabat 10000",

    type: 2,
    lng: -6.8490207,
    province:"Marrakech", lat: 33.9894155
  },







  {
    id: uuid.v1(),
    name: "Clinique Le Jasmin",

    tel: "05 37 73 42 43",


    etat: "En cours",


    note: "Resp en congé, reprend le 25 Avril",


    address: "23 RUE D OUJDA HASSAN Rabat 10020",

    type: 2,
    lng: -6.8238818,
    province:"Marrakech", lat: 34.0180079
  },







  {
    id: uuid.v1(),
    name: "Clinique La Menara",

    tel: "05 37 70 74 74",


    address: "10 RUE MOULAY ABD AL AZIZ Rabat 10010",

    type: 2,
    lng: -6.8301549,
    province:"Marrakech", lat: 34.0146893
  },







  {
    id: uuid.v1(),
    name: "Clinique La Capitale",

    tel: "05 37 76 72 76",


    address: "46 AVENUE DU CHELLAH EX ABDERRAHMANE ANNEGAY Rabat 10010",

    type: 2,
    lng: -6.8282794,
    province:"Marrakech", lat: 34.0168838
  },







  {
    id: uuid.v1(),
    name: "Clinique Ibno Rochd",

    tel: "05 37 67 24 21",


    address: "18 BD IBN ROCHD Rabat 10180",

    type: 2,
    lng: -6.8532454,
    province:"Marrakech", lat: 33.9879732
  },







  {
    id: uuid.v1(),
    name: "Clinique Khachani",

    tel: "05 37 76 37 19",


    address: "58 AVENUE DU CHELLAH EX ABDERRAHMANE ANNEGAY Rabat 10010",

    type: 2,
    lng: -6.827213,
    province:"Marrakech", lat: 34.0156655
  },







  {
    id: uuid.v1(),
    name: "Clinique Benjelloun",

    tel: "05 37 76 68 98",


    etat: "En cours",


    note: "Resp en réunion externe / Rappel le 14 Avril",


    address: "11 AVENUE AHMED LYAZIDI EX MEKNES Rabat 10010",

    type: 2,
    lng: -6.8237785,
    province:"Marrakech", lat: 34.0153708
  },







  {
    id: uuid.v1(),
    name: "Clinique du Bou Regreg",

    tel: "05 37 72 25 46",


    etat: "En cours",


    note: "Resp absent / Dispo 14/04 à 11h",


    address: "21 RUE DE TAZA HASSAN Rabat 10020",

    type: 2,
    lng: -6.8234853,
    province:"Marrakech", lat: 34.0208684
  },







  {
    id: uuid.v1(),
    name: "Clinique Des Zaers",

    tel: "05 37 65 65 65",


    etat: "En cours",


    note: "rappel le 15/04",


    address: "BD MOHAMMED VI EX JOHN KENNEDY ANG RUE SEKOUR Rabat 10180",

    type: 2,
    lng: -6.8271225,
    province:"Marrakech", lat: 34.0021124
  },







  {
    id: uuid.v1(),
    name: "Clinique De Chellah",

    tel: "05 37 72 34 76",


    leadership: "Responsable d&apos;Achat",


    prenom: "Laila",


    rdv: "Mardi 19 Avril à 14h00",


    etat: "RDV Validé",


    potentiel: "11 Lits Maximum",


    address: "18 RUE D ANNABA Rabat 10020",

    type: 2,
    lng: -6.8297504,
    province:"Marrakech", lat: 34.0165476
  },







  {
    id: uuid.v1(),
    name: "Clinique d&apos;Ophtalmologie Rafi",

    tel: "05 37 66 03 89",


    address: "70 AVENUE DU CHELLAH EX ABDERRAHMANE ANNEGAY Rabat 10010",

    type: 2,
    lng: -6.827323,
    province:"Marrakech", lat: 34.016004
  },







  {
    id: uuid.v1(),
    name: "Clinique Chirurgie Cardio Vasculaire",

    tel: "05 37 77 90 90",


    mail: "ccva.rabat@gmail.com",


    etat: "Mail Contact",


    note: "Demande de lui adresser un E-mail",


    address: "6 PLACE TALHA AV IBN SINA Rabat 10080",

    type: 2,
    lng: -6.851416,
    province:"Marrakech", lat: 33.991356
  },







  {
    id: uuid.v1(),
    name: "Clinique Belvedere",

    tel: "05 37 77 89 89",


    prenom: "Soulaimane",


    mail: "centrecardiorabat@yahoo.fr",


    etat: "Mail Contact",


    note: "Demande de lui adresser un E-mail",


    address: "1 AVENUE IBN KHALDOUN AGDAL Rabat 10090",

    type: 2,
    lng: -6.841613,
    province:"Marrakech", lat: 34.0107373
  },







  {
    id: uuid.v1(),
    name: "Clinique Alboustane",

    tel: "05 37 73 55 35",


    address: "7 RUE DESCARTES LES ORANGERS Rabat 10060",

    type: 2,
    lng: -6.8398026,
    province:"Marrakech", lat: 34.0160624
  },







  {
    id: uuid.v1(),
    name: "Clinique Chirurgicale Ghazlane",

    tel: "05 24 62 34 80 / 05 24 61 03 55",


    address: "35 AV DE LA LIBERTE VN Safi 46000",

    type: 2,
    lng: -9.2307385,
    province:"Marrakech", lat: 32.2897249
  },







  {
    id: uuid.v1(),
    name: "Clinique Abda",

    tel: "05 24 61 01 49",


    address: "RUE ALLAL ILLANE ANG ABDELOUHAB DERRAK Safi -",

    type: 2,
    lng: -9.233919,
    province:"Marrakech", lat: 32.291809
  },







  {
    id: uuid.v1(),
    name: "Centre Ibno Rochd d&apos;Ophtalmologie",

    tel: "05 24 61 04 44",


    address: "4 AV KENEDY RUE DE SEVILLE VILLE NOUVELLE SAFI Safi -",

    type: 2,
    lng: -7.6233,
    province:"Marrakech", lat: 33.579503
  },







  {
    id: uuid.v1(),
    name: "Polyclinique de Salé",

    tel: "05 37 81 23 35",


    leadership: "Responsable d&apos;Achat",


    prenom: "Doha",


    rdv: "Mardi 18 Avril à 14h",


    etat: "RDV Validé",


    potentiel: "15 Lits minimum",


    address: "47 AV HASSAN II SECT 1 HAY SALAM Salé 11030",

    type: 2,
    lng: -6.7891884,
    province:"Marrakech", lat: 34.0352252
  },







  {
    id: uuid.v1(),
    name: "Clinique Othtalmologique Cheikh Maouelainin",

    tel: "05 37 88 27 96",


    etat: "En cours",


    note: "Resp non-disponible / Rappel",


    address: "10 RUE SBIHI Salé 11150",

    type: 2,
    lng: -6.823703,
    province:"Marrakech", lat: 34.0344795
  },







  {
    id: uuid.v1(),
    name: "Clinique Beauséjour",

    tel: "05 37 78 13 13",


    note: "Pas de réponse au standard / Rappel",


    address: "QUARTIER R MEL RUE SBIHI VILLE NOUVELLE Salé 11150",

    type: 2,
    lng: -6.8242639,
    province:"Marrakech", lat: 34.0325544
  },







  {
    id: uuid.v1(),
    name: "Polyclinique de la CNSS Settat",

    statut: "CNSS",


    tel: "05 23 40 12 33",


    address: "BD EL MASSIRA Settat 26000",

    type: 2,
    lng: -7.6222009,
    province:"Marrakech", lat: 32.9924462
  },







  {
    id: uuid.v1(),
    name: "Clinique As Salam",

    tel: "05 23 40 04 06",


    address: "BD BIR ANZARAN Settat 26000",

    type: 2,
    lng: -7.6170394,
    province:"Marrakech", lat: 32.9945842
  },







  {
    id: uuid.v1(),
    name: "Polyclinique de la CNSS Tanger",

    statut: "CNSS",


    tel: "05 39 94 01 99",


    address: "AV MOHAMMED VI Tanger 90000",

    type: 2,
    lng: -5.7721754,
    province:"Marrakech", lat: 35.7752727
  },







  {
    id: uuid.v1(),
    name: "La Rose Blanche Médic SARL",

    tel: "06 06 06 70 85",


    address: "A1 H55 AVENUE OMANE ANBAR Tanger 90080",

    type: 2,
    lng: -5.8071499,
    province:"Marrakech", lat: 35.7421118
  },







  {
    id: uuid.v1(),
    name: "Institut Cardio Vasculaire de Tanger",

    tel: "05 39 33 45 75",


    address: "29 RUE NADOR Tanger 90000",

    type: 2,
    lng: -5.8405435,
    province:"Marrakech", lat: 35.7798001
  },







  {
    id: uuid.v1(),
    name: "Clinique Val Fleuri",

    tel: "05 39 93 32 93",


    address: "17 AV MLY RACHID VAL FLEURI Tanger -",

    type: 2,
    lng: -5.8292201,
    province:"Marrakech", lat: 35.7775828
  },







  {
    id: uuid.v1(),
    name: "Clinique Tingis",

    tel: "05 39 94 69 90",


    address: "AV ABOU BAKR ARRAZI Tanger 90000",

    type: 2,
    lng: -5.8094075,
    province:"Marrakech", lat: 35.7709294
  },







  {
    id: uuid.v1(),
    name: "Clinique du Croissant Rouge",

    tel: "05 39 94 69 76",


    address: "6 RUE MANSOUR DAHABI Tanger 90000",

    type: 2,
    lng: -5.6688212,
    province:"Marrakech", lat: 35.6746938
  },







  {
    id: uuid.v1(),
    name: "Clinique d&apos;Oncologie de Tanger",

    tel: "05 39 30 17 86",


    address: "LOT 12 ZAT EL GHANDOURI MALABATA Tanger 90000",

    type: 2,
    lng: -5.7599345,
    province:"Marrakech", lat: 35.7863488
  },







  {
    id: uuid.v1(),
    name: "Clinique Chafik",

    tel: "05 39 36 05 80",


    address: "41 RUE BARCELONE DRISSIA Tanger -",

    type: 2,
    lng: -5.3932326,
    province:"Marrakech", lat: 35.5724976
  },







  {
    id: uuid.v1(),
    name: "Clinique Bennis",

    tel: "05 39 34 07 47",


    address: "RTE DE TETOUAN Tanger 90070",

    type: 2,
    lng: -5.8118147,
    province:"Marrakech", lat: 35.7653272
  },







  {
    id: uuid.v1(),
    name: "Clinique Assalam",

    tel: "05 39 32 25 58",


    address: "10 AV DE LA PAIX Tanger -",

    type: 2,
    lng: -5.8000909,
    province:"Marrakech", lat: 35.769938
  },







  {
    id: uuid.v1(),
    name: "Clinique Aoufi",

    tel: "05 39 94 90 98",


    address: "1 RUE CORDOBA Tanger -",

    type: 2,
    lng: -5.8242526,
    province:"Marrakech", lat: 35.7820355
  },







  {
    id: uuid.v1(),
    name: "Clinique Taroudant",

    tel: "05 28 85 41 00",


    address: "CITE ADMINISTRATIVE M HAITA Taroudant -",

    type: 2,
    lng: -8.870245,
    province:"Marrakech", lat: 30.4764195
  },







  {
    id: uuid.v1(),
    name: "Polyclinique Ibnou Baja",

    tel: "05 35 28 08 90",


    address: "RUE JOHN KENNEDY VN Taza -",

    type: 2,
    lng: -4.004787,
    province:"Marrakech", lat: 34.2255604
  },







  {
    id: uuid.v1(),
    name: "Clinique Taza",

    tel: "05 35 28 15 27",


    address: "224 AV MOHAMMED VI Taza 35000",

    type: 2,
    lng: -4.0080088,
    province:"Marrakech", lat: 34.2297632
  },







  {
    id: uuid.v1(),
    name: "Polyclinique Témara",

    tel: "05 37 74 07 74",


    address: "AV ALLAL BEN ABDELLAH RUE 4 CITE ANDALOUSS Témara 12040",

    type: 2,
    lng: -6.9202845,
    province:"Marrakech", lat: 33.9373666
  },







  {
    id: uuid.v1(),
    name: "Clinique Tétouan",

    tel: "05 39 964 252",


    address: "PLACE LA COLOMBE AL HAMAMA Tétouan -",

    type: 2,
    lng: -5.3845434,
    province:"Marrakech", lat: 35.569771
  },







  {
    id: uuid.v1(),
    name: "Clinique Rif",

    tel: "05 39 99 91 42",


    address: "242 AV DES FAR Tétouan 93000",

    type: 2,
    lng: -5.3490812,
    province:"Marrakech", lat: 35.5867328
  },







  {
    id: uuid.v1(),
    name: "Clinique Jassim",

    tel: "05 28 60 16 77",


    address: "HORS BAB AGLOU Tiznit 85000",

    type: 2,
    lng: -9.7380572,
    province:"Marrakech", lat: 29.7047202
  },







  {
    id: uuid.v1(),
    name: "CENTRE AL KINDY",

    tel: "05 22 39 33 33",


    address: "24 Rue Abou Youssef El Kindy، angle Bd Ibn sina، Casablanca 20100 Casa-Anfa 20100",

    type: 2,
    lng: -7.6508147,
    province:"Marrakech", lat: 33.5845638
  },







  {
    id: uuid.v1(),
    name: "Centre d&apos;Oncologie Majorelle - Marrakech",

    tel: "05 24 39 65 65",


    address: "Targa Zdaghia Menara Marrakech -",

    type: 2,
    lng: -8.0509566,
    province:"Marrakech", lat: 31.6537217
  },







  {
    id: uuid.v1(),
    name: "centre de réanimation et pédiatrie",

    tel: "05 37 20 86 00",


    leadership: "Responsable d&apos;achat",


    etat: "Pas de projet prévu",


    note: "Pas de besoin / coordonnées communiquées pour nous recontater en cas de projet",


    address: "4 Rue de Tunis, Rabat 10000 Rabat 10000",

    type: 2,
    lng: -6.8229908,
    province:"Marrakech", lat: 34.0211133
  },







  {
    id: uuid.v1(),
    name: "centre international des aptitudes Medicale",

    tel: "05 22 47 46 46",


    address: "119 Bd Abdelmoumen, Casablanca 20360 Casa-Anfa 20360",

    type: 2,
    lng: -7.6253709,
    province:"Marrakech", lat: 33.5773743
  },







  {
    id: uuid.v1(),
    name: "centre oncologie Ryad",

    tel: "05 22 86 27 00",


    address: "19 All. du Cygne, Casablanca 20250 Casa-Anfa 20250",

    type: 2,
    lng: -7.6236655,
    province:"Marrakech", lat: 33.5678185
  },







  {
    id: uuid.v1(),
    name: "CENTRE VISION LAZER",

    tel: "05 37 77 66 23",


    mail: "chakour.ahlam@gmail.com",


    etat: "Pas de projet prévu",


    note: "Pas de besoin actuellement mais souhaite avoir une présentation par mail pour nous recontacter en cas de projet",


    address: "39 Avenue Ibn Sina, Rabat, Maroc Rabat 10080",

    type: 2,
    lng: -6.8511875,
    province:"Marrakech", lat: 33.9961875
  },







  {
    id: uuid.v1(),
    name: "PolyCLinique. C.N.S.S. - Marrakech",

    tel: "05 24 34 72 98",


    address: "ROUTE DE TARGA –MARRAKECH Marrakech -",

    type: 2,
    lng: -8.0523007,
    province:"Marrakech", lat: 31.6477839
  },







  {
    id: uuid.v1(),
    name: "Clinique ABDELMOUMEN",

    tel: "05 22 98 02 98",


    address: "Bd Abdelmoumen, Casablanca 20250 Casa-Anfa 20250",

    type: 2,
    lng: -7.6274971,
    province:"Marrakech", lat: 33.5651789
  },







  {
    id: uuid.v1(),
    name: "Clinique Ach Chifa",

    tel: "05 24 88 52 76",


    address: "Lotissement du Center Ouarzazate, 45000 OUARZAZATE Ouarzazate 45000",

    type: 2,
    lng: -6.9196556,
    province:"Marrakech", lat: 30.9231426
  },







  {
    id: uuid.v1(),
    name: "Clinique ACHARK - Oujda Angad",

    tel: "05 36 69 22 22",


    address: "6 Rue Saad Zaghloul - Oujda Maroc Oujda Angad 60000",

    type: 2,
    lng: -1.9249471,
    province:"Marrakech", lat: 34.6753506
  },







  {
    id: uuid.v1(),
    name: "Clinique Achifae - Oujda Angad",

    tel: "05 36 53 09 09",


    address: "boulevard al irfane en face du CHU Mohamed 6 60000 Oujda, Maroc Oujda Angad 60000",

    type: 2,
    lng: -1.9161272,
    province:"Marrakech", lat: 34.6547989
  },







  {
    id: uuid.v1(),
    name: "Clinique ACHIFFA (KARRAOUA) - Khenifra",

    tel: "05 35 38 59 50",


    address: "1 bd Al Massira rte Amalou Khenifra -",

    type: 2,
    lng: -5.6648563,
    province:"Marrakech", lat: 32.9379893
  },







  {
    id: uuid.v1(),
    name: "Clinique Ain Borja (Groupe Akdital)",

    tel: "05 22 41 63 63",


    leadership: "Directrice",


    etat: "Validation créneau",


    note: "Directrice ok / Rappel le 15/04 pour valider le créneau du RDV",


    address: "25 Boulevard Ifni – Casablanca Ain Sebaa Hay Mohammadi 20250",

    type: 2,
    lng: -7.5965752,
    province:"Marrakech", lat: 33.5852901
  },







  {
    id: uuid.v1(),
    name: "Clinique Ain Sebaa",

    tel: "05 22 68 00 00",


    address: "279, Bd chefchaouni, Aïn sebaâ Casablanca Ain Sebaa Hay Mohammadi 20590",

    type: 2,
    lng: -7.5430546,
    province:"Marrakech", lat: 33.6115175
  },







  {
    id: uuid.v1(),
    name: "Clinique Ajial",

    tel: "05 22 87 81 81",


    address: "347 Bd Panoramique, Casablanca Ain Chock 20470",

    type: 2,
    lng: -7.6091993,
    province:"Marrakech", lat: 33.5518913
  },







  {
    id: uuid.v1(),
    name: "Clinique AL ABBADI",

    tel: "05 22 39 52 61",


    address: "Bd Sidi Abderrahmane, Casablanca 20000 Hay Hassani 20000",

    type: 2,
    lng: -7.6639063,
    province:"Marrakech", lat: 33.5696585
  },







  {
    id: uuid.v1(),
    name: "Clinique Al Amane",

    tel: "05 22 25 10 62",


    address: "39, Rue Mohamed Al Hayani (ex Lamenais) Franceville، Casablanca 20000 Casa-Anfa 20000",

    type: 2,
    lng: -7.6327801,
    province:"Marrakech", lat: 33.5664216
  },







  {
    id: uuid.v1(),
    name: "Clinique AL AZHAR",

    tel: "05 37 20 11 00",


    etat: "En cours",


    note: "Resp en congé, reprend le 25 Avril",


    address: "Rue Idriss Al Azhar, Quartier Hassan, Rabat Rabat 10020",

    type: 2,
    lng: -6.8238497,
    province:"Marrakech", lat: 34.0207201
  },







  {
    id: uuid.v1(),
    name: "Clinique AL FARAH",

    tel: "05 23 72 23 72",


    address: "lot AlOuahda Farah Settat 28830",

    type: 2,
    lng: -7.6295114,
    province:"Marrakech", lat: 33.0124041
  },







  {
    id: uuid.v1(),
    name: "Clinique AL HAKIM - Nador",

    tel: "05 36 32 08 08",


    address: "Bd Mohamed Zerktouni BP 136 62522, Nador Nador 62522",

    type: 2,
    lng: -2.9273321,
    province:"Marrakech", lat: 35.1840793
  },







  {
    id: uuid.v1(),
    name: "Clinique AL HAKIME",

    tel: "05 22 86 22 86",


    address: "Rue Lahcen Laarjoune, El Maarif, Casa-Anfa 20360",

    type: 2,
    lng: -7.6197909,
    province:"Marrakech", lat: 33.5749298
  },







  {
    id: uuid.v1(),
    name: "Clinique AL HAYAT - Meknes",

    tel: "05 35 51 50 55",


    address: "32 lotiss. Ismailia 8 - Autre ville Hamria Meknès 50000",

    type: 2,
    lng: -5.54459,
    province:"Marrakech", lat: 33.9042553
  },







  {
    id: uuid.v1(),
    name: "Clinique Al Hayat Oasis",

    tel: "05 22 99 94 44",


    address: "29 rue Rostand Oasis Casa-Anfa 20410",

    type: 2,
    lng: -7.6377488,
    province:"Marrakech", lat: 33.5632121
  },







  {
    id: uuid.v1(),
    name: "Clinique Al Irfane - Oujda Angad",

    tel: "05 36 53 20 20",


    address: "Rue Al Irfane lot Talhaoui n° 232 Oujda Angad 60050",

    type: 2,
    lng: -1.9165993,
    province:"Marrakech", lat: 34.6549136
  },







  {
    id: uuid.v1(),
    name: "Clinique Al Majd - Fes",

    tel: "05 35 96 73 37",


    address: "QUARTIER AL ADARISSA, FES 30040 Fès 30040",

    type: 2,
    lng: -5.0169732,
    province:"Marrakech", lat: 34.0173846
  },







  {
    id: uuid.v1(),
    name: "Clinique Al Malaika",

    tel: "05 47 35 28 28",


    address: "hay Alwalaa secteur 1 T4 Sidi Moumen Sidi Bernoussi 20640",

    type: 2,
    lng: -7.5518917,
    province:"Marrakech", lat: 33.5733051
  },







  {
    id: uuid.v1(),
    name: "Clinique AL MASIRA - Agadir Ida Ou Tanane",

    tel: "05 28 84 32 38",


    address: "bd Vignt Neuf Février Talborjt Agadir Ida ou Tanane 80005",

    type: 2,
    lng: -9.597705,
    province:"Marrakech", lat: 30.4200733
  },







  {
    id: uuid.v1(),
    name: "Clinique Al Qods - Fes",

    tel: "05 35 93 06 00",


    address: "81 rue Taha Houcine - Autre ville bled Sehrij Fès 30000",

    type: 2,
    lng: -5.0088962,
    province:"Marrakech", lat: 34.0281228
  },







  {
    id: uuid.v1(),
    name: "Clinique ALFELLAH - Nador",

    tel: "05 36 33 44 33",


    address: "96 Av des Tomates Q Arrid Nador 62020",

    type: 2,
    lng: -2.9331699,
    province:"Marrakech", lat: 35.1436751
  },







  {
    id: uuid.v1(),
    name: "Clinique ALOUMOUMA - Marrakech",

    tel: "05 24 39 33 30",


    address: "lotiss Jawhara II Targa Marrakech 40130",

    type: 2,
    lng: -8.041842,
    province:"Marrakech", lat: 31.6505319
  },







  {
    id: uuid.v1(),
    name: "Clinique Amal-Rajae",

    tel: "05 37 76 74 76",


    address: "Avenue Mohamed El Fassi (ex-Marrakech) 15, Hassan Rabat -",

    type: 2,
    lng: -6.8248606,
    province:"Marrakech", lat: 34.0131592
  },







  {
    id: uuid.v1(),
    name: "Clinique ANFA ESTETIQUE",

    tel: "05 22 36 13 07",


    address: "43 rue Bouizakarne -ex Sables d&apos;Olonne lotiss. Mezouka 5 Bourgogne Casa-Anfa 20160",

    type: 2,
    lng: -7.3822077,
    province:"Marrakech", lat: 33.6950938
  },







  {
    id: uuid.v1(),
    name: "Clinique ANNAKHIL",

    tel: "05 35 96 63 63",


    address: "155 lotiss. Riad Nakhil rte d&apos;Imouzzer El Jadida -",

    type: 2,
    lng: -8.5176337,
    province:"Marrakech", lat: 33.2415783
  },







  {
    id: uuid.v1(),
    name: "Clinique radiologie ANOUAL",

    tel: "05 22 86 29 00",


    address: "111, Boulevard Anoual – Quartier des Hopitaux Casablanca Casablanca 20360",

    type: 2,
    lng: -7.6195899,
    province:"Marrakech", lat: 33.5693632
  },







  {
    id: uuid.v1(),
    name: "Clinique AR-RAZZI",

    tel: "05 22 25 33 80",


    address: "RUE ARRAZI, Casablanca, Casablanca 20340 · Casa-Anfa 20340",

    type: 2,
    lng: -7.6185773,
    province:"Marrakech", lat: 33.5857906
  },







  {
    id: uuid.v1(),
    name: "Clinique Arrazi - Fes",

    tel: "06 61 46 76 98",


    address: "10 hay Anass rte de Aïn Chkef Fès 30050",

    type: 2,
    lng: -5.0030721,
    province:"Marrakech", lat: 34.004502
  },







  {
    id: uuid.v1(),
    name: "Clinique ASSALAM - Beni Mellal",

    tel: "05 23 48 64 38",


    address: "33, av Taïf, q administratif, Beni Mellal, Maroc Beni mellal 23000",

    type: 2,
    lng: -6.3720326,
    province:"Marrakech", lat: 32.3344886
  },







  {
    id: uuid.v1(),
    name: "Clinique ASSALAM - Fes",

    tel: "05 35 60 54 54",


    address: "25 Rue Iben Zaidoune, Fès 30050 Fès 30050",

    type: 2,
    lng: -4.9996912,
    province:"Marrakech", lat: 34.0258724
  },







  {
    id: uuid.v1(),
    name: "Clinique Assalam - Nador",

    tel: "05 36 38 00 31",


    address: "QUARTIER ALMATRA,LOT ONDA,ILIT 25, N° 113 NADOR Nador 62010",

    type: 2,
    lng: -2.9265258,
    province:"Marrakech", lat: 35.1544865
  },







  {
    id: uuid.v1(),
    name: "Clinique ASSOULIL - Agadir Ida Ou Tanane",

    tel: "05 28 84 35 59",


    address: "Av Hassan II Imm Assoulil Agadir Ida ou Tanane 80020",

    type: 2,
    lng: -9.5964258,
    province:"Marrakech", lat: 30.4160745
  },







  {
    id: uuid.v1(),
    name: "Clinique ATFAL",

    tel: "05 22 23 18 18",


    address: "50 Bd Abderrahim Bouabid, Casablanca Casa-Anfa 20410",

    type: 2,
    lng: -7.6397833,
    province:"Marrakech", lat: 33.5586397
  },







  {
    id: uuid.v1(),
    name: "Clinique ATLAS",

    tel: "05 37 73 03 01",


    etat: "En cours",


    note: "Resp absente / Rappel",


    address: "30 rue Chouaïb Doukkali Les Orangers Rabat -",

    type: 2,
    lng: -6.8420659,
    province:"Marrakech", lat: 34.0189164
  },







  {
    id: uuid.v1(),
    name: "Clinique AVERROES",

    tel: "05 22 20 13 30",


    address: "1 rue Ennakhla -ex Claude Bernard Centre Ville Casa-Anfa 20130",

    type: 2,
    lng: -7.6177245,
    province:"Marrakech", lat: 33.590792
  },







  {
    id: uuid.v1(),
    name: "Clinique AVICENNE - Marrakech",

    tel: "05 24 44 66 73",


    address: "76 Rue Ibn Toummert, Marrakesh 40000 Marrakech 40000",

    type: 2,
    lng: -8.0062864,
    province:"Marrakech", lat: 31.6340517
  },







  {
    id: uuid.v1(),
    name: "Clinique Avicenne des spécialités",

    tel: "05 22 79 33 00",


    address: "Angle Avenue Nador، Bd de l&apos;Atlantide, Casablanca 20000 Casa-Anfa 20000",

    type: 2,
    lng: -7.6131077,
    province:"Marrakech", lat: 33.5543738
  },







  {
    id: uuid.v1(),
    name: "Clinique BAB DOUKKALA - Marrakech",

    tel: "05 24 44 91 21",


    address: "17 Derb Laârab El Hara Bab Doukkala Marrakech 40030",

    type: 2,
    lng: -8.0388332,
    province:"Marrakech", lat: 31.627494
  },







  {
    id: uuid.v1(),
    name: "Clinique BADES - Al Hoceima",

    tel: "05 39 84 06 62",


    address: "Cité Calabonita Al hoceima -",

    type: 2,
    lng: -3.9257673,
    province:"Marrakech", lat: 35.2345326
  },







  {
    id: uuid.v1(),
    name: "Clinique Badr - Oujda Angad",

    tel: "05 36 50 19 01",


    address: "375 lots la coline Al Irfane Oujda Angad 60040",

    type: 2,
    lng: -1.9111889,
    province:"Marrakech", lat: 34.6547469
  },







  {
    id: uuid.v1(),
    name: "Clinique BADRE",

    tel: "05 22 49 23 80",


    address: "Rue de l&apos; Imam El Aloussi, Casablanca 20050 Casa-Anfa 20050",

    type: 2,
    lng: -7.6431289,
    province:"Marrakech", lat: 33.5947511
  },







  {
    id: uuid.v1(),
    name: "Clinique BENI MELLAL - Beni Mellal",

    tel: "05 23 48 99 92",


    address: "425 bd Mohammed V q Al Adarissa Beni mellal 23000",

    type: 2,
    lng: -6.3668581,
    province:"Marrakech", lat: 32.3321875
  },







  {
    id: uuid.v1(),
    name: "Clinique BENNOUR",

    tel: "05 23 35 92 06",


    address: "lot Yassine, lot n°31 et 32, Sidi Bennour Sidi Bennour 24350",

    type: 2,
    lng: -8.4172889,
    province:"Marrakech", lat: 32.655639
  },







  {
    id: uuid.v1(),
    name: "Clinique BENSOUDA",

    tel: "05 22 94 08 64",


    address: "53 Bd Abdelkrim Al Khattabi, Casablanca 20250 Casa-Anfa 20250",

    type: 2,
    lng: -7.6507406,
    province:"Marrakech", lat: 33.5859682
  },







  {
    id: uuid.v1(),
    name: "Clinique BONNE OEuvre du COeur",

    statut: "CPBNL",


    tel: "06 61 32 53 69",


    address: "4, Bd Ghandi, Casablanca Hay Hassani 20210",

    type: 2,
    lng: -7.6628254,
    province:"Marrakech", lat: 33.5816793
  },







  {
    id: uuid.v1(),
    name: "Clinique BOUHOU - Beni Mellal",

    tel: "05 23 48 45 30",


    address: "175 bd Ibn Khaldoun Beni mellal 23040",

    type: 2,
    lng: -6.3742458,
    province:"Marrakech", lat: 32.3367015
  },







  {
    id: uuid.v1(),
    name: "Clinique BOURJILA - Al Hoceima",

    tel: "05 39 98 26 62",


    address: "av Alexandrie, hay Salam, Al Hoceima Al hoceima 32022",

    type: 2,
    lng: -3.9404681,
    province:"Marrakech", lat: 35.2411199
  },







  {
    id: uuid.v1(),
    name: "Clinique CALIFORNIA",

    tel: "05 22 50 80 80",


    address: "545 Bd Panoramique, Casablanca 20150 Ain Chock 20150",

    type: 2,
    lng: -7.6309626,
    province:"Marrakech", lat: 33.5467305
  },







  {
    id: uuid.v1(),
    name: "Clinique CARDIO OASIS",

    tel: "05 22 98 75 75",


    address: "20 Rue de Franceville, Casablanca 20000 Casa-Anfa 20000",

    type: 2,
    lng: -7.6357212,
    province:"Marrakech", lat: 33.5473602
  },







  {
    id: uuid.v1(),
    name: "Clinique CARDIO VASCULAIRE",

    tel: "05 20 05 82 59",


    address: "Bd Sidi Abderrahmane, Casablanca 20250 Casa-Anfa 20250",

    type: 2,
    lng: -7.663256,
    province:"Marrakech", lat: 33.5692293
  },







  {
    id: uuid.v1(),
    name: "Clinique CENTRE CARDIOLOGIQUE DU SUD - Agadir Ida Ou Tanane",

    tel: "05 28 21 09 21",


    address: "Ave Abderrahim Bouabid, Agadir 80000 Agadir Ida ou Tanane 80000",

    type: 2,
    lng: -9.5752009,
    province:"Marrakech", lat: 30.4129867
  },







  {
    id: uuid.v1(),
    name: "Clinique Centre Oriental Al-Kindy - Oujda Angad",

    tel: "05 20 20 29 29",


    address: "Secteur Nord Marjane, Ryad Isly, Oujda Oujda Angad 60030",

    type: 2,
    lng: -1.9158191,
    province:"Marrakech", lat: 34.7229459
  },







  {
    id: uuid.v1(),
    name: "Clinique Chaouia",

    tel: "05 22 33 66 22",


    address: "3 rue Okba Ibnou Nafie Berrechid 26010",

    type: 2,
    lng: -7.5888691,
    province:"Marrakech", lat: 33.267545
  },


  {
    id: uuid.v1(),
    name: "Clinique CHARAF - Marrakech",

    tel: "05 24 29 93 30",


    address: "lotiss Charaf Al Manar 3 Marrakech 40100",

    type: 2,
    lng: -8.0234689,
    province:"Marrakech", lat: 31.6704266
  },


  {
    id: uuid.v1(),
    name: "Clinique Chichaoua",

    statut: "CPBNL",


    tel: "05 24 35 22 00",


    address: "QUARTIER ADMINISTRATIF CHICHAOUA Chichaoua 41000",

    type: 2,
    lng: -8.7679712,
    province:"Marrakech", lat: 31.540111
  },


  {
    id: uuid.v1(),
    name: "Clinique COCHAIN",

    tel: "05 22 94 33 55",


    address: "42 Bd Driss Slaoui, Casablanca 20250 Casa-Anfa 20250",

    type: 2,
    lng: -7.6568953,
    province:"Marrakech", lat: 33.5978603
  },


  {
    id: uuid.v1(),
    name: "Clinique d&apos;esthetique Dr Tazi",

    tel: "05 22 85 92 20",


    address: "Angle rue Lahcen el arjoun et Lavoisier, en face de l’hôpital 20 Août، Casablanca 20000 Casa-Anfa 20000",

    type: 2,
    lng: -7.6194351,
    province:"Marrakech", lat: 33.5758944
  },


  {
    id: uuid.v1(),
    name: "Clinique D&apos;ORTHOPEDIE",

    tel: "05 37 37 30 81",


    address: "6 rue Houssein Heikel Kénitra 14000",

    type: 2,
    lng: -7.673359,
    province:"Marrakech", lat: 33.550613
  },


  {
    id: uuid.v1(),
    name: "Clinique Dai Assiha - Meknes",

    tel: "05 35 51 61 10",


    address: "20 hay Ismailia 8 vn Meknès 50000",

    type: 2,
    lng: -5.5450541,
    province:"Marrakech", lat: 33.9044582
  },


  {
    id: uuid.v1(),
    name: "Clinique DAKHLA - Khouribga",

    tel: "05 23 56 64 64",


    address: "2 Rue Palmier, Khouribga, Maroc khouribga 25000",

    type: 2,
    lng: -6.9107271,
    province:"Marrakech", lat: 32.8921015
  },


  {
    id: uuid.v1(),
    name: "Clinique de l&apos;œil",

    tel: "06 15 59 92 02",


    address: "544 Bd Panoramique, Casablanca 20150 Ain Chock 20150",

    type: 2,
    lng: -7.6266304,
    province:"Marrakech", lat: 33.5484589
  },


  {
    id: uuid.v1(),
    name: "CLINIQUE DES JUMELLES - Fes",

    tel: "05 35 65 63 63",


    address: "bd le Roi Houssein rue Ibn Hanie rte d&apos;Imouzzer Fès -",

    type: 2,
    lng: -5.0005516,
    province:"Marrakech", lat: 34.0300973
  },


  {
    id: uuid.v1(),
    name: "Clinique Dr. ACHAK - Fes",

    tel: "05 35 65 10 68",


    address: "26, Rue Abessalem Serghini, Fès Fès 30050",

    type: 2,
    lng: -4.9983787,
    province:"Marrakech", lat: 34.0426719
  },


  {
    id: uuid.v1(),
    name: "Clinique Dr. BENMOUSSA - Fes",

    tel: "05 35 62 27 34",


    address: "Rue Mohammed Zerktouni, Fes 30050 Fès 30050",

    type: 2,
    lng: -4.9956851,
    province:"Marrakech", lat: 34.0385162
  },


  {
    id: uuid.v1(),
    name: "CLINIQUE NOUR - Fes",

    tel: "05 35 94 46 47",


    address: "6 rue Kacem Amine Fès -",

    type: 2,
    lng: -5.0114326,
    province:"Marrakech", lat: 34.030629
  },


  {
    id: uuid.v1(),
    name: "Clinique Errahma - Marrakech",

    tel: "05 24 34 34 61",


    address: "Lot Zineb 2, Marrakech 40130 Marrakech 40130",

    type: 2,
    lng: -8.0483628,
    province:"Marrakech", lat: 31.6496683
  },


  {
    id: uuid.v1(),
    name: "Clinique ERRAZI - Beni Mellal",

    tel: "05 23 48 79 30",


    address: "Quartier administratif beni mellal Beni mellal 23000",

    type: 2,
    lng: -6.3691887,
    province:"Marrakech", lat: 32.32904
  },


  {
    id: uuid.v1(),
    name: "Clinique ESPAGNOL - Tanger Assilah",

    statut: "CPBNL",


    tel: "05 39 93 10 18",


    address: "Rue de l&apos;Hôpital Espagnol, 90000 TANGER - Maroc Tanger Assilah 90000",

    type: 2,
    lng: -5.7787415,
    province:"Marrakech", lat: 35.7752942
  },


  {
    id: uuid.v1(),
    name: "Clinique ESSAADA",

    tel: "05 24 46 33 81",


    address: "Av Sidi Mohamed Ben Abdellah Safi 28810",

    type: 2,
    lng: -7.6830982,
    province:"Marrakech", lat: 33.5591366
  },


  {
    id: uuid.v1(),
    name: "Clinique ESSAFAE",

    tel: "05 37 55 01 21",


    address: "145, lot Tadart, Khemisset Khémisset 15020",

    type: 2,
    lng: -6.0805447,
    province:"Marrakech", lat: 33.8249768
  },


  {
    id: uuid.v1(),
    name: "Clinique IBN SINA sidi kacem",

    tel: "05 37 59 05 59",


    address: "175 hay El Qods Sidi Kacem -",

    type: 2,
    lng: -5.7246176,
    province:"Marrakech", lat: 34.2310271
  },







  {
    id: uuid.v1(),
    name: "Clinique IBN SINA Guelmin",

    tel: "05 28 77 41 77",


    address: "quartier Ibn Rochd rue n°14 Guelmin 81000",

    type: 2,
    lng: -10.0296361,
    province:"Marrakech", lat: 28.9818956
  },







  {
    id: uuid.v1(),
    name: "clinique IBN SINA - Agadir Ida Ou Tanane",

    tel: "05 28 23 85 49",


    address: "bd Hassan 1er cité Dakhla Agadir Ida ou Tanane 80060",

    type: 2,
    lng: -9.5649585,
    province:"Marrakech", lat: 30.4116284
  },







  {
    id: uuid.v1(),
    name: "Clinique IBN SINA - Beni Mellal",

    tel: "05 23 48 88 00",


    address: "Quartier Administratif lotiss Alfath n°4 Beni mellal 23000",

    type: 2,
    lng: -6.3602732,
    province:"Marrakech", lat: 32.3278663
  },







  {
    id: uuid.v1(),
    name: "Clinique IBN TOFAIL - Marrakech",

    tel: "05 24 43 87 18",


    address: "Rue Ibn Abdelmalik, Quartier De L&apos;Hopital Gueliz Marrakech-Tensift-Al, Haouz, Marrakech 40000 Marrakech 40000",

    type: 2,
    lng: -8.0126556,
    province:"Marrakech", lat: 31.6388016
  },


  {
    id: uuid.v1(),
    name: "Clinique Farah",
    tel: "05 22 96 27 93",
    address: "1 lotiss Al Omrane Berrechid 26100",
    type: 2,
    lng: -7.8347537,
    province:"Marrakech", lat: 33.423216
  },


  {
    id: uuid.v1(),
    name: "Clinique Arrahma",
    address: "Taourirt -",
    type: 2,
    lng: -2.9187396,
    province:"Marrakech", lat: 34.4152658
  },


  {
    id: uuid.v1(),
    name: "Clinique ABOU KASSIM EZZAHRAOUI",
    tel: "05 24 47 48 61",
    address: "Clinique Khattabi Borj I, Essaouira, Maroc Essaouira 44000",
    type: 2,
    lng: -9.7609395,
    province:"Marrakech", lat: 31.5057075
  },


  {
    id: uuid.v1(),
    name: "Clinique C.R.M. - Tetouan",
    tel: "05 39 70 13 10",
    address: "Rue croissant Rouge, tetouan Tetouan 93000",
    type: 2,
    lng: -5.3827117,
    province:"Marrakech", lat: 35.5717098
  },


  {
    id: uuid.v1(),
    name: "Clinique ADAM BAHIA",
    statut: "multidisciplinaire",
    tel: "0522873333",
    prenom: "Hafida",
    etat: "Validation créneau",
    note: "Mme Hafida ok / elle rappelle pour valider le créneau",
    address: "Bd Mohamed 6, Babylone n7, rue 98, Hay El Ousra Ain Chock 20480",
    type: 2,
    lng: -7.5790450684334,
    province:"Marrakech", lat: 33.540090371829
  },


  {
    id: uuid.v1(),
    name: "Clinique ASSALAM - Beni Mellal",

    tel: "05 23 48 64 38",


    address: "33, av Taïf, q administratif, Beni Mellal, Maroc Beni mellal 23000",

    type: 2,
    lng: -6.3320326,
    province:"Marrakech", lat: 32.0044886
  },







  {
    id: uuid.v1(),
    name: "Clinique ASSALAM - Fes",

    tel: "05 35 60 54 54",


    address: "25 Rue Iben Zaidoune, Fès 30050 Fès 30050",

    type: 2,
    lng: -4.4996912,
    province:"Marrakech", lat: 34.0258724
  },







  {
    id: uuid.v1(),
    name: "Clinique Assalam - Nador",

    tel: "05 36 38 00 31",


    address: "QUARTIER ALMATRA,LOT ONDA,ILIT 25, N° 113 NADOR Nador 62010",

    type: 2,
    lng: -2.5265258,
    province:"Marrakech", lat: 34.8544865
  },







  {
    id: uuid.v1(),
    name: "Clinique ASSOULIL - Agadir Ida Ou Tanane",

    tel: "05 28 84 35 59",


    address: "Av Hassan II Imm Assoulil Agadir Ida ou Tanane 80020",

    type: 2,
    lng: -9.1964258,
    province:"Marrakech", lat: 30.1160745
  },







  {
    id: uuid.v1(),
    name: "Clinique ATFAL",

    tel: "05 22 23 18 18",


    address: "50 Bd Abderrahim Bouabid, Casablanca Casa-Anfa 20410",

    type: 2,
    lng: -7.2397833,
    province:"Marrakech", lat: 33.3586397
  },







  {
    id: uuid.v1(),
    name: "Clinique ATLAS",

    tel: "05 37 73 03 01",


    etat: "En cours",


    note: "Resp absente / Rappel",


    address: "30 rue Chouaïb Doukkali Les Orangers Rabat -",

    type: 2,
    lng: -6.8420659,
    province:"Marrakech", lat: 33.4189164
  },







  {
    id: uuid.v1(),
    name: "Clinique AVERROES",

    tel: "05 22 20 13 30",


    address: "1 rue Ennakhla -ex Claude Bernard Centre Ville Casa-Anfa 20130",

    type: 2,
    lng: -7.6177245,
    province:"Marrakech", lat: 32.290792
  },







  {
    id: uuid.v1(),
    name: "Clinique AVICENNE - Marrakech",

    tel: "05 24 44 66 73",


    address: "76 Rue Ibn Toummert, Marrakesh 40000 Marrakech 40000",

    type: 2,
    lng: -7.6062864,
    province:"Marrakech", lat: 31.6340517
  },







  {
    id: uuid.v1(),
    name: "Clinique Avicenne des spécialités",

    tel: "05 22 79 33 00",


    address: "Angle Avenue Nador، Bd de l&apos;Atlantide, Casablanca 20000 Casa-Anfa 20000",

    type: 2,
    lng: -7.3131077,
    province:"Marrakech", lat: 33.1543738
  },







  {
    id: uuid.v1(),
    name: "Clinique BAB DOUKKALA - Marrakech",

    tel: "05 24 44 91 21",


    address: "17 Derb Laârab El Hara Bab Doukkala Marrakech 40030",

    type: 2,
    lng: -7.0388332,
    province:"Marrakech", lat: 31.627494
  },







  {
    id: uuid.v1(),
    name: "Clinique BADES - Al Hoceima",

    tel: "05 39 84 06 62",


    address: "Cité Calabonita Al hoceima -",

    type: 2,
    lng: -3.9257673,
    province:"Marrakech", lat: 34.2345326
  },







  {
    id: uuid.v1(),
    name: "Clinique Badr - Oujda Angad",

    tel: "05 36 50 19 01",


    address: "375 lots la coline Al Irfane Oujda Angad 60040",

    type: 2,
    lng: -1.6111889,
    province:"Marrakech", lat: 34.1547469
  },







  {
    id: uuid.v1(),
    name: "Clinique BADRE",

    tel: "05 22 49 23 80",


    address: "Rue de l&apos; Imam El Aloussi, Casablanca 20050 Casa-Anfa 20050",

    type: 2,
    lng: -7.6431289,
    province:"Marrakech", lat: 32.5947511
  },







  {
    id: uuid.v1(),
    name: "Clinique BENI MELLAL - Beni Mellal",

    tel: "05 23 48 99 92",


    address: "425 bd Mohammed V q Al Adarissa Beni mellal 23000",

    type: 2,
    lng: -6.3668581,
    province:"Marrakech", lat: 31.3321875
  },







  {
    id: uuid.v1(),
    name: "Clinique BENNOUR",

    tel: "05 23 35 92 06",


    address: "lot Yassine, lot n°31 et 32, Sidi Bennour Sidi Bennour 24350",

    type: 2,
    lng: -8.1172889,
    province:"Marrakech", lat: 32.155639
  },







  {
    id: uuid.v1(),
    name: "Clinique BENSOUDA",

    tel: "05 22 94 08 64",


    address: "53 Bd Abdelkrim Al Khattabi, Casablanca 20250 Casa-Anfa 20250",

    type: 2,
    lng: -6.8507406,
    province:"Marrakech", lat: 31.5859682
  },







  {
    id: uuid.v1(),
    name: "Clinique BONNE OEuvre du COeur",

    statut: "CPBNL",


    tel: "06 61 32 53 69",


    address: "4, Bd Ghandi, Casablanca Hay Hassani 20210",

    type: 2,
    lng: -7.2628254,
    province:"Marrakech", lat: 33.2816793
  },







  {
    id: uuid.v1(),
    name: "Clinique BOUHOU - Beni Mellal",

    tel: "05 23 48 45 30",


    address: "175 bd Ibn Khaldoun Beni mellal 23040",

    type: 2,
    lng: -6.1742458,
    province:"Marrakech", lat: 31.5367015
  },







  {
    id: uuid.v1(),
    name: "Clinique BOURJILA - Al Hoceima",

    tel: "05 39 98 26 62",


    address: "av Alexandrie, hay Salam, Al Hoceima Al hoceima 32022",

    type: 2,
    lng: -3.9404681,
    province:"Marrakech", lat: 34.6411199
  },







  {
    id: uuid.v1(),
    name: "Clinique CALIFORNIA",

    tel: "05 22 50 80 80",


    address: "545 Bd Panoramique, Casablanca 20150 Ain Chock 20150",

    type: 2,
    lng: -6.9309626,
    province:"Marrakech", lat: 33.5467305
  },







  {
    id: uuid.v1(),
    name: "Clinique CARDIO OASIS",

    tel: "05 22 98 75 75",


    address: "20 Rue de Franceville, Casablanca 20000 Casa-Anfa 20000",

    type: 2,
    lng: -7.3357212,
    province:"Marrakech", lat: 33.1473602
  },







  {
    id: uuid.v1(),
    name: "Clinique CARDIO VASCULAIRE",

    tel: "05 20 05 82 59",


    address: "Bd Sidi Abderrahmane, Casablanca 20250 Casa-Anfa 20250",

    type: 2,
    lng: -7.663256,
    province:"Marrakech", lat: 32.5692293
  },







  {
    id: uuid.v1(),
    name: "Clinique CENTRE CARDIOLOGIQUE DU SUD - Agadir Ida Ou Tanane",

    tel: "05 28 21 09 21",


    address: "Ave Abderrahim Bouabid, Agadir 80000 Agadir Ida ou Tanane 80000",

    type: 2,
    lng: -9.5752009,
    province:"Marrakech", lat: 28.4129867
  },







  {
    id: uuid.v1(),
    name: "Clinique Centre Oriental Al-Kindy - Oujda Angad",

    tel: "05 20 20 29 29",


    address: "Secteur Nord Marjane, Ryad Isly, Oujda Oujda Angad 60030",

    type: 2,
    lng: -1.9158191,
    province:"Marrakech", lat: 33.7229459
  },







  {
    id: uuid.v1(),
    name: "Clinique Chaouia",

    tel: "05 22 33 66 22",


    address: "3 rue Okba Ibnou Nafie Berrechid 26010",

    type: 2,
    lng: -5.5888691,
    province:"Marrakech", lat: 32.267545
  },



]

export const labsBiologiques = [
  {
    name: "Laboratoire Ayouche",
    city: "Agadir",
    address: "av Hassan II. Imm Kabbaj, 80000",
    title: "Docteur",
    province:"Marrakech", lat: "30.3948172",
    lng: "-9.6016103"
  },


  {
    name: "Laboratoire Amrani",
    city: "Agadir",
    address: "9, rue de l'Hotel de Ville, Agadir",
    title: "Docteur",
    province:"Marrakech", lat: "30.4201431",
    lng: "-9.5997777"
  },


  {
    name: "Laboratoire Harguil",
    city: "Agadir",
    address: "av Kennedy. Talborjt, Agadir",
    title: "Docteur",
    province:"Marrakech", lat: "30.4231972",
    lng: "-9.5976125"
  },


  {
    name: "Laboratoire Oumlil",
    city: "Agadir",
    address: "21, rue de Marrakech, Agadir",
    title: "Docteur",
    province:"Marrakech", lat: "30.4122385",
    lng: "-9.5890612"
  },


  {
    name: "Laboratoire Elmghari",
    city: "Agadir",
    address: "5, av Mly Hassan 1er. Dakhla, Agadir",
    title: "Docteur",
    province:"Marrakech", lat: "30.4122385",
    lng: "-9.5890612"
  },


  {
    name: "Laboratoire d'analyses médicales Rifkoun",
    city: "Agadir",
    address: "20, rue Tarablous. au dessus Ecole Pigier, Agadir",
    title: "Docteur",
    province:"Marrakech", lat: "30.4145941",
    lng: "-9.595173"
  },


  {
    name: "Laboratoire Mahfoud",
    city: "Agadir",
    address: "imm Marhaba, bd Cheikh Saadi. 29 Février, Agadir",
    title: "Docteur",
    province:"Marrakech", lat: "30.4245747",
    lng: "-9.5919946"
  },


  {
    name: "Laboratoire d'analyses médicales Tahiri",
    city: "Agadir",
    address: "av Allal Ben Abdellah, imm Tamri. Talborjt, Agadir",
    title: "Docteur",
    province:"Marrakech", lat: "30.4221699",
    lng: "-9.5977225"
  },


  {
    name: "Laboratoire d'analyses Azaou",
    city: "Agadir",
    address: "25, rue Président Bekkai, Agadir",
    title: "Docteur",
    province:"Marrakech", lat: "30.41628",
    lng: "-9.591276"
  },


  {
    name: "Laboratoire Elallali",
    city: "Agadir",
    address: "61 bis, rue de Marrakech. QI, Agadir",
    title: "Docteur",
    province:"Marrakech", lat: "30.4159308",
    lng: "-9.5911735"
  },


  {
    name: "Laboratoire Al Massira d'analyses médicales",
    city: "Agadir",
    address: "av Hassan II, imm Ifrane 2, Agadir",
    title: "Docteur",
    province:"Marrakech", lat: "31.9630678",
    lng: "-8.4768044"
  },


  {
    name: "Laboratoire d'analyses médicales Assalama",
    city: "Agadir",
    address: "77, av Jamal Abdennaser. cité Al Massira, Agadir",
    title: "Docteur",
    province:"Marrakech", lat: "30.4085149",
    lng: "-9.5692774"
  },


  {
    name: "Laboratoire central d'analyses médicales",
    city: "Agadir",
    address: "av Cheikh Saadi, 8, imm Sourour. Talborjt, Agadir",
    title: "Docteur",
    province:"Marrakech", lat: "30.4232574",
    lng: "-9.589315"
  },


  {
    name: "Laboratoire Al Asri  d'analyses médicales",
    city: "Agadir",
    address: "3, av Hassan II. imm Borj Al Hikma, Agadir",
    title: "Docteur",
    province:"Marrakech", lat: "30.419298",
    lng: "-9.5956559"
  },


  {
    name: "Laboratoire Rahmouni d'analyses médicales",
    city: "Agadir",
    address: "av Abdellah Guennoun, hay Essalam, Agadir",
    title: "Docteur",
    province:"Marrakech", lat: "30.4193344",
    lng: "-9.5956559"
  },


  {
    name: "Laboratoire Tifaouine",
    city: "Agadir",
    address: "av Moukaouama, imm Tifaouine, Agadir",
    title: "Docteur",
    province:"Marrakech", lat: "30.4193708",
    lng: "-9.5956559"
  },


  {
    name: "Laboratoire Sanhaji",
    city: "Agadir",
    address: "51 bis, bloc A. quartier Zaitoune, Tikiouine, Agadir",
    title: "Docteur",
    province:"Marrakech", lat: "30.3815684",
    lng: "-9.5342439"
  },


  {
    name: "Laboratoire d'analyses médicales Hay Mohammadi",
    city: "Agadir",
    address: "3320, bd Casablanca. hay Mohammadi, Agadir",
    title: "Docteur",
    province:"Marrakech", lat: "30.4299126",
    lng: "-9.5582243"
  },


  {
    name: "Laboratoire A\u00EFn Harrouda Zenata",
    city: "A\u00EFn Harrouda ",
    address: " lot Al Wafaa 2, n°23, Ain Harrouda",
    title: "Docteur",
    province:"Marrakech", lat: "33.6381902",
    lng: "-7.4490325"
  },


  {
    name: "Laboratoire Central Argana",
    city: "Ait Melloul",
    address: "1 rue des écoles Imm Chahrazade, 86350",
    title: "Docteur",
    province:"Marrakech", lat: "33.5737104",
    lng: "-7.6447339"
  },


  {
    name: "Laboratoire d'analyses médicales  Chaoui",
    city: "Ait Melloul",
    address: "17, av Hassan II. imm commercial, Ait Melloul",
    title: "Docteur",
    province:"Marrakech", lat: "30.3403018",
    lng: "-9.5075951"
  },


  {
    name: "Laboratoire d'analyses médicales El Othmani",
    city: "Ait Melloul",
    address: "rte Tiznite ang. rue de l'hopital imm. Ibn Sinaa",
    title: "Docteur",
    province:"Marrakech", lat: "30.3403202",
    lng: "-9.5075951"
  },


  {
    name: "Laboratoire Andalous",
    city: "Al Hoceima",
    address: "ang. rues d'Algérie & Hajrate Nekkor, Al Hoceima",
    title: "Docteur",
    province:"Marrakech", lat: "33.5817543",
    lng: "-7.6941522"
  },


  {
    name: "Laboratoire Al Hoceima",
    city: "Al Hoceima",
    address: "94, av Hassan II. RC, Al Hoceima",
    title: "Docteur",
    province:"Marrakech", lat: "35.2423809",
    lng: "-3.9358287"
  },


  {
    name: "Laboratoire Elkhattabi d'analyses médicales",
    city: "Al Hoceima",
    address: "38, rue Cadi Ayad, Al Hoceima",
    title: "Docteur",
    province:"Marrakech", lat: "35.2443464",
    lng: "-3.9356757"
  },


  {
    name: "Laboratoire d'analyse médicales Azemmour",
    city: "Azemmour",
    address: "118, bd Mohamed V, hay Al Amal, Azemmour",
    title: "Docteur",
    province:"Marrakech", lat: "33.2833506",
    lng: "-8.3527471"
  },


  {
    name: "33.437414",
    city: "Azrou",
    address: "route de Midelt. Ajellab, Azrou",
    title: "Docteur",
    province:"Marrakech", lat: "33.437414",
    lng: "-5.222696"
  },


  {
    name: "Laboratoire Central Rhamna",
    city: "Benguerir",
    address: "542, bd Moulay Abdellah, Benguerir",
    title: "Docteur",
    province:"Marrakech", lat: "32.2411443",
    lng: "-7.9584632"
  },


  {
    name: "Laboratoire d'analyses médicales Ben Ahmed Amzab BAMLAB",
    city: "Benahmed",
    address: "n°38 bis, 2 rue Cheikh Chouaib. derb Haj Lahcen, Benahmed",
    title: "Docteur",
    province:"Marrakech", lat: "33.0614217",
    lng: "-7.2447822"
  },


  {
    name: "Laboratoire central Tadla",
    city: "Béni-Mellal",
    address: "281, bd Mohamed V, Béni Mellal",
    title: "Docteur",
    province:"Marrakech", lat: "32.3289305",
    lng: "-6.3736274"
  },


  {
    name: "Laboratoire du Maroc",
    city: "Béni-Mellal",
    address: "1, av Taha Houssein, Béni Mellal",
    title: "Docteur",
    province:"Marrakech", lat: "32.3340945",
    lng: "-6.3681053"
  },


  {
    name: "Laboratoire Ibn Rochd",
    city: "Béni-Mellal",
    address: "3, av des Far, Béni Mellal",
    title: "Docteur",
    province:"Marrakech", lat: "32.3341301",
    lng: "-6.3681053"
  },


  {
    name: "Laboratoire Al Qods",
    city: "Béni-Mellal",
    address: "63, Bd Al Qods, Résid. Saguia, 23000",
    title: "Docteur",
    province:"Marrakech", lat: "32.3393557",
    lng: "-6.3630182"
  },


  {
    name: "Laboratoire Alfarabi d'analyses médicales",
    city: "Béni-Mellal",
    address: "19, lot Larbi Ben Rahal. hay Miftah, Béni Mellal",
    title: "Docteur",
    province:"Marrakech", lat: "32.330972",
    lng: "-6.4082484"
  },


  {
    name: "Laboratoire Béni Mellal d'Analyses Médicales ",
    city: "Béni-Mellal",
    address: "Quartier Meftah N°13 Bloc 2 ",
    title: "Docteur",
    province:"Marrakech", lat: "32.3424475",
    lng: "-6.3779877"
  },


  {
    name: "Laboratoire Elkadaoui",
    city: "Bénslimane",
    address: "14, av Hassan II, Benslimane",
    title: "Docteur",
    province:"Marrakech", lat: "33.6277928",
    lng: "-7.1323307"
  },


  {
    name: "Laboratoire Ibn Rochd",
    city: "Berkane",
    address: "105, angle rues Ibn Sina et Moulay iss, Berkane",
    title: "Docteur",
    province:"Marrakech", lat: "33.983625",
    lng: "-6.8286633"
  },


  {
    name: "Laboratoire Moderne",
    city: "Berkane",
    address: "78, bd Mohamed V, Berkane",
    title: "Docteur",
    province:"Marrakech", lat: "33.9836237",
    lng: "-6.8439842"
  },


  {
    name: "Laboratoire de l'Oriental",
    city: "Berkane",
    address: "49, bd Soltan Mly M'hamed, Berkane",
    title: "Docteur",
    province:"Marrakech", lat: "34.9179605",
    lng: "-2.6848914"
  },


  {
    name: "Laboratoire d'analyses médicales El Ayadi",
    city: "Berkane",
    address: "32, bd Mohamed V, Berkane",
    title: "Docteur",
    province:"Marrakech", lat: "34.9262786",
    lng: "-2.3348121"
  },


  {
    name: "Laboratoire Ibn Sina",
    city: "Berrechid",
    address: "angle bd Mohamed V et rue lngacoub El Mansour, Berrechid",
    title: "Docteur",
    province:"Marrakech", lat: "33.2749469",
    lng: "-7.7279533"
  },


  {
    name: "Laboratoire Errazi",
    city: "Berrechid",
    address: "169, av Moulay Ismail, Berrechid",
    title: "Docteur",
    province:"Marrakech", lat: "33.2652446",
    lng: "-7.5861173"
  },


  {
    name: "Laboratoire Deroua",
    city: "Berrechid",
    address: "El Wahda 2, n°252. BP : 220 Deroua Oulad Zyane, Berrechid",
    title: "Docteur",
    province:"Marrakech", lat: "33.375581",
    lng: "-7.5386242"
  },


  {
    name: "Laboratoire Sekkat d'analyses médicales",
    city: "Berrechid",
    address: "12, lot El lngoussr 2, Berrechid",
    title: "Docteur",
    province:"Marrakech", lat: "33.2616033",
    lng: "-7.582197"
  },


  {
    name: "Laboratoire d'analyses médicales Chaouia",
    city: "Berrechid",
    address: "bd Mohamed V, lot Al lngousr, Berrechid",
    title: "Docteur",
    province:"Marrakech", lat: "33.2616254",
    lng: "-7.5887631"
  },


  {
    name: "Laboratoire Bouznika",
    city: "Bouznika",
    address: "45, angle rues Palestine et Chourouk. hay Elhana, Bouznika",
    title: "Docteur",
    province:"Marrakech", lat: "33.7826124",
    lng: "-7.1610531"
  },


  {
    name: "Laboratoire Bouzoubaa d'analyses médicales",
    city: "Casablanca",
    address: "11, bd Mohamed Zerktouni, résid. Tarfaya 1°ét. 20360",
    title: "Docteur",
    province:"Marrakech", lat: "33.5828496",
    lng: "-7.6192829"
  },


  {
    name: "Laboratoire d'Analyses Biomédica",
    city: "Casablanca",
    address: "160, rue Mostapha El Maani, 2°ét 20130",
    title: "Docteur",
    province:"Marrakech", lat: "33.589656",
    lng: "-7.6149976"
  },


  {
    name: "Laboratoire de Biologie Médicale et Recherche, Biomer",
    city: "Casablanca",
    address: "Passage Tazi (7 av. Hassan II), esc. B 20080",
    title: "Docteur",
    province:"Marrakech", lat: "33.5942676",
    lng: "-7.6181463"
  },


  {
    name: "Laboratoire Armes",
    city: "Casablanca",
    address: "55, avenue Moulay Hassan 1er 20070",
    title: "Docteur",
    province:"Marrakech", lat: "33.5945265",
    lng: "-7.6209228"
  },


  {
    name: "Laboratoire Bioclas",
    city: "Casablanca",
    address: "82 RUE SOUMlngA ANGLE BD ABDELMOUMEN",
    title: "Docteur",
    province:"Marrakech", lat: "33.5771875",
    lng: "-7.6259375"
  },


  {
    name: "Laboratoire d'Analyses Médicales Sentissi",
    city: "Casablanca",
    address: "305, bd Bir Anzarane, tour 2 résid. Romandie 2 20370",
    title: "Docteur",
    province:"Marrakech", lat: "33.5851564",
    lng: "-7.6471111"
  },


  {
    name: "Laboratoire Sidi Othamn",
    city: "Casablanca",
    address: "31, avenue Abou Houra\u00EFra 20700",
    title: "Docteur",
    province:"Marrakech", lat: "33.5630578",
    lng: "-7.5703083"
  },


  {
    name: "Labomedic",
    city: "Casablanca",
    address: "462, rue Mostapha El Maani, 1°ét. 20140 ",
    title: "Docteur",
    province:"Marrakech", lat: "33.583679",
    lng: "-7.6252856"
  },


  {
    name: "Laboratoire Central d'Analyses Médicales ",
    city: "Casablanca",
    address: "1, place du Seize Novembre, 1°ét. 20080",
    title: "Docteur",
    province:"Marrakech", lat: "33.5929935",
    lng: "-7.6238624"
  },


  {
    name: "Laboratoires d'Applications Scientifiques Coudon",
    city: "Casablanca",
    address: "11, rue El Houcine ben Ali -ex Murdoch 20070",
    title: "Docteur",
    province:"Marrakech", lat: "33.5929846",
    lng: "-7.6238624"
  },


  {
    name: "Laboratoire Regragui",
    city: "Casablanca",
    address: "Angle bd sidi Abderrahmane et Abdelhadi Boutaleb,hay hassani , Casablanca",
    title: "Docteur",
    province:"Marrakech", lat: "33.5769123",
    lng: "-7.6757614"
  },


  {
    name: "Laboratoire Benjelloun d'analyses médicales",
    city: "Casablanca",
    address: "109, bd Omar Al Khayam. Beauséjour, Casablanca",
    title: "Docteur",
    province:"Marrakech", lat: "33.5627729",
    lng: "-7.6509047"
  },


  {
    name: "Laboratoire Panoramique",
    city: "Casablanca",
    address: "195 angle bd Panoramique et 2 Mars, Ain Chock",
    title: "Docteur",
    province:"Marrakech", lat: "33.5333001",
    lng: "-7.5856181"
  },


  {
    name: "Laboratoire d'analyses médicales GLAB",
    city: "Casablanca",
    address: " 93, bd Anoual, Casablanca",
    title: "Docteur",
    province:"Marrakech", lat: "33.5693816",
    lng: "-7.6218298"
  },


  {
    name: "Laboratoires d'analyses médicales Farah Essalam",
    city: "Casablanca",
    address: "36, imm A, Farah Essalam. Al Azhar, Oulfa, Casablanca",
    title: "Docteur",
    province:"Marrakech", lat: "33.5409529",
    lng: "-7.6926863"
  },


  {
    name: "Laboratoire LIAB",
    city: "Casablanca",
    address: "Angle Boulevard Abdelmoumen et Rue Arrachati",
    title: "Docteur",
    province:"Marrakech", lat: "33.5795337",
    lng: "-7.6270003"
  },


  {
    name: "Laboratoire Al Qods",
    city: "Casablanca",
    address: "158, bd de la Grande Ceinture. hay Mohammadi, Casablanca",
    title: "Docteur",
    province:"Marrakech", lat: "33.5828181",
    lng: "-7.5572434"
  },


  {
    name: "Laboratoire Arrazi",
    city: "Casablanca",
    address: "125, av Mers Sultan, Casablanca",
    title: "Docteur",
    province:"Marrakech", lat: "33.5857906",
    lng: "-7.6185773"
  },


  {
    name: "Laboratoire BioQods",
    city: "Casablanca",
    address: "Angle Boulevard al Qods et Bd lngafa, 1er étage, Résidence des Princes, Immeuble B",
    title: "Docteur",
    province:"Marrakech", lat: "33.5344524",
    lng: "-7.61412"
  },


  {
    name: "Laboratoire Biolam",
    city: "Casablanca",
    address: "314, rue Mustapha El Maani, Casablanca",
    title: "Docteur",
    province:"Marrakech", lat: "33.5874038",
    lng: "-7.6205746"
  },


  {
    name: "Laboratoire Cirab",
    city: "Casablanca",
    address: "90, av 2 Mars, Casablanca",
    title: "Docteur",
    province:"Marrakech", lat: "33.5768874",
    lng: "-7.6176563"
  },


  {
    name: "Laboratoire Osman",
    city: "Casablanca",
    address: "21, rue Abou Abdallah Nafii. rés. Benomar, Casablanca",
    title: "Docteur",
    province:"Marrakech", lat: "33.584401",
    lng: "-7.6347578"
  },


  {
    name: "Laboratoire Sqalli",
    city: "Casablanca",
    address: "240, bd Brahim Roudani (ex route El Jadida), Casablanca",
    title: "Docteur",
    province:"Marrakech", lat: "33.5729204",
    lng: "-7.6437668"
  },


  {
    name: "Laboratoire Tazi",
    city: "Casablanca",
    address: "78, rue d'Alger, Casablanca",
    title: "Docteur",
    province:"Marrakech", lat: "33.5909124",
    lng: "-7.6277141"
  },


  {
    name: "Laboratoire Borj El Bernoussi",
    city: "Casablanca",
    address: "612, bd Souhaib Arroumi. Sidi Bernoussi, Casablanca",
    title: "Docteur",
    province:"Marrakech", lat: "33.6088346",
    lng: "-7.5029862"
  },


  {
    name: "Laboratoire Du Dispensaire",
    city: "Casablanca",
    address: "110, rue Bachir Belhoucine Jamila 4.Cité Djemaa, Casablanca",
    province:"Marrakech", lat: "33.546652",
    lng: "-7.5687711"
  },


  {
    name: "Laboratoire Aba Chouaib Doukkali",
    city: "Casablanca",
    address: "439, bd Aba Chouaib Doukkali. hay Farah, Casablanca",
    title: "Docteur",
    province:"Marrakech", lat: "33.56662",
    lng: "-7.5892682"
  },


  {
    name: "Laboratoire Bandoeng",
    city: "Casablanca",
    address: "11 ter, rue Mohamed Diouri, Casablanca",
    title: "Docteur",
    province:"Marrakech", lat: "33.5926174",
    lng: "-7.6090907"
  },


  {
    name: "Labomac",
    city: "Casablanca",
    address: "40, rue du Prince Mly Abdellah, Casablanca",
    title: "Docteur",
    province:"Marrakech", lat: "33.5934495",
    lng: "-7.6188356"
  },


  {
    name: "Laboratoire du Maghreb",
    city: "Casablanca",
    address: "283, bd Zerktouni, Casablanca",
    title: "Docteur",
    province:"Marrakech", lat: "33.5925209",
    lng: "-7.6372703"
  },


  {
    name: "Laboratoire Semlali",
    city: "El Jadida",
    address: "138, av Chouaib Doukkali, El Jadida",
    title: "Docteur",
    province:"Marrakech", lat: "33.2501523",
    lng: "-8.5105605"
  },


  {
    name: "Laboratoire Jorf",
    city: "El Jadida",
    address: "118, rue Louis Pasteur",
    title: "Docteur",
    province:"Marrakech", lat: "33.2521705",
    lng: "-8.5088464"
  },


  {
    name: "Laboratoire de biologie médicale Benchekchou",
    city: "El Jadida",
    address: "1, lot Californie II. bd Jabrane Khalil Jabrane",
    title: "Docteur",
    province:"Marrakech", lat: "33.2396434",
    lng: "-8.4809358"
  },


  {
    name: "Laboratoire Imane",
    city: "El Jadida",
    address: "2, av Nakhil",
    title: "Docteur",
    province:"Marrakech", lat: "33.2440064",
    lng: "-8.495365"
  },


  {
    name: "Laboratoire Almassira d'analyses médicales",
    city: "El Jadida",
    address: "21, bd Al Massira",
    title: "Docteur",
    province:"Marrakech", lat: "33.2385096",
    lng: "-8.4867843"
  },


  {
    name: "Laboratoire Achifa",
    city: "El Kelaa Des Sraghna",
    address: "64, bd lngoussef Ben Tachfine",
    title: "Docteur",
    province:"Marrakech", lat: "33.5356427",
    lng: "-7.7253576"
  },


  {
    name: "Laboratoire d'analyses médicales",
    city: "Errachidia",
    address: "2, rue Ait Khlifa",
    title: "Docteur",
    province:"Marrakech", lat: "31.9283378",
    lng: "-4.4296027"
  },


  {
    name: "Laboratoire Mogador",
    city: "Essaouira",
    address: "Imm Galaxy 17 lot Tamanar",
    title: "Docteur",
    province:"Marrakech", lat: "31.5103277",
    lng: "-9.7596943"
  },


  {
    name: "Laboratoire Abdelkrim Khattabi",
    city: "Essaouira",
    address: "287, bd Oued El Makhazin",
    title: "Docteur",
    province:"Marrakech", lat: "31.513719",
    lng: "-9.7658968"
  },


  {
    name: "Laboratoire Moderne",
    city: "Fès",
    address: "9, av Lalla Meryem",
    title: "Docteur",
    province:"Marrakech", lat: "34.0423621",
    lng: "-5.0017943"
  },


  {
    name: "Laboratoire Annakhil ",
    city: "Fès",
    address: "lotiss. Riad Nakhil rte d'Imouzzer",
    title: "Docteur",
    province:"Marrakech", lat: "33.9950751",
    lng: "-4.9935889"
  },


  {
    name: "Laboratoire Filali",
    city: "Fès",
    address: "16, av Slaoui",
    title: "Docteur",
    province:"Marrakech", lat: "34.0374514",
    lng: "-5.0002809"
  },


  {
    name: "Laboratoire Central",
    city: "Fès",
    address: "119, bd Mohamed V",
    title: "Docteur",
    province:"Marrakech", lat: "34.0394919",
    lng: "-4.9989545"
  },


  {
    name: "Laboratoire Guessous",
    city: "Fès",
    address: "53, av des Far",
    title: "Docteur",
    province:"Marrakech", lat: "34.0344076",
    lng: "-4.998217"
  },


  {
    name: "Laboratoire Benchekroun",
    city: "Fès",
    address: "41, av Hassan II",
    title: "Docteur",
    province:"Marrakech", lat: "34.0420022",
    lng: "-4.9982573"
  },


  {
    name: "BIOLABO Avicenne",
    city: "Fès",
    address: "34, bd Mohamed V",
    title: "Docteur",
    province:"Marrakech", lat: "34.0390364",
    lng: "-4.998578"
  },


  {
    name: "Laboratoire Al Fath",
    city: "Fès",
    address: "5, bd Abdelali Benchekroun",
    title: "Docteur",
    province:"Marrakech", lat: "34.0402422",
    lng: "-4.9981721"
  },


  {
    name: "Laboratoire d'analyses Tagemouati",
    city: "Fès",
    address: "15, av Lalla Meryem",
    title: "Docteur",
    province:"Marrakech", lat: "33.5478784",
    lng: "-7.6414976"
  },


  {
    name: "Laboratoire d'analyses médicales Abouinane",
    city: "Fès",
    address: "8, rue Cherif Radi. Imm Taj",
    title: "Docteur",
    province:"Marrakech", lat: "34.0350099",
    lng: "-4.9992012"
  },


  {
    name: "Laboratoire d'analyses médicales Kandoussi",
    city: "Fès",
    address: "67, des Far. bureaux Al Fath",
    title: "Docteur",
    province:"Marrakech", lat: "34.0353268",
    lng: "-5.0023961"
  },


  {
    name: "Laboratoire Saada d'analyses médicales",
    city: "Fès",
    address: "av Saint Louis, rue Sindiane n°82. quartier Zaz",
    title: "Docteur",
    province:"Marrakech", lat: "34.0909781",
    lng: "-5.6513137"
  },


  {
    name: "Laboratoire Le Palais",
    city: "Fès",
    address: "8, rue Aman",
    title: "Docteur",
    province:"Marrakech", lat: "34.048179",
    lng: "-5.0013097"
  },


  {
    name: "Laboratoire d'analyses médicales Ghrissi",
    city: "Fès",
    address: "hay Essaada, rue 6 n°5. VN",
    title: "Docteur",
    province:"Marrakech", lat: "34.0251892",
    lng: "-5.0074803"
  },


  {
    name: "Laboratoire Rihab",
    city: "Fès",
    address: "99, av Allal Ben Abdellah. Rihab Fès",
    title: "Docteur",
    province:"Marrakech", lat: "34.0355703",
    lng: "-5.0068083"
  },


  {
    name: "Laboratoire Agdal",
    city: "Fès",
    address: "54, av Hassan II. imm Mauritania",
    title: "Docteur",
    province:"Marrakech", lat: "34.0148187",
    lng: "-6.4845268"
  },


  {
    name: "Laboratoire Saiss",
    city: "Fès",
    address: "79, imm. Hanane. hay Narjis, Al Amal. en face Faculté de Médecine",
    title: "Docteur",
    province:"Marrakech", lat: "34.0072025",
    lng: "-4.9682323"
  },


  {
    name: "Laboratoire Abouzid d'analyses médicales",
    city: "Fès",
    address: "lot Ouafae 4, lot n°357. route de Sefrou",
    title: "Docteur",
    province:"Marrakech", lat: "34.0069355",
    lng: "-4.9687436"
  },


  {
    name: "Laboratoire Adib",
    city: "Fès",
    address: "31, bd Karaouiene. hay Lamcalla",
    title: "Docteur",
    province:"Marrakech", lat: "34.0644328",
    lng: "-5.0049049"
  },


  {
    name: "Laboratoire Benjelloun d'analyses médicales",
    city: "Fès",
    address: "116, bd Mohamed V. bureaux Nada",
    title: "Docteur",
    province:"Marrakech", lat: "34.0385984",
    lng: "-5.000376"
  },


  {
    name: "Laboratoire d'analyses médicales Bouayad",
    city: "Fès",
    address: "av Moulay Rachid, 3, lot Basma. route de Sefrou",
    title: "Docteur",
    province:"Marrakech", lat: "34.0113718",
    lng: "-4.9828343"
  },


  {
    name: "Laboratoire Fès d'analyses médicales",
    city: "Fès",
    address: "bd Bir Anzarane, lot n°8. rés Nour",
    title: "Docteur",
    province:"Marrakech", lat: "34.0113715",
    lng: "-4.9894004"
  },


  {
    name: "Laboratoire Fnideq",
    city: "Fnideq",
    address: "53, rue de Rabat",
    title: "Docteur",
    province:"Marrakech", lat: "35.848188",
    lng: "-5.3590337"
  },


  {
    name: "Laboratoire Hafir",
    city: "Fquih Ben Salah",
    address: " 12, rue 28. Lakouacem",
    title: "Docteur",
    province:"Marrakech", lat: "32.4973692",
    lng: "-6.6846218"
  },


  {
    name: "Laboratoire d'analyses médicales Errazi",
    city: "Fquih Ben Salah",
    address: "37 bis, av Hassan II",
    title: "Docteur",
    province:"Marrakech", lat: "33.5908599",
    lng: "-7.5686393"
  },


  {
    name: "LABOCENTRE d'analyses médicales",
    city: "Fquih Ben Salah",
    address: "62, av Hassan II",
    title: "Docteur",
    province:"Marrakech", lat: "32.5018194",
    lng: "-6.686801"
  },


  {
    name: "Laboratoire Essaada",
    city: "Inezgane",
    address: "109, av Hassan 1er. les Amicales",
    title: "Docteur",
    province:"Marrakech", lat: "30.3552636",
    lng: "-9.5382651"
  },


  {
    name: "Laboratoire Jamal",
    city: "Inezgane",
    address: "1, av des Far",
    title: "Docteur",
    province:"Marrakech", lat: "30.3639868",
    lng: "-9.5387736"
  },


  {
    name: "Laboratoire Hagounia",
    city: "Inezgane",
    address: "av Mokhtar Soussi, imm Hagounia",
    title: "Docteur",
    province:"Marrakech", lat: "30.360302",
    lng: "-9.5280889"
  },


  {
    name: "Laboratoire Argana",
    city: "Inezgane",
    address: "1, bd Mohamed V. imm Sheherazad",
    title: "Docteur",
    province:"Marrakech", lat: "30.35846",
    lng: "-9.533585"
  },


  {
    name: "Laboratoire B Lab",
    city: "Inezgane",
    address: "avenue mokhtar soussi, immeuble el far, 2éme etage. 80000",
    title: "Docteur",
    province:"Marrakech", lat: "30.3593437",
    lng: "-9.5292803"
  },


  {
    name: "Laboratoire Akchouch",
    city: "Inezgane",
    address: "29, av Moulay Ali Cherif",
    title: "Docteur",
    province:"Marrakech", lat: "30.352247",
    lng: "-9.5376979"
  },


  {
    name: "Laboratoire Firdaous",
    city: "Kénitra",
    address: "322 A, bd Mohamed V",
    title: "Docteur",
    province:"Marrakech", lat: "34.2618892",
    lng: "-6.5788687"
  },


  {
    name: "Laboratoire Sidi Larbi Boujemaa",
    city: "Kénitra",
    address: "272, bd Mohamed V",
    title: "Docteur",
    province:"Marrakech", lat: "34.2632138",
    lng: "-6.5742471"
  },


  {
    name: "DHL LAB",
    city: "Kénitra",
    address: "bd Imam Ali angle av Moulay Abdellah",
    title: "Docteur",
    province:"Marrakech", lat: "34.2635625",
    lng: "-6.5844375"
  },


  {
    name: "Laboratoire Central",
    city: "Kénitra",
    address: "413, bd Mohamed V. Imm. Ouarda",
    title: "Docteur",
    province:"Marrakech", lat: "34.2595625",
    lng: "-6.5839375"
  },


  {
    name: "Laboratoire du Gharb",
    city: "Kénitra",
    address: "angle bd Mohamed V et rue Sebta",
    title: "Docteur",
    province:"Marrakech", lat: "34.2608809",
    lng: "-6.5835251"
  },


  {
    name: "Laboratoire Ittihad",
    city: "Kénitra",
    address: "212, av Mohamed V. rue 41 n° 3",
    title: "Docteur",
    province:"Marrakech", lat: "34.2635798",
    lng: "-6.5720059"
  },


  {
    name: "Laboratoire d'analyses de biologie Mimosas",
    city: "Kénitra",
    address: "3, angle av Mohamed V et rue Med El Qorri",
    title: "Docteur",
    province:"Marrakech", lat: "34.2566625",
    lng: "-6.595725"
  },


  {
    name: "Laboratoire d'analyses médicales Maamora",
    city: "Kénitra",
    address: "150, av Mohamed Diouri",
    title: "Docteur",
    province:"Marrakech", lat: "34.2584579",
    lng: "-6.5894335"
  },


  {
    name: "Laboratoire Alhikma",
    city: "Kénitra",
    address: "bloc 1 n°87. Ouled Oujih",
    title: "Docteur",
    province:"Marrakech", lat: "34.2578374",
    lng: "-6.6236592"
  },


  {
    name: "Laboratoire Saada",
    city: "Kénitra",
    address: "427, av Mohamed Diouri",
    title: "Docteur",
    province:"Marrakech", lat: "34.2592853",
    lng: "-6.5867744"
  },


  {
    name: "Laboratoire Saknia",
    city: "Kénitra",
    address: "73, bd Massira Khaa",
    title: "Docteur",
    province:"Marrakech", lat: "34.2462059",
    lng: "-6.5551496"
  },


  {
    name: "Laboratoire Ibn Sina",
    city: "Kénitra",
    address: "angle bd Mohamed V et rue Ibn Abi Zarara",
    title: "Docteur",
    province:"Marrakech", lat: "34.2462446",
    lng: "-6.5704705"
  },


  {
    name: "Laboratoire Khémisset d'Analyses Médicales ",
    city: "Khémissat",
    address: "140 Avenue Khalid Ibn Al Oualid 15000",
    title: "Docteur",
    province:"Marrakech", lat: "33.8304475",
    lng: "-6.0637155"
  },


  {
    name: "Laboratoire El Harbi",
    city: "Khénifra",
    address: "av Mohamed V",
    title: "Docteur",
    province:"Marrakech", lat: "32.9743417",
    lng: "-5.6449162"
  },


  {
    name: "LABO Atlas",
    city: "Khénifra",
    address: "hay Hammou Hassan, rue 2 n°237 bis",
    province:"Marrakech", lat: "32.3424475",
    lng: "-6.3779877"
  },


  {
    name: "Laboratoire Sbai",
    city: "Khénifra",
    address: "7, lot Caid iss n°1",
    title: "Docteur",
    province:"Marrakech", lat: "32.9338786",
    lng: "-5.6739236"
  },


  {
    name: "Laboratoire Central d'Analyses Médicales",
    city: "Khouribga",
    address: "13, Rue Moulay Abderrahmane 25000",
    title: "Docteur",
    province:"Marrakech", lat: "33.5707589",
    lng: "-7.6820664"
  },


  {
    name: "Laboratoire Charfaoui",
    city: "Khouribga",
    address: "32, bd Moulay lngoussef",
    title: "Docteur",
    province:"Marrakech", lat: "32.8920254",
    lng: "-6.9125432"
  },


  {
    name: "Laboratoire Avicienne d'analyses médicales",
    city: "Khouribga",
    address: "5, rue de Marrakech",
    title: "Docteur",
    province:"Marrakech", lat: "32.8813857",
    lng: "-6.9147397"
  },


  {
    name: "Laboratoire Essaada",
    city: "Ksar El Kebir",
    address: "101/103, route de Rabat",
    title: "Docteur",
    province:"Marrakech", lat: "33.5905111",
    lng: "-7.5708541"
  },


  {
    name: "Laboratoire Si Makhlouf",
    city: "Ksar El Kebir",
    address: "3, hay Salam",
    province:"Marrakech", lat: "33.4827232",
    lng: "-7.647925"
  },


  {
    name: "Laboratoire El Kamar",
    city: "Laayoune",
    address: "135, bd de la Mecque",
    title: "Docteur",
    province:"Marrakech", lat: "33.4826838",
    lng: "-7.6479251"
  },


  {
    name: "Laboratoire Medicina ",
    city: "Laayoune",
    address: "3, av de la Place. hay Moulay Rachid",
    province:"Marrakech", lat: "33.5851456",
    lng: "-7.6183586"
  },


  {
    name: "Laboratoire Biologica",
    city: "Larache",
    address: "1 bis, bd Moulay Abdellah",
    title: "Docteur",
    province:"Marrakech", lat: "35.1965406",
    lng: "-6.1546258"
  },


  {
    name: "LABOMED",
    city: "M'diq",
    address: "29, av Oujda. hay Souiniya",
    title: "Docteur",
    province:"Marrakech", lat: "33.5916304",
    lng: "-7.6373218"
  },


  {
    name: "Laboratoire d'Analyses Médicales Tensift",
    city: "Marrakesh",
    address: "7, rte de Targa, c.com. Ennakhil, quartier El Ghoul 40000",
    title: "Docteur",
    province:"Marrakech", lat: "33.5627684",
    lng: "-7.648716"
  },


  {
    name: "Laboratoire Alahbass",
    city: "Marrakesh",
    address: "Imm. des Habbous, av Hassan II. Bab Doukkala",
    title: "Docteur",
    province:"Marrakech", lat: "31.6335631",
    lng: "-8.0037882"
  },


  {
    name: "Laboratoire Anoual",
    city: "Marrakesh",
    address: "3, rue Tarik Bnou Ziad. rés Andalous",
    province:"Marrakech", lat: "33.5693571",
    lng: "-7.6148765"
  },


  {
    name: "Laboratoire Dar El Baroud",
    city: "Marrakesh",
    address: "276, av Mohamed V",
    title: "Docteur",
    province:"Marrakech", lat: "31.6255648",
    lng: "-7.9935129"
  },


  {
    name: "Pharmacie Laboratoire du Harti",
    city: "Marrakesh",
    address: " angle Oued EL Makhazine & lngacoub El Marini",
    title: "Docteur",
    province:"Marrakech", lat: "31.6311687",
    lng: "-8.010617"
  },


  {
    name: "Pharmacie Laboratoire La Liberté",
    city: "Marrakesh",
    address: "1, place de la Liberté",
    title: "Docteur",
    province:"Marrakech", lat: "31.6360604",
    lng: "-8.0109608"
  },


  {
    name: "Laboratoire du Tensift",
    city: "Marrakesh",
    address: "quartier El Ghoul, route de Targa, n°7 centre Ennakhil",
    title: "Docteur",
    province:"Marrakech", lat: "31.638319",
    lng: "-8.0186634"
  },


  {
    name: "Laboratoire du Pont",
    city: "Marrakesh",
    address: "hay Al Massira 1, Lot D n° 66",
    title: "Docteur",
    province:"Marrakech", lat: "31.6348699",
    lng: "-8.0601573"
  },


  {
    name: "Laboratoire Ibn Sina",
    city: "Marrakesh",
    address: "13, rue Mauritanie",
    title: "Docteur",
    province:"Marrakech", lat: "33.5805684",
    lng: "-7.6348704"
  },


  {
    name: "Laboratoire Majorelle",
    city: "Marrakesh",
    address: "angle av Allal Fassi et lngacoub El Mansour",
    title: "Docteur",
    province:"Marrakech", lat: "31.6401833",
    lng: "-7.9996471"
  },


  {
    name: "Laboratoire Nakhil",
    city: "Marrakesh",
    address: "av lngacoub El Mansour. imm Nakhil",
    title: "Docteur",
    province:"Marrakech", lat: "33.9894054",
    lng: "-6.8179533"
  },


  {
    name: "Laboratoire Belkhayat",
    city: "Marrakesh",
    address: "580 C, hay El Massira I",
    title: "Docteur",
    province:"Marrakech", lat: "31.634393",
    lng: "-8.060978"
  },


  {
    name: "Laboratoire lngacoud El Mansour",
    city: "Marrakesh",
    address: "31, av lngacoub El Mansour",
    title: "Docteur",
    province:"Marrakech", lat: "31.6388688",
    lng: "-8.0050935"
  },


  {
    name: "Laboratoire Mhamid",
    city: "Marrakesh",
    address: "595, av Tafilalet. Mhamid",
    title: "Docteur",
    province:"Marrakech", lat: "31.5932873",
    lng: "-8.0418072"
  },


  {
    name: "Laboratoire Pasteur",
    city: "Marrakesh",
    address: "bd Allal El Fassi, rés El Houda n°7",
    title: "Docteur",
    province:"Marrakech", lat: "33.5733859",
    lng: "-7.6257871"
  },


  {
    name: "Laboratoire Ibn Tofail",
    city: "Marrakesh",
    address: "57, rue Ibn Aicha. rés Nfiis",
    title: "Docteur",
    province:"Marrakech", lat: "31.6508064",
    lng: "-8.0245636"
  },


  {
    name: "Laboratoire Dar Albir",
    city: "Marrakesh",
    address: "av Ibn Sina. établissement dar Al Bir Walihssan, bat Q",
    title: "Docteur",
    province:"Marrakech", lat: "31.6624087",
    lng: "-7.9955793"
  },


  {
    name: "Laboratoire de biologie médicale Gueliz",
    city: "Marrakesh",
    address: "182, av Abdelkrim Khattabi. rés Zaitouna",
    title: "Docteur",
    province:"Marrakech", lat: "31.6330151",
    lng: "-8.0186116"
  },


  {
    name: "Laboratoire Andalouss d'analyses médicales",
    city: "Marrakesh",
    address: "168, lot Boumesmar, hay Mohammadi",
    title: "Docteur",
    province:"Marrakech", lat: "31.6490161",
    lng: "-8.019676"
  },


  {
    name: "Laboratoire d'analyses médicales Bab Doukkala",
    city: "Marrakesh",
    address: "13, bd Prince Mly Abdellah. rés Bab Doukkala, bloc A",
    title: "Docteur",
    province:"Marrakech", lat: "31.6440229",
    lng: "-8.0142794"
  },


  {
    name: "Laboratoire Al Manar",
    city: "Marrakesh",
    address: "164, hay Ibnou Sina ( face Chu Mohamed VI). BP : 4000",
    title: "Docteur",
    province:"Marrakech", lat: "31.6652151",
    lng: "-7.9967893"
  },


  {
    name: "Laboratoire Al Massira",
    city: "Marrakesh",
    address: "hay Al Massira I , lotiss. C, villa n°18",
    province:"Marrakech", lat: "31.6328941",
    lng: "-8.0553042"
  },


  {
    name: "Laboratoire Al Atlas d'analyses médicales",
    city: "Marrakesh",
    address: "rond point route de Rabat et Safi. imm Loumami n°7",
    title: "Docteur",
    province:"Marrakech", lat: "31.6542206",
    lng: "-8.021696"
  },


  {
    name: "Laboratoire de biologie médicale Menara",
    city: "Marrakesh",
    address: "imm Al Amane, C90. Massira 1",
    title: "Docteur",
    province:"Marrakech", lat: "32.5955311",
    lng: "-8.9507988"
  },


  {
    name: "Laboratoire Al Farabi",
    city: "Marrakesh",
    address: "lot Marrakech n°1263. Mhamid",
    province:"Marrakech", lat: "31.5995731",
    lng: "-8.0676377"
  },


  {
    name: "Laboratoire Al Haouz d'analyses médicales",
    city: "Marrakesh",
    address: "bd Guemassa, rés Al Bassatine, imm n°93 et 82. Mhamid 9",
    title: "Docteur",
    province:"Marrakech", lat: "31.5882628",
    lng: "-8.0406947"
  },


  {
    name: "Laboratoire l'Aéroport",
    city: "Marrakesh",
    address: "lot n°378, Mhamid 3. imm Charaf",
    province:"Marrakech", lat: "31.594356",
    lng: "-8.0318154"
  },


  {
    name: "Laboratoire Sybabio",
    city: "Marrakesh",
    address: " 4, av Lamcalla. Syba 3",
    province:"Marrakech", lat: "31.6284461",
    lng: "-8.058103"
  },


  {
    name: "Laboratoire Eliraki",
    city: "Meknès",
    address: "1, rue Zemmouri. Dar Smen",
    province:"Marrakech", lat: "33.8937471",
    lng: "-5.5657236"
  },


  {
    name: "Laboratoire d'analyses médicales Guerras",
    city: "Meknès",
    address: "bd Mohamed V. Imm. Khamlich",
    province:"Marrakech", lat: "33.8937468",
    lng: "-5.5722897"
  },


  {
    name: "Laboratoire El Founini",
    city: "Meknès",
    address: "7, place du Marché",
    province:"Marrakech", lat: "33.8991654",
    lng: "-5.5527375"
  },


  {
    name: "Laboratoire Nejjar",
    city: "Meknès",
    address: "6, rue Pasteur",
    province:"Marrakech", lat: "34.0384611",
    lng: "-6.8087921"
  },


  {
    name: "Laboratoire Chbicheb",
    city: "Meknès",
    address: "rue Houcine Ben Ali. rés Atlas",
    title: "Docteur",
    province:"Marrakech", lat: "33.8998499",
    lng: "-5.5482084"
  },


  {
    name: "Laboratoire Ala",
    city: "Meknès",
    address: "24, av Hassan II",
    title: "Docteur",
    province:"Marrakech", lat: "33.8988059",
    lng: "-5.5543604"
  },


  {
    name: "Laboratoire d'analyses El Maadani",
    city: "Meknès",
    address: "23, rue Antsirabé",
    province:"Marrakech", lat: "33.8988059",
    lng: "-5.5543604"
  },


  {
    name: "Laboratoire Ismailia",
    city: "Meknès",
    address: "2, av Ennasr. diour Salam. imm Al Aaila",
    province:"Marrakech", lat: "33.8988059",
    lng: "-5.5543604"
  },


  {
    name: "Laboratoire Zitoune",
    city: "Meknès",
    address: "93, av Med Ben Ahmed Edighoussi. Zitoune. BP : 11168",
    province:"Marrakech", lat: "33.961893",
    lng: "-6.8658327"
  },


  {
    name: "Laboratoire Alwilaya d'analyses médicales",
    city: "Meknès",
    address: "rue Abou Hassan Al Marini, rés Amira",
    province:"Marrakech", lat: "33.9618927",
    lng: "-6.8723988"
  },


  {
    name: "Laboratoire Tafilalet",
    city: "Meknès",
    address: "1, rue Melilia, rés Atlas",
    province:"Marrakech", lat: "33.898661",
    lng: "-5.5474927"
  },


  {
    name: "Laboratoire Biougnach",
    city: "Meknès",
    address: " av Prince Moulay Abdellah. 8 lot Al Khawarizmi",
    title: "Docteur",
    province:"Marrakech", lat: "33.9015482",
    lng: "-5.5502312"
  },


  {
    name: "Laboratoire El Menzeh",
    city: "Meknès",
    address: "26, av Iiss II. bureaux place Administrative",
    province:"Marrakech", lat: "33.8969488",
    lng: "-5.5503924"
  },


  {
    name: "Laboratoire de Midelt",
    city: "Midelt",
    address: "13, place Al Ittihad",
    province:"Marrakech", lat: "32.6886833",
    lng: "-4.7430707"
  },


  {
    name: "Laboratoire Central",
    city: "Mohammédia",
    address: "bd Abderrahmane Serghini, imm El Hore n°1",
    title: "Docteur",
    province:"Marrakech", lat: "33.6948513",
    lng: "-7.3926275"
  },


  {
    name: "Laboratoire Assounnah",
    city: "Mohammédia",
    address: "93, bd de la Résistance. Derb Elfath",
    province:"Marrakech", lat: "33.6819387",
    lng: "-7.3906687"
  },


  {
    name: "Laboratoire Amara",
    city: "Mohammédia",
    address: "3, bd Mohammed V",
    province:"Marrakech", lat: "33.6954553",
    lng: "-7.3932268"
  },


  {
    name: "Laboratoire la Colline",
    city: "Mohammédia",
    address: "75, lot Chaouta. bd Sebta",
    province:"Marrakech", lat: "33.6923028",
    lng: "-7.3771134"
  },


  {
    name: "Laboratoire Al Mahatta",
    city: "Nador",
    address: "254, av Hassan II",
    province:"Marrakech", lat: "35.1752353",
    lng: "-2.9248718"
  },


  {
    name: "Laboratoire Pasteur",
    city: "Nador",
    address: "103, route Principale de Touima",
    province:"Marrakech", lat: "34.3710761",
    lng: "-6.3933682"
  },


  {
    name: "Laboratoire Al Masjid",
    city: "Nador",
    address: "41, bd lngoussef Ben Tachfine",
    province:"Marrakech", lat: "33.5618884",
    lng: "-7.6067166"
  },


  {
    name: "Laboratoire Nakhil",
    city: "Nador",
    address: "178, bd lngoussef Ben Tachfine",
    province:"Marrakech", lat: "33.9894054",
    lng: "-6.820142"
  },


  {
    name: "Laboratoire 3 Mars",
    city: "Nador",
    address: "53, av 3 Mars",
    province:"Marrakech", lat: "33.9894041",
    lng: "-6.8354629"
  },


  {
    name: "Laboratoire Imad Attaibi ",
    city: "Nador",
    address: "171, bd lngoussef Ben Tachfine",
    title: "Docteur",
    province:"Marrakech", lat: "35.176664",
    lng: "-2.9324667"
  },


  {
    name: "Laboratoire Alpha",
    city: "Nador",
    address: "5, rue Larache",
    title: "Docteur",
    province:"Marrakech", lat: "35.1769196",
    lng: "-2.9294874"
  },


  {
    name: "Laboratoire Daraa d'analyses médicales",
    city: "Ouarzazate",
    address: "22, rue Al Mouahiddine. Kissariat Al Mouahiddin",
    province:"Marrakech", lat: "30.9212225",
    lng: "-6.9193082"
  },


  {
    name: "Laboratoire Al Hayat ",
    city: "Ouezzane",
    address: "3, av Allal Ben Abdellah",
    province:"Marrakech", lat: "34.0428469",
    lng: "-6.7931222"
  },


  {
    name: "Laboratoire Moumni",
    city: "Oujda",
    address: "13, bd Oumam Moutahida",
    province:"Marrakech", lat: "34.6786",
    lng: "-1.9203094"
  },


  {
    name: "Laboratoire Rahmani",
    city: "Oujda",
    address: "bd Zerktouni. rés. Zerktouni",
    province:"Marrakech", lat: "34.6807409",
    lng: "-1.9213823"
  },


  {
    name: "Laboratoire El Qods",
    city: "Oujda",
    address: "3, bd Mohammed V. Imm. Kada",
    province:"Marrakech", lat: "34.6796077",
    lng: "-1.9207762"
  },


  {
    name: "Laboratoire Hijaz",
    city: "Oujda",
    address: "40, bd El Hijaz",
    province:"Marrakech", lat: "34.6741141",
    lng: "-1.9197662"
  },


  {
    name: "Laboratoire Al Farabi",
    city: "Oujda",
    address: "3, rue Errazi. rés Al Mourabitine",
    province:"Marrakech", lat: "34.6747674",
    lng: "-1.9158536"
  },


  {
    name: "Laboratoire Route de Taza",
    city: "Oujda",
    address: "route de Taza, hay Si Lakhdar",
    province:"Marrakech", lat: "34.679276",
    lng: "-1.9334382"
  },


  {
    name: "Laboratoire Oujda",
    city: "Oujda",
    address: " bd Iiss Alakbar. Boukharabila",
    province:"Marrakech", lat: "34.6792977",
    lng: "-1.9400043"
  },


  {
    name: "Laboratoire Zoheir",
    city: "Oujda",
    address: "place 16 Aout",
    province:"Marrakech", lat: "34.6830938",
    lng: "-1.9168112"
  },


  {
    name: "Laboratoire Aarab",
    city: "Oujda",
    address: "47 bis, av Nations Unies",
    province:"Marrakech", lat: "34.6787621",
    lng: "-1.9204295"
  },


  {
    name: "Laboratoire d'analyses médicales Abdelmoumen",
    city: "Oujda",
    address: "angle rues Jakarta et Lieutenant Belhoucine. rés Saidi",
    province:"Marrakech", lat: "34.675403",
    lng: "-1.913851"
  },


  {
    name: "Laboratoire Mokhtari",
    city: "Oujda",
    address: "44, rue Al Mourabitine. Al Mahatta",
    title: "Docteur",
    province:"Marrakech", lat: "34.6856435",
    lng: "-1.9218295"
  },


  {
    name: "Laboratoire Zeghli",
    city: "Oujda",
    address: "71, bd Allal Ben Abdellah",
    province:"Marrakech", lat: "34.679437",
    lng: "-1.922012"
  },


  {
    name: "Laboratoire Houari",
    city: "Oujda",
    address: "lotissement les Iris, route Aouiate Serrak n° 835",
    title: "Docteur",
    province:"Marrakech", lat: "34.6580451",
    lng: "-1.9158846"
  },


  {
    name: "Laboratoire Bouchentouf",
    city: "Oujda",
    address: "av Armée de Libération",
    province:"Marrakech", lat: "34.6716902",
    lng: "-1.9222212"
  },


  {
    name: "Laboratoire Ibn Nafiss",
    city: "Rabat",
    address: "8, bd Ibn Rochd 10080",
    title: "Professeur",
    province:"Marrakech", lat: "33.9875088",
    lng: "-6.8543378"
  },


  {
    name: "Laboratoire du Maghreb",
    city: "Rabat",
    address: "5, rue Hussein 1er, Hassan 10000",
    province:"Marrakech", lat: "34.0166762",
    lng: "-6.8339109"
  },


  {
    name: "Laboratoire Bouazzaoui",
    city: "Rabat",
    address: "46, avenue Maghrib Al Arabi, 1°ét., appt.1-2 10040",
    province:"Marrakech", lat: "34.0189414",
    lng: "-6.8515157"
  },


  {
    name: "Centre de biologie Riad Ennakhil",
    city: "Rabat",
    address: "secteur 11, bloc Q lot n°5. angle av Ennakhil et Attanoub. hay Ryad",
    province:"Marrakech", lat: "33.9517349",
    lng: "-6.8644286"
  },


  {
    name: "Laboratoire Prof Alaoui",
    city: "Rabat",
    address: "21, rue Zyayda. Aviation",
    province:"Marrakech", lat: "33.9902273",
    lng: "-6.827641"
  },


  {
    name: "Centre de Biologie Souissi",
    city: "Rabat",
    address: "38, av El Haouz. Souissi",
    title: "Docteur",
    province:"Marrakech", lat: "33.9831103",
    lng: "-6.8298392"
  },


  {
    name: "Laboratoire Alaoui",
    city: "Rabat",
    address: "13, rue de Tunis",
    title: "Professeur",
    province:"Marrakech", lat: "33.983181",
    lng: "-6.86267"
  },


  {
    name: "Laboratoire Benhassain",
    city: "Rabat",
    address: "4, rue Tihana",
    title: "Docteur",
    province:"Marrakech", lat: "34.0181551",
    lng: "-6.8374451"
  },


  {
    name: "Laboratoire El Wafae",
    city: "Rabat",
    address: "50, bd El Haouz. cité Administrative",
    province:"Marrakech", lat: "33.9852885",
    lng: "-6.826273"
  },


  {
    name: "Laboratoire Al Wifaq",
    city: "Rabat",
    address: "3, av Ibn Sina",
    province:"Marrakech", lat: "33.9254714",
    lng: "-6.9317039"
  },


  {
    name: "Laboratoire Central",
    city: "Rabat",
    address: "21, rés. Minaret. rue Amman",
    province:"Marrakech", lat: "34.0211893",
    lng: "-6.8348788"
  },


  {
    name: "Laboratoire Sentissi",
    city: "Rabat",
    address: "av Hassan II. imm G, rés. Réda",
    province:"Marrakech", lat: "34.024376",
    lng: "-6.83145"
  },


  {
    name: "Laboratoire Slimani",
    city: "Rabat",
    address: "3, rue Annaba. Hassan",
    province:"Marrakech", lat: "34.0170394",
    lng: "-6.8305315"
  },


  {
    name: "Laboratoire Cité Amal",
    city: "Rabat",
    address: "57, bloc D.O. av Al Massira",
    province:"Marrakech", lat: "33.982644",
    lng: "-6.8827966"
  },


  {
    name: "MEDILAB",
    city: "Rabat",
    address: "angle rues Al Kahira et Ghandi",
    province:"Marrakech", lat: "34.0194743",
    lng: "-6.8364846"
  },


  {
    name: "BIOLAB",
    city: "Rabat",
    address: "4, rue Beyrouth. imm Essaada, entrée n°4",
    province:"Marrakech", lat: "34.021477",
    lng: "-6.8383446"
  },


  {
    name: "Laboratoire Agdal",
    city: "Rabat",
    address: "49, av Atlas. Agdal",
    title: "Docteur",
    province:"Marrakech", lat: "33.996338",
    lng: "-6.8482785"
  },


  {
    name: "Laboratoire  Mamounia",
    city: "Rabat",
    address: "av Hassan II. rés. Mamounia",
    province:"Marrakech", lat: "34.0216461",
    lng: "-6.838763"
  },


  {
    name: "Laboratoire Hassouni",
    city: "Rabat",
    address: "200, av Med Ben Abdellah. Akkari",
    title: "Docteur",
    province:"Marrakech", lat: "34.00831",
    lng: "-6.861332"
  },


  {
    name: "Laboratoire El Atlas",
    city: "Rabat",
    address: "204, av Sidi Med Ben Abdellah. secteur C, ClngM",
    title: "Docteur",
    province:"Marrakech", lat: "33.9953073",
    lng: "-6.8664654"
  },


  {
    name: "Laboratoire du Golf",
    city: "Rabat",
    address: "av Imam Malik, route des Zaers, Km 7,5",
    province:"Marrakech", lat: "33.9406516",
    lng: "-6.8197598"
  },


  {
    name: "POLlngBIO",
    city: "Rabat",
    address: "7, rue Oued Souss. Agdal",
    province:"Marrakech", lat: "34.0015219",
    lng: "-6.8443259"
  },


  {
    name: "PRECILAB",
    city: "Rabat",
    address: "2, rue du Caire angle av Med V",
    province:"Marrakech", lat: "34.019237",
    lng: "-6.8363592"
  },


  {
    name: "Santé LAB",
    city: "Rabat",
    address: "73, rue Patrice Lumumba",
    province:"Marrakech", lat: "34.0133183",
    lng: "-6.8286288"
  },


  {
    name: "Laboratoire Frat Gendarmerie",
    city: "Rabat",
    address: "av Ibn Sina, Agdal",
    province:"Marrakech", lat: "33.9948125",
    lng: "-6.8514375"
  },


  {
    name: "Laboratoire Lebbar",
    city: "Rabat",
    address: "15, rue Moulay Rachid. Hassan",
    title: "Docteur",
    province:"Marrakech", lat: "34.0204634",
    lng: "-6.8309758"
  },


  {
    name: "Laboratoire Al Qods",
    city: "Safi",
    address: "av Ibn Khaldoun",
    title: "Docteur",
    province:"Marrakech", lat: "32.2901001",
    lng: "-9.2382769"
  },


  {
    name: "Laboratoire Al Farabi",
    city: "Safi",
    address: "63, av Kennedy",
    title: "Docteur",
    province:"Marrakech", lat: "32.2869855",
    lng: "-9.2336927"
  },


  {
    name: "Laboratoire Djerifat",
    city: "Safi",
    address: "2, av Kennedy",
    title: "Docteur",
    province:"Marrakech", lat: "32.2891857",
    lng: "-9.2375935"
  },


  {
    name: "Laboratoire Rhazali",
    city: "Salé",
    address: "11, rue El Ayachi",
    province:"Marrakech", lat: "34.0333993",
    lng: "-6.823234"
  },


  {
    name: "Laboratoire Alhayat",
    city: "Salé",
    address: "av Atlas. hay Essalam",
    title: "Docteur",
    province:"Marrakech", lat: "34.0428425",
    lng: "-6.7909335"
  },


  {
    name: "Laboratoire Lamzibri",
    city: "Salé",
    address: "37, av Ben Aboud. Mellah J'did",
    title: "Docteur",
    province:"Marrakech", lat: "34.0348965",
    lng: "-6.8218747"
  },


  {
    name: "Laboratoire Alkhawarizmi",
    city: "Salé",
    address: "21, av Lalla Asmaa. rés. Doukkali.Tabriquet",
    province:"Marrakech", lat: "34.0384611",
    lng: "-6.8066034"
  },


  {
    name: "Laboratoire El Oumnia",
    city: "Salé",
    address: "28, rue Sidi Boumnina. secteur 4. Kariat",
    province:"Marrakech", lat: "33.9891369",
    lng: "-6.8324317"
  },


  {
    name: "Laboratoire El Wahda",
    city: "Salé",
    address: "63, av Hassan II. hay El Inbiat",
    title: "Docteur",
    province:"Marrakech", lat: "34.0493076",
    lng: "-6.781746"
  },


  {
    name: "Laboratoire Errazi",
    city: "Salé",
    address: "bd Mohamed V. imm 1, n°2. Tabriquet",
    province:"Marrakech", lat: "34.0451235",
    lng: "-6.8079465"
  },


  {
    name: "Laboratoire de Salé d'Analyses Médicales",
    city: "Salé",
    address: "10, av Mohamed V. Lot Assia. hay Karima",
    province:"Marrakech", lat: "34.0540625",
    lng: "-6.7928125"
  },


  {
    name: "Pharmacie Laboratoire Al Farah",
    city: "Salé",
    address: "secteur E, n°927. hay Arrahma",
    title: "Docteur",
    province:"Marrakech", lat: "34.033327",
    lng: "-6.7999299"
  },


  {
    name: "Laboratoire le Caducée",
    city: "Salé",
    address: "95, av Mohamed V. hay Karima",
    title: "Docteur",
    province:"Marrakech", lat: "33.959329",
    lng: "-6.825953"
  },


  {
    name: "Laboratoire Lakwass",
    city: "Salé",
    address: "rés Mhaia, imm C. route Kénitra",
    title: "Docteur",
    province:"Marrakech", lat: "34.0492456",
    lng: "-6.8154767"
  },


  {
    name: "Laboratoire Karia",
    city: "Salé",
    address: "58 bis, av Hassan II. hay Walaa, Karia",
    province:"Marrakech", lat: "34.0422273",
    lng: "-6.8115748"
  },


  {
    name: "Laboratoire les Arcades d'Analyses Médicales",
    city: "Salé",
    address: "Avenue Ouali Al Had Sidi Mohamed, Résidence Mehdi n°1",
    title: "Docteur",
    province:"Marrakech", lat: "34.052295",
    lng: "-6.811459"
  },


  {
    name: "Centre d'Analyses Médicales Ibn Zohr",
    city: "Salé",
    address: "Boulevard Med V, imm lngasmina 22 Mag 9, Tabriquet",
    title: "Docteur",
    province:"Marrakech", lat: "34.0485034",
    lng: "-6.8002909"
  },


  {
    name: "Laboratoire Benddine",
    city: "Salé",
    address: "35, av Mohamed V. Sala Al jadida",
    province:"Marrakech", lat: "33.9969701",
    lng: "-6.743224"
  },


  {
    name: "Laboratoire Al Farabi",
    city: "Salé",
    address: "60, bd D, cité Al Amal. route de Kénitra",
    title: "Docteur",
    province:"Marrakech", lat: "34.0693071",
    lng: "-6.7919174"
  },


  {
    name: "Laboratoire Salé Al Jadida",
    city: "Salé",
    address: "bd Abderrahim Bouabid, centre comm. Mazagan. Sala Al Jadida",
    title: "Docteur",
    province:"Marrakech", lat: "34.0004901",
    lng: "-6.7428588"
  },


  {
    name: "Laboratoire Central d'Analyses",
    city: "Salé",
    address: "6, rés RizkAllah. av Prince Héritier Sidi Med. Laghrablia",
    title: "Docteur",
    province:"Marrakech", lat: "34.0397846",
    lng: "-6.8161905"
  },


  {
    name: "Laboratoire Ibn Al Haytam",
    city: "Salé",
    address: "7, av Ibn Al Haytam. secteur Al Ba, Laayayda",
    province:"Marrakech", lat: "34.0580021",
    lng: "-6.7772383"
  },


  {
    name: "Centre de Biologie Bennani",
    city: "Salé",
    address: "323, lot Wafa imm n°2. hay Farah, Hssain",
    title: "Docteur",
    province:"Marrakech", lat: "34.0248438",
    lng: "-6.7567429"
  },


  {
    name: "Laboratoire Bab El Makam",
    city: "Séfrou",
    address: "4, rue lngoussef Ben Tachfine",
    title: "Docteur",
    province:"Marrakech", lat: "33.8313508",
    lng: "-4.8387198"
  },


  {
    name: "Laboratoire Séfrou",
    city: "Séfrou",
    address: " 1, rue El Bowe. bd Mohamed V",
    title: "Docteur",
    province:"Marrakech", lat: "34.0787809",
    lng: "-4.817124700000001"
  },


  {
    name: "Laboratoire El Amal",
    city: "Settat",
    address: "74, bd Belabssir",
    title: "Docteur",
    province:"Marrakech", lat: "32.9925056",
    lng: "-7.6220254"
  },


  {
    name: "Laboratoire Azzouzi",
    city: "Settat",
    address: "97, bd Mohamed V. quartier Al Hofra",
    title: "Docteur",
    province:"Marrakech", lat: "33.0011293",
    lng: "-7.6188836"
  },


  {
    name: "Laboratoire Pasteur",
    city: "Settat",
    address: " 20, bd Hassan II. Bp : 557",
    province:"Marrakech", lat: "32.9978059",
    lng: "-7.6207039"
  },


  {
    name: "Laboratoire Averroes",
    city: "Settat",
    address: "64, av Mohamed V",
    title: "Docteur",
    province:"Marrakech", lat: "33.0011203",
    lng: "-7.6186709"
  },


  {
    name: "Laboratoire Al Qods",
    city: "Sidi Bennour",
    address: "413, bd El Massira. lot. Widad",
    title: "Docteur",
    province:"Youssoufia", lat: "32.6507125",
    lng: "-8.4236719"
  },


  {
    name: "Pharmacie Laboratoire Ba ",
    city: "Sidi Kacem",
    address: "rue 9, N° 19, Sidi Kacem",
    title: "Docteur",
    province:"Marrakech", lat: "34.2386597",
    lng: "-5.7409142"
  },


  {
    name: "Laboratoire Ennasr",
    city: "Sidi Slimane",
    address: "av Allal Ben Abdellah, bloc 2, hay Salam",
    title: "Docteur",
    province:"Marrakech", lat: "34.1624894",
    lng: "-6.5056417"
  },


  {
    name: "Laboratoire Sidi Slimane d'Analyses Médicales",
    city: "Sidi Slimane",
    address: "83 à 84, n°3. bloc 10, hay Salam",
    province:"Marrakech", lat: "34.2588914",
    lng: "-5.9253648"
  },


  {
    name: "Laboratoire d'Analyses Médicales Achifaa",
    city: "Sidi Slimane",
    address: "2, av Hassan II. rés Nour",
    province:"Marrakech", lat: "34.2588914",
    lng: "-5.9253648"
  },


  {
    name: "Laboratoire d'Analyses Médicales et de Diagnostic, Biodiagnostica",
    city: "Tanger",
    address: "89, bd Moussa Ibn Noussair, 90000",
    title: "Docteur",
    province:"Marrakech", lat: "35.7787483",
    lng: "-5.8208587"
  },


  {
    name: "BIODIAGNOSTICA",
    city: "Tanger",
    address: "89, bd Moussa Ben Noussair",
    title: "Docteur",
    province:"Marrakech", lat: "35.7787483",
    lng: "-5.8208587"
  },


  {
    name: "LABONORD",
    city: "Tanger",
    address: "69, rue de Fès (à coté de garage Zeriah et place Mabrouk)",
    title: "Docteur",
    province:"Marrakech", lat: "35.7764704",
    lng: "-5.8164532"
  },


  {
    name: "Laboratoire Chellah",
    city: "Tanger",
    address: "14, rue Lafayette",
    title: "Docteur",
    province:"Marrakech", lat: "35.7767999",
    lng: "-5.8131012"
  },


  {
    name: "Laboratoire Rhouni",
    city: "Tanger",
    address: " 2, angle rue Kendi et av Ibn Toumert",
    province:"Marrakech", lat: "35.7751704",
    lng: "-5.8141628"
  },


  {
    name: "Laboratoire El Andaloussi",
    city: "Tanger",
    address: " 5, bd de la Résistance",
    province:"Marrakech", lat: "35.7777012",
    lng: "-5.8096263"
  },


  {
    name: "Laboratoire Mohamed V",
    city: "Tanger",
    address: "9, av Moulay Rachid",
    title: "Docteur",
    province:"Marrakech", lat: "35.7779926",
    lng: "-5.8288173"
  },


  {
    name: "Laboratoire Médi Lab",
    city: "Tanger",
    address: "60, av Omar Ibn Khattab",
    title: "Docteur",
    province:"Marrakech", lat: "35.7588761",
    lng: "-5.8676076"
  },


  {
    name: "Laboratoire Médical Iberia",
    city: "Tanger",
    address: "2, place Koweit",
    title: "Docteur",
    province:"Marrakech", lat: "35.781454",
    lng: "-5.8234072"
  },


  {
    name: "Laboratoire d'Analyses Médicales Castilla",
    city: "Tanger",
    address: "137, rue de Fès",
    title: "Docteur",
    province:"Marrakech", lat: "35.7711313",
    lng: "-5.8164511"
  },


  {
    name: "Laboratoire d'Analyses Médicales La Santé",
    city: "Tanger",
    address: "70, av Moukaouama",
    title: "Docteur",
    province:"Marrakech", lat: "35.7761611",
    lng: "-5.8122859"
  },


  {
    name: "Laboratoire Sekkat",
    city: "Tanger",
    address: "70, av Moukaouama",
    title: "Docteur",
    province:"Marrakech", lat: "35.7761611",
    lng: "-5.8122859"
  },


  {
    name: "Laboratoire d'Analyses Médicales de Tanger",
    city: "Tanger",
    address: "19 bis, rue Omar Ben Khattab",
    title: "Docteur",
    province:"Marrakech", lat: "35.7761611",
    lng: "-5.8122859"
  },


  {
    name: "Laboratoire d'Analyses Médicales Casabarata",
    city: "Tanger",
    address: "av Moulay Abdelhafid , rés Al Firdaous. imm 112",
    title: "Docteur",
    province:"Marrakech", lat: "35.7669628",
    lng: "-5.8252026"
  },


  {
    name: "Laboratoire Franco-Marocain d'Analyses Médicales",
    city: "Tanger",
    address: "8, rue Erriche. Jamma Mokraa, Jbel El Kebir",
    title: "Docteur",
    province:"Marrakech", lat: "35.7811698",
    lng: "-5.8454303"
  },


  {
    name: "Laboratoire d'Analyses Médicales AL WILAlngA",
    city: "Tanger",
    address: "N° 2, 1er Etage, Immeuble Lina, Boulevard Marche Verte",
    title: "Docteur",
    province:"Marrakech", lat: "33.5601816",
    lng: "-7.6680996"
  },


  {
    name: "Laboratoire Ibn Batouta d'Analyses Médicales",
    city: "Tanger",
    address: "bd El Qods, lot n°3. quartier Al Majd Awama",
    province:"Marrakech", lat: "35.7364139",
    lng: "-5.8033833"
  },


  {
    name: "Laboratoire Riad Tétouan d'Analyses Médicales",
    city: "Tanger",
    address: "16, angle bd Mly lngoussef et rue Sijilmassa",
    title: "Docteur",
    province:"Marrakech", lat: "35.7703056",
    lng: "-5.806735"
  },


  {
    name: "Laboratoire d'Analyses Médicales Alami",
    city: "Tanger",
    address: "lot Dalmar 1, rue 61 n°1. Béni Makada",
    title: "Docteur",
    province:"Marrakech", lat: "35.7541187",
    lng: "-5.8124011"
  },


  {
    name: "Laboratoire Derdabi",
    city: "Tanger",
    address: "angle av Moulay lngoussef et Abderrahman lngoussefi",
    title: "Docteur",
    province:"Marrakech", lat: "35.7541174",
    lng: "-5.827722"
  },


  {
    name: "Laboratoire Qods",
    city: "Taourirt",
    address: "83, route Debdou",
    title: "Docteur",
    province:"Marrakech", lat: "34.4090136",
    lng: "-2.8959573"
  },


  {
    name: "Laboratoire Abouabdillah",
    city: "Taza",
    address: " place Zerktouni",
    title: "Docteur",
    province:"Marrakech", lat: "34.221061",
    lng: "-4.2908154"
  },


  {
    name: "Laboratoire Central",
    city: "Taza",
    address: "rue de Fès, imm lngahya n°2",
    province:"Marrakech", lat: "33.6948469",
    lng: "-7.3926275"
  },


  {
    name: "Laboratoire Taza",
    city: "Taza",
    address: "N° 78, Résidence Al Firdaous, N° 4, Bd. Med V",
    title: "Docteur",
    province:"Marrakech", lat: "34.2175257",
    lng: "-4.0130898"
  },


  {
    name: "Laboratoire Chifaa",
    city: "Témara",
    address: "4, av Hassan II. Sigal Sigalon",
    title: "Docteur",
    province:"Marrakech", lat: "33.9222575",
    lng: "-6.9222389"
  },


  {
    name: "Laboratoire Chifaa",
    city: "Témara",
    address: "4, av Hassan II. Sigal Sigalon",
    title: "Docteur",
    province:"Marrakech", lat: "33.9222575",
    lng: "-6.9222389"
  },


  {
    name: "Laboratoire Bennis",
    city: "Témara",
    address: "26, rue d'Iran",
    province:"Marrakech", lat: "33.9254023",
    lng: "-6.9209588"
  },


  {
    name: "Laboratoire Al Massira",
    city: "Témara",
    address: "32, av Moulay Ali Cherif. Massira 1",
    province:"Marrakech", lat: "33.9262044",
    lng: "-6.9171186"
  },


  {
    name: "Laboratoire Ennahda",
    city: "Témara",
    address: "15, av Zerktouni. hay Nahda I",
    province:"Marrakech", lat: "33.9261858",
    lng: "-6.908911"
  },


  {
    name: "Laboratoire Central Témara",
    city: "Témara",
    address: "32, lot Marie Idda. hay Alaouiyine",
    province:"Marrakech", lat: "33.9253125",
    lng: "-6.9179375"
  },


  {
    name: "Laboratoire Centre Témara",
    city: "Témara",
    address: "32, bd Hassan II",
    title: "Docteur",
    province:"Marrakech", lat: "33.9252752",
    lng: "-6.926662"
  },


  {
    name: "Laboratoire Almohit d'Analyses Médicales",
    city: "Témara",
    address: "57, bd Hassan 1er. lot Al Mohit",
    province:"Marrakech", lat: "33.9099886",
    lng: "-6.9240716"
  },


  {
    name: "Laboratoire Touzani",
    city: "Témara",
    address: "bd Moulay Ali Cherif, secteur 5 lot n°8. Massira 1",
    title: "Docteur",
    province:"Marrakech", lat: "33.9307435",
    lng: "-6.9036476"
  },


  {
    name: "Laboratoire Mellouki",
    city: "Témara",
    address: "bd Tarik Ibn Ziad. rés Chemsi, imm G. Fouarate",
    title: "Docteur",
    province:"Marrakech", lat: "33.9110765",
    lng: "-6.91332"
  },


  {
    name: "Laboratoire Bioguich",
    city: "Témara",
    address: "n°859, Guich Loudaya",
    province:"Marrakech", lat: "33.9401337",
    lng: "-6.8896807"
  },


  {
    name: "Laboratoire International de Témara",
    city: "Témara",
    address: "13, av Mohamed VI",
    title: "Docteur",
    province:"Marrakech", lat: "33.9303152",
    lng: "-6.9303406"
  },


  {
    name: "Laboratoire Chaouki",
    city: "Témara",
    address: "9, bd Abdelkrim Khattabi",
    title: "Docteur",
    province:"Marrakech", lat: "33.9266051",
    lng: "-6.9091404"
  },


  {
    name: "Laboratoire Les Oudayas",
    city: "Témara",
    address: "secteur 1, lot Oulad Mtaa. Sabl Rhone",
    title: "Docteur",
    province:"Marrakech", lat: "33.9398506",
    lng: "-6.9016485"
  },


  {
    name: "Laboratoire Bakouri",
    city: "Tétouan",
    address: "5, rue Allal Ben Abdellah",
    province:"Marrakech", lat: "35.6708199",
    lng: "-5.7307066"
  },


  {
    name: "Laboratoire Slaoui",
    city: "Tétouan",
    address: "14, av Mauritanie",
    title: "Docteur",
    province:"Marrakech", lat: "35.5712273",
    lng: "-5.3823429"
  },


  {
    name: "Laboratoire Fassi Fehri",
    city: "Tétouan",
    address: "6, rue Chakib Arsalane",
    title: "Docteur",
    province:"Marrakech", lat: "35.5698128",
    lng: "-5.3780212"
  },


  {
    name: "Laboratoire Fassi Fehri",
    city: "Tétouan",
    address: "6, rue Chakib Arsalane",
    title: "Docteur",
    province:"Marrakech", lat: "35.5698128",
    lng: "-5.3780212"
  },


  {
    name: "Laboratoire d'Analyses Médicales Ibn Nafis",
    city: "Tétouan",
    address: "17, av Ibn Tachfine",
    title: "Docteur",
    province:"Marrakech", lat: "35.5698215",
    lng: "-5.3780212"
  },


  {
    name: "Laboratoire El Mamoun d'Analyses Médicales",
    city: "Tétouan",
    address: "138, av des Far. rés El Kadissia",
    province:"Marrakech", lat: "35.5698389",
    lng: "-5.3780212"
  },


  {
    name: "Laboratoire d'Analyses Médicales RIAD TETOUAN",
    city: "Tétouan",
    address: "461, av des Far. rés Soundous",
    title: "Docteur",
    province:"Marrakech", lat: "35.7703099",
    lng: "-5.806735"
  },


  {
    name: "Laboratoire d'Analyses Médicales Derdabi",
    city: "Tétouan",
    address: "av Mohamed V, résidence Assafae. quartier Scolaire",
    title: "Docteur",
    province:"Marrakech", lat: "35.7703186",
    lng: "-5.806735"
  },


  {
    name: "Laboratoire Majdoubi",
    city: "Tiznit",
    address: "65, place Mechouar",
    title: "Docteur",
    province:"Marrakech", lat: "29.6973273",
    lng: "-9.7332642"
  },


  {
    name: "Laboratoire d'Analyses Abouzaid",
    city: "Tiznit",
    address: "573, rue Abdellatif Sbihi. route d'Aglou",
    title: "Docteur",
    province:"Marrakech", lat: "29.701011",
    lng: "-9.7480363"
  },


]

export const labsPharma = [
  {
    association: " LEMM",
    name: "AMGEN",
    city: "Casablanca",
    address: "Green Bureau 62, 1er étage, Appt 6, Angle Route D'el Jadida Et Bvd Abderrahim Bouabid",
    codePostal: 20140,
    province:"Marrakech", lat: "33.5650252",
    lng: "-7.6469727"
  },


  {
    association: " LEMM",
    name: "Bayer",
    city: "Casablanca",
    address: "Bd Sidi Mohamed Ben Abdellah Tour Ivoire 1 - 3° Etage - Marina ",
    codePostal: 20030,
    province:"Marrakech", lat: "33.6089845",
    lng: "-7.6277414"
  },


  {
    association: " LEMM",
    name: "LEO Pharma",
    city: "Casablanca",
    address: "8 rue Turgot 2°ét. Racine",
    codePostal: 20100,
    province:"Marrakech", lat: "33.5911557",
    lng: "-7.6441503"
  },


  {
    association: " LEMM",
    name: "Novo Nordisk",
    city: "Casablanca",
    address: "5 Rue des Tilleuls, angle rue des Coriandres ",
    codePostal: 20370,
    province:"Marrakech", lat: "33.5881063",
    lng: "-7.6553055"
  },


  {
    association: " LEMM",
    name: "Pierre Fabre",
    city: "Casablanca",
    address: "92, Bd D'Anfa, 1Er Etage, Plateau N°2",
    codePostal: 20020,
    province:"Marrakech", lat: "33.5334564",
    lng: "-7.6533728"
  },


  {
    association: " LEMM",
    name: "Roche",
    city: "Casablanca",
    address: "05 Boulevard Sidi Mohammed Ben Abdellah",
    codePostal: 20300,
    province:"Marrakech", lat: "33.6007729",
    lng: "-7.5013226"
  },


  {
    association: " LEMM",
    name: "Sanofi",
    city: "Casablanca",
    address: "Route de Rabat RP 1 Ain Sebaa ",
    codePostal: 20250,
    province:"Marrakech", lat: "33.6195485",
    lng: "-7.5268434"
  },


  {
    association: " LEMM",
    name: "Servier",
    city: "Casablanca",
    address: "Immeuble Zevaco, Lot Fath 4 Bd Abdelhadi Boutaleb (Ex. Rte d'Azemmour) 20180",
    province:"Marrakech", lat: "33.5729758",
    lng: "-7.6882852"
  },


  {
    association: "FMIIP",
    name: "AMANlngS PHARMA",
    city: "Casablanca",
    address: "6EME ETAGE 216 BOULEVARD MOHAMMED ZERKTOUNI , 20060",
    province:"Marrakech", lat: "33.587998",
    lng: "-7.6348885"
  },


  {
    association: "FMIIP",
    name: "ARWA MEDIC",
    city: "Marrakesh",
    address: "40 Z I MARRAKECH, SIDI GHANEM 40100",
    province:"Marrakech", lat: "31.7175112",
    lng: "-8.085177"
  },


  {
    association: "FMIIP",
    name: "AlatESS PHARMA",
    city: "Settat",
    address: "LOTISSEMENT 67 HAD SOUALEM, 26402 ",
    province:"Marrakech", lat: "33.4228443",
    lng: "-7.8552116"
  },


  {
    association: "FMIIP",
    name: "ATLAS PHARMA",
    city: "Berrechid",
    address: "ROUTE PRINCIPALE N 7 RUE C , 26100",
    province:"Marrakech", lat: "33.2532406",
    lng: "-7.5885913"
  },


  {
    association: "FMIIP",
    name: "BOTTU",
    city: "Casablanca",
    address: "82, Allée des Casuarinas - B.P. 2583 - Ain Sebaa 20580",
    province:"Marrakech", lat: "33.6117803",
    lng: "-7.5454802"
  },


  {
    association: "FMIIP",
    name: "BIOCODElat",
    city: "Casablanca",
    address: "Résidence Le Joyau IV - 2 rue Ibnou Al Moataz 20300",
    province:"Marrakech", lat: "33.3654218",
    lng: "-7.5742896"
  },


  {
    association: "FMIIP",
    name: "COOPER PHARMA",
    city: "Casablanca",
    address: "Douar Ouled Sidi Abbou, Km 2,5- Tit Mellil",
    province:"Marrakech", lat: "33.5347116",
    lng: "-7.4956135"
  },


  {
    association: "FMIIP",
    name: "CIPLA MAROC",
    city: "Casablanca",
    address: "1 Etage, Building B, Residence Ben Mahyou, rue ibnou Toufail, Abdelhak Ben Mahyou street corner",
    province:"Marrakech", lat: "33.5826774",
    lng: "-7.6277421"
  },


  {
    association: "FMIIP",
    name: "ERGO MAROC",
    city: "Casablanca",
    address: "108 RUE PIERRE PARENT, 20110",
    province:"Marrakech", lat: "33.5943669",
    lng: "-7.6092082"
  },


  {
    association: "FMIIP",
    name: "GALENICA",
    city: "Casablanca",
    address: "2 RUE JILALI GHAFIRI , 20250",
    province:"Marrakech", lat: "33.6005439",
    lng: "-7.5512219"
  },


  {
    association: "FMIIP",
    name: "GlngNEBIO PHARMA",
    city: "Casablanca",
    address: "529 & 530 Parc industriel SAPINO, Nouaceur",
    province:"Marrakech", lat: "33.4027018",
    lng: "-7.5464672"
  },


  {
    association: "FMIIP",
    name: "ISIO LABORATOIRES",
    city: "Casablanca",
    address: "ZI. Dar Bouazza (oulad Azzouz) Lot° 104 20220 ",
    province:"Marrakech", lat: "33.4930323",
    lng: "-7.8101609"
  },


  {
    association: "FMIIP",
    name: "IBERMA",
    city: "Casablanca",
    address: "237, Bd ZERKTOUNI ",
    province:"Marrakech", lat: "33.5840695",
    lng: "-7.6289193"
  },


  {
    association: "FMIIP",
    name: "KEMIPHARM",
    city: "Casablanca",
    address: "LOCAL N 15 LOTISSEMENT N 92 PARC INDUSTRIEL BOUSKOURA",
    province:"Marrakech", lat: "33.4397436",
    lng: "-7.6569168"
  },


  {
    association: "FMIIP",
    name: "LAPROPHAN",
    city: "Casablanca",
    address: "18 BOULEVARD EMILE ZOLA, 20310",
    province:"Marrakech", lat: "33.5915527",
    lng: "-7.6034757"
  },


  {
    association: "FMIIP",
    name: "MAPHAR",
    city: "Casablanca",
    address: "Boulevard Alkimia n°6 - Quartier Industriel - Sidi Bernouss",
    province:"Marrakech", lat: "33.5962862",
    lng: "-7.4785384"
  },


  {
    association: "FMIIP",
    name: "MC PHARMA",
    city: "Casablanca",
    address: "Rue Ahmed Ben Bouchta Hay el Manar, groupe U, numéro 5",
    province:"Marrakech", lat: "33.5553886",
    lng: "-7.6274511"
  },


  {
    association: "FMIIP",
    name: "NOVOPHARMA",
    city: "Casablanca",
    address: " Zone Industrielle I2, Tit Mellil",
    province:"Marrakech", lat: "33.5353213",
    lng: "-7.5020949"
  },


  {
    association: "FMIIP",
    name: "PHARMA 5",
    city: "Casablanca",
    address: "21, rue des Asphodéles , Maarif extension 20100",
    province:"Marrakech", lat: "33.5715051",
    lng: "-7.6479694"
  },


  {
    association: "FMIIP",
    name: "PHARMED",
    city: "Casablanca",
    address: "Oulad Saleh sect. 17 Bouskoura",
    province:"Marrakech", lat: "33.4252268",
    lng: "-7.6416726"
  },


  {
    association: "FMIIP",
    name: "PHI",
    city: "Rabat",
    address: "RS 203 Route Akreuch - OUM AZZA - BP 4492 - 12100 AIN EL AOUDA",
    province:"Marrakech", lat: "33.8168374",
    lng: "-6.7840367"
  },


  {
    association: "FMIIP",
    name: "PROMOPHARM/HIKMA",
    city: "Berrechid",
    address: "DU SAHEL 97 26 400 RUE N 7 HAD SOUALEM, 26402",
    province:"Marrakech", lat: "33.4185414",
    lng: "-7.849484"
  },


  {
    association: "FMIIP",
    name: "SERVIER ",
    city: "Casablanca",
    address: "Immeuble Zevaco, Lot Fath 4 Bd Abdelhadi Boutaleb (Ex. Rte d'Azemmour) 20180",
    province:"Marrakech", lat: "33.5729758",
    lng: "-7.6882852"
  },


  {
    association: "FMIIP",
    name: "SOTHEMA",
    city: "Casablanca",
    address: "BP 20180 - Z.I. Bouskoura",
    province:"Marrakech", lat: "33.4380624",
    lng: "-7.6449502"
  },


  {
    association: "FMIIP",
    name: "SOLUDIA MAGHREB",
    city: "Salé",
    address: "Angle avenue de Fés et de la résistance - 11 105",
    province:"Marrakech", lat: "34.0325004",
    lng: "-6.8231964"
  },


  {
    association: "FMIIP",
    name: "STERIPHARMA",
    city: "Casablanca",
    address: "Lotiss. Lina, z.i. lot 347, Sidi Maarouf 20280 ",
    province:"Marrakech", lat: "33.5216506",
    lng: "-7.6543539"
  },


  {
    association: "FMIIP",
    name: "SUN PHARMA",
    city: "Casablanca",
    address: "219, Boulevard Zerktouni, Angle Boulevard Roudani, Etage 5, 20330 El Maarif",
    province:"Marrakech", lat: "33.577675",
    lng: "-7.6394784"
  },


  {
    association: "FMIIP",
    name: "SlngNTHEMEDIC",
    city: "Casablanca",
    address: "20-22 Rue Zoubeir Bnou Aouam, Casablanca 20300",
    province:"Marrakech", lat: "33.5988432",
    lng: "-7.5986292"
  },


  {
    association: "FMIIP",
    name: "SPIMACO MAROC",
    city: "Tanger",
    address: "KM 4 ANCIENNE ROUTE DE L AVIATION, 90030",
    province:"Marrakech", lat: "35.7625341",
    lng: "-5.8436444"
  },


  {
    association: "FMIIP",
    name: "VERSALlngA PHARMA",
    city: "Casablanca",
    address: "Versalya Zone industrielle du Sahel, Lot 149 Had Soualem",
    province:"Marrakech", lat: "33.4246478",
    lng: "-7.8441465"
  },


  {
    association: "FMIIP",
    name: "ZENITH PHARMA",
    city: "Casablanca",
    address: "Résidence les Alizés, la colline 2 n°33 Sidi Maarouf ",
    province:"Marrakech", lat: "33.5700771",
    lng: "-7.6603609"
  },


  {
    association: "FMIIP",
    name: "INSTITUT PASTEUR",
    city: "Casablanca",
    address: "1, Place Louis Pasteur 20360 ",
    province:"Marrakech", lat: "33.5733859",
    lng: "-7.6257871"
  }
]

export const orderAndCorp = [
  {
    name: "Conseil National de l’Ordre National des Médecins",
    city: "Rabat",
    address: "Rue M’Touga Souissi, BP 6555, Madinat Al Irfane, CP 10170",
    title: "CNONM",
    tel: "0537670284",
    province:"Marrakech", lat: "33.9810961",
    lng: "-6.853163"
  },


  {
    name: "Conseil des Pharmaciens Biologistes",
    city: "Rabat",
    address: "Maison du Pharmacien, Hay Riad, Secteur 10, N°6, 2ème étage,  BP 1374,20 000",
    title: "CPB",
    tel: "0537713391",
    province:"Marrakech", lat: "33.9604113",
    lng: "-6.8686626"
  },


  {
    name: "Ordre national des médecins dentistes du Maroc",
    city: "Rabat",
    address: "44, rue Aguelmane Sidi Ali Agdal 10090 Rabat",
    title: "ONDM",
    tel: "0537681963",
    province:"Marrakech", lat: "34.0042709",
    lng: "-6.8504065"
  },


  {
    name: "Conseil Régional Sud - Ordre National des Médecins Dentistes - Maroc",
    city: "",
    address: "30 Rue Hadj Omar Riffi-1er étage- N°02-03-Casablanca",
    title: "CRSUDMD",
    tel: "0522314315, 0522301201",
    province:"Marrakech", lat: "33.5871613",
    lng: "-7.616211"
  },


  {
    name: "Conseil Régional des Pharmaciens d'Officine du Sud",
    city: "CASABLANCA",
    address: "3, Rue Mohamed Abdouh Place du Parc. Casablanca.",
    title: "CRPO",
    tel: "0522205489, 0522200043",
    province:"Marrakech", lat: "33.586061",
    lng: "-7.6261543"
  },


  {
    name: "Club Marocain d'Immunologie",
    city: "CASABLANCA",
    address: "Université Hassan II, 19 Rue Tariq Ibn Ziad. Casablanca",
    title: "CMI",
    tel: "0522271630",
    province:"Marrakech", lat: "33.545709",
    lng: "-7.655830"
  },


  {
    name: "Fédération Nationale de Radiologie et l'Imagerie Médicale",
    city: "CASABLANCA",
    address: "111, bd Anoual -ex Felix Faure c/o radiologie Anoual 20360",
    title: "FNRIM",
    tel: "0522860989",
    province:"Marrakech", lat: "33.5691334",
    lng: "-7.6204724"
  },


  {
    name: "Fédération Marocaine de Reproduction Humaine",
    city: "CASABLANCA",
    address: "10. Rue Bartholdi 20050 – Casablanca",
    title: "FMRH",
    tel: "0522393920, 0661141623",
    province:"Marrakech", lat: "33.5883762",
    lng: "-7.6490132"
  },


  {
    name: "Fédération Nationale de la Santé",
    city: "",
    address: "",
    title: "FNS",
    tel: "",
    province:"Marrakech", lat: "34.011094",
    lng: "-6.828339"
  },


  {
    name: "Fédération Marocaine de l'Industrie et de l'Innovation Pharmaceutiques",
    city: "CASABLANCA",
    address: "Bd Abderrahim Bouaabid Place Division Leclerc",
    title: "FMIIP",
    tel: "0661466393, 0522234445",
    province:"Marrakech", lat: "33.5562368",
    lng: "-7.6345586"
  },


  {
    name: "Association marocaine du médicament générique",
    city: "CASABLANCA",
    address: "Rue Amyot d’Inville Quartier Arsalane - Ain Borja 20320 ",
    title: "AMMG",
    tel: "0522618118, 0522618216",
    province:"Marrakech", lat: "33.5869682",
    lng: "-7.5861402"
  },


  {
    name: "Les Entreprises du médicament au Maroc",
    city: "CASABLANCA",
    address: "65, Boulevard de la corniche- Projet le Yacht- Entrée A, 3ème étage- N° 83",
    title: "LEMM",
    tel: "0522996485",
    province:"Marrakech", lat: "33.604159",
    lng: "-7.646234"
  },


  {
    name: "Groupement professionnel de la distribution pharmaceutique au Maroc",
    city: "CASABLANCA",
    address: "Rue Abdelmoumen Imm 2 Hamza Bnou Abdelmoutalib Derb Ghellef",
    title: "GPDP",
    tel: "0661169166",
    province:"Marrakech", lat: "33.576468",
    lng: "-7.629651"
  },


  {
    name: "Association Nationale des Cliniques Privée du Maroc",
    city: "",
    address: "38 Rue Omar Slaoui, Casablanca 20250",
    title: "ANCP",
    tel: "0522205757",
    province:"Marrakech", lat: "33.5864134",
    lng: "-7.6213892"
  },


  {
    name: "Chambre Syndicale des Biologistes",
    city: "Rabat",
    address: "Maison du Pharmacien, Hay Ryad secteur 10 n°6 BP 1374 RP",
    title: "CSB",
    tel: "0537713391",
    province:"Marrakech", lat: "33.9591451",
    lng: "-6.8910457"
  },


  {
    name: "Association des Professionnels des dispositifs médicaux",
    city: "Rabat",
    address: "2, Rue Annabia, Hay Ryad, 10106 Rabat Maroc",
    title: "AMPDM",
    tel: "0661785618, 0537710070",
    province:"Marrakech", lat: "33.9511642",
    lng: "-6.8623863"
  },


  {
    name: "Fédération Nationale des Syndicats des Pharmaciens du Maroc",
    city: "Rabat",
    address: "Fédération Nationale des Syndicats des Pharmaciens du Maroc",
    title: "FNSPM",
    tel: "0537713236",
    province:"Marrakech", lat: "33.9603052",
    lng: "-6.8687211"
  },


]

export const steSavantes = [

  {
    title: "SMC",
    name: "Société Marocaine de cancérologie",
    city: "Casablanca",
    "Site Internet": "https://smc.ma/",
    "Mission": "Défendre les intérêts moraux de ses membres, Organiser les manifestations scientifiques, Promouvoir l’enseignement post universitaire de cancérologie, Etablir des liens avec des sociétés, associations et organismes nationaux et internationaux, Publier des travaux scientifiques et une revue médicale, Participer à toute action ayant pour but de promouvoir des mesures préventives ou curatives dans le domaine de la cancérologie",
    specialite: "Chirurgiens cancérologues, Radiothérapeutes, Oncologues médicaux et anatomopathologistes",
    "Membres Clés": "Président",
    "Titre": "Docteur",
    "Nom": "FILALI Dounia",
    tel: "0537761121",
    email: "societemarocainedecancerologie@gmail.com",
    address: "41 rue Lahcen El arjoun, Quartiers des Hôpitaux,  Casablanca",
    province:"Marrakech", lat: "33.5740472",
    lng: "-7.6197353"
  },


  {
    title: "SMC",
    name: "Société Marocaine de cardiologie",
    city: "Rabat",
    "Site Internet": "https://smcmaroc.org/",
    "Mission": "Indiquer les règles du bon exercice de la pratique cardiologique, Sensibiliser les médecins et la population générale sur les risques des maladies cardio vasculaires et leur prévention,Développer la recherche scientifique dans le domaine cardio vasculaire,Promouvoir un enseignement et une formation médicale continue de qualité à travers ses journées scientifiques",
    specialite: "Rythmologie, Imagerie cardiaque, Cardiologie interventionnelle, Insuffisance cardiaque, Cardiologie pédiatrique et congénitale, Prévention, Réadaptation et Cardiologie du Sport",
    "Membres Clés": "Président ",
    "Titre": "Professeur",
    "Nom": "MOUSTAGHFIR Abdelhamid                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             ",
    tel: "0537671415",
    email: "contact@smcmaroc.org",
    address: "Appt 32 3ème étage Immeuble E Résidence Reda Rue Al Hoceima Rabat",
    province:"Marrakech", lat: "34.0233346",
    lng: "-6.830637"
  },


  {
    title: "SMCPRE",
    name: "Société Marocaine de chirurgie plastique reconstructive et esthétique.",
    city: "Agadir",
    "Site Internet": "www.somcep.org",
    "Mission": "De promouvoir et de défendre la Chirurgie Esthétique en contribuant au développement légitime de son exercice, par des Chirurgiens qualifiés et compétents en Chirurgie Plastique Reconstructrice et Esthétique, en assurant sa promotion et son enseignement, D’organiser les rencontres et confrontations scientifiques concernant la Chirurgie Esthétique, La promotion et la diffusion des travaux de ses membres, La transmission effective de l’expérience de ses membres, par des cours, des tables rondes, et des sessions de travail, d’en contrôler la diffusion médiatique objective, D’encourager la plus haute compétence et la plus grande éthique au sein de la Chirurgie Esthétique Plastique, De maintenir la Chirurgie Esthétique Plastique parmi les meilleurs éléments de la Médecine Marocaine, De mettre tout en œuvre pour que le Maroc soit une plateforme d’excellence à l’échelle internationale pour l’enseignement post universitaire de la spécialité, La mise en commun des moyens nécessaires à la constitution d’une société de services, La SOMCEP assure les fonctions d'interlocuteur privilégié des pouvoirs publics, associations, groupes et personnes sur tous les sujets concernant la chirurgie esthétique.",
    specialite: "Chirurgie Plastique, Reconstructive & Esthétique",
    "Membres Clés": "Presidente",
    "Titre": "Docteur",
    "Nom": "MRADMI Wafaa ",
    tel: "0528846080",
    email: "plasticadvice@yahoo.fr",
    address: "Les Résidences Talborjt - Espace Bureaux Agadir",
    province:"Marrakech", lat: "30.426117880508222",
    lng: "-9.591889752633314"
  },


  {
    title: "SMEDIAN",
    name: "Société Marocaine d’endocrinologie, de diabétologie et nutrition",
    city: "Rabat",
    "Site Internet": "www.smedian.ma",
    "Mission": "La promotion de l’Endocrinologie, de la Diabétologie et des Maladies de la Nutrition dans la communauté médicale et  Scientifique nationale, maghrébine et internationale, Le Développement de la recherche médicale épidémiologique, clinique, clinico-biologique et  fondamentale, La promotion des actions de Santé Publique, de Prévention et d’éducation sanitaire en collaboration avec les associations de diabétiques, Le développement et l’amélioration de l’information en particulier par des actions de formation médicale continue, La sensibilisation de l’opinion publique à l’égard des problèmes d’Endocrinologie, Diabétologie, des Maladies Métaboliques et de la Nutrition, La représentation des Endocrinologues – Diabétologues vis à vis des Pouvoirs Publics, L’étude des problèmes professionnels et scientifiques intéressant la communauté des Endocrinologues – Diabétologues.",
    specialite: "Endocrinologie, Diabétologie, Maladies Métaboliques et de la Nutrition",
    "Membres Clés": "President",
    "Titre": "Docteur",
    "Nom": "LHASSANI Hamdoun",
    tel: "0537707978",
    email: "hamdounlhassani@gmail.com",
    address: "3 Rue Al Achâari, Appt 07, 10 000 Agdal – Rabat.",
    province:"Marrakech", lat: "34.0077757",
    lng: "-6.8497629"
  },


  {
    title: "SMMU",
    name: "Société Marocaine de Médecine d'Urgence",
    city: "Rabat",
    "Site Internet": "SMMU: Société Marocaine De Médecine D'Urgence",
    "Mission": "Organisation de congrès et réunions scientifiques, Publication de périodiques, d'ouvrages ou de tous supports éditoriaux, Elaboration de recommandations scientifiques ou professionnelles, la mise en œuvre d'un site Internet, l'octroi de bourses et de prix, l’organisation de projets de recherche et éventuellement la recherche de leur financement, Représentation marocaine au sein de sociétés savantes en particulier de médecine d’urgence internationales notamment européennes, l’organisation des pratiques professionnelles et leur évaluation, la formation continue des professionnels, la représentation professionnelle en tant que société savante auprès des autorités et des autres professionnels, l’information du public en matière de médecine d’urgence et toute autre activité conforme aux buts de l’association",
    specialite: "Médecine d'Urgences et de Catastrophe Soins d'Urgence",
    "Membres Clés": "Président",
    "Titre": "Professeur",
    "Nom": "BELYAMANI Lahcen",
    tel: "0537799709",
    email: "belyamani@gmail.com",
    address: "sidi bibi, Rabat, Maroc, 10100, Rabat-Salé-Zemmour-Zaer",
    province:"Marrakech", lat: "33.9914138",
    lng: "-6.8442752"
  },


  {
    title: "SMA",
    name: "Société Marocaine d'Arthroscopie",
    city: "Rabat",
    "Site Internet": "https://smarthroscopie.com/",
    "Mission": "La contribution au développement de la recherche, de l’étude et de la diffusion des techniques diagnostiques et chirurgicales arthroscopiques et de la chirurgie du sport au Maroc,  La promotion, la coordination et la diffusion des travaux effectués par les spécialistes de cette branche de la chirurgie et ceux de disciplines chirurgicales et médicales ayant un rapport avec elle, L' organisation de séminaires et de conférences sur les thèmes la concernant, Le renforcement des liens de coopération avec les ministères de tutelles ou tout autre organisme gouvernemental ou non ayant une relation avec la discipline. Elle est l’interlocuteur incontournable auprès des instances dirigeantes en ce qui concerne la discipline.",
    specialite: "chirurgie orthopédique et traumatologique",
    "Membres Clés": "Président",
    "Titre": "Docteur",
    "Nom": "ABDELOUADOUD Mouhamed",
    tel: "0522224109",
    email: "sma.smarthroscopie@gmail.com",
    address: "Avenue Bin El Ouidane Imm. 23 Apt 17, Agdal Rabat, Maroc",
    province:"Marrakech", lat: "34.006387",
    lng: "-6.850579"
  },


  {
    title: "SMEGYN",
    name: "Société Marocaine d'Endoscopie Gynécologique",
    city: "Rabat",
    "Site Internet": "https://www.pharmapresse.net/content/societe-marocaine-d%E2%80%99endoscopie-gynecologique-smegyn",
    tel: "0661143898",
    address: "Résidence de Minaret, Angle rues Ammane et Yougoslavie, n°23 Rabat 10000",
    province:"Marrakech", lat: "34.0203573",
    lng: "-6.8313778"
  },


  {
    title: "SMH",
    name: "Société Marocaine d'Hématologie",
    city: "Casablanca",
    "Site Internet": "www.smh.co.ma",
    "Mission": "Promouvoir la qualité de la pratique clinique et biologique de l’hématologie au Maroc, Promouvoir l’enseignement de l’hématologie, Promouvoir la recherche scientifique clinique et fondamentale dans le domaine de l’hématologie, Promouvoir les échanges scientifiques, technologiques et cliniques entre les départements cliniques et les laboratoires travaillant dans le domaine de l’hématologie, Établir les relations nécessaires pour représenter l’hématologie marocaine auprès des entreprises et services publics ou privés, nationaux ou internationaux impliqués dans la pratique clinique, l’enseignement ou la recherche en hématologie, Organiser des conférences, des symposiums et d’autres réunions scientifiques dans le domaine de l’hématologie, Développer des programmes d’enseignement et / ou de formation pour les étudiants et / ou praticiens en hématologie, Développer des programmes d’enseignement et / ou de formation pour les étudiants et / ou praticiens en hématologie, Préparer et diffuser des lignes directrices cliniques, ",
    specialite: "Hématologie clinique  et transfusion sanguine",
    "Membres Clés": "Président",
    "Titre": "Professeur",
    "Nom": "MADANI Abdellah",
    tel: "0522483030",
    email: "contact@smh.co.ma",
    address: "Hôpital du 20 Août 1953, Rue Lahcen Arjoune, Casablanca 20360",
    province:"Marrakech", lat: "33.5750567",
    lng: "-7.6198899"
  },


  {
    title: "SMI",
    name: "Société marocaine d'Immunologie ",
    city: "Marrakech",
    "Site Internet": "http://www.smi.ma/",
    "Mission": "Promouvoir la qualité de l’enseignement et de la recherche, Tisser des liens entre les immunologistes, Mener des programmes éducatifs, des cours de formation et des ateliers, Favoriser la communication et la collaboration entre les chercheurs , Encourager les échanges d'informations scientifiques et la diffusion des dernières avancées en immunologie.",
    specialite: "Immunologie clinique",
    "Membres Clés": "Président",
    "Titre": "Professeur",
    "Nom": "HAFID Jamal",
    tel: "0524434649",
    email: "smi1@menara.ma",
    address: "Université Cadi Ayyad, Faculté des Sciences Semlalia, Marrakech 40 000",
    province:"Marrakech", lat: "31.6490864",
    lng: "-8.0154408"
  },


  {
    title: "SMACOT",
    name: "Société Marocaine d'Orthopédie Traumatologie",
    city: "Rabat",
    "Site Internet": "https://smacot.ma/",
    "Mission": "La SMACOT est l’interlocuteur principal vis-à-vis des autorités ministérielles et universitaires concernant les programmes universitaires de la spécialité, ainsi qu’au niveau de la législation et la déontologie de l’exercice de la fonction.",
    specialite: "Chirurgie orthopédique et traumatologique",
    "Membres Clés": "Président",
    "Titre": "Docteur",
    "Nom": "RAFID Mohamed",
    tel: "0522224109",
    email: "smacot@menara.ma",
    address: "Avenue Atlas Imm 19 Apt 7 Agdal – Rabat",
    province:"Marrakech", lat: "33.99623862855166",
    lng: "-6.847475448401852"
  },


  {
    title: "SMU",
    name: "Société Marocaine d'Urologie",
    city: "Rabat",
    "Membres Clés": "Président",
    "Nom": "BELAHNECH Zakaria",
    tel: "06 61 14 43 29",
    email: "marocuro@yahoo.fr",
    address: "Service d'Urologie A Hôpital Avicenne. Rabat",
    province:"Marrakech", lat: "33.985894935526304",
    lng: "-6.855479887015033"
  },


  {
    title: "SMCSL",
    name: "Société Marocaine de Cancérologie du secteur Libéral",
    city: "Casablanca",
    "Mission": "De défendre les intérêts moraux de ses membres, D’organiser les manifestations scientifiques, De promouvoir l’enseignement post universitaire de cancérologie, D’établir des liens avec des sociétés, associations et organismes nationaux et internationaux, De publier des travaux scientifiques et une revue médicale, De participer à toute action ayant pour but de promouvoir des mesures préventives ou curatives dans le domaine de la cancérologie",
    specialite: "Maladies oncologiques chirurgicales",
    "Membres Clés": "Président",
    "Titre": "Docteur",
    "Nom": "EL HAFED Abdenbi",
    tel: "0522202151",
    email: "dr_elhafed@hotmail.com",
    address: "192 Bd Zerktouni 1er étage Rés.Baghdad 20060 Casablanca",
    province:"Marrakech", lat: "33.5866013",
    lng: "-7.6317527"
  },


  {
    title: "SMC",
    name: "Société Marocaine de Chirurgie",
    city: "Rabat",
    specialite: "Chirurgie générale",
    "Membres Clés": "Président",
    "Titre": "Professeur",
    "Nom": "HRORA Abdelmalek",
    tel: "0537670330",
    email: "a.hrora@medramo.ac.ma",
    address: "Service de Chirurgie “D”. Hôpital Avicenne. Rabat",
    province:"Marrakech", lat: "33.9865834",
    lng: "-6.8607263"
  },


  {
    title: "SMCD",
    name: "Société Marocaine de Chirurgie Digestive",
    city: "Casablanca",
    "Membres Clés": "Président ",
    "Titre": "Professeur",
    "Nom": "KAFIH Mohamed",
    tel: "0522224109",
    address: "19, Avenue Franklin Roosvelt-20050 Casablanca, ",
    province:"Marrakech", lat: "33.5893746",
    lng: "-7.6497141"
  },


  {
    title: "SMCFH",
    name: "Société Marocaine de Chirurgie Fondation Hassan II",
    city: "Rabat",
    "Site Internet": "https://www.pharmapresse.net/content/societe-marocaine-de-chirurgie-fondation-hassan-ii",
    "Membres Clés": "Président ",
    "Nom": "A. El Khamlichi ",
    tel: "0537770228",
    email: "fh2nch@iam.net.ma",
    address: "Service de Neuro-Chirurgie B.P. 6444. Rabat-Institus.Hôpital des Spécialités. Rabat",
    province:"Marrakech", lat: "33.9820765",
    lng: "-6.8579745"
  },


  {
    title: "SMCP",
    name: "Société Marocaine de Chirurgie Pédiatrique",
    city: "Casablanca",
    "Site Internet": "http://smcp.ma/",
    "Mission": "Promouvoir et développer la Chirurgie Pédiatrique, Développer la recherche scientifique dans le même domaine, Participer à la formation médicale continue par l'organisation de congrès, séminaires ou toute forme forme de réunion scientifique, Editer des revues ou des bulletins traitant des sujets se rapportant à la spécialité, Défendre les intérêts de la spécialité, Organiser et participer aux actions d'information des familles, ainsi qu'aux programmes d'éducation et de prévention, en collaboration avec des associations de malades, ou les associations scientifiques similaires s'occupant de l'enfant et de la famille",
    specialite: "Chirurgie pédiatrique : viscérale, vidéochirurgie, orthopédie, urologie, plastique",
    "Membres Clés": "Président ",
    "Titre": "Docteur",
    "Nom": "El Mostafa ARSALANE  ",
    tel: "0522253124",
    address: "Service Orthopédie et de Traumatologie Pédiatrique, Hôpital d’Enfants. Casablanca",
    province:"Marrakech", lat: "33.5518913",
    lng: "-7.6091993"
  },


  {
    title: "SMCV",
    name: "Société Marocaine de Chirurgie Vasculaire",
    city: "Rabat",
    "Membres Clés": "Président ",
    "Titre": "Professeur",
    "Nom": "BENSAID Younes",
    tel: "0537670789",
    address: "Service de Chirurgie ‘D”.Hôpital Avicenne.Rabat",
    province:"Marrakech", lat: "33.9846262",
    lng: "-6.8536797"
  },


  {
    title: "SMDV",
    name: "Société Marocaine de Dermatologie",
    city: "Fes",
    "Site Internet": "http://smdermato.org/",
    "Mission": "Promouvoir la dermatologie marocaine dans la communauté médicale et scientifique nationale et internationale. , -Promouvoir la dermatologie marocaine dans la communauté médicale et scientifique nationale et internationale. , -Développer la formation continue en dermatologie. -Promouvoir la recherche médicale clinique, clinico- biologique et fondamentale en dermatologie. , -Promouvoir des actions de santé publique, de prévention, d'épidémiologie, ainsi que l'éducation sanitaire , -Elaborer des référentiels de prise en charge et évaluer les soins et en particulier les innovations diagnostiques et/ou thérapeutiques. , -Toute action de développement et de recherche dans les domaines de la dermatologie.",
    specialite: "Pathologies dermathologique",
    "Membres Clés": "Présidente",
    "Titre": "Professeur",
    "Nom": "Said AMAL",
    tel: "0661414139",
    address: "Service de Dermatologie du CHU Hassan II, Fès.",
    province:"Marrakech", lat: "34.0039905",
    lng: "-4.9630494"
  },


  {
    title: "SMMLC",
    name: "Société Marocaine de Médecine Légale",
    city: "Casablanca",
    "Site Internet": "http://smml.ma/",
    "Mission": "Contribuer au développement de la Médecine Légale au Maroc, Promouvoir la médecine légale et les disciplines annexes aussi bien sur le plan pratique que théorique, Apporter son concours aux instances judiciaires dans toutes les circonstances où elle peut être consultée, Renforcer les liens de coopération avec les ministères de tutelles ou tout autre organisme ou département gouvernemental ou des organisations non gouvernementales ayant une relation avec la spécialité, Défendre les intérêts de ses membres et de la spécialité, Organiser la formation continue et l’enseignement de ses disciplines et encourager la recherche dans ces domaines.",
    "Membres Clés": "Président ",
    "Titre": "Professeur",
    "Nom": "BELHOUSS Ahmed",
    tel: "0661180333",
    email: "smml.medecinelegale@gmail.com",
    address: "Service de Médecine Légale, CHU Ibn Rochd, Casablanca",
    province:"Marrakech", lat: "33.5777477",
    lng: "-7.6224574"
  },


  {
    title: "SMAMM",
    name: "Société Marocaine de Microbiologie Médicale",
    city: "Rabat",
    "Site Internet": "http://smamm.ma/",
    "Mission": "La promotion des différents aspects de la Microbiologie clinique au Maroc dans le secteur publique et privé et la création de liens de partenariat et collaboration entre les scientifiques qui œuvrent dans ce domaine.",
    specialite: "Microbiologie clinique",
    "Membres Clés": "Président",
    "Titre": "Professeur",
    "Nom": "Amina BENAOUDA",
    tel: "661078412, 661291754, 672740883",
    email: "benoudaamina@yahoo.fr",
    address: "Faculté de Médecine et de Pharmacie de Rabat, Département de Microbiologie, Hay Riad - Souissi, Rabat",
    province:"Marrakech", lat: "33.9829695",
    lng: "-6.8559009"
  },


  {
    title: "SOMAREF",
    name: "Société Marocaine de Médecine Physique & Réadaptation Fonctionnelle",
    city: "Casablanca",
    "Site Internet": "http://somaref.co.ma/",
    "Mission": "l'introduction de modules d'enseignement de la MPR dans le cursus universitaire de médecine générale au sein toutes les facultés de médecine du royaume, aussi l'instauration de stages d'externat et d'internat dans les différents CHU. Ceci permettrait à toutes les générations futures de médecins d'impliquer davantage le MPR dans la prise en charge et l'orientation de leurs patients s'ils le jugent utile,(consultation chez un confrère médecin spécialiste en MPR pour avis, billans spécifiques et éventuelle prise en charge)., Développer l'hospitalisation en MPR, ainsi que des réseaux de soin avec les confrères d'autres disciplines médico-chirurgicales, auxilliaires médicaux et tous les intervenants dans les circuits de soins., L'objectif et la finalité est d'optimiser le parcours de soins pour toutes les personnes avec limitation de participation (handicaps). Et partant de là, minimiser au maximum le coût des soins.",
    "Membres Clés": "Président ",
    "Titre": "Professeur",
    "Nom": "EL FATIMI Abdellatif",
    tel: "0661066520",
    address: "Rés Houda. N° 80,rue Brahim Anakhai, RDC n° 20, maârif près du complexe Sportif Med V. 20370 Casablanca",
    province:"Marrakech", lat: "33.5801253",
    lng: "-7.6428666"
  },


  {
    title: "SMPV",
    name: "Société Marocaine de Pharmacovigilance",
    city: "Rabat",
    "Site Internet": "https://pharmapresse.net/content/societe-marocaine-de-pharmacovigilance-smpv",
    "Mission": "La participation à l’enseignement en collaboration avec la SMTCA dans le diplôme universitaire de toxicologie et pharmacovigilance , L’amélioration de circuit de notification de l’effet indésirable des médicaments et produit de santé ;, La connaissance de  l’attitude et la perception de médecin prescripteur vis-à-vis de la pharmacovigilance par la réalisation d’une enquête auprès des professionnels de santé ;, L’organisation annuelle  des journées scientifiques  nationales depuis 2007 ;, La formation des professionnels de santé des différentes industries pharmaceutiques par l’organisation de workshop de Pharmacovigilance ",
    specialite: "Pharmacovigilance",
    "Membres Clés": "Président",
    "Titre": "Docteur",
    "Nom": "Amina Tebaa",
    tel: "0537777174",
    email: "smpv@menara.ma",
    address: "Rue Lamfadel cherkaoui BP 6671 Rabat Unstituts Madinat AlIFRANE Rabat 10100",
    province:"Marrakech", lat: "33.9875716",
    lng: "-6.8569365"
  },


  {
    title: "SMR",
    name: "Société Marocaine de Radiologie",
    city: "Casablanca",
    "Site Internet": "https://smradiologie.com/",
    "Mission": "Défendre les intérêts moraux de ses membres, Organiser les manifestations scientifiques, Promouvoir et d'organiser l'enseignement post universitaire, Etablir des liens avec les associations nationales et internationales et de participer à leurs travaux, Publier une revue médicale.",
    specialite: "Imagerie médicale et Radiologie",
    "Membres Clés": "Président",
    "Titre": "Docteur",
    "Nom": "EL ALAMI My Ahmed ",
    tel: "0666775778",
    email: "secretariat.smradiologie@gmail.com",
    address: "Abdelouafi -Service de Radiologie. Hôpital 20 Août Casablanca",
    province:"Marrakech", lat: "33.5740729",
    lng: "-7.6189765"
  },


  {
    title: "SMRMT",
    name: "Société Marocaine de Recherche en Médecine Traditionnelle",
    city: "Casablanca",
    "Site Internet": "https://pharmapresse.ma/content/societe-marocaine-de-recherche-en-medecine-traditionnelle-0",
    tel: "0522271630",
    address: "19, rue Tarik Ibn Ziad. Faculté de Médecine. Casablanca",
    province:"Marrakech", lat: "33.5746508",
    lng: "-7.6226054"
  },


  {
    title: "SOMIPEV",
    name: "Société Marocaine d’Infectiologie Pédiatrique et de Vaccinologie",
    city: "Marrakech",
    "Site Internet": "somipev.ma/",
    "Mission": "La promotion et le développement des connaissances dans les maladies infectieuses pédiatriques et la vaccinologie au Maroc, La promotion de l'éducation sanitaire pour la prévention des maladies infectieuses chez le citoyen marocain, L’enseignement et la formation continue en médecine et en pharmacie, L'encouragement à la recherche scientifique en infectiologie pédiatrique et en vaccinologie, Le développement d'une recherche scientifique de niveau international dans cette discipline notamment en établissant des partenariats, La réalisation de travaux de recherche, La contribution à la formation des scientifiques de haut niveau en particulier dans les domaines de la biologie moléculaire, la génétique, la vaccinologie et de la bio-informatique appliquée aux maladies infectieuses., La proposition de l'expertise et des orientations à suivre dans le domaine de la prévention des maladies infectieuses pédiatriques et les strategies nationales de vaccination au Ministère de la santé dans notre pays ainsi que l'évaluation de leurs impacts.",
    "Membres Clés": "Président",
    "Titre": "Professeur",
    "Nom": "Bouskraoui Mohammed",
    tel: "(212)673083978, (212)673083978",
    email: "contact@somipev.ma, mbouskraoui@gmail.com, bouskraoui@yahoo.fr",
    address: "Lot Des Palmiers1, n° 146 40060 Marrakech",
    province:"Marrakech", lat: "31.6647208",
    lng: "-7.9963213"
  },


  {
    title: "SMMR",
    name: "Société Marocaine des Maladies Respiratoires",
    city: "Casablanca",
    "Site Internet": "http://www.efpneumo.org/index.php/smmr",
    "Mission": "Contribuer au progrès et au développement de la médecine au Maroc, Etudier particulièrement les problèmes que posent les maladies respiratoire, Participer à la Formation Médicale Continue et à la recherche médicale, Participer à l’Education pour la Santé",
    "Membres Clés": "Président",
    "Titre": "Docteur",
    "Nom": "BAHLAOUI Abdelkrim",
    tel: "0537297070, 0522296850",
    email: "  smmrcasa@yahoo.fr",
    address: "Faculté de Médecine et de Pharmacie de Casablanca , BP : 1828 Derb Ghallef 201005.,Rue Mohamed Tritki, Imm Tissir Appt.B9 Agdal",
    province:"Marrakech", lat: "33.5746508",
    lng: "-7.6247514"
  },


  {
    title: "SMSM",
    name: "Société Marocaine des Sciences Médicales",
    city: "Rabat",
    "Site Internet": "https://www.smmg.ma/",
    "Mission": "Coordonner les actions ayant pour objectif la promotion de la médecine générale, Coordonner les actions entre les différents acteurs ayant les mêmes objectifs, Placer le médecin généraliste dans le système d’enseignement de la médecine générale, Encourager et participer à la recherche scientifique, Améliorer et harmoniser les connaissances et les comportements, Participer à l’élaboration des recommandations de bonne pratique (Guidelines) dans la prise en charge et la prévention des maladies",
    "Membres Clés": "Président",
    "Titre": "Docteur",
    "Nom": "My Said AFIF",
    tel: "(+212) 5 22 27 16 30",
    email: "contact@smsmmaroc.com",
    address: "Faculté de médecine, Rue Tarik Ibnou Ziad - Casablanca",
    province:"Marrakech", lat: "33.5658357",
    lng: "-7.6226054"
  },


  {
    title: "SMVAO",
    name: "Société marocaine pour la valorisation de l’acte officinal",
    city: "Rabat ",
    "Membres Clés": "Président",
    "Titre": "Docteur",
    "Nom": "Aicha ZAHI",
    tel: "0661193534",
    email: "smvao.ma@gmail.com",
    province:"Marrakech", lat: "33.5378207",
    lng: "-7.6409182"
  },


  {
    title: "SMT",
    name: "Société Marocaine de Télémédecine",
    "Membres Clés": "Directeur Exécutif",
    "Titre": "Docteur",
    "Nom": "Saad CHAACHO",
    province:"Marrakech", lat: "33.5749542",
    lng: "-7.6450226"
  },


  {
    title: "SMHOP",
    name: "Société Marocaine d'Hématologie et d'Oncologie Pédiatrique",
    city: "Rabat ",
    "Site Internet": "http://smhop.org.ma/",
    "Mission": "Assurer la promotion et le développement de l’Hématologie Oncologie Pédiatrique au Maroc, Renforcer les liens existants entre ceux qui l’exercent, Organiser des travaux collectifs et des réunions scientifiques portant sur l’oncologie et l’hématologie pédiatrique,  Entretenir des relations avec des organisations marocaines ou étrangères poursuivant des buts similaires, ",
    "Membres Clés": "Présidente",
    "Titre": "Professeur",
    "Nom": "HESSISSEN Laila",
    tel: "(+212) 6 54 48 47 01",
    email: "smhopinfo@gmail.com",
    address: "Service d'Hémato-Oncologie, Hôpital d'Enfants de Rabat - Maroc",
    province:"Marrakech", lat: "33.5749109",
    lng: "-7.6318368"
  },


  {
    title: "SMO",
    name: "Société Marocaine d'Ophtalmologie",
    city: "Casablanca ",
    "Site Internet": "https://smo.ma/",
    "Mission": "La Société Marocaine d'Ophtalmologie a pour but l'étude des questions médico-chirurgicales concernant cette branche de la pathologie. Elle participe à la lutte contre la cecité",
    "Membres Clés": "Président ",
    "Titre": "Professeur",
    "Nom": "Mohamed BENNANI",
    tel: "(+212) 5 22 27 61 14, (+212) 66656 71 76, (+212) 666 41 99 64",
    email: "direction.smo@gmail.com, smoam2013@gmail.com",
    address: "38 Rue Omar Slaoui BP.9001 Mers Sultan Casablanca - Maroc",
    province:"Marrakech", lat: "33.5865237",
    lng: "-7.6211671"
  },


  {
    title: "SMORL",
    name: "Société Marocaine d'Otho-Rhino-Laryngologie",
    city: "Rabat ",
    "Site Internet": "SMORL",
    "Mission": "Viser la promotion et le développement de l’oto-rhino-laryngologie et de la chirurgie cervico-maxillo-faciale, Organiser et parrainer les manifestations scientifiques (Congres, conférences, séminaires, ateliers, cours post universitaires, Publier les travaux scientifiques relatifs à l’oto-rhino-laryngologie et la chirurgie cervico-maxillo-faciale et des spécialités qui s’y rapprochent, Créer des liens avec d'autres sociétés et associations qui ont le même objectif",
    "Membres Clés": "Président ",
    "Titre": "Professeur",
    "Nom": "ESSAKALLI HOSSYNI Leila ",
    tel: "0537773194",
    email: "contact@smorl.ma",
    address: "Service ORL Hôpital des Spécialités CHU Rabat",
    province:"Marrakech", lat: "33.9820625",
    lng: "-6.8579375"
  },


  {
    title: "SRMGO",
    name: "Société Royale Marocaine de Gynécologie Obstétrique",
    city: "Casablanca",
    "Site Internet": "https://srmgo.org/",
    "Membres Clés": "Président ",
    "Titre": "Docteur",
    "Nom": "MAAOUNI Houcine",
    tel: "0661225651  , 0661458660  , 0661339235  , 0661152171 , 0661141684",
    email: "CONTACT@SRMGO.ORG",
    address: "70 RÉSIDENCE DU CENTRE - ANGLE BOULEVARD DE LA RÉSISTANCE ET RUE LIBOURNE, CASABLANCA, MAROC.",
    province:"Marrakech", lat: "33.5857064",
    lng: "-7.6053897"
  },


  {
    title: "SMMI",
    name: "Société Marocaine de médecine interne",
    city: "Rabat ",
    "Site Internet": "http://www.smmi.ma/",
    "Mission": "Améliorer les conditions d'exercice de la Rééducation Fonctionnelle de la Médecine Physique et de Réadaptation, pour optimiser les prestations, Convergence d'actions Médicales débouchant sur de nouvelles approche,  Prise en charge de pathologies nécessitant une Rééducation Fonctionnelle dans leur traitement, Prévention de certaines dégradations fonctionnelles pouvant survenir dans certaines pathologies chroniques, ainsi qu'une éducation thérapeutique bénéfique pour les patients",
    specialite: "spécialiste des organes de la personne adulte ",
    "Membres Clés": "Président",
    "Titre": "Professeur",
    "Nom": "Mohamed ADNAOUI",
    tel: "0537674544",
    email: "adnaouim@yahoo.fr , z.tazi@menara.ma",
    address: "Service de Médecine A Hôpital Avicenne RABAT",
    province:"Marrakech", lat: "33.9849422",
    lng: "-6.8535541"
  },


  {
    title: "SMMAD",
    name: "Société Marocaine des maladies de l’appareil digestif",
    city: "Casablanca",
    "Site Internet": "https://www.smmad.net/",
    "Mission": "Organiser des actions de Formation Médicale Continue, notamment dans le cadre de la formation médicale continue statutaire, et des formations scientifiques en hépatogastroentérologie pour les professionnels de santé ou toute catégorie de personnes intéressées, Participer à l’évaluation des pratiques dans la discipline, notamment à l’évaluation des pratiques professionnelles et à l’accréditation pour les pratiques à risque, Participer à toutes actions dont l’objectif est l’amélioration et l’optimisation de la prise en charge des malades et son évaluation",
    specialite: "Gastroentérologues",
    "Membres Clés": "Président",
    "Titre": "Professeur",
    "Nom": "BADRE Wafaa",
    tel: "0522224109",
    address: "Service de Médecine C Hôpital Averroès CHU.IBN ROCHD",
    province:"Marrakech", lat: "33.5777477",
    lng: "-7.6224574"
  },


  {
    title: "SMN",
    name: "Société Marocaine de néphrologie",
    city: "Meknès",
    "Site Internet": "SMN (nephromaroc.com)",
    "Mission": "Favoriser le développement de la Néphrologie, Promouvoir la recherche et l’enseignement dans tous les domaines de la Néphrologie, Animer la formation continue post-universitaire en Néphrologie, Contribuer à l’éducation sanitaire et à la sensibilisation de la population, Réunir ses membres en assemblées périodiques, Organiser des réunions, des colloques et congrès scientifiques à caractère national ou international, Favoriser les échanges dans ce domaine avec les Sociétés similaires Nationales, Régionales et Internationales, Représenter les Néphrologues Marocains au sein des Sociétés similaires Nationales, Régionales et Internationales de Néphrologie, Parrainer ou conduire des études ou des travaux concernant la Néphrologie à l’échelle nationale, Donner son avis sur des problèmes concernant la Néphrologie, chaque fois que cela lui est demandé, Informer régulièrement ses membres sur les dispositions réglementaires régissant l’exercice de la profession de Médecin Néphrologue au Maroc",
    specialite: "Néphrologie",
    "Membres Clés": "President",
    "Nom": "SQALLI HOUSSAINI Tarik ",
    tel: "0535514445",
    email: "tarik.sqalli@usmba.ac.ma, nephro.maroc@gmail.com, contact@nephro-maroc.org",
    address: "Centre d'Hémodialyse de Meknès, 12 place esplanade ABDELKRIM EL KHATIB , ville nouvelle MEKNES",
    province:"Marrakech", lat: "33.9044727",
    lng: "-5.5519096"
  },


  {
    title: "SMR",
    name: "Société Marocaine de rhumatologie",
    city: "Rabat",
    "Site Internet": "www.smr.ma",
    "Mission": "La promotion des progrès scientifiques en rhumatologie, ainsi qu’une participation active dans la formation et l’information dans notre spécialité.",
    specialite: "Rhumatologie",
    "Membres Clés": "President",
    "Titre": "Professeur",
    "Nom": "BAHIRI Rachid",
    tel: "0661215562, 0537681959",
    email: "bahirirachid@yahoo.fr, smr@smr.ma",
    address: "Avenue Al Abtal, Immeuble 42, Appartement N°1 Rabat",
    province:"Marrakech", lat: "34.0008246",
    lng: "-6.849301"
  },


  {
    title: "SMAIC",
    name: "Société Marocaine d’allergologie et d’immunologie clinique",
    "Site Internet": "http://www.smaic.ma",
    specialite: "Allergologie et d’immunologie",
    "Membres Clés": "Presidente",
    "Titre": "Professeur",
    "Nom": "YASSINE Najiba",
    tel: "0522224109, 0522983718, 0665360962",
    email: "smaiccasa@yahoo.com",
    address: "250 Bd Abdelmoumen, Res. Abraj Abdelmoumen Escalier A, 5ème étage App N°22- Casablanca",
    province:"Marrakech", lat: "33.577068",
    lng: "-7.615466"
  },


  {
    title: "SOMALDEC",
    name: "Société Marocaine de Lasers, Dermatologie Esthétique et Chirurgicale",
    city: "Fès",
    "Site Internet": "http://www.somaldec.com/",
    specialite: "Dermatologie chirurgicale",
    "Membres Clés": "Président",
    "Titre": "Professeur",
    "Nom": "BELGNAOUI Fatima-Zahra",
    tel: "0661067669, 0535652049/53",
    email: "zalaghvoyages.somaldec@gmail.com",
    address: "23 Avenue de la Palestine, résidence El Houria, Fès",
    province:"Marrakech", lat: "34.0415656",
    lng: "-5.0053461"
  },


  {
    title: "SMNC",
    name: "Société Marocaine de Neurochirurgie",
    "Site Internet": "http://www.neurochirurgiemaroc.ma/",
    "Mission": "Favoriser le regroupement de tous les médecins, neurochirurgiens et biologistes dont la majeure partie de leur activité est de nature neurochirurgicale, Encourager les études et les recherches se rapportant à la neurochirurgie, Représenter les neurochirurgiens auprès des organismes internationaux de neurochirurgie ou lors des réunions ayant un rapport avec la neurochirurgie, qu'elles soient de portée nationale ou internationale.",
    specialite: "Neurochirurgie",
    "Membres Clés": "Vice-Président",
    "Titre": "Docteur",
    "Nom": "Fahd DERKAOUI HASSANI",
    email: "smneurochir@gmail.com, fahdderkaoui@hotmail.com",
    province:"Marrakech", lat: "33.9824149",
    lng: "-6.8609635"
  },


  {
    title: "SMMU",
    name: "Société Marocaine d'urgentologie Pré Hospitalière",
    "Site Internet": "www.smurph.info",
    "Mission": "La promotion, le développement et l’enseignement des connaissances scientifiques et professionnelles, dans tous les domaines d’exercice de la médecine d’urgence, ainsi que la promotion et le soutien de la recherche en médecine d’urgence, Organisation de congrès et réunions scientifiques, Publication de périodiques, Elaboration de recommandations scientifiques ou professionnelles, Mise en œuvre d'un site Internet, Octroi de bourses et de prix, Organisation de projets de recherche et éventuellement la recherche de leur financement,  Représentation marocaine au sein de sociétés savantes en particulier de médecine d’urgence internationales notamment européennes, Organisation des pratiques professionnelles et leur évaluation, Formation continue des professionnels, Représentation professionnelle en tant que société savante auprès des autorités et des autres professionnels, Information du public en matière de médecine d’urgence et toute autre activité conforme aux buts de l’association",
    specialite: "Urgentologie",
    "Membres Clés": "Président ",
    "Titre": "Professeur",
    "Nom": "BELYAMANI Lahcen",
    province:"Marrakech", lat: "34.0058272",
    lng: "-6.8668878"
  },


  {
    title: "SMACOT",
    name: "Société Marocaine de Chirurgie orthopédique et traumatologique",
    city: "Rabat",
    "Site Internet": "https://www.smacot.ma/",
    "Mission": "L’orthorisq Maroc : pour la protection professionnelle de l’orthopédiste, Le collège de la SMACOT : pour perfectionner la formation médicale continue de ses membres, La banque d’os Maroc : pour répondre aux problématiques de certains malades qui ont une perte de substance osseuse, ",
    specialite: "Chirurgie orthopédique et traumatologique",
    "Membres Clés": "Président",
    "Titre": "Docteur",
    "Nom": "RAFAI Mohamed",
    tel: "05 37 77 98 04",
    email: "smacot@menara.ma",
    address: "23 avenue Bin Al Ouidane résid. Rajae 4°ét. appt. n°17 Rabat",
    province:"Marrakech", lat: "34.0057885",
    lng: "-6.8515669"
  },


  {
    title: "SMCC",
    name: "Société Marocaine de chimie clinique",
    city: "Rabat",
    "Mission": "De contribuer au développement de ces disciplines dans tous leurs aspects : formation, recherche et développement",
    specialite: "Chimie",
    "Membres Clés": "Président",
    "Titre": "Professeur",
    "Nom": "Layachi CHABRAOUI",
    address: "Faculté de Médecine et de Pharmacie de Rabat - BP 8048 Rabat Nations Unies",
    province:"Marrakech", lat: "33.9829695",
    lng: "-6.8559009"
  },


  {
    title: "SMFC",
    name: "Société Marocaine de fertilité et de contraception",
    city: "Rabat",
    "Site Internet": "http://www.smfc.ma/",
    specialite: "Fertilité et de contraception",
    "Membres Clés": "Président",
    "Titre": "Docteur",
    "Nom": "M. BENNIS",
    tel: "0522.39.39.20 , 0522.94.22.61, 0522.94.42.12",
    email: "secretariat.sfmc@gmail.com, contact@smfc.ma, fmrh@menara.ma",
    address: "10, rue Oulad Bouzid ex.Bartholdi 20170 Casablanca Maroc",
    province:"Marrakech", lat: "33.5883762",
    lng: "-7.6490132"
  }
]

export const dispoMed = [
  {
    name: "2B MEDICAL",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "N22RUE ABOU BAKER IBN ZOHR QUARTIER DES HOPITAUX CASABLANCA",
    tel: "0612240750",
    province:"Marrakech", lat: 33.5788654,
    lng: -7.6252267
  },


  {
    name: "2H BUSINESS SARL",
    specialite: "Importation, Distribution, DM non steriles",
    address: "RUE EL BARAKA MAGASIN N 11 CYM RABAT",
    tel: "0537296549",
    province:"Marrakech", lat: 34.0040176,
    lng: -6.8668106
  },


  {
    name: "3D MEDICA",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "84 BOULEVARD MOULAY DRISS PREMIER GALERIE LA RENAISSANCE MAGASIEN 34 ",
    tel: "0522985440",
    province:"Marrakech", lat: 34.0040882,
    lng: -6.8996413
  },


  {
    name: "3GCOM SARL",
    specialite: "Exportation, Maintenance,",
    address: "11.RUE ANISSONE SECTEURE 22 HAY RIYAD RABAT",
    tel: "0537566782",
    province:"Marrakech", lat: 33.9652285,
    lng: -6.8701258
  },


  {
    name: "3M MAROC",
    specialite: "Importation, DM steriles",
    address: "IMMEUBLE 4 ZENITH LOTISSEMENT ATTAOUFIK SIDI MAAROUF CASABLANCA",
    tel: "0522431600",
    province:"Marrakech", lat: 33.5374914,
    lng: -7.6303046
  },


  {
    name: "3MEDICAL SARL",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles",
    address: "128 BOULEVARD LAHCEN OUIDDER ETAGE 2 APPARTEMENT A8 IMMEUBLE AL WAHDA ",
    tel: "0522543195",
    province:"Marrakech", lat: 33.5842077,
    lng: -7.6111114
  },


  {
    name: "5G DENTAL SARL",
    specialite: " importation fourniture produits et appareillage dentaire ",
    address: "HAY JAMIL RUE 5 N 26 SIDI MOUMEN LAKDIM",
    tel: "0522703076",
    province:"Marrakech", lat: 33.6126469,
    lng: -7.496888
  },


  {
    name: "AABOUD NEGOCE SARL",
    specialite: "Importation, DM steriles",
    address: "RUE LALLA CHAFIA N 9 9 BIS TANGER",
    tel: "0539335322",
    province:"Marrakech", lat: 34.6472771,
    lng: -7.167904
  },


  {
    name: "ABC DENTAIRE SARL",
    specialite: "Importation, Distribution, DM non steriles",
    address: "HAY MOULAY ABDELLAH RUE 246 N 27/29 CASABLANCA",
    tel: "0522872987",
    province:"Marrakech", lat: 34.6415385,
    lng: -7.1679242
  },


  {
    name: "ABLENS",
    specialite: "Fabrication Importation, Distribution, DM steriles",
    address: "457 AVENUE HASSAN 2 RESIDENCE MERYEM MAGASIEN 10 RABAT",
    tel: "0537283000",
    province:"Marrakech", lat: 33.9806065,
    lng: -6.8778957
  },


  {
    name: "ABMED SARL",
    specialite: "Importation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "4RUE ABOU FARIS MARINI IMMEUBLE B APPARTEMENT 2 RABAT",
    tel: "0537207631",
    province:"Marrakech", lat: 34.0197747,
    lng: -6.8254011
  },


  {
    name: "ACADEMY SARL AU",
    specialite: "Exportation, DM non steriles",
    address: "3 RUE TABIT BNOU KORRA QUARTIER DES HOPITEUAX CASABLANCA",
    tel: "0522862671",
    province:"Marrakech", lat: 33.5441583,
    lng: -7.6574923
  },


  {
    name: "ACCEMEDIC SARL",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "223.QUARTIER ECONOMIQUE TIFLET.",
    tel: "0537514167",
    province:"Marrakech", lat: 33.8879224,
    lng: -6.3191457
  },


  {
    name: "ACCENTIS MEDICAL",
    specialite: "Importation, DM steriles",
    address: "ANGEL BOULEVERD DES FAR ET RUE ALLAL BEN ABDELLAH IMMEUBLE HAJAR APPARTEMANT 8 ETAGE 3 ",
    tel: "0523311524",
    province:"Marrakech", lat: 36.7274996,
    lng: -3.0404884
  },


  {
    name: "ACROMEDIC SARL",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "HAMZA 2 FROUGA N 9 M`HAMID MARRAKECH",
    tel: "0524370029",
    province:"Marrakech", lat: 31.632147,
    lng: -7.9219047
  },


  {
    name: "ACTILAB SARL",
    specialite: "Fabrication Importation, Distribution, DM steriles DM non steriles",
    address: "1BIS RUE TOBROUK HASSAN RABAT",
    tel: "0537207575",
    province:"Marrakech", lat: 34.0163702,
    lng: -6.8342533
  },


  {
    name: "ADVANCED MEDICAL SERVICES SARL",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM non steriles",
    address: "15 RUE AIN CHIFAA QU BOURGOGNE CASABLANCA",
    tel: "05222-08744",
    province:"Marrakech", lat: 33.5851045,
    lng: -7.663308
  },


  {
    name: "AETHER MAROC",
    specialite: "Importation, Distribution,",
    address: "43 SALOMA 1 AIN SEBAA CASABLANCA",
    tel: "0661-201392",
    province:"Marrakech", lat: 33.5851886,
    lng: -7.6895726
  },


  {
    name: "AFNORMED",
    specialite: "Importation, Distribution, Maintenance, DM non steriles",
    address: "AMAL 1 N 336 TIT MILLIL CASABLANCA",
    tel: "0522331191",
    province:"Marrakech", lat: 33.5853217,
    lng: -7.6895726
  },


  {
    name: "AFRI 5 SARL",
    specialite: "Importation, DM non steriles",
    address: "MAGASIEN 2 IMMEUBLE 10 BELLE VUE 3 MEKNES",
    tel: "0661779376",
    province:"Marrakech", lat: 33.8702714,
    lng: -5.5400457
  },


  {
    name: "AFRIC MED INSTRUMENTS",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "RUE SOUMAYA RESIDENCE SHEHRAZADE 3 ETAGE 5 N 22 PALMIER CASANLANCA",
    province:"Marrakech", lat: 33.5719183,
    lng: -7.6302513
  },


  {
    name: "AFRICA INTERNET HOLDING",
    specialite: "Importation, Distribution, DM steriles",
    address: "COMPLEX ERAC N 29 MED VI IMMEUBLE F3 N 2 ETAGE 1 CASABLANCA",
    tel: "0661549874",
    province:"Marrakech", lat: 33.5720024,
    lng: -7.656516
  },


  {
    name: "AFRICAINE GATE",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "RESIDENCE EL BARAKA GH1 APPARTEMENT N09 ADRAR TIKIOUNE AGADIR",
    province:"Marrakech", lat: 33.5722762,
    lng: -7.7615802
  },


  {
    name: "AFRICAMEDICAL SARL AU",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "12 RUE SABRI BOUJEMAA 1ER ETAGE CASABLANCA",
    tel: "0662-084891",
    province:"Marrakech", lat: 33.5751971,
    lng: -7.6153039
  },


  {
    name: "AFRICARE",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "11 BOULEVARD ZEKTOUNI APPARTEMENT N 11 4EME ?TAGE CASABLANCA",
    tel: "0522828225",
    email: "info@africare.ma",
    province:"Marrakech", lat: 33.582969,
    lng: -7.6289675
  },


  {
    name: "AFRIC-PHAR",
    specialite: "Fabrication Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "ZONE INDUSTRIELLE OUEST AIN HARROUDA ROUTE REGIONALE N322 KM 12.400 AIN HARROUDA 28630",
    tel: "0522662915",
    email: "africphar@africphar.com",
    province:"Marrakech", lat: 33.640879,
    lng: -7.5002235
  },


  {
    name: "AFROBIOMEDIC",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "RUE 82 N?94 SIDI MARROUF CASABLANCA",
    tel: "0522974494",
    province:"Marrakech", lat: 33.5221475,
    lng: -7.6480706
  },


  {
    name: "AGENTIS",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "29 RUE ZAID IBNOU REFAA 1ER ETAGE MARIF CASABLANCA",
    tel: "0522259090",
    province:"Marrakech", lat: 33.5902138,
    lng: -7.6586297
  },


  {
    name: "AGROLEAD",
    specialite: "Importation, Exportation,",
    address: "BOULEVARD MEHDI BEN BERKA.LOTISSEMENT EL ALEJ RUE 4 N 3 BOURGOGNE CASABLANCA",
    tel: "0522261331",
    province:"Marrakech", lat: 37.9230828,
    lng: -32.5357856
  },


  {
    name: "AIR LIQUIDE MAROC",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM non steriles",
    address: "IMMEBLE MYRIAM ANGLE AUTOROUTE CASABLANCA RABAT ET ROUTE DE TIT MELLIL",
    tel: "0522762000",
    province:"Marrakech", lat: 33.5334279,
    lng: -7.6534692
  },


  {
    name: "AIR MEDICAL",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "25 RUE 300 HAY MLY ABDELLAH AIN CHOCK CASABLANCA",
    tel: "0522872749",
    province:"Marrakech", lat: 33.5333001,
    lng: -7.5856181
  },


  {
    name: "AIRLIFE SARL AU",
    specialite: "Fabrication Importation, Exportation, Distribution, Maintenance, DM non steriles",
    address: "N 16 RUE 3 ET 4 MAZOLA HAY HASSANI CASABLANCA",
    tel: "0697283026",
    email: "airlifesarl@gmail.com",
    province:"Marrakech", lat: 33.6094998,
    lng: -7.5123653
  },


  {
    name: "ALABO SARL AU",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "7 RUE SAYAD EDDINE RESIDENCE NOURWAY2 N 2 BOURGAGNE",
    tel: "0522269268",
    email: "CONTACT@ALABODENTAL.MA",
    province:"Marrakech", lat: 33.5996701,
    lng: -7.6451861
  },


  {
    name: "ALAMEDIC",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "39 HAY ERRAHA BEAUSEJOUR",
    tel: "0522255002",
    province:"Marrakech", lat: 25.5401419,
    lng: -103.4461443
  },


  {
    name: "ALAMI PHARMA SARL AU",
    specialite: "Importation, DM non steriles",
    address: "17 RUE BAHT APPARTEMENT N 5 AGDAL-RABAT",
    tel: "0619632471",
    email: "hiba_alami@hotmail.com",
    province:"Marrakech", lat: 33.5699129,
    lng: -7.6527334
  },


  {
    name: "ALCON MAROC",
    specialite: "Importation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "TOUR CRYSTAL I BOULEVARD SIDI MOHAMED BEN ABDELLAH 7EME ETAGE PLATEAU 11 QUARTIER MARINA 20030",
    tel: "0522235518",
    province:"Marrakech", lat: 33.5890883,
    lng: -7.6364782
  },


  {
    name: "ALGOL MEDICAL",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "295.ANGLE BD ABDELMOUMEN ET RUE PERCEE CASABLANCA",
    tel: "0612888878",
    email: "contact@algolmedical.com",
    province:"Marrakech", lat: 33.5721155,
    lng: -7.6817312
  },


  {
    name: "ALLIANCE ENVIRONNEMENT",
    specialite: "Fabrication Importation, Exportation, Distribution,",
    address: "2 RUE BOUGAINVILLE RESIDENCE TURPIN RDC BOURGOGNE CASABLANCA",
    province:"Marrakech", lat: 33.5720766,
    lng: -7.6664103
  },


  {
    name: "ALMED SANTE",
    specialite: "Distribution, DM steriles DM non steriles",
    address: "N 309 LOTISSEMENT HADJ FATEH HAY OULEFA CASABLANCA",
    province:"Marrakech", lat: 36.7514677,
    lng: -3.045254
  },


  {
    name: "ALPHA PHAR",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "VILLA 390 NASSIM CASABLANCA",
    tel: "0661213135",
    email: " alphaphar.ayyada@gmail.com",
    province:"Marrakech", lat: 33.525367,
    lng: -7.6632585
  },


  {
    name: "ALTHEA SA",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "76.ALLEE DES CASUARINAS AIN SEBAA 20 580 CASABLANCA",
    tel: "0522342399",
    email: "contact@althea.ma",
    province:"Marrakech", lat: 33.6127711,
    lng: -7.5455468
  },


  {
    name: "AMED DENTAL SARL AU",
    specialite: "Importation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "47 RUE DE BRUXELLES IMMEUBLE A MAG 9 MARS SULTAN 20300 CASABLANCA",
    tel: "0522823134",
    province:"Marrakech", lat: 33.5744311,
    lng: -7.6156501
  },


  {
    name: "AMIF SARL AU",
    specialite: "Fabrication Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "LOTISSEMENT 1234 QUARTIER EL WIFAK TEMARA",
    province:"Marrakech", lat: 35.6592329,
    lng: -0.5427142
  },


  {
    name: "AMIPARA",
    specialite: "Distribution, DM steriles DM non steriles",
    address: "MAGASIEN RDC N 28 R?SIDENCE AL AKHAWAYN .SOCOMA 1 MARRAKECH",
    tel: "0524395044",
    province:"Marrakech", lat: 31.6206531,
    lng: -8.1276969
  },


  {
    name: "AMIYAD IMPLANTS SARL",
    specialite: "Distribution, DM non steriles",
    address: "46.AVENUE OKBA APPARTEMENT 2 AGDAL RABAT",
    province:"Marrakech", lat: 22.3278197,
    lng: 8032803
  },


  {
    name: "AMOUCH DENT SARL AU",
    specialite: "Distribution, DM steriles DM non steriles",
    address: "AVENUE JEBER BNE HAYAN BLOC A N 513 ELHOUDA AGADIR",
    tel: "0528321886",
    province:"Marrakech", lat: 31.9970886,
    lng: -9.7004855
  },


  {
    name: "AMOUDENT SARL",
    specialite: "Importation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "RESIDENCE JARDINS DE LA VILLETTE RUE MORMOUCHA IMMEUBLE D APPARTEMENT 12 CASABLANCA",
    tel: "0522601001",
    province:"Marrakech", lat: 33.582614,
    lng: -7.5737193
  },


  {
    name: "ARDCHIR NABIL",
    specialite: "Distribution, DM non steriles",
    address: "N 26 TRANCHE 3 DAR AL BIR WALI HSANE MARRAKECH",
    province:"Marrakech", lat: 33.9862228,
    lng: -6.8590553
  },


  {
    name: "ASSIHA SARL",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "514 .AVENUE 2 MARS ANDALOUS 2 CASABLANCA",
    tel: "0522856727",
    province:"Marrakech", lat: 33.5583942,
    lng: -7.609875
  },


  {
    name: "ASSUT EUROPE MAROC SARL",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "RESIDENCE AZHAR 24 RUE JALAL EDDINE ESSAYAUTI REZ DE CHAUSSEE N 2 CASABLANCA",
    tel: "0522272791",
    province:"Marrakech", lat: 41.8430498,
    lng: 12.595855
  },


  {
    name: "ASTUTE SARL AU",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "DIOUR SIDI ABBAD SAKAR N?64 MARRAKECH",
    tel: "0524309070",
    province:"Marrakech", lat: 31.6559335,
    lng: -7.9927037
  },


  {
    name: "AT MEDIC INTER",
    specialite: "Importation, Distribution, DM non steriles",
    address: "ANGLE AVENUE CADI AYAD ET MAGNOLIAS IMMEUBLE ABA SOUFIANE N 7 TANGER",
    province:"Marrakech", lat: 33.7261543,
    lng: -7.5853147
  },


  {
    name: "ATLANTIC MEDICAL",
    specialite: "Importation distribution de materiel et equipement medico dentaire",
    address: "MASIRA 2 A829 .40000 MARRAKECH",
    tel: "052449417",
    email: "atlanticmedicall@yahoo.fr",
    province:"Marrakech", lat: 31.6590242,
    lng: -8.0364764
  },


  {
    name: "ATLAS COSMETIQUE",
    specialite: "Importation, Exportation, Distribution de produits de beauté et de soins",
    address: "RESIDENCE AL FADL MAGASIEN N1 IMMEUBLE 28 8 EME TRANCHE MARRAKECH",
    tel: "0668101524",
    email: "youssef@atlascosmetic.com",
    province:"Marrakech", lat: 33.7266639,
    lng: -7.5853165
  },


  {
    name: "ATLAS MED SARL AU",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "N 134 MHAMID MARRAKECH",
    tel: "05 24 36 18 42",
    province:"Marrakech", lat: 33.1064102,
    lng: -8.8696908
  },


  {
    name: "ATLAS SERVICE SANTE SARL",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "BLOC 16 N 1023 BOUAAKAZ MHAMID MARRAKECH",
    tel: "066082174",
    email: "contact@atlaservicesante.ma",
    province:"Marrakech", lat: 31.5948197,
    lng: -8.0259281
  },


  {
    name: "AUDIOPTIC",
    specialite: "Importation, Distribution, Maintenance dispositifs auditifs et optiques",
    address: "19.AVENUE D`ALGER RABAT",
    tel: "0537705905",
    email: "info@audioptic.ma",
    province:"Marrakech", lat: 34.0174089,
    lng: -6.8280198
  },


  {
    name: "AUDITION SANTE",
    specialite: "Appareils et prothèses auditives",
    address: "223 BOULEVARD ABDELMOUMEN ET CHORLES NICOLE 5EME ETAGE CASABLANCA",
    tel: "0666033993",
    province:"Marrakech", lat: 33.5734172,
    lng: -7.6277776
  },


  {
    name: "AUDITION UNIVERS",
    specialite: "Centre de correction auditive",
    address: "Résidence Zerktouni, Appt 2, 1er étage, Bd Zerktouni – Guéliz",
    tel: "0524423704",
    province:"Marrakech", lat: 31.6366661,
    lng: -8.0155958
  },


  {
    name: "AVAD MAROC",
    specialite: "Importation, Distribution, Maintenance Matériel d’oxygénothérapie",
    address: "20 RUE AL GHADFA RESIDENCE WAFA MAARIF20100 CASABLANCA",
    tel: "0663438631",
    province:"Marrakech", lat: 33.5716923,
    lng: -7.5984965
  },


  {
    name: "AVICENNE GROUP SARL",
    specialite: "Matériel médical - Equipement et consommables d'hémodialyse",
    address: "BOULEVARD ABDELMOUMEN RUE 90N 15 DERB GHALLEF CASABLANCA",
    tel: "0522981859",
    email: "magasin@avicennegroup.com",
    province:"Marrakech", lat: 33.5762132,
    lng: -7.6276834
  },


  {
    name: "AVM CHIM SARL AU",
    specialite: "Négoce et distribution détergent, cosmétique, l’alimentaire, la peinture et le textile.",
    address: "39 RUE DE LILLE 1ER ETAGE APPARTEMENT 4 BELVEDER CASABLANCA",
    tel: "0522663370",
    email: "avmchim@menara.ma",
    province:"Marrakech", lat: 33.6199629,
    lng: -7.5100207
  },


  {
    name: "AVMEDICAL SARL",
    specialite: "Importation, distribution d’équipement médico-chirurgical principalement en orthopédie, ophtalmologie et appareils auto-dignostics.",
    address: "N63 QUARTIER INDUSTRIAL AIN CHKAF FES",
    tel: "0535606853",
    email: "avmedical@hotmail.fr",
    province:"Marrakech", lat: 33.9915412,
    lng: -5.0534946
  },


  {
    name: "AYA PHARM SARL AU",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "N 16 IMMEUBLE D LOTISSEMENT RIAD AGDAL SYBA MARRAKECH",
    tel: "05225-94455",
    province:"Marrakech", lat: 33.5496503,
    lng: -7.5580456
  },


  {
    name: "AYCODENT",
    specialite: "Importation et distribustion de produits consommables pour la fabrication de prothèses dentaires ",
    address: "12 RUE TAOURIRT 20060 CASABLANCA",
    tel: "0522484493",
    email: "aycodent@aycodent.com",
    province:"Marrakech", lat: 33.5971904,
    lng: -7.6344687
  },


  {
    name: "AZUR MEDICAL",
    specialite: "Vente matériel médical et chirurgical ",
    address: "AMAL 5 EL MASSIRA N 1135 HAY EL FATH CYM RABAT",
    tel: " 05372-80281",
    province:"Marrakech", lat: 33.5497213,
    lng: -7.5908763
  },


  {
    name: "B2S HEALTH SERVICES SARL",
    specialite: "Importation, distribution et promotion, Compléments Alimentaires, Dispositifs Médicaux, Promotion des produits pharmaceutiques",
    address: "9 AVENUE MOHAMED BELHASSAN EL OUAZZANI CENTRE COMMERCIAL DAR ESSALAM SOUISSI -RABAT",
    tel: "0537753750",
    province:"Marrakech", lat: 33.5935062,
    lng: -7.6039813
  },


  {
    name: "BAK DENT",
    specialite: "Distribution des produits et matériel dentaire ",
    address: "RUE EGBALA RUE 4 N 2 BIS BENI MELLAL",
    tel: "0523483256",
    province:"Marrakech", lat: 33.5333001,
    lng: -7.5856181
  },


  {
    name: "BATRA MEDICAL",
    specialite: "Marchant importateur de produits de désinfection d'hygiène et prophylaxie et les appareils radiologie (vente matériel médical) ",
    address: "47 AVENUE MAJD CYM RABAT",
    tel: "0537573700",
    email: "batram@menara.ma",
    province:"Marrakech", lat: 33.999333,
    lng: -6.8617575
  },


  {
    name: "BEIERSDORF SA",
    specialite: "Fabrication et de Distribution de Produits Cosmétiques",
    address: "Rue 1, Immeuble 29, 3ième étage Lotissement Attawfik, Sidi Maarouf 20270",
    tel: "0522879300",
    province:"Marrakech", lat: 37.0136034,
    lng: -10.1607601
  },


  {
    name: "BELMO PHARMA",
    specialite: "MARCHAND D'ACCESSOIRES OU FOURNITURES POUR LA PHARMACIE",
    address: "MASSIRA 2 A N 187 MARRAKECH",
    province:"Marrakech", lat: 33.9862228,
    lng: -6.8590553
  },


  {
    name: "BENGUERIR MEDICAL",
    specialite: "MARCHAND DE PRODUITS COSMETIQUES (PARAPHARMACIE)",
    address: "HAY ENNOUR N 188 BEN GUERIR",
    tel: "0665186825",
    province:"Marrakech", lat: 32.2285583,
    lng: -7.9497649
  },


  {
    name: "BERBERE MEDICALE SARL AU",
    specialite: "Distribution, DM steriles DM non steriles",
    address: "RUE SOUMAYA RESIDENCE SHEHRAZADE 3 5 EME ETAGE N 22 PALMIERS MAARIF CASABLANCA -MAROC",
    province:"Marrakech", lat: 33.5789514,
    lng: -7.6236735
  },


  {
    name: "BEST MADE",
    specialite: "Distribution de matériel et de produits dentaires",
    address: "464 Bvd El Fida, Casablanca",
    tel: "0522834482",
    email: "larbi35@caramail.com\n",
    province:"Marrakech", lat: 34.0451825,
    lng: -6.8004858
  },


  {
    name: "BEST WAY MEDICAL",
    specialite: "Importation, DM steriles, MARCHAND D'APPAREILS D'ELECTRICITE MEDICALE.",
    address: " 132, rue Meskallile-ex Bleuets, Beauséjour casablanca.",
    tel: "0522270377",
    province:"Marrakech", lat: 31.4767055,
    lng: 74.2689071
  },


  {
    name: "BETA SANTE SARL",
    specialite: "MARCHAND D'APPAREILS OU DE PIÈCES DÉTACHÉES OU ACCESSOIRES POUR APPAREILS DE RADIOLOGIE ET ÉLECTRICITÉ MÉDICALE, RADIUMTHÉRAPIE",
    address: "MAGASIEN N 2BIS RUE ABDERAHMANE MOUNIR PEPINIER-SALE",
    province:"Marrakech", lat: 33.5051956,
    lng: -7.6969734
  },


  {
    name: "BF MEDICAL SARL AU",
    specialite: "Magasin de matériel médical · Fournisseur d’équipement médical · Fabricant de matériel médical",
    address: "6 SOUK EL YOUSSOUFIA COMMERCIAL KONIFA I CASABLANCA",
    province:"Marrakech", lat: 33.5041959,
    lng: -7.6659979
  },


  {
    name: "BINARIOS",
    specialite: "Expert en informatique médicale",
    address: "LOTISSEMENT 84 ZONE INDUSTRIELLE OULAD SALEH PROVINCE NOUACEUR BOUSKOURA",
    tel: "0522592004",
    email: "contact@binarios-it.com",
    province:"Marrakech", lat: 33.4264751,
    lng: -7.6437082
  },


  {
    name: "BIO & ECO SOLUTIONS",
    specialite: "Conception et distribution des équipements d’hygiène professionnelle, de protection individuelle et de transformation alimentaire.",
    address: "5 IMP IBNO KATIR RESIDENCE ZAKI LOTISSEMENT AL MAWID 20380 MAARIF CASABLANCA",
    tel: "0522238152",
    email: "contact@bio-eco-solutions.ma",
    province:"Marrakech", lat: 33.4184622,
    lng: -7.6474556
  },


  {
    name: "BIO PRODUIT SARL",
    specialite: "Distribution des produits pharmaceutiques",
    address: "IMMOUBLE NADA N 1 BIT GHOULAM TAZA VN",
    tel: "0535285251",
    province:"Marrakech", lat: 33.5044428,
    lng: -7.665998
  },


  {
    name: "BIOCIDIS PHARMA SARL AU",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "265 BOULEVARD ZERKTOUNI 9EME N 92 MAARIF CASABLANCA",
    tel: "0522949402",
    province:"Marrakech", lat: 33.5828703,
    lng: -7.6215722
  },


  {
    name: "BIOCODEX MAROC",
    specialite: "Elabore, fabrique et distribue des produits pharmaceutiques et des solutions de santé",
    address: "Résidence Le Joyau IV – 2 rue Ibnou Al Moataz 20300",
    tel: "0522247502",
    province:"Marrakech", lat: 33.3654218,
    lng: -7.5742896
  },


  {
    name: "BIOCORP SARL",
    specialite: "Matériel produits chimiques pour laboratoires à Casablanca",
    address: "27,Rue Abou hmed El ghazali, Bd Rachidi - Casablanca، Casablanca 20000",
    tel: "0522487216",
    email: "Biocorp@menara.ma",
    province:"Marrakech", lat: 33.5936155,
    lng: -7.629521
  },


  {
    name: "BIOLENS LAB",
    specialite: "Fabrication et le développement de lentilles de contact souples, rigides et sclérales, l’importation et la distribution de solutions d’entretien, d’accessoires pour lentilles de contact ainsi que des équipements médicaux.",
    address: "Lot N° 6, El Farah II, 28810 Mohammedia, Maroc",
    tel: "0523323056",
    email: "info@biolens.ma",
    province:"Marrakech", lat: 33.680303,
    lng: -7.4083148
  },


  {
    name: "BIOLOGY GLOBAL SERVICES SARL",
    specialite: "IMPORT, EXPORT & DISTRIBUTION DES REACTIFS A USAGE DIAGNOSTIC IN VITRO, PRODUITS CHIMIQUES, MATERIELS, APPAREILS, FONGIBLES ET CONSOMMABLES BIOLOGIQUES, AGROALIMENTAIRES, MEDICAUX, PARAMEDICAUX, CHIRURGICAUX ET RADIOLOGIE, AINSI QUE: DISPOSITIFS MEDICAUX, COMPLEMENTS ALIMENTAIRES, INSTALLATION & MAINTENANCE",
    address: "23 APPARTEMENT 8 AIN ATIQ TEMARA REGION DE RABAT",
    tel: "0537640840",
    province:"Marrakech", lat: 33.5829271,
    lng: -7.6245467
  },


  {
    name: "BIOMEDECA MAROC SARL",
    specialite: "Distribution de dispositifs médicaux et paramédicaux,",
    address: "RUE SEBOU RESIDENCE ISMAILIA IMMEUBLE 22 APPARTEMENT 1 VN MEKNES",
    tel: "0535515746",
    email: " Biomedeca.maroc@biomedeca.ma",
    province:"Marrakech", lat: 33.5802123,
    lng: -7.6694668
  },


  {
    name: "BIOMEDICAL ALLIANCE SARL",
    specialite: "Vente et maintenance des équipements médicaux ( Endoscopie, Echographie, Laboratoire, Bloc opératoire …) ainsi que la distribution de consommable et des accessoires endoscopiques et chirurgicaux",
    address: "LOTISSEMENT NASSIM LOT N?110 CASABLANCA",
    tel: "0522932389",
    email: "contact@biomedical-alliance.com",
    province:"Marrakech", lat: 33.5274449,
    lng: -7.6610306
  },


  {
    name: "BIOMEDICAL EQUIPMENT MAROC",
    specialite: "La commercialisation et la maintenance d'appareils d'échographies,",
    address: "LOTISSEMENT CHABAB LOT C4 AIN SEBAA CASABLANCA",
    tel: "0529044277",
    email: "karimasri@biomedicalcenter.fr",
    province:"Marrakech", lat: 33.6090614,
    lng: -7.5227077
  },


  {
    name: "BIOMEDICAL NORD SARL",
    specialite: "MARCHAND D'ACCESSOIRES DE BUREAU, D'INFORMATIQUE OU D'APPAREILS POUR LA MÉDECINE",
    address: "N 1 AVENUE SIDI BOUABID IMMEUBLE 42 TANGER",
    tel: "0539331783",
    province:"Marrakech", lat: 33.9491557,
    lng: -6.9357814
  },


  {
    name: "BIOMEDICAL PRO",
    specialite: "l’importation, la distribution, la vente et la maintenance du matériel médical",
    address: "131 Rue Farhat Hachad Amal 4, Rabat, Maroc",
    tel: "0537261956",
    email: "contact@biomedical.ma",
    province:"Marrakech", lat: 33.9490287,
    lng: -6.8679299
  },


  {
    name: "BIOPHAMED",
    specialite: "IMPORTATION COMMERCIALISATION DE MATERIEL ET CONSOMMABLE MEDICAL / MATERIEL ET CONSOMMABLE DE LABORATOIRE PRODUITS PARA MEDICAUX",
    address: "84 BOULEVARD MOULAY DRISS GALERIE ENAHDA N09 CASABLANCA",
    tel: "0637139677",
    email: "biophamed@gmail.com",
    province:"Marrakech", lat: 33.5126258,
    lng: -7.6617852
  },


  {
    name: "BLEU PHARMA SARL",
    specialite: "Distribution, DM steriles DM non steriles",
    address: "BLOC 16 NO 1037 BOUAAKAZ MHAMID MARRAKECH",
    tel: "0676527129",
    email: "naciri.mjid@gmail.com",
    province:"Marrakech", lat: 31.600224,
    lng: -8.0521485
  },


  {
    name: "BM PROMO SERVICES SARL AU",
    specialite: "Distribution, DM non steriles",
    address: "15 BIS RUE EDDAMIR EL KABIR ETAGE 3 APPARTEMENT 6 CASABLANCA",
    province:"Marrakech", lat: 31.6398981,
    lng: -8.020369
  },


  {
    name: "BOB SERVICE MEDICALE SARL",
    specialite: "Distribution, DM steriles DM non steriles",
    address: "TEMARA D7 N 466 BIS MARRAKECH",
    tel: "0524497601",
    province:"Marrakech", lat: 31.6206531,
    lng: -8.1276972
  },


  {
    name: "BOTANIC PHARMA SARL",
    specialite: "Importation, Distribution, DM non steriles",
    address: "ZAHRII LOTISSEMENT N 4 SIDI MAAROUF CASABLANCA",
    tel: "0522780439",
    province:"Marrakech", lat: 33.5141745,
    lng: -7.6487247
  },


  {
    name: "BOTECH SARL",
    specialite: "Fabrication Importation, Exportation, Distribution, DM non steriles",
    address: "1 RUE ZAHRAT ELOU LOU HAY ERRAHA CASABLANCA",
    tel: "0522397993",
    email: "botech2008@gmail.com",
    province:"Marrakech", lat: 33.5680542,
    lng: -7.6566665
  },


  {
    name: "BOTTU SA",
    specialite: "Fabrication Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "82 ALLEE DES CASURINAS BP 2583 AIN SEBAA 20580 CASABLANCA",
    tel: "0522355350",
    email: "contact@bottu.com",
    province:"Marrakech", lat: 33.6117803,
    lng: -7.5454802
  },


  {
    name: "BOUMEHDY TRADE",
    specialite: "IMPORT EXPORT ET NEGOCE-PARAMEDICAL, PRESTATION SERVICE",
    address: "DERB FOKARA RUE 22 APPARTEMENT 18 BOULEVARD DARB SOLTAN CASABLANCA",
    province:"Marrakech", lat: 34.2563132,
    lng: -6.5851825
  },


  {
    name: "BOUZIANE PRODUCT SBPRO",
    specialite: "Importation, Distribution, DM non steriles",
    address: "44 ZONE INDUSTRIELLE AIN CHKAF FES",
    tel: "0535609488",
    email: "Contact@sbpro.ma",
    province:"Marrakech", lat: 34.0121508,
    lng: -5.0339511
  },


  {
    name: "BROTHERS HEALTHCAR PHARMACEUTICALS",
    specialite: "Exportation, DM steriles",
    address: "RESIDENCE AL MANSOUR IMMEUBLE 46APPARTEMENT 10 HAY ESSALAM HAY HASSANI AIN CHOCK CASABLANCA",
    province:"Marrakech", lat: 33.9031638,
    lng: -117.4932646
  },


  {
    name: "BRUT DENT SARL AU",
    specialite: "Distribution, DM steriles DM non steriles",
    address: "HAY AL WAHDA N 50 MOHAMEDIA",
    tel: "05 23 28 50 35",
    province:"Marrakech", lat: 33.5906358,
    lng: -7.5503152
  },


  {
    name: "BUSINESS DEVELOPPEMENT MANAGEMENT (BDM) PHARMA",
    specialite: "Importation, Distribution, Maintenance,",
    address: "14022 RUE DIJLA ANFA CASABLANCA",
    tel: "0522207895",
    email: "bdmanagement@menara.ma",
    province:"Marrakech", lat: 33.5026774,
    lng: -7.6626138
  },


  {
    name: "C.A.D.M (Centrale d'Achat Dentaire du Maroc)",
    specialite: "Distribution des fournitures dentaires",
    address: "RUE ABOU KACEM ZAHARI RESIDENCE MANAR 3 CASABLANCA",
    tel: "0522865865",
    email: "lacentraledentaire@gmail.com",
    province:"Marrakech", lat: 33.5729553,
    lng: 7.6172436
  },


  {
    name: "C.P.D.M-MAROC SARL-AU",
    specialite: "Distribution dispositifs médicals",
    address: "AVENUE AL MAGHREB ALARABIE RESIDENCE PERLE DE LA MEDINA-C-C8 RABAT -OCEAN-MAROC",
    tel: "0537261087",
    email: "cpdm@cpdm.ma",
    province:"Marrakech", lat: 34.0195171,
    lng: -6.84771
  },


  {
    name: "CARDIO PLUS SARL",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "42.RUE ABDESLAM KHETTABI 21940.CASABLANCA",
    tel: "0522258525",
    province:"Marrakech", lat: 33.5642861,
    lng: -7.6446262,
  },


  {
    name: "CASA DENTAIRE SARL",
    specialite: "Importation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "20 RUE HABACHA CASABLANCA",
    tel: "0522481600",
    province:"Marrakech", lat: 33.5879284,
    lng: -7.6140704
  },


  {
    name: "CASACOLLINE SARL AU",
    specialite: "TOUTE OPERATION D'OPTIQUE, ACHAT ET VENTE MATERIEL MEDICAL, OPTIQUE ET INFORMATIQUE, INSTALLATION FORMATION ET REPARATION DE MATERIEL MEDICAL ET OPTIQUE - IMPORT EXPORT",
    address: "LOTISSEMENT LOUBNA RUE 100 N 62 SIDI MAAROUF CASABLANCA",
    tel: "0522583734",
    province:"Marrakech", lat: 33.5114387,
    lng: -7.6436869
  },


  {
    name: "CCPMA SARL AU",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "8 RUE IBNOU EL ADILE CASABLANCA",
    tel: "0522220056",
    province:"Marrakech", lat: 33.5963174,
    lng: -7.6439669
  },


  {
    name: "CENTRE 3D DENTAIRE MA  ",
    specialite: "Fabrication Importation, Distribution,",
    address: "BOULEVARD BOUZIANE RESIDENCE AYA N 192 1ERE ETAGE APPARTEMENT 01 BEN MSIK 20700 CASABLANCA",
    tel: "0522373737",
    email: "info@c3d-dentaire.com",
    province:"Marrakech", lat: 34.0122195,
    lng: -5.0339511
  },


  {
    name: "CENTRE AFRICAIN PHARMACEUTIQUE",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "46.RUE LIBOURNE CASABLANCA",
    tel: "0522449216",
    province:"Marrakech", lat: 33.5332956,
    lng: -7.5834294
  },


  {
    name: "CENTRE AUDIOVITALE",
    specialite: "Importation, DM steriles",
    address: "ANGLE BD GHANDI ET BD YACOUB EL MANSOUR RESIDENCE DU PALAIS IMMEUBLE D 5 EME ETAGE B 22 CASABLANCA",
    tel: "0522363232",
    province:"Marrakech", lat: 33.5716444,
    lng: -7.6478649
  },


  {
    name: "CENTRE AUDITIF FAHIMI",
    specialite: "Importation, Distribution, Maintenance, DM steriles",
    address: "47.BOULEVARD MED V RESIDENCE DIAMAND VERT N 22 TANGER",
    tel: "0536601800",
    province:"Marrakech", lat: 33.5721136,
    lng: -7.6179248
  },


  {
    name: "CENTRE KASSIMI DAPPAREILLAGE ORTHOPEDIQUE",
    specialite: "Fabrication Importation, Exportation, Distribution, Maintenance, DM non steriles",
    address: "10 RUE 16 MISSIMI HAY HASSANI CASABLANCA",
    tel: "0522904930",
    province:"Marrakech", lat: 33.5635211,
    lng: 7.6744731
  },


  {
    name: "CENTRE MAROCAIN DE PROTHESE AUDITIVE",
    specialite: "Fabrication Importation, Exportation, Distribution, Maintenance, DM steriles DM steriles",
    address: "120 BOULEVARD 11 JANVIER CASABLANCA",
    tel: "0522315050",
    email: "contact@correction-auditive.ma <contact@correction-auditive.ma>;",
    province:"Marrakech", lat: 33.588294,
    lng: 7.612586
  },


  {
    name: "CESMA SA",
    specialite: "Importation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "32 AVENUE MARS SULTAN 20130 CASABLANCA",
    tel: "0522205526",
    province:"Marrakech", lat: 33.5893806,
    lng: -7.6189675
  },


  {
    name: "CHAHIMED SARL",
    specialite: "Importation, Distribution, DM steriles DM steriles",
    address: "81 BOULEVARD 11 JANVIER ETAGE 3 N3 CASABLANCA",
    province:"Marrakech", lat: 33.5896865,
    lng: -7.6159227
  },


  {
    name: "CHAIMPCO SARL AU",
    specialite: "Importation, Exportation, Distribution, DM steriles",
    address: "28 RESIDENCE VERDI BELVEDERE CASABLANCA",
    tel: "0522304388",
    province:"Marrakech", lat: 34.0122195,
    lng: -5.0339511
  },


  {
    name: "CHALLENGER MEDICAL SERVICE SARL",
    specialite: "Fabrication Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "N 7 RUE DAMAS HAY ELALAOUINE TEMARA",
    tel: "0667136940",
    province:"Marrakech", lat: 33.9180633,
    lng: -6.9223108
  },


  {
    name: "CHARISMADENT SARL",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "2 RUE DU MAUSOLE QUARTIER DES HOPITAUX CASABLANCA",
    tel: "0522862064",
    province:"Marrakech", lat: 33.5726648,
    lng: -7.6174845
  },


  {
    name: "CHIFA MEDIC",
    specialite: "Distribution, DM steriles DM non steriles",
    address: "N 471 CENTRE COMMERCIAL ILLIGH AGADIR",
    tel: "0528846578",
    province:"Marrakech", lat: 33.7236955,
    lng: -7.4625430999
  },


  {
    name: "CHIFA MEDICAL",
    specialite: "Distribution, DM steriles",
    address: "BLOC 16 N 34 BOULEVARD EL JOULANE SIDI OTHMANE CASABLANCA",
    tel: "0522599515",
    province:"Marrakech", lat: 33.6173159,
    lng: 7.5728455
  },


  {
    name: "CHIFIL SARL",
    specialite: "Fabrication Distribution, DM non steriles",
    address: "83.RUE 7 HAY ABSALANE HAY MOHAMADIE CASABLANCA",
    province:"Marrakech", lat: 34.0122195,
    lng: -5.0339511
  },


  {
    name: "CHIRUMED SARL",
    specialite: "Importation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "BOULEVARD ZIRAOUI 58.RUE MOHAMED SIDKI 1ER ETAGE BOURGOGNE CASABLANCA",
    tel: "0522777100",
    province:"Marrakech", lat: 33.5126213,
    lng: -7.6595965
  },


  {
    name: "CHIRURGIE MED",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "20188 ALWIFAQ 2 TEMARA",
    tel: "0537627212",
    province:"Marrakech", lat: 33.9197593,
    lng: -6.9318468
  },


  {
    name: "CHRISTEYNS",
    specialite: "Importation, DM non steriles",
    address: "206.RUE ABOU ZAID EDDOUSSI MAARIF CASABLANCA",
    tel: "0522980577",
    province:"Marrakech", lat: 33.5332956,
    lng: -7.5834294
  },


  {
    name: "CITY MEDICAL",
    specialite: "Distribution, DM steriles DM non steriles",
    address: "N 534 LOTISSEMENT C MASSIRA 3 MARRAKECH",
    tel: "0524395296",
    province:"Marrakech", lat: 31.688771,
    lng: -7.9963207
  },


  {
    name: "CIVIL MED",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "RESIDENCE N 168 LOTISSEMENT BOUMASMAR HAY MOHAMADI MARRAKECH",
    tel: "0664396125",
    province:"Marrakech", lat: 33.0754286,
    lng: -8.8698086
  },


  {
    name: "CIVIL MEDIC",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "N 4 RUE ABDELEUAHAD EDDARAK GUELIZ MARRAKECH",
    tel: "0524432376",
    province:"Marrakech", lat: 34.0041753,
    lng: -6.8188975
  },


  {
    name: "CLAES MEDICAL SERVICE",
    specialite: "Importation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "ANGLE RUE DES ACACIAS BOULEVARD YACOUB EL MANSOUR MAARIF CASABLANCA MAROC",
    tel: "0522250226",
    province:"Marrakech", lat: 33.491689,
    lng: -7.6837165
  },


  {
    name: "CLAIRE DENT",
    specialite: "Importation, Distribution,",
    address: "6 RUE GHANDI APPARTEMENT3 1ER ETAGE RABAT",
    tel: "0537726264",
    province:"Marrakech", lat: 34.0199137,
    lng: -6.8346069
  },


  {
    name: "CLARIMED SARL",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "8 RUE MURILLO QUARTIER PALMIER CASABLANCA",
    tel: "0522235054",
    province:"Marrakech", lat: 33.5544634,
    lng: 7.6083136
  },


  {
    name: "CLARITAS SARL",
    specialite: "Importation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "1°Ét. N°6, 119 Bd Abdelmoumen, Casablanca 20100",
    tel: "0522203080",
    province:"Marrakech", lat: 33.5771372,
    lng: 7.6255837
  },


  {
    name: "CLINICA SARL",
    specialite: "Importation, DM steriles DM non steriles",
    address: "6 RUE KOWEIT CASABLANCA",
    tel: "0522319586",
    province:"Marrakech", lat: 33.5889983,
    lng: 7.6114813
  },


  {
    name: "CLINIQUE IBERIA SARL AU",
    specialite: "Importation, Maintenance, DM steriles",
    address: "AVENUE SIDI AMAR 3 TANGER",
    tel: "0539944546",
    province:"Marrakech", lat: 35.779975,
    lng: -5.82528
  },


  {
    name: "COMARTIP SARL",
    specialite: "Distribution, DM steriles DM non steriles",
    address: "177 YASMINA 1 KHOURIBGA",
    tel: "0523497536",
    province:"Marrakech", lat: 32.8892072,
    lng: -6.8992908
  },


  {
    name: "COMED SARL",
    specialite: "Importation, DM steriles DM non steriles",
    address: "N38/39 LOTISSEMENT KADRIYA-LISSASFA-CASABLACA",
    tel: "0522951238",
    province:"Marrakech", lat: 33.576157,
    lng: -7.6524984
  },


  {
    name: "COMPAGNE MAROCAINE DE VENTE EN DETAIL",
    specialite: "Importation, Distribution, DM steriles DM steriles",
    address: "19 LOTISSEMENT ZOHOUR BOULEVARD MIMOZA AIN SEBAA CASABLANCA",
    province:"Marrakech", lat: 33.5601958,
    lng: -7.5885779
  },


  {
    name: "COMPTOIR MEDICAL BERRADA ET FILS (CMBF ATLAS MEDICAL)",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "RESIDENCE IMAM ALI RUE HASSAN EL BASI 30000 FES",
    tel: "0535621693",
    email: "info@cmbf-atlas.com",
    province:"Marrakech", lat: 34.0415651,
    lng: -5.0085264
  },


  {
    name: "COMPTOIR MEDICAL MAROCAIN",
    specialite: "Importation, Distribution, DM non steriles",
    address: "13 LOUTISSEMENT SALIMA BIR RAMI KENITRA",
    tel: "0660750697",
    email: "comptoiremedicalmarocainsarl@gmail.com",
    province:"Marrakech", lat: 34.2492308,
    lng: 6.5796327
  },


  {
    name: "COMPTOIR MEDICO DENTAIRE ET CHIRURGICAL SARL AU",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "17RUE AIN TAOUJTATE R?SIDENCE GOLD 4 EME ETEGE APPARTEMENT 8.QUARTIER TRIANGLE D`OR CASABLANCA",
    tel: "05224-75861",
    province:"Marrakech", lat: 33.5945753,
    lng: -7.6413303
  },


  {
    name: "CONFORT PARAMEDIC SARL AU",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "84 BOULEVARD MLY DRISS 1 ER GALERIC LA RENAISSANCE N 28 CASABLANCA",
    tel: "05 22 86 34 08\n",
    province:"Marrakech", lat: 33.5723939,
    lng: -7.6177776
  },


  {
    name: "CONSOMMABLE MEDICAL ET LABORATOIRE (COMELAB )",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "195 BOULEVARD EMILE ZOLA CASABLANCA",
    tel: "05224-07402",
    province:"Marrakech", lat: 33.5938907,
    lng: -7.5968078
  },


  {
    name: "COOPER PHARMA",
    specialite: "Producteur, distributeur et exportateur de spécialités pharmaceutiques.",
    address: "41.RUE MOHAMED DIOURI 20110 CASABLANCA MAROC",
    tel: "0522453200",
    province:"Marrakech", lat: 33.5604425,
    lng: -7.588578
  },


  {
    name: "COPHAMET SARL",
    specialite: "Distribution, DM steriles DM non steriles",
    address: "34 RUE BOUKRAA QURTIER MODERNE BENI MELLAL",
    tel: "05234-81700",
    province:"Marrakech", lat: 32.3330765,
    lng: -6.3582141
  },


  {
    name: "COSMO CHURGIE SARL AU",
    specialite: "Importation, Distribution,",
    address: "387 BOULEVARD MOHAMED V ETAGE 7 N 19 CASABLANCA",
    tel: "05223-42818",
    province:"Marrakech", lat: 33.6151467,
    lng: -7.5259017
  },


  {
    name: "COSMOMED",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "45 RUE ZAKI EDDINE TAOUSSI DEUX MARS CASABLANCA",
    tel: "05228-64725",
    province:"Marrakech", lat: 33.570026,
    lng: -7.613372
  },


  {
    name: "CYBERNETIC MEDICAL SARL",
    specialite: "Importation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "445.BOULEVARD MOUHAMED V CASABLANCA",
    tel: "05 22 24 86 05",
    province:"Marrakech", lat: 33.6152744,
    lng: -7.5937532
  },


  {
    name: "CYNOSURE MAROC",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles DM steriles",
    address: "ROUTE D`AZZEMOUR RUE 2 N 40 AIN DIAB CASABLANCA",
    tel: "0661237063",
    email: "infomorocco@cynosure.com",
    province:"Marrakech", lat: 33.5764574,
    lng: -7.6811294
  },


  {
    name: "DEBRO SARL",
    specialite: "Importation, Distribution, Maintenance, DM non steriles",
    address: "6 RUE KADI AYADE SETTAT",
    province:"Marrakech", lat: 33.5608076,
    lng: -7.4627419
  },


  {
    name: "DEFIB MAGHREB",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "TWIN CENTER TOUR A 16EME ETAGE BOULEVARD ZERKTOUNI CASABLANCA",
    tel: "0522233657",
    email: "info@himaya.ma",
    province:"Marrakech", lat: 33.5609353,
    lng: -7.530593
  },


  {
    name: "DELTATEC SARL",
    specialite: "Importation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "21 PLACE LOUIS PASTEUR RESIDENCE PASTEUR CASABLANCA MAROC",
    tel: "0522487506",
    province:"Marrakech", lat: 33.5837142,
    lng: -7.5842939
  },


  {
    name: "DEMI SARL",
    specialite: "Importation, Distribution, Maintenance, DM non steriles",
    address: "4 .11 RUE ELWAHDA 11 JANVIER 20130 CASABLANCA",
    tel: "0522441414",
    email: "demi@demi.ma",
    province:"Marrakech", lat: 33.5894789,
    lng: -7.616329
  },


  {
    name: "DENTACARE",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "ANGLE BOULEVARD YACOUB EL MANSOUR 3 RUE ISHAQIBN HANIN ETAGE 1 APPARTEMENT 1MAARIF ANFA CASABLANCA",
    province:"Marrakech", lat: 33.6156537,
    lng: -7.8039021
  },


  {
    name: "DENTAIRE FASSI",
    specialite: "Distribution,",
    address: "MAGASIEN N 1 H328 RESIDENCE LOTISSEMENT RIAD YASLIN AIN CHKEF FES",
    province:"Marrakech", lat: 33.6161658,
    lng: -7.8039039
  },


  {
    name: "DENTAL BENNIS",
    specialite: "Distribution,",
    address: "43 BOULEVARD DU CAIRE RUE B N 1 ER ETAGE CASABLANCA",
    tel: "0522809595",
    province:"Marrakech", lat: 33.5276837,
    lng: -7.6819248
  },


  {
    name: "DENTAL DOUKKALA",
    specialite: "Distribution, DM non steriles",
    address: "SAADA2 RUE 30 N 91 HAY MOHAMMADI CASABLANCA",
    tel: "0522609415",
    province:"Marrakech", lat: 33.6056708,
    lng: -7.5310231
  },


  {
    name: "DENTAL EXPERT",
    specialite: "Importation,",
    address: "RUE IBNO KOTIR LOTISSEMENT N 7 QUARTIER MAARIF EXTENSION CASABLANCA",
    tel: "0522987979",
    email: "dentalexpert1@gmail.com",
    province:"Marrakech", lat: 33.5762603,
    lng: -7.6483621
  },


  {
    name: "DENTAL EXPRESS SARL",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "23 AVENUE SIDI BOUKHARI TANGER",
    tel: "0539947795",
    email: "CONTACT@DENTALEXPRESS.MA",
    province:"Marrakech", lat: 35.7829684,
    lng: -5.8348663
  },


  {
    name: "DENTAL FAST",
    specialite: "Distribution,",
    address: "457 RESIDENCE MARIAM AVENUE HASSAN 2 RABAT",
    province:"Marrakech", lat: 33.5279305,
    lng: -7.6819249
  },


  {
    name: "DENTAL GATEWAY INTERNATIONAL",
    specialite: "Importation, Exportation, DM steriles DM non steriles",
    address: "ANGLE BOULEVARD SIDI ABDERRAHMANE ET BOULEVARD OMAR EL KHAYAM HAY ARRAHA ETAGE APPARTEMENT 5 CASABLANCA",
    tel: "0669407714",
    province:"Marrakech", lat: 33.5654111,
    lng: -7.6497741
  },


  {
    name: "DENTAL KEY",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "264 BOULEVARD GHANDI 20200 CASABLANCA",
    tel: "0522397863",
    province:"Marrakech", lat: 33.5638652,
    lng: -7.6365342
  },


  {
    name: "DENTAL PRODUCTS",
    specialite: "Exportation, Distribution,",
    address: "ANGLE BOULEVARD YACOUB EL MANSOUR 3 RUE ISHAQ IBN HANIN ETAGE 1 APPARTEMENT 1 CASABLANCA",
    province:"Marrakech", lat: 33.5638872,
    lng: -7.6431003
  },


  {
    name: "DENTAL PROJECT",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "55 RUE MOQALAD AKILI -BOURGONE CASABLANCA",
    tel: "0522473713",
    province:"Marrakech", lat: 33.5727615,
    lng: -7.6192047
  },


  {
    name: "DENTAL TECHNIK MAROC",
    specialite: "Importation, Distribution, Maintenance, DM non steriles",
    address: "80 BOULEVARD ANOUAL ETAGE 1 N 3QUARTIER DES HOPITAUX CASABLANCA",
    tel: "0522302541",
    email: "dentaltechnik@outlook.fr",
    province:"Marrakech", lat: 33.5875242,
    lng: -7.6163606
  },


  {
    name: "DENTAL WEB",
    specialite: "Distribution, DM steriles DM non steriles",
    address: "RUE 41 N 14 GROUPE Z OULFA",
    province:"Marrakech", lat: 33.5281773,
    lng: -7.681925
  },


  {
    name: "DENTAL WORLD",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "APPARTEMENT N 1 SAADA IMMEUBLE 46 MARRKECH",
    tel: "0524308775",
    province:"Marrakech", lat: 31.6422235,
    lng: -8.0811257
  },


  {
    name: "DENTALE EVOLUTION SARL AU",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "12 RUE SABRI BOUJEMAA 1 ERE ETAGE N 6 CASABLANCA",
    tel: "0520525708",
    province:"Marrakech", lat: 33.5846864,
    lng: -7.5312202
  },


  {
    name: "DENTALIA SARL AU",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "32 RUE ABOU ZIAD EDDADOUSSI ETAGE 3 MAARIF CASABLANCA",
    tel: "0522935656",
    province:"Marrakech", lat: 33.7962618,
    lng: -7.5161974
  },


  {
    name: "DENTIUM MAROC",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "RUE IBNOU AL ARIF N 3 1ER ETAGE CASABLANCA",
    tel: "0522250847",
    province:"Marrakech", lat: 33.5843991,
    lng: -7.6339157
  },


  {
    name: "DETENTE MEDICAL SARL AU",
    specialite: "Distribution,",
    address: "RUE TARIK IBN ZIYAD N? 105 ETAGE 2 TANGER",
    province:"Marrakech", lat: 33.7972778,
    lng: -7.516201
  },


  {
    name: "DIABETIC CENTER SARL",
    specialite: "Distribution, DM non steriles",
    address: "N20 RUE AHMED LOUKILI AVENUE HASSAN 2 V.N FES",
    tel: "0535944760",
    province:"Marrakech", lat: 34.0405584,
    lng: -4.9968318
  },


  {
    name: "DIACARE MAROC SARL",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "4 RUE ROCROIX 1ER ETEGE APPARTEMENT 9 CASABLANCA",
    province:"Marrakech", lat: 33.5503877,
    lng: -7.6735757
  },


  {
    name: "DIAPARA",
    specialite: "Importation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "LOTISSEMENT ROUMIA 1 N 387 L`MHAMID MARRAKECH",
    tel: "0524371867",
    province:"Marrakech", lat: 3.5189914,
    lng: -76.9381622
  },


  {
    name: "DIBITUN",
    specialite: "Importation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "15 RUE N1 CALIFORNIA BERRECHID MAROC",
    province:"Marrakech", lat: 33.5438131,
    lng: -7.6553954
  },


  {
    name: "Difarm Snc",
    specialite: "Fabrication Distribution, DM steriles DM non steriles",
    address: "LOTISSEMENT SAADA IMMEUBLE 179 APPARTEMENT N 24 MARRAKECH",
    tel: "0524432181",
    province:"Marrakech", lat: 32.7885869,
    lng: -8.5672529
  },


  {
    name: "DIMAMED DIALYSE & MATERIEL MEDICAL",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "3 RUE DU POINT DU JOUR CASABLANCA 20 000",
    province:"Marrakech", lat: 33.5763972,
    lng: -7.6137205
  },


  {
    name: "DINAMEDIC SARL",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "50 RUE ABOU BAKR MEHAMMED IBNOU ZOUHAIR",
    tel: "0522228615",
    province:"Marrakech", lat: 33.5785606,
    lng: -7.6237654
  },


  {
    name: "DISPROPAL",
    specialite: "Exportation, Distribution, DM non steriles",
    address: "Rue 12 N° 24 Hay Lalla Soukaina Bp 7110, Fés Saiss, Ave Sant Louis, Fès 30000",
    tel: "0535942036",
    province:"Marrakech", lat: 34.0195392,
    lng: -5.0086206
  },


  {
    name: "DISTRIBUTEUR CLARBEN MAROC SARL",
    specialite: "Importation, Exportation, Distribution, DM non steriles",
    address: "12 OMAR EL KHIYAM TANGER APPARTEMENT2",
    tel: "0539943983",
    province:"Marrakech", lat: 35.7784793,
    lng: -5.8104977
  },


  {
    name: "DISTRIBUTEUR PLANTES",
    specialite: "Distribution, DM non steriles",
    address: "N 35 LOTISSEMENT BAB SALAM MAGASIEN N 1 AIN CHKEF FES",
    province:"Marrakech", lat: 33.9840012,
    lng: -5.1100462
  },


  {
    name: "DKT INTERNATIONAL",
    specialite: "Importation, Distribution, DM steriles",
    address: "LOTISSEMENT FLORIDA N 124 SIDI MAAROUF CASABLANCA",
    province:"Marrakech", lat: 33.5333001,
    lng: -7.5856181
  },


  {
    name: "DOCTI MED SARL",
    specialite: "Importation, et Distribution, des DM steriles et DM non steriles",
    address: "191 BOULEVARD FOUARAT HAY EL MOHAMMADI CASABLANCA",
    tel: "0522754950",
    email: "contact@doctimedmaroc.com",
    province:"Marrakech", lat: 33.6934182,
    lng: -7.3759183
  },


  {
    name: "DOHAMED SERVICES",
    specialite: "Distribution, DM steriles DM non steriles",
    address: "GROUPE 42.RUE 66 N 59 OULFA.CASABLANCA",
    tel: "0664280705",
    email: "dohamedservices@gmail.com",
    province:"Marrakech", lat: 33.5791605,
    lng: -7.6005094
  },


  {
    name: "DOS SANTOS MEDICALE (D S M E)SARL",
    specialite: "Importation, Distribution, Maintenance, DM steriles",
    address: "6 RUE MAUSOLEE QUARTIER DES HOPITAUX CASABLANCA",
    tel: "0522860221",
    province:"Marrakech", lat: 33.5729619,
    lng: -7.6185389
  },


  {
    name: "DPM INDUSTRY",
    specialite: "Fabrication Importation, Exportation, Distribution, DM non steriles",
    address: "55 RUE MOQALAD AKILI -BOURGONE CASABLANCA",
    province:"Marrakech", lat: 33.572757,
    lng: -7.617016
  },


  {
    name: "DUO MEDICAL",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "PISTE MOULAY THAMI RESIDENCE ABOUAB OUM RABII OULFA N 9 CASABLANCA",
    tel: "0522904034",
    province:"Marrakech", lat: 33.5695674,
    lng: -7.6699699
  },


  {
    name: "ECHAMED SARL",
    specialite: "Fabrication Importation, Distribution, Maintenance, DM steriles DM steriles",
    address: "JAMILA 5 RUE 46 N 41 CASABLANCA",
    province:"Marrakech", lat: 33.5458805,
    lng: -7.6502991
  },


  {
    name: "ECHOMED SARL",
    specialite: "Importation, Exportation, Distribution, Maintenance,",
    address: "2 RUE HONAINE ANGLE AVENUE 16 NOVEMBRE APPARTEMENT N 5 AGDAL - RABAT",
    tel: "0537673732",
    email: "marketing@echomed.ma",
    province:"Marrakech", lat: 33.9926768,
    lng: -6.849675
  },


  {
    name: "ECHOS INTERNATIONAL",
    specialite: "Importation, Exportation, Distribution, Maintenance,",
    address: "RESIDENCE AL AMIR N 36 QUARTIER DES HOPITAUX MAARIF CASABLANCA",
    tel: "0522276318",
    province:"Marrakech", lat: 33.5798508,
    lng: -7.6237933
  },


  {
    name: "EFIMED SARL",
    specialite: "Fabrication Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "02A93 GROUP ADDAMANE GH 12 AIN CHOCK 20000",
    tel: "0522526484",
    email: "info@efimed.ma",
    province:"Marrakech", lat: 33.590396,
    lng: 7.6390422
  },


  {
    name: "EJ MEDIC",
    specialite: "Importation, DM non steriles",
    address: "26 RUE OUED ZIZ APPARTEMENT 1 R?SIDENCE AGDAL RABAT",
    tel: "0661969594",
    email: "contact@ejmedic.com",
    province:"Marrakech", lat: 33.9972243,
    lng: -6.8508361
  },


  {
    name: "EKZOLAB SARL",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "5 RUE AHMED AL MOQRI 6EME ETAGE CASABLANCA",
    tel: "0614262967",
    email: "ekzolab.service@gmail.com",
    province:"Marrakech", lat: 33.9862228,
    lng: -6.8590553
  },


  {
    name: "EL AMINE PARA",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "RUE ABOU EL QACEM ZZAHRAOUI RESIDENCE JNANE PASTEUR N 1 CASABLANCA",
    tel: "0522865685",
    province:"Marrakech", lat: 33.5913333,
    lng: -7.6319717
  },


  {
    name: "EL ARCHI PARAMEDICAL SARL",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "2 RUE DE TUNIS EL JADIDA",
    tel: "0523373738",
    province:"Marrakech", lat: 33.9862447,
    lng: -6.8656214
  },


  {
    name: "EL HOURIA PARAMEDICAL SARL",
    specialite: "Distribution, DM non steriles",
    address: "N 57 A ABOU OBAIDA EL JARRAH COMPLEXE EL HOURIA FES DBIBAGH",
    tel: "0661132610",
    province:"Marrakech", lat: 34.0181294,
    lng: -5.0100337
  },


  {
    name: "EL KSAIBI MEDICAL",
    specialite: "Importation, Exportation, Distribution,",
    address: "211 AVENUE HARROUN ARRACHID MAGASIEN D TANGER",
    tel: "0676291667",
    province:"Marrakech", lat: 35.7786223,
    lng: -5.8272367
  },


  {
    name: "EL MANSOURI NEGOCE SARL",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "LOTISSEMENT AZZOUZIA N 2122 MARRAKECH",
    province:"Marrakech", lat: 33.0453368,
    lng: -7.660178
  },


  {
    name: "ELANCE MEDICAL",
    specialite: "Importation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "59 BOULEVARD ZERKTOUNI RESIDENCE LES FLEURS ETAGE 9 APPARTEMENT 26",
    tel: "0522228515",
    province:"Marrakech", lat: 33.5787674,
    lng: 7.6236204
  },


  {
    name: "ELECTROMED SARL",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "50 AVENUE SIDI MOHAMAD BALHASSAN APPARTEMENT 3 SALA ALJADIDA",
    tel: "0537532974",
    province:"Marrakech", lat: 33.9924131,
    lng: -6.7448199
  },


  {
    name: "EMED SARL",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles",
    address: "2 RUE ESSANOBRE N 12 4EME ETAGE CASABLANCA",
    tel: "0660047586",
    email: "emedmateriel@gmail.com",
    province:"Marrakech", lat: 33.5672057,
    lng: -7.6699686
  },


  {
    name: "EMOTECHNIC SARL",
    specialite: "Fabrication Importation, DM steriles DM steriles",
    address: "PARC D`ACTIVITE OUKACHA HANGAR N 1 A 5 AIN SEBAA CASABLANCA",
    tel: "0522355663",
    province:"Marrakech", lat: 33.6198662,
    lng: -7.6038728
  },


  {
    name: "EMS HEALTH SARL",
    specialite: "Importation, Distribution,",
    address: "202 BOULVARD BRAHIM ROUDANI ANGLE RUE BRGOUNE CASABLANCA",
    tel: "0522986015",
    email: "info@ems-health.com",
    province:"Marrakech", lat: 33.5767067,
    lng: -7.6416798
  },


  {
    name: "EMSYS SARL",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM non steriles",
    address: "REZ DE CHAUSSEE N 46 A HAY AL WIFAQ BENSERGAO AGADIR",
    tel: "0528281004",
    province:"Marrakech", lat: 30.3901655,
    lng: -9.5652544
  },


  {
    name: "ENDOMEDICAL EQUIPEMENT",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "7 RUE SEBTA RESIDENCE RAMI 2 EME ETAGE BUREAU N 820100 CASABLANCA ANFA",
    province:"Marrakech", lat: 33.5822479,
    lng: -7.6201013
  },


  {
    name: "ENEM SARL",
    specialite: "Importation, Exportation, DM steriles DM non steriles",
    address: "RESIDENCE KAISSE IMMEUBLE EN5 2EME ETAGE AGDAL-RABAT",
    province:"Marrakech", lat: 33.9991967,
    lng: -6.8638993
  },


  {
    name: "ENTENDRE",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "4 ANGLE RUE ARGO ET LAVOISIER 20360 CASABLANCA",
    tel: "0522860218",
    province:"Marrakech", lat: 33.5731998,
    lng: -7.6283107
  },


  {
    name: "EOLANE TEMARA",
    specialite: "Fabrication Importation, DM steriles",
    address: "ZI MASSIRA 1 N 14 TEMARA",
    tel: "0522535420",
    province:"Marrakech", lat: 33.933168,
    lng: -6.9070728
  },


  {
    name: "EQUHOS SARL",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "HAY MOULAY ABDELLAH RUE 72.N 70-72 AIN CHOCK CASABLANCA",
    tel: "0522356620",
    province:"Marrakech", lat: 33.029763,
    lng: -9.3434244
  },


  {
    name: "EQUIPEMENT MEDICAL BIOLOGIQUE",
    specialite: "Importation, DM steriles DM steriles",
    address: "43.RUE IMAM EL KABBAB BELVEDERE CASABLANCA",
    tel: "0522240098",
    email: "contact@emb.ma",
    province:"Marrakech", lat: 33.5923527,
    lng: -7.5977179
  },


  {
    name: "EQUIPEMENT PARAMEDICAL",
    specialite: "Importation, DM steriles DM non steriles",
    address: "52.RUE SOCRATE 5 EME ETAGE N 10 -20100 CASABLANCA",
    province:"Marrakech", lat: 34.0309664,
    lng: -6.3770811
  },


  {
    name: "EQUIPMEDICAL SARL",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "AVENUE DES FAR RUE 15 N 30 Q .A NADOR",
    province:"Marrakech", lat: 33.5398024,
    lng: -7.7204692
  },


  {
    name: "EQUIPMENT NEGOCE SARL AU",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "387 BOULEVARD MOHAMMED V N7 19 CASABLANCA",
    tel: "0661077631",
    province:"Marrakech", lat: 35.1686816,
    lng: -2.962603
  },


  {
    name: "ERAMEDIC",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "246 BOULEVARD MOHAMED V CASABLANCA 20110",
    tel: "0522306921",
    email: "ERAMEDIC@ERAMEDIC.COM",
    province:"Marrakech", lat: 33.5935041,
    lng: -7.6098595
  },


  {
    name: "ERGO-MAROC",
    specialite: "Importation, Distribution, DM steriles",
    address: "108.RUE PIERRE PARENT CASABLANCA MAROC",
    tel: "0522542880",
    province:"Marrakech", lat: 33.5943669,
    lng: -7.6092082
  },


  {
    name: "ESNAPHARM SARL",
    specialite: "Importation, Distribution,",
    address: "7 RUE ABDELMAJID BRNJALLOUN MAARIF-CASABLANCA",
    tel: "0522233753",
    email: "Contact@Esnapharm.Com",
    province:"Marrakech", lat: 33.5807277,
    lng: -7.6445836
  },


  {
    name: "ESPACE BIOLOGIE",
    specialite: "Importation, Distribution, Maintenance,",
    address: "45.RUE DE LA PHARMIDE BELVEDERE CASABLANCA",
    tel: "0522240679",
    province:"Marrakech", lat: 33.594195,
    lng: -7.5925407
  },


  {
    name: "ESPACE DE CORRECTION AUDITIVE",
    specialite: "Importation, DM steriles",
    address: "N° 38, Boulevard Mohammed V Résidence Tlemçani , 3ème étage Bureau 29 Fes (Ville Nouvelle)",
    tel: "0535931478",
    email: "ecamaroc@gmail.com",
    province:"Marrakech", lat: 34.0347629,
    lng: -4.9995783
  },


  {
    name: "ESTHELITE SARL AU",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles",
    address: "QUARTIER BEAUSITE LOTISSEMENT AMANE 1 IMMEUBLE N 314 APPARTEMENT N 4 CASABLANCA",
    tel: "0661474820",
    email: "esthelite75@yahoo.fr",
    province:"Marrakech", lat: 33.5988298,
    lng: -7.5388197
  },


  {
    name: "ETOILE MEDICALE",
    specialite: "Distribution, DM steriles DM non steriles",
    address: "BOULEVARD HAMZA IBN ABDELMOUTTALIB N 42 OUJDA",
    province:"Marrakech", lat: 34.7194217,
    lng: 1.9282003
  },


  {
    name: "ETRIMED SARL",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "26.AV OMAR IBN KHATTAB APPARTEMENT N 08 AGDAL .RABAT",
    province:"Marrakech", lat: 33.9862228,
    lng: -6.8590553
  },


  {
    name: "ETS BENNIS",
    specialite: "Importation, Distribution, DM non steriles",
    address: "15 BOULEVARD MOULAY ISMAIL BP 967-30012 FES",
    tel: "0522283126",
    province:"Marrakech", lat: 34.0298575,
    lng: -4.9943076
  },


  {
    name: "EUREKA COM",
    specialite: "Importation, Exportation, DM steriles DM non steriles",
    address: "BAULEVARD YACOUB ALMANSOUR RESIDENCE PLAZA1 IMMEUBLEE F2 RDC MOHAMMADIA",
    province:"Marrakech", lat: 33.5396746,
    lng: -7.6526181
  },


  {
    name: "EURO BUREAU",
    specialite: "Fabrication Distribution, DM non steriles",
    address: "1 RUE ZAHRATE ELOU LOU HAY ERRAHA CASABLANCA",
    tel: "0522397993",
    province:"Marrakech", lat: 33.566833,
    lng: -7.6526181
  },


  {
    name: "EURO DENTAIRE SARL",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "ROUTE DE RABAT SANAE 2 RUE 4 N 01 LOTISSEMENT 11 TANGER",
    tel: "0662553008",
    email: "euro.dental@hotmail.com",
    province:"Marrakech", lat: 35.7595625,
    lng: -5.8332937
  },


  {
    name: "EUROMEDIC",
    specialite: "Importation, Distribution, DM steriles",
    address: "73 BIS RUE IBNOU MOUNIR MAARIF -CASABLANCA",
    tel: "0522947155",
    province:"Marrakech", lat: 33.5334564,
    lng: -7.6533728
  },


  {
    name: "EUROPRISME MEDICAL SARL",
    specialite: "Importation, DM non steriles",
    address: "131 BOULEVARD ABDELMOUMEN N 17 4 EME ETAGE CASABLANCA N 172000",
    tel: "0522250910",
    province:"Marrakech", lat: 48.456532,
    lng: 7.494452
  },


  {
    name: "EVOLUTION PARA",
    specialite: "Importation, Exportation, Distribution,",
    address: "LOTISSEMENT 264 ARSET ALI OU SALEH BAB LAKHMISS",
    province:"Marrakech", lat: 33.4834036,
    lng: -7.6746295
  },


  {
    name: "EXCEL IMPLANT",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "3 AVENUE DE FRANCE APPARTEMENT 4 AGDAL RABAT",
    tel: "0537670654",
    email: "excelimplant@gmail.com",
    province:"Marrakech", lat: 33.9884077,
    lng: -6.8518271
  },


  {
    name: "EXELLE MEDIC SARL",
    specialite: "Importation, Distribution, DM steriles",
    address: "59.RUE ALKHANSSA BUREAU 5 2 ETAGE QUARTIER PALMIER CASABLANCA",
    tel: "0522470105",
    province:"Marrakech", lat: 33.5784594,
    lng: -7.6299164
  },


  {
    name: "EXPERT-MED SARL",
    specialite: "Importation, Distribution, DM steriles",
    address: "AVENUE HASSAN 2 KM 509 ROUTE DE CASABLANCA RABAT",
    province:"Marrakech", lat: 33.5789467,
    lng: 7.6231151
  },


  {
    name: "EXPRESS DENTAIRE",
    specialite: "Importation, Distribution, Maintenance,",
    address: "N 36 AMAL 4 RUE 59 BERNOUSSI CASABLANCA",
    tel: "0522758644",
    province:"Marrakech", lat: 33.5295513,
    lng: 7.5802137
  },


  {
    name: "EXPRESS NEGOCE SARL",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "IMMEUBLE ANTEE 8 RUE IMAM GHAZALI N A6 TANGER",
    province:"Youssoufia", lat: 32.5355416,
    lng: -8.2865658
  },


  {
    name: "EXTRA MED",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "MAGASIEN N 10 IMMEUBLE 16 RUE RUE YACOUB EL MANSOUR MEKNES",
    tel: "0535403913",
    province:"Marrakech", lat: 33.8997573,
    lng: -5.5412845
  },


  {
    name: "EXTRA MEDIC",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "6 IMMEUBLE 1 PROJET HASSAN 2 HAY MOHAMMADI CASABLANCA",
    tel: "0522626268",
    province:"Marrakech", lat: 33.5333001,
    lng: -7.5856181
  },


  {
    name: "EYE LENSES",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "RESIDENCE AL BADR IMMEUBLE 95N =5 AIN SEBAA CASABLANCA",
    province:"Marrakech", lat: 33.5724108,
    lng: -7.6570323
  },


  {
    name: "FACE LOOK SARL",
    specialite: "Importation, Distribution,",
    address: "RESIDENCE LINA 8 ETAGE APPARTEMENT 68 AVENUE TANTAN ET LEBANON TANGER",
    province:"Marrakech", lat: 33.5751655,
    lng: 7.646412
  },


  {
    name: "FAPRODENT SARL",
    specialite: "Fabrication Importation, Exportation, Distribution, DM non steriles",
    address: "KM 8 ROUTE D`ASSAOUIRA BP 5300 IZIKI MARRAKECH",
    tel: "0522861287",
    province:"Marrakech", lat: 33.5726089,
    lng: -7.624189
  },


  {
    name: "FATH DENTAIRE",
    specialite: "Importation, Distribution, DM non steriles",
    address: "19 RUE 41.IDRISSIA ARRONDISSEMENT AL FIDA CASABLANCA",
    tel: "0522284244",
    province:"Marrakech", lat: 33.5631269,
    lng: -7.5926837
  },


  {
    name: "FELIX TECNIX",
    specialite: "Importation, Distribution, Maintenance, DM non steriles",
    address: "47.BIS RUE JABAL TOUR BETTANA-SALE",
    province:"Marrakech", lat: 36.7818375,
    lng: -6.3547793
  },


  {
    name: "FELIZ PARA SARL",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "N 349 HAY TARGA MARRAKECH",
    province:"Marrakech", lat: 31.6353974,
    lng: -9.1714163
  },


  {
    name: "FES PARA DIABETE",
    specialite: "Distribution, DM steriles DM non steriles",
    address: "COMPLEXE EL HOURIA N 52 FES",
    tel: "0535932232",
    province:"Marrakech", lat: 34.0398358,
    lng: -5.0052681
  },


  {
    name: "FILMED",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "RESIDENCE LA DEFENSE 4 RUE MOHAMED EL FIDOUZI (EX RUE JENNER) QUARTIER DES HOPITAUX CASABLANCA",
    tel: "0522247980",
    province:"Marrakech", lat: 33.5821487,
    lng: -7.628144
  },


  {
    name: "FINATECH GROUP",
    specialite: "Importation, Exportation, Distribution, Maintenance,",
    address: "1100 BOULEVARD AL QODS CASANEASHORE SHORE 10 CASABLANCA",
    tel: "0520377000",
    province:"Marrakech", lat: 33.5254418,
    lng: -7.6487213
  },


  {
    name: "FIRST DENT SARL",
    specialite: "Importation, DM steriles DM non steriles",
    address: "ANGLE BOULEVARD ANOUAL EL ABDELMOUMEN RESIDENCE ABDELMOUMEN CENTRE APPARTEMENT 506 CASABLANCA",
    tel: "0522860282",
    province:"Marrakech", lat: 33.6000063,
    lng: -7.6374176
  },


  {
    name: "FIRST LAB SARL",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "N 79 LOTISSEMENT AL MOUSTAKBAL GH A1 SIDI MAAROUF CASABLANCA",
    tel: "0652771673",
    province:"Marrakech", lat: 33.5310203,
    lng: -7.6502646
  },


  {
    name: "FIRST MEDICAL",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "RESIDENCE AL MANSOUR ANGLE BD GHANDI BOULEVARD YACOUB EL MANSOUR IMMEUBLE 20 APPARTEMENT 6",
    tel: "0522474782",
    province:"Marrakech", lat: 33.5785587,
    lng: -7.6237649
  },


  {
    name: "FIRST PARA SARL",
    specialite: "Distribution, DM steriles DM non steriles",
    address: "144 RUE MOHAMMED SMIHA RESIDENCE JAWHARATE MED SMIHA 6 EME ETAGE APPARTEMENT 35 CASABLANCA",
    province:"Marrakech", lat: 33.6343295,
    lng: -7.4719663
  },


  {
    name: "FLUIDES ON SITE SERVICES (FLOSIT) SA",
    specialite: "Fabrication Importation, Exportation, Distribution, Maintenance, DM non steriles",
    address: "AEROPOLE NOUASSEUR 20240 CASABLANCA MAROC",
    tel: "0522539367",
    province:"Marrakech", lat: 33.5333001,
    lng: -7.5856181
  },


  {
    name: "FORCEMED ULTRA SARL",
    specialite: "Importation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "MAGASIEN N3 BLOC 12 N 384 MHAMED MENARA 40000 MARRAKECH",
    province:"Marrakech", lat: 31.604645,
    lng: -8.0463193
  },


  {
    name: "FOREIGN ENTREPRISE SOLUTIONS MEDICAL FESMED SARL",
    specialite: "Importation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "15.AVENUE ALABTAL APPARTEMENT 4 AGDAL RABAT",
    province:"Marrakech", lat: 33.9862228,
    lng: -6.8590553
  },


  {
    name: "FORMIX SARL",
    specialite: "Importation, DM non steriles",
    address: "AVENUE HASSAN2 BAB BOUHAJA APPARTEMENT 3 BETTANA SALE",
    tel: "0522526711",
    province:"Marrakech", lat: 34.0238483,
    lng: -6.8292416
  },


  {
    name: "FOURNITURE MATERIEL INSTRUMENTATION CHIRURGIE ENDOSCOPIQUE (FMICE)",
    specialite: "Importation, Distribution, DM steriles",
    address: "6 RUE MOLIERE QUARTIER RACINE CASABLANCA",
    tel: "0522392701",
    province:"Marrakech", lat: 33.5904004,
    lng: -7.6412309
  },


  {
    name: "FRESENIUS MEDICAL CARE NORD-OUEST ET CENTRE AFRIQUE SA",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "33 ANGLE BOULEVARD MOULAY YOUSSEF ET RUE MOUSSA IBNOU NOUSSAIR CASABLANCA BP20060",
    tel: "0522491991",
    province:"Marrakech", lat: 33.5903059,
    lng: -7.6283282
  },


  {
    name: "FRESENIUS MEDICAL CARE PHARMA AFRIQUE SA",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "ZONE TECHNOPOLE DE L`AEROPORT MOHAMMED V CASABLANCA",
    tel: "0522538983",
    province:"Marrakech", lat: 33.3694181,
    lng: -7.5712266
  },


  {
    name: "FRY MEDICAL SARL",
    specialite: "Distribution, Maintenance, DM steriles DM non steriles",
    address: "RESIDENCE 90 PROJET DAMAN GH 11 N 2 AIN CHOK CASABLANCA 20000",
    tel: "0661944032",
    province:"Marrakech", lat: 33.5568636,
    lng: -7.5336512
  },


  {
    name: "GAM`LENS",
    specialite: "Importation, DM steriles",
    address: "291 RUE DAR MINIE QUARTIER DES HOPITAUX CASABLANCA",
    tel: "0522264136",
    province:"Marrakech", lat: 33.5860932,
    lng: -7.6223594
  },


  {
    name: "GAMA MEDIC",
    specialite: "Distribution, DM steriles DM non steriles",
    address: "AVENUE LALA ASSMAE MAGASIEN N 4 RESIDENCE AZZAHRA TABRIQUET SALE",
    tel: "0550099332",
    province:"Marrakech", lat: 32.8526261,
    lng: -8.5340229
  },


  {
    name: "GAMA-MED",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "BOULEVARD MOHAMMED VI F B N 20 AL MASSIRA MOHAMMADIA",
    tel: "0523321831",
    province:"Marrakech", lat: 33.6974073,
    lng: -7.3678227
  },


  {
    name: "GAMMA MEDICAL",
    specialite: "Distribution,",
    address: "17 BOULEVARD EL MAGHREB EL ARABI HAY EL KASSAM-SETTAT",
    province:"Marrakech", lat: 33.0055639,
    lng: -7.6341885
  },


  {
    name: "GAMMA RAD TECH SARL",
    specialite: "Importation, Distribution,",
    address: "10B RUE YAMAMA APPARTEMENT N 41 RABAT",
    tel: "0537207370",
    province:"Marrakech", lat: 33.9744135,
    lng: -6.8871148
  },


  {
    name: "GEANT MEDICAL SARL",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "457 AVENUE HASSAN 2 RESIDENCE MARIAM MAG 18 AGDAL RABAT",
    tel: "0537699451",
    province:"Marrakech", lat: 34.0054185,
    lng: -6.8590646
  },


  {
    name: "GEMED",
    specialite: "Importation, Distribution, DM non steriles",
    address: "46 BOULEVARD ALAMIR ABDELKADER QUARTIER AL MASSIRA 80 000 AGADIR",
    province:"Marrakech", lat: 30.3299334,
    lng: -9.4124746
  },


  {
    name: "GENERAL MAROC SERVICES SANTE",
    specialite: "Importation, Distribution, Maintenance, DM non steriles",
    address: "12 RUE REAUMER APPARTEMENT N 9 QUARTIER DES HOPITAUX CASABLANCA",
    tel: "0522341718",
    province:"Marrakech", lat: 33.6036858,
    lng: -7.5356033
  },


  {
    name: "GENERAL MEDICAL HOSPITALIER SARL(GMH)",
    specialite: "Importation, Distribution, Maintenance, DM non steriles",
    address: "RUE 45 N 23 BOULEVARD OUED DARAA OULFA CASABLANCA",
    tel: "0522906418",
    province:"Marrakech", lat: 33.5581439,
    lng: -7.6865205
  },


  {
    name: "GENERAL ORTHODONTICS SARL",
    specialite: "Exportation, Maintenance,",
    address: "165 BOULEVARD ABDELMOUMEN 20100 CASABLANCA",
    tel: "0522471703",
    province:"Marrakech", lat: 33.5438085,
    lng: -7.6716511
  },


  {
    name: "GENERALE DES EQUIPEMENTS MEDICAUX ET INDUSTRIELS MAROC (GEMIMAR)",
    specialite: "Exportation, Distribution, DM non steriles",
    address: "110 AVENUE 2 MARS RESIDENCE MEDITERRANEENNE 1 ETAGE 4 N 19 QUARTIER DES HOPITAUX CASABLANCA",
    tel: "0522401639",
    province:"Marrakech", lat: 33.6024623,
    lng: -7.5860261
  },


  {
    name: "GENI MEDIC",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "889 BLOC 32 BOULEVARD NIL SIDI OTHMANE CASABLANCA",
    tel: "0522370489",
    province:"Marrakech", lat: 33.5658645,
    lng: -7.5665087
  },


  {
    name: "GENIEPARA",
    specialite: "Distribution, DM steriles DM non steriles",
    address: "RUE 17 N 92 MANDARONA RDC AIN CHOCK CASABLANCA",
    tel: "0522215010",
    province:"Marrakech", lat: 33.5316997,
    lng: -7.6176008
  },


  {
    name: "GEOMED SARL",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "7 RUE ABOU BAKR MOUHAMED IBN ZAHR QUARTIER DES HOPITAUX-CASABLANCA",
    tel: "0522205435",
    province:"Marrakech", lat: 33.5788573,
    lng: -7.6249518
  },


  {
    name: "GHALI & ADAM CORP SARL",
    specialite: "Importation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "64 RESIDENCE LES JARDINS ZOHOUR 2 FES",
    tel: "0535668081",
    province:"Rehamna", lat: 32.8210175,
    lng: -7.8617627
  },


  {
    name: "GHITA EQUIPEMENT MEDICALIS ET SURGICALS",
    specialite: "Distribution, DM steriles DM non steriles",
    address: "RESIDENCE MED MAROMANE BOULEVARD DE LA POLESTURE LOTISSEMENT RATMS MARRAKECH",
    tel: "0524330725",
    province:"Marrakech", lat: 31.6334013,
    lng: -8.0350195
  },


  {
    name: "GLASSES AND LENS",
    specialite: "Importation, Exportation, Distribution, DM steriles",
    address: "MASSIRA 1 B N 346 MARAKECH",
    province:"Marrakech", lat: 31.6057739,
    lng: -8.0767426
  },


  {
    name: "GLAXOSMITHKLINE MAROC",
    specialite: "Fabrication Importation, Distribution, DM non steriles",
    address: "4244 ANGLE BOULEVARD RACHIDI ET ABOU HAMED EL GHAZALI AIN AOUDA RABAT",
    tel: "0522480002",
    province:"Marrakech", lat: 33.593406,
    lng: -7.6294235
  },


  {
    name: "GLOBAL DENTAIRE",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "N 24 RUE ALYOUSSOUFIA HAY AL AMAL RTE SEFROU FES",
    tel: "0661390313",
    province:"Marrakech", lat: 33.1127241,
    lng: -7.3241438
  },


  {
    name: "GLOBAL MEDICAL SOLUTION",
    specialite: "Importation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "217 BOULEVARD BRAHIM ROUDANI RESIDENCE AL FATH ETAGE 1 N 3 MAARIF CASABLANCA",
    province:"Marrakech", lat: 33.5577091,
    lng: -7.6670685
  },


  {
    name: "GLOBAL SANTE",
    specialite: "Fabrication Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "IMMEUBLE N 29 RUE 2 APPARTEMENT 20 YOUGOUSLAVIE GUELIZ MARRAKECH",
    province:"Marrakech", lat: 31.6339817,
    lng: -8.0160104
  },


  {
    name: "GLOBALE TECHNIQUE SANTE SARL",
    specialite: "Importation, Distribution, DM non steriles",
    address: "LOTISSEMENT RIAD OULED METAA SECTEUR 3 N 163 TEMARA",
    tel: "0522932066",
    province:"Marrakech", lat: 33.5492845,
    lng: -7.6900117
  },


  {
    name: "GLOBEX IMPLANT DENTAIRE SARL AU",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "RUE SOUMAYA RESIDENCE SHEHRAZADE 3 5 EME ETAGE N 22 PALMIERS MAARIF CASABLANCA -MAROC",
    province:"Marrakech", lat: 33.5774323,
    lng: -7.6286706
  },


  {
    name: "GOLD PHARMA",
    specialite: "Exportation, Distribution, DM steriles DM non steriles",
    address: "102 LOTISSEMENT DANDOUNE SIDI MAAROUFE CASABLANCA",
    tel: "0522580457",
    province:"Marrakech", lat: 33.5775033,
    lng: -7.6615013
  },


  {
    name: "GOLDMED SARL AU",
    specialite: "Importation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "N 3257 LOTISSEMENT WIFAK TEMARA",
    tel: "0655528892",
    province:"Marrakech", lat: 33.9262998,
    lng: -6.930761
  },


  {
    name: "GRAND LABORATOIRE",
    specialite: "Importation, Distribution, Maintenance,",
    address: "46 RESIDENCE OUM EL KOURA RUE DE LILE BD EMILE ZOLA CASABLANCA",
    tel: "0522441783",
    province:"Marrakech", lat: 33.5919371,
    lng: -7.6017429
  },


  {
    name: "GRAND TANGER MEDICAL SARL",
    specialite: "Distribution, DM steriles DM non steriles",
    address: "ANGLE BD DOCTEUR FARAJ RUE 21 N 88 BIS TANGER",
    tel: "0539930722",
    province:"Marrakech", lat: 35.7757393,
    lng: -5.8228033
  },


  {
    name: "GREEN MATERIAL MEDICAL SARL",
    specialite: "Fabrication Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "LOTISSEMENT MOUMEN N 67 MOHAMMEDIA",
    tel: "0523281154",
    province:"Marrakech", lat: 33.6844364,
    lng: -7.3737697
  },


  {
    name: "GROUPE DENTALE D`ITALIE SARL",
    specialite: "Importation,",
    address: "66 RUE 136 HAY EL HOUDA SIDI MOUMEN",
    tel: "0611515272",
    province:"Marrakech", lat: 33.5894047,
    lng: -7.6163184
  },


  {
    name: "GUENNOUN FRANCHISE SERVICE",
    specialite: "Distribution,",
    address: "AVENUE MOULAY ALI CHERIF N?33 TANGER",
    tel: "0539361506",
    province:"Marrakech", lat: 35.7540325,
    lng: -5.8155327
  },


  {
    name: "GUERAMY IMPORT EXPORT SARL",
    specialite: "Importation, Exportation, Distribution, DM non steriles",
    address: "ROUTE MAGHNIA R A7 LOT TALHAOUI N?35 OUJDA",
    province:"Marrakech", lat: 34.6549748,
    lng: -1.94943
  },


  {
    name: "GYLMED",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "10RUE BOULMANE BOURGOUNE CASABLANCA",
    tel: "0522221255",
    province:"Marrakech", lat: 33.5995369,
    lng: -7.6403653
  },


  {
    name: "GYNEBIOPHARMA SA",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "63.BOULVARD D`ANFA CASABLANCA",
    tel: "0522423190",
    province:"Marrakech", lat: 33.4981793,
    lng: -7.6574824
  },


  {
    name: "H`IMPORT",
    specialite: "Importation, DM steriles DM steriles",
    address: "2 RUE ASSANOBAR 4EME ETAGE N 12 CASABLANCA",
    province:"Marrakech", lat: 33.5891936,
    lng: -7.6156995
  },


  {
    name: "H2SS SARL",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "RUE EZZARKTOUNI RESIDENCE SAMY IMMEUBLE 50 MAGASIEN 4 FES",
    tel: "0535623710",
    province:"Marrakech", lat: 34.0365554,
    lng: -4.9957513
  },


  {
    name: "HALTYS SARL AU",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "265 BOULEVARD ZERKTOUNI 20050 CASABLANCA",
    province:"Marrakech", lat: 33.5913329,
    lng: -7.6389564
  },


  {
    name: "HARTMED SARL",
    specialite: "Importation, Distribution,",
    address: "N 637 SAADA 1-A N 637 MHAMID MARRAKECH",
    tel: "0662169132",
    province:"Marrakech", lat: 31.5977217,
    lng: -8.0380891
  },


  {
    name: "HAYAT MOROCCO SARL",
    specialite: "Importation, Distribution,",
    address: "CENTRE COMMERCIAL PARANFA AIN DIAB CASABLANCA",
    tel: "0522949795",
    province:"Marrakech", lat: 31.6615868,
    lng: -8.008307
  },


  {
    name: "HEALING MEDICAL SARL AU",
    specialite: "Fabrication Importation, Exportation, Distribution, DM steriles DM steriles",
    address: "61 BIS BOULEVARD BIR ANZARAN LOTISSEMENT BEN KACEM SETTAT",
    province:"Marrakech", lat: 33.2755889,
    lng: -7.8998413
  },


  {
    name: "HEALTH FACTORY",
    specialite: "Fabrication Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "RESIDENCE AL AZRAK BERDIGO IMMEUBLE 55 APPARTEMENT 2 DOMICILIE TEMARA",
    tel: "0522657541",
    province:"Marrakech", lat: 33.9099699,
    lng: -6.9732981
  },


  {
    name: "HEALTH INNOVATION",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "19 RUE RACINE VAL FLEURI MAARIF CASABLANCA",
    tel: "0522232251",
    province:"Marrakech", lat: 33.5750999,
    lng: -7.6393319
  },


  {
    name: "HEALTHCARE INSTITUTE",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "6 RUE DAYET AOUA APPARTEMENT 18 ETAGE 4 AGDAL RABAT",
    tel: "0661323928",
    province:"Marrakech", lat: 33.9855069,
    lng: -6.8290508
  },


  {
    name: "HEMODIA SARL",
    specialite: "Importation, Exportation,",
    address: "230 RUE 14 HAY MANDAROUNA 20153 CASABLANCA AIN CHOC CASABLANCA",
    tel: "0522219504",
    province:"Marrakech", lat: 33.5313574,
    lng: -7.6153028
  },


  {
    name: "HEMOLAB",
    specialite: "Importation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "12 RUE DE BERNE RESIDENCE WALILI QUARTIER DES HOPITAUX CASABLANCA",
    tel: "05222-64141",
    province:"Marrakech", lat: 33.5760514,
    lng: -7.6535455
  },


  {
    name: "HEMOLAB-PHARMA",
    specialite: "Importation, Distribution, DM steriles",
    address: "ZONE INDUSTRIELLE SAHEL LOTISSEMENT N 125 HAD SOUALEM",
    tel: "0522264141",
    province:"Marrakech", lat: 33.4200844,
    lng: -7.8500937
  },


  {
    name: "HEMOTEC SARL",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "AL MOUSTAKBAL GH 08 IMMEUBLE 55 APPARTEMENT 04 SIDI MAAROUF CASABLANCA",
    tel: "0522584259",
    province:"Marrakech", lat: 33.5214087,
    lng: -7.6461843
  },


  {
    name: "HERMATE PHARM SARL AU",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "ALKASBA SOUIKA KSSIBTE NHASS N 129 MARRAKECH",
    tel: "0524385083",
    province:"Marrakech", lat: 33.4203098,
    lng: -7.9814223
  },


  {
    name: "HIBA MED SARL AU",
    specialite: "Distribution, DM steriles DM non steriles",
    address: "15 RU 31 BIN LAMDOUN AIN CHOOK CASABLANCA",
    tel: "0522728431",
    province:"Marrakech", lat: 33.5372544,
    lng: -7.6537361
  },


  {
    name: "HIGH MED SARL",
    specialite: "Importation, Distribution,",
    address: "BOULEVARD BOURGOGNE RUE JAAFAR IBNOU HABIB RESIDENCE AL MACHRIK 2 1ER ETAGE N 3 CASABLANCA",
    tel: "0522511111",
    email: "info@highmed.ma",
    province:"Marrakech", lat: 33.5540295,
    lng: -7.4675651
  },


  {
    name: "HIGHTEC MEDICAL DEVICES",
    specialite: "Importation, Distribution, DM steriles",
    address: "LOTISSEMENT AL MANAR ZONE 6 N 38 EL JADIDA",
    province:"Marrakech", lat: 33.2316371,
    lng: -8.5029003
  },


  {
    name: "HM MEDICAL SARL",
    specialite: "Fabrication Exportation, Maintenance, DM steriles DM non steriles",
    address: "BOULEVARD YAKOUB MANSOUR, RUE HAJ JILALI EL OUFIR RESIDENCE MAARIF CASABLANCA",
    tel: "0522993595",
    email: "contact@hm-pharma.com",
    province:"Marrakech", lat: 33.5737164,
    lng: -7.6430742
  },


  {
    name: "HOLDING MEDICAL SARL",
    specialite: "Fabrication Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "GARAGE N 25 TRANCHE CENTRE COMMERCIAL DAR EL BIR WA AL IHSSANE MARRAKECH",
    tel: "0524305255",
    email: "hmedical4@gmail.com",
    province:"Marrakech", lat: 31.6623873,
    lng: -7.9961277
  },


  {
    name: "HOPE MEDICALE",
    specialite: "Importation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "IMMOUBLE 113 PORTES DE SALE APPARTEMENT N 1 ROUTE DE KNITRA-SALE",
    province:"Marrakech", lat: 33.5728552,
    lng: -7.658995
  },


  {
    name: "HORIZON MEDICAL SARL",
    specialite: "Distribution, DM steriles DM non steriles",
    address: "19 RUE AHMED EL MANJOUR BEL-AIR TANGER",
    tel: "0539374064",
    province:"Marrakech", lat: 35.7756459,
    lng: -5.82675
  },


  {
    name: "HOSPIMED SARL",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles DM steriles",
    address: "18 RUE JEAN JAURES RESIDENCE CERNA TRAVA (D) 1 ER ETAGE N 2 GAUTHIER .CP 20060 CASABLANCA",
    tel: "0522207651",
    province:"Marrakech", lat: 33.5895992,
    lng: -7.6296441
  },


  {
    name: "HOSPITERA",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "33 AVENUE IBN SINA APPARTEMENT N 12 AGDAL RABAT",
    tel: "0537776342",
    province:"Marrakech", lat: 33.9981135,
    lng: -6.8533673
  },


  {
    name: "HOUSE MEDIC SARL",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "RUE IBN AICHA R?SIDENCE AL HADIKA AL KOBRA IMMEUBLE F APPARTEMENT 8 GUELIZ MARRAKECH",
    province:"Marrakech", lat: 31.6378078,
    lng: -8.0120677
  },


  {
    name: "HOVI MAROC MEDICAL SERVICES",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "223 BOULEVARD ABDELMOUMEN 2 ET 4EME ETAGE 20360 CASABLANCA",
    province:"Marrakech", lat: 33.5612239,
    lng: -7.6263399
  },


  {
    name: "HT PHARMA",
    specialite: "Importation, Distribution, DM steriles",
    address: "RUE 9 N 18 HAY ADIL RUE 9N 18 MOHAMMDIA",
    tel: "0522609442",
    email: "contact@htpharma.ma",
    province:"Marrakech", lat: 33.6401277,
    lng: -7.5187503
  },


  {
    name: "HUMAN CARE PHARMA",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "LAMHAMID 5 IMMOUBLE 40 1ERE ETAGE APPARTEMENT N 2 MARRAKECH",
    province:"Marrakech", lat: 31.617395,
    lng: -8.0676377
  },


  {
    name: "HYDROCOT",
    specialite: "Fabrication Distribution, DM non steriles",
    address: "LOCAL N 5 CHEMIN 1029 RMEL LAHLAL - BOUSKOURA CASABLANCA",
    tel: "0522334803",
    province:"Marrakech", lat: 33.4497587,
    lng: -7.6353017
  },


  {
    name: "HYGIENE GHARB",
    specialite: "Distribution, DM steriles DM non steriles",
    address: "26 AVENUE RYADA KENITRA",
    tel: "0537376111",
    province:"Marrakech", lat: 34.2591415,
    lng: -6.5754003
  },


  {
    name: "HYPER PARASUD SARL AU",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "LOT NAKHIL BLOC 5 N?15 SYBA MARRAKECH",
    tel: "0524403709",
    province:"Marrakech", lat: 33.6877374,
    lng: -7.3720327
  },


  {
    name: "HYPERMEDIC",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "ENSEMBLE RESIDENTAL ET COMMERCIAL EL AKKARI C 95 EL MASSIRA 1 N17 MENARA MARRAKECH",
    tel: "0524344750",
    province:"Marrakech", lat: 31.6351971,
    lng: -8.0607469
  },


  {
    name: "ICOMAP SARL",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "19 RUE HENRI MOREAU VAL FLEURI CASABLANCA",
    tel: "0522995744",
    email: "icomap-casa@menara.ma",
    province:"Marrakech", lat: 33.5865207,
    lng: -7.6822147
  },


  {
    name: "IDENTAL MOROCCO",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "1ER ETAGE APT 2 RUE OUED MAKHAZINE NARJIS A FES",
    province:"Marrakech", lat: 33.5865428,
    lng: -7.6887808
  },


  {
    name: "IDENTITE MEDICALE SARL",
    specialite: "Importation, Distribution, Maintenance, DM steriles",
    address: "1.ANGLE BOULEVARD ZERKTOUNI ET RUE CASABLANCA",
    tel: "0522208564",
    province:"Marrakech", lat: 33.5832876,
    lng: -7.6188343,
  },


  {
    name: "IDEX PHARME",
    specialite: "Importation, Distribution,",
    address: "RESIDENCE LE JAYAU IV 2 RUE IBN AL MOATAZ 20300 CASABLANCA",
    tel: "0522247502",
    province:"Marrakech", lat: 33.5927925,
    lng: -7.5983948
  },


  {
    name: "IDRISSI MED SARL AU",
    specialite: "Distribution, DM steriles DM non steriles",
    address: "LOTISSEMENT NADIA GR4 ETAGE RD N1 N1243 LAHRAOUINE CASABLANCA",
    tel: "0522665112",
    email: "idrissiy@hotmail.com",
    province:"Marrakech", lat: 33.5928146,
    lng: 7.6049609
  },


  {
    name: "IHSSANE MEDICALE",
    specialite: "Distribution,",
    address: "20 COMPLEXE DAR AL BIR OUA AL IHSSANE DAOUDIATE MARRAKECH",
    tel: "0524314435",
    province:"Marrakech", lat: 33.5928497,
    lng: -7.6049609
  },


  {
    name: "IM ALLIANCE",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "ANGLE BOULEVARD OMAR EL KHAYAM ET SIDI ABDERRAHMANE BEAUSEJOUR CASABLANCA",
    tel: "0522592086",
    province:"Marrakech", lat: 33.4263949,
    lng: -7.6438027
  },


  {
    name: "IMCHEM PHARMA",
    specialite: "Importation, Distribution, DM steriles",
    address: "218 GR EL BARID -HAY NAHDA RABAT",
    province:"Marrakech", lat: 33.4265229,
    lng: -7.7116538
  },


  {
    name: "IMPLAFITT SARL",
    specialite: "Exportation, Distribution, DM steriles DM non steriles",
    address: "AVENUE PATRICE LUMUMBA IMMEUBLE 7 APPARTEMENT 6 HASSAN RABAT MAROC",
    province:"Marrakech", lat: 33.9862228,
    lng: -6.8590553
  },


  {
    name: "INDO MAROC SARL",
    specialite: "Fabrication Importation, Exportation, Distribution, DM steriles",
    address: "ZONE INDUSTRIELLE ALLEE N 1 LOTISSEMENT N 5 RTE DE TETOUAN TANGER",
    tel: "0539369620",
    email: "indo@indo.ma",
    province:"Marrakech", lat: 35.7468282,
    lng: -5.7879619
  },


  {
    name: "INGENIERIE BIOMEDICALE S.A.RL",
    specialite: "Maintenance, DM non steriles",
    address: "N 7 IMMEUBLE CONCORDE RUE DE LA FOIRE Q.I AGADIR",
    province:"Marrakech", lat: 33.9862228,
    lng: -6.8590553,
  },


  {
    name: "INNO MADE SARL",
    specialite: "Importation, Distribution, Maintenance, DM steriles",
    address: "25 BIE RUE BOUIBLANE APPARTEMENT N 6 RABAT",
    tel: "0537674298",
    email: "contact@innomade.ma",
    province:"Marrakech", lat: 33.9942024,
    lng: -6.8504316
  },


  {
    name: "INNO-SETS SARL",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "42.RUE DES HOPITAUX QUARTIER DES HOPITAUX MAARIF CASABLANCA",
    tel: "0522221735",
    email: "info@innosets.ma",
    province:"Marrakech", lat: 33.5843581,
    lng: -7.6246433
  },


  {
    name: "INNOVATION MANAGEMENT PHARMACEUTIQUE",
    specialite: "Exportation, Distribution, DM steriles DM steriles",
    address: "38 RUE AHMED ENNACIRI RESIDENCE NASR PALMIER CASABLANCA",
    tel: "0522986362",
    email: "contact.impmaroc@gmail.com",
    province:"Marrakech", lat: 33.5793704,
    lng: -7.6313171
  },


  {
    name: "INNOVATIVE DENTAL SOLUTIONS",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "PLACE MOULAY ABDELAZIZ RESIDENCE ENNARES 4 EME ETAGE N 15 TANGER",
    tel: "0539945582",
    province:"Marrakech", lat: 35.7681407,
    lng: -5.8270035
  },


  {
    name: "INOVAMED SARL",
    specialite: "Importation, Distribution,",
    address: "N 11 RUE AZIZ BELLAL BOULEVERD ZARKTOUNI CASABLANCA",
    province:"Marrakech", lat: 33.5126258,
    lng: -7.6617852
  },


  {
    name: "INSTAMED SARL",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "BOULEVARD MOULAY YOUSSEF RESIDENCE YASINE2 1ER ETAGE N 14 TANGER",
    tel: "0539341231",
    province:"Marrakech", lat: 35.7709816,
    lng: -5.8113352
  },


  {
    name: "INSTITUT PHARMACEUTIQUE BIO",
    specialite: "Fabrication Importation, Exportation, Distribution, DM steriles",
    address: "142 OPERATION KHALIL 1 ASKEJOUR MARRAKECH",
    tel: "0524373255",
    province:"Marrakech", lat: 31.6180121,
    lng: -8.0633076
  },


  {
    name: "INTER EQUIPEMENT MEDICAL SERVICES MAROC SARL",
    specialite: "Importation, Exportation, Distribution, Maintenance,",
    address: "8 RUE EZZAHRAOUI ABOU EL KACEM OU DES HOPITAUX ANFA 20100 CASABLANCA",
    tel: "0522209797",
    province:"Marrakech", lat: 33.5333001,
    lng: -7.5856181
  },


  {
    name: "INTER LENS",
    specialite: "Fabrication Importation, Distribution,",
    address: "77 RUE MOHAOU HAMOU CASABLANCA",
    tel: "0522445314",
    province:"Marrakech", lat: 33.5900009,
    lng: -7.6131707
  },


  {
    name: "INTERNATIONAL MEDICAL SERVICE ( IMS)",
    specialite: "Importation, DM steriles",
    address: "26 AVENUE MERS SULTAN EATG 1 APPARTEMENT 3 CASABLANCA",
    province:"Marrakech", lat: 33.5708872,
    lng: -7.6800612
  },


  {
    name: "INTIMA MEDICAL",
    specialite: "Exportation, DM non steriles",
    address: "10.AVENUE ALLAL BEN ABDELLAH FES",
    tel: "0535620764",
    email: "contact@intima-medical.ma\n",
    province:"Marrakech", lat: 33.5710149,
    lng: -7.7479123
  },


  {
    name: "INTRACE SARL",
    specialite: "Importation, DM non steriles",
    address: "AVENUE AHMEDBENABBOUD KISSARIAT CHERKAOUI APPARTEMENT 44 SALE MAROC",
    tel: "0537780106",
    province:"Marrakech", lat: 34.0367477,
    lng: -6.8258803
  },


  {
    name: "IP SANTE SARL AU",
    specialite: "Importation, Distribution, DM steriles",
    address: "3EME ETAGE N 10 AHMED CHARCI IMMEUBLE A QUARTIER RACINE CASABLANCA",
    province:"Marrakech", lat: 33.5955171,
    lng: -7.6538105
  },


  {
    name: "IPEXA",
    specialite: "Importation,",
    address: "RUE BANAFSAJ ANGLE BOULEVARD OMAR KHAYAM RESIDENCE YASMINE IMMEUBLE B BEAUSEJOUR CASABLANCA",
    tel: "0522947766",
    email: "info@kedex.ma",
    province:"Marrakech", lat: 33.5862544,
    lng: -7.6202137
  },


  {
    name: "IPHABIOTICS",
    specialite: "Importation, Distribution,",
    address: "6 RUE IBNOU KHALIKANE PALMIER CASABLANCA",
    tel: "0522253607",
    province:"Marrakech", lat: 33.5611271,
    lng: -7.6335825
  },


  {
    name: "IRAQI MEDICAL PROMO",
    specialite: "Distribution, DM steriles DM non steriles",
    address: "N 65 RUE HAJ AHMED AKHENOUCH AGADIR",
    tel: "0528826070",
    province:"Marrakech", lat: 30.4169828,
    lng: -9.5940569
  },


  {
    name: "IRIS COSMETOLOGIE (IRCOS)",
    specialite: "Fabrication",
    address: "N 109 QUARTIER INDUSTRIEL SIDI GHANEM .MARRAKECH",
    tel: "0524335544",
    email: "Contact@ircoslaboratoires.com",
    province:"Marrakech", lat: 31.6747786,
    lng: -8.0352253
  },


  {
    name: "ISOMED SARL",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "43.RUE AHMED ANNASSIRI RESIDENCE BADR RDC APPARTEMENT N2 CASABLANCA",
    tel: "0522993061",
    province:"Marrakech", lat: 33.5792432,
    lng: -7.6314556
  },


  {
    name: "ISOPHARM",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "12 RUE SOULEIMANE AZMI QUARTIER DES HOPITAUX CASABLANCA",
    tel: "0522864472",
    province:"Marrakech", lat: 33.5736205,
    lng: -7.6188007
  },


  {
    name: "ITH MAROC",
    specialite: "Exportation, Distribution, Maintenance, DM steriles",
    address: "N 26 RUE 8 LOTISSEMENT NASSIM ROUTE D`AZEMOUR CASABLANCA",
    tel: "0522942746",
    province:"Marrakech", lat: 33.5618307,
    lng: -7.6407476
  },


  {
    name: "ITRI DENT",
    specialite: "Distribution, DM steriles DM non steriles",
    address: "LOTISSEMENT N 17 ROUTE ANTARA LOTISSEMENT MIMA -KENITRA",
    province:"Marrakech", lat: 34.255844,
    lng: -6.5821479
  },


  {
    name: "IZEST SARL",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "RESIDENCE SALMA 02 IMMEUBLE 6 ETAGE 1 N 4 HAY EL JAMEAA BOUSKOUR CASABLANCA",
    province:"Youssoufia", lat: 32.5927754,
    lng: -8.3612696
  },


  {
    name: "JALAL MED SARL",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "80 BOULEVARD AL GRANDE CEINTURE N 70 CASABLANCA",
    tel: "0661150070",
    province:"Marrakech", lat: 33.577341,
    lng: -7.6281647
  },


  {
    name: "K AND A PARA SARL",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "N 75 DOUAR LAHRACH ASKEJOUR MARRAKECH",
    tel: "0522278595",
    province:"Marrakech", lat: 32.2107381,
    lng: -8.5941136
  },


  {
    name: "KADI MED",
    specialite: "Fabrication Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "15 RUE ABOU BAKR MED BNOU ZAHR QH CASABLANCA",
    tel: "0522226677",
    email: "Stekadimed@gmail.com",
    province:"Marrakech", lat: 33.5786573,
    lng: -7.6241688
  },


  {
    name: "KAFMEDIC SARL",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "11.B MOHAMED BEN ABDELLAH RESIDENCE DAR ESSALAOUI CASABLANCA",
    tel: "0522476876",
    province:"Marrakech", lat: 33.5126258,
    lng: -7.6617852
  },


  {
    name: "KAMADENT SARL",
    specialite: "Distribution, DM steriles DM non steriles",
    address: "152 AVENUE FATIMA ZOHRA.TANGER",
    tel: "0539955438",
    province:"Marrakech", lat: 35.7585843,
    lng: -5.8040097
  },


  {
    name: "KAMENDIS SARL",
    specialite: "Importation, DM steriles",
    address: "8 RUE BOULMANE BOURGOUNE-CASABLANCA",
    tel: "0522221668",
    province:"Marrakech", lat: 33.5126258,
    lng: -7.6617852
  },


  {
    name: "KEDEX",
    specialite: "Importation, Distribution, DM steriles",
    address: "RUE BANFSAJ ANGLE BD OMAR KHAYAM RESIDENCE YASMINE IMMEUBLE B BEAUSEJOUR CASABLANCA",
    tel: "0522947766",
    email: "info@kedex.ma",
    province:"Marrakech", lat: 33.5334564,
    lng: -7.6533728
  },


  {
    name: "KENITRA DENTAIRE SARL",
    specialite: "Distribution, DM steriles DM non steriles",
    address: "RUE QORTOBA IMMEUBLE N 5 BUREAU N 4 RISIDENCE NISRINE KENITRA",
    tel: "0537366652",
    province:"Marrakech", lat: 34.2669638,
    lng: -6.6353993
  },


  {
    name: "KERDENT",
    specialite: "Importation, Maintenance, DM non steriles",
    address: "N 22 RUE ABDELMOIMEN HASSAN RABAT",
    tel: "0537704308",
    province:"Marrakech", lat: 34.0237319,
    lng: -6.8282207
  },


  {
    name: "KHAWAREZMI PARA SARL AU",
    specialite: "Importation, DM steriles DM non steriles",
    address: "144 RUE MOHAMED SMIHA RESIDENCE JAWHARTE MOHAMED SMIHA 6 ETAGE APPARTEMENT N 35 CASABLANCA",
    province:"Marrakech", lat: 33.5615275,
    lng: -7.6589135
  },


  {
    name: "KHOURIBGA MEDIC",
    specialite: "Distribution,",
    address: "N?58 RUE LAYOUN BLOC J NAHDA KHOURIBGA",
    tel: "0523497535",
    province:"Marrakech", lat: 32.880879,
    lng: -6.9523129
  },


  {
    name: "KINGPHAR SARL",
    specialite: "Distribution, DM steriles DM non steriles",
    address: "N 20 BLOC A COMPEXE IBN TACHFINE AVENUE PRINCE MOULAY ABDELLAH MARRAKECH",
    tel: "0524437474",
    province:"Marrakech", lat: 31.6513983,
    lng: -8.0170843
  },


  {
    name: "KISSI MED SARL",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "4 RUE 188 HAY EL HASSANI OUJDA",
    tel: "0687171552",
    email: "medkissi26@live.fr",
    province:"Marrakech", lat: 34.6828417,
    lng: -1.9322328
  },


  {
    name: "KIT MED SLAOUI ET CIE SARL",
    specialite: "Importation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "33 RUE LAHCEN AL ARJOUNE.QUARTIERDES HOPITAUX CASABLANCA",
    tel: "0522860366",
    province:"Marrakech", lat: 33.5623253,
    lng: -7.6319499
  },


  {
    name: "KSD MEDICAL SARL",
    specialite: "Exportation, Distribution, DM steriles DM non steriles",
    address: "17 PLACE PASTEUR BUILD ETAGE 06 N 5 CASABLANCA",
    province:"Marrakech", lat: 33.5846817,
    lng: -7.6163568
  },


  {
    name: "KYA MEDICAL SARL",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "26 AVENUE MERS SULTAN CASABLANCA",
    province:"Marrakech", lat: 3.5875359,
    lng: -7.6175792
  },


  {
    name: "L EMPREINTE MEDICALE SARL AU",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "7 RUE ANTISERABE APPARTEMENT N 6 AVENUE MEKNES",
    tel: "0535400408",
    province:"Marrakech", lat: 34.1111133,
    lng: -7.8389761
  },


  {
    name: "L N OPTIC",
    specialite: "Importation, Distribution,",
    address: "ZI GZENAYA LOTISSEMENT N 379 ROUTE DE RABAT TANGER",
    tel: "0522441156",
    province:"Marrakech", lat: 35.7486763,
    lng: -5.8864797
  },


  {
    name: "L`EQUIPMENT MEDICAL",
    specialite: "Distribution, DM non steriles",
    address: "8 AVENUE MOULAY RACHID TANGER",
    province:"Marrakech", lat: 35.7782204,
    lng: -5.8268404
  },


  {
    name: "LA MAGHREBINE D`OPTIQUE Distribution,",
    specialite: "Importation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "25 RUE EL OQHOWANE MERS SULTAN CASABLANCA",
    tel: "0522221694",
    province:"Marrakech", lat: 33.5662643,
    lng: -7.649096
  },


  {
    name: "LA MAISON DU MEDICAL ET DU LABORATOIRE",
    specialite: "Importation, Distribution, Maintenance, DM steriles DM steriles",
    address: "BOULEVARD IBNOUTACHFINE R?SIDENCE FATIMA ZAHRA 7 APPARTEMENT 7 3EME ETAGE",
    tel: "0522636330",
    province:"Marrakech", lat: 33.5855196,
    lng: -7.5925095
  },


  {
    name: "LABCO SARL",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "ETAGE RDC.APPARTEMENT N 2 LOTESSEMENT ANNA 3 N 1 SIDI MAAROUF CASABLANCA",
    province:"Marrakech", lat: 33.517994,
    lng: -7.6641656
  },


  {
    name: "LABEL MEDICAL SARL",
    specialite: "Importation, Distribution,",
    address: "77 BOULEVARD DE PARIS APPARTEMENT N3 CASABLANCA",
    tel: "0522222344",
    province:"Marrakech", lat: -7.6167884,
    lng: -7.6167884
  },


  {
    name: "LABMEDIC TECHNOLOGIES",
    specialite: "Importation, Distribution, Maintenance,",
    address: "ALIA 1 RUE 13 N 2 OULFA CASABLANCA",
    tel: "0522276673",
    province:"Marrakech", lat: 33.5365215,
    lng: -7.5847224
  },


  {
    name: "LABORATOIRE TRADIPHAR MAROC",
    specialite: "Exportation, Distribution, DM steriles DM non steriles",
    address: "BERRECHID 57 RUE ALLAL BEN ABDELLAH CASABLANCA",
    tel: "0522507177",
    province:"Marrakech", lat: 33.5441943,
    lng: -7.6160049
  },


  {
    name: "LABORATOIRES PHARMACEUTIQUES IBERMA",
    specialite: "Fabrication Distribution, DM steriles DM non steriles",
    address: "BOULEVARD ZERKTOUNI MAARIF N? 237",
    tel: "0522964632",
    email: "contact@iberma.com",
    province:"Marrakech", lat: 33.5840651,
    lng: -7.6267306
  },


  {
    name: "LABORATOIRES TECHNIPHARM",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "1075 BOULEVARD MED VI RESIDENCE FATHZHAR.MAGASIEN N 84 CASABLANCA",
    tel: "0522599876",
    province:"Marrakech", lat: 33.5943079,
    lng: -7.5425105
  },


  {
    name: "LABRINI MED SARL AU",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "AVENUE ENNAKHIL IMMEUBLE REDA 1 ER ETAGE APPARTEMENT 6 EL JADIDA 24000",
    tel: "0523332033",
    province:"Marrakech", lat: 33.2423851,
    lng: -8.4973689
  },


  {
    name: "LAPROPHAN SA",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "18.BOULEVARD EMILE ZOLA 20 300 CASABLANCA",
    tel: "0522240100",
    province:"Marrakech", lat: 33.5915483,
    lng: -7.601287
  },


  {
    name: "LARAMEDIC",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "OP. PALMIERS 4 IMMEUBLE 113 BIS APPARTEMENT B4 TAMENSOURT MARRAKECH",
    province:"Marrakech", lat: 31.7581444,
    lng: -8.1300063
  },


  {
    name: "LASAMED SARL",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "N 12 1ER ETAGE RUE SABRI BOUJEMAA CASABLANCA",
    province:"Marrakech", lat: 33.9862184,
    lng: -6.8568666
  },


  {
    name: "LAWAZIME ASNANE SARL",
    specialite: "Importation, Distribution,",
    address: "GROUPE DOHA KHEIR RESIDENCE 20N 6 SALA AL JADIDA SALE",
    province:"Marrakech", lat: 33.9964787,
    lng: -6.7444038
  },


  {
    name: "LE COMPTOIRE MEDICAL DENTAIRE",
    specialite: "Importation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "HAY FARAH BOULEVARD ABA CHOUAIB DOUKKALI RUE 30 N 81 RESIDENCE DE CHAUSSEE CASABLANCA",
    tel: "0522818140",
    province:"Marrakech", lat: 33.3997026,
    lng: -8.1758788
  },


  {
    name: "LEM GROUPE",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "ETAGE MAGASIEN N22 RUE 2 HAY EL FARAH MOULAY RACHID CASABLANCA",
    province:"Marrakech", lat: 33.5342441,
    lng: -7.5641049
  },


  {
    name: "LEPINE MAROC SARL",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "79 AVENUE IBN SINA N54 RABAT",
    tel: "0537670567",
    province:"Marrakech", lat: 33.9880787,
    lng: -6.8517073
  },


  {
    name: "LES HERITIRES GUESSOUS MOHAMMED",
    specialite: "Importation, Distribution, DM steriles",
    address: "12 BOULEVARD MOULAY ISMAIL FES",
    tel: "0535624302",
    province:"Marrakech", lat: 33.8391887,
    lng: -6.7990012
  },


  {
    name: "LES NORMES ELECTRO-MEDICAL SARL ",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM non steriles",
    address: "519 A LOTISSEMENT 519 HAJ FATEH EL OULFA 20260 CASABLANCA",
    tel: "0522930067",
    province:"Marrakech", lat: 33.5439425,
    lng: -7.6781011
  },


  {
    name: "LES PLUS BELLES LUNETTES SARL",
    specialite: "Importation, Distribution, DM steriles",
    address: "23 RUE MOUSSA BEN NOUSSAIR ETAGE 2 APPARTEMENT 5 TANGER",
    tel: "0539330556",
    province:"Marrakech", lat: 35.7790104,
    lng: -5.81042
  },


  {
    name: "LEUCOTHEE SARL",
    specialite: "Importation, Distribution, DM non steriles",
    address: "196 AVENUE MERS SULTAN 20120 CASABLANCA",
    tel: "0522228070",
    province:"Marrakech", lat: 33.5839614,
    lng: -7.6423036
  },


  {
    name: "LILIM PHARM SARL",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "3 RUE TOUR HASSAN APPARTEMENT 301 RUE CHAOUEN HASSAN-RABAT",
    province:"Marrakech", lat: 33.5714564,
    lng: -7.6466426
  },


  {
    name: "LOCAMED SERVICE",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "11 RUE MAMOUNIA (EX CTM) RABAT",
    tel: "0537630807",
    province:"Marrakech", lat: 34.021092,
    lng: -6.8386342
  },


  {
    name: "LOGIC DIFFUSION",
    specialite: "Importation, Distribution, Maintenance, DM non steriles",
    address: "22 RUE NASSIH EDDINE MAARIF CASABLANCA",
    tel: "0522986734",
    province:"Marrakech", lat: 33.5759234,
    lng: -7.6457359
  },


  {
    name: "LOHMANN&RAUSCHER",
    specialite: "Importation, Distribution, DM steriles",
    address: "15 RUE SARIA BNOU ZOUNAIM RESIDENCE ANASS QUARTIER PALMIER CASABLANCA",
    tel: "0522991270",
    province:"Marrakech", lat: 33.555338,
    lng: -7.5903157
  },


  {
    name: "LONGILIGNE",
    specialite: "Importation, DM steriles",
    address: "1 LOTISSEMENTT NOOR SIDI MAROUF CASABLANCA",
    province:"Marrakech", lat: 33.5364692,
    lng: -7.648314
  },


  {
    name: "LOUKOUS PARAMEDICAL",
    specialite: "Distribution,",
    address: "QUARTIER ANDALOUS GROUPE A RUE 17 N?10 KSAR EL KEBIR",
    tel: "0539902828",
    province:"Marrakech", lat: 34.9986561,
    lng: -5.9103624
  },


  {
    name: "LUSTINER MAROC SARL",
    specialite: "Importation, DM non steriles",
    address: "5 BIS RUE GHANA -APPARTEMENT 2 RABAT",
    tel: "0537611244",
    province:"Marrakech", lat: 33.9956888,
    lng: -6.8861584
  },


  {
    name: "M B S DIFFUSION MEDICAL SARL",
    specialite: "Importation, DM steriles",
    address: "N 434 BLOC A4 AOUINAT EL HAJJAJ FES",
    tel: "0522211312",
    province:"Marrakech", lat: 32.0871376,
    lng: -9.2814005
  },


  {
    name: "M.B PARAMEDICALE",
    specialite: "Distribution, DM non steriles",
    address: "276 NAHDA KHEMIS ZEMAMRA SIDI BENNOUR",
    province:"Marrakech", lat: 33.5677778,
    lng: -7.6508604
  },


  {
    name: "M.M.C SANTE",
    specialite: "Distribution, DM steriles DM non steriles",
    address: "RUE .OUED ZIZ LOTISSEMENT LES IRISS OUJDA",
    tel: "0522863349",
    province:"Marrakech", lat: 34.7047178,
    lng: -1.8890143
  },


  {
    name: "M2S.S SARL AU",
    specialite: "Importation, Distribution, Maintenance, DM non steriles",
    address: "BOULEVARD OUED DARRA RUE 45 N 25 OULFA CASABLANCA",
    tel: "0643028267",
    email: "Contact@m2s.ma",
    province:"Marrakech", lat: 33.5539719,
    lng: -7.6844445
  },


  {
    name: "MABIOTECH SARL",
    specialite: "Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "BEAUFRUIT II 24 ZONE INDUSTRIELLE AIN ATIQ",
    tel: "0537749071",
    email: "mabiotech@mabiotech.com",
    province:"Marrakech", lat: 33.8838957,
    lng: -6.9842791
  },


  {
    name: "MAGHREB MED SARL",
    specialite: "Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "JNANE NAHDA-IMMEUBLE 43 APPARTEMENT N 8 HAY NAHDA RABAT",
    tel: "0537658846",
    province:"Marrakech", lat: 39.5981369,
    lng: -5.6988723
  },


  {
    name: "MAGHREB OXYGENE",
    specialite: "Importation, Exportation, Distribution,",
    address: "IMMEUBLE TAFRAOUTI KM 7.5 ROUTE DE RABAT AIN SEBAA CASABLANCA",
    tel: "0522352222",
    province:"Marrakech", lat: 33.6117693,
    lng: -7.5245218
  },


  {
    name: "MAGHREB-EUROPEEN PHARMACEUTICAL INDUSTRIES SARL",
    specialite: "Fabrication Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "48 BOULEVARD PASTEUR GALERIE ALJISR 3 EME ETAGE N 17 TANGER",
    tel: "0522355956",
    province:"Marrakech", lat: 35.7805041,
    lng: -5.8134758
  },


  {
    name: "MAHDATI PARA",
    specialite: "Distribution, DM steriles DM non steriles",
    address: "MAGASIN 7 RAYAD AZZA YTOUNE E 18 MEKNES",
    tel: "0535462940",
    province:"Marrakech", lat: 33.8597443,
    lng: -5.5711432
  },


  {
    name: "MAISON DE LA SANTE",
    specialite: "Distribution, DM steriles DM non steriles",
    address: "57 BOULEVARD HOPITAL EL HASSANI NADOR",
    tel: "0536602121",
    province:"Marrakech", lat: 35.1759835,
    lng: -2.9351665
  },


  {
    name: "MAISON PARA-MED",
    specialite: "Fabrication Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "N 79 LOTISSEMENT ARRAOUD SECTEUR 4 KARIA SALE",
    tel: "0661542628",
    email: "graph.dimensionz@gmail.com",
    province:"Marrakech", lat: 34.0256909,
    lng: -6.7598239
  },


  {
    name: "MAJAL PARA SARL",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "SIDI ABBAD 3 N4 MAGAZIEN AU SOUS SOL MARRAKECH",
    tel: "0693553723",
    province:"Marrakech", lat: 31.6781711,
    lng: -8.0053569
  },


  {
    name: "MAJORELLE ORTHOPEDIE",
    specialite: "Fabrication Importation, Distribution, DM non steriles",
    address: "2 RUE ANGLE DU SERGENT LEVET ET FERKLA-GUELIZ MARRAKECH",
    tel: "0524434963",
    province:"Marrakech", lat: 31.6388041,
    lng: -8.0209487
  },


  {
    name: "MAKAMED",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "AVENUE 6 NOVEMBRE N 27 LOTISSEMENT AL AMALTABRIQUET SALE",
    province:"Marrakech", lat: 34.0523323,
    lng: -6.8051053
  },


  {
    name: "MANAGEMENT ET DEVELOPPEMENT PHARMACEUTIQUE",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "59 RUE AL KHANSSA ETAGE APPARTEMENT 4 PALMIER CASABLANCA",
    tel: "0522986362",
    email: "contact.impmaroc@gmail.com",
    province:"Marrakech", lat: 33.5783396,
    lng: -7.6277153
  },


  {
    name: "MAPHAR S.A",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "KM 10 ROUTE COTIERE 111 QUARTIER INDUSTRIEL -ZENATA AIN SEBAA.CASABLANCA -MAROC",
    tel: "0522678000",
    email: "contact@maphar.ma",
    province:"Marrakech", lat: 33.63831,
    lng: -7.502567
  },


  {
    name: "MAREDIC SARL",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "46 RUE SALEM CHERKAOUI 20360 CASABLANCA",
    tel: "0522487407",
    province:"Marrakech", lat: 33.5785555,
    lng: -7.6261908
  },


  {
    name: "MAROC BUSINESS SERVICE TRADING SOLUTIONS SARL",
    specialite: "Importation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "58 RUE ELIGH QUARTIER ESSALAM 80000 AGADIR MAROC",
    tel: "0528237323",
    province:"Marrakech", lat: 33.5785644,
    lng: -7.6261908
  },


  {
    name: "MAROC CHIRURGIE",
    specialite: "Importation, Distribution,",
    address: "16 RUE 169 GROUPE H-HAY OULFA CASABLANCA",
    tel: "0522227050",
    province:"Marrakech", lat: 33.533333,
    lng: -7.5855217
  },


  {
    name: "MAROC DENTAIRE BENJELLOUN",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "IMM 57 ANGLE AVENUE DES ALMOHADES ET RUE DES EMIRATES UNIES FES V.N",
    tel: "0535621322",
    province:"Marrakech", lat: 34.0385735,
    lng: -5.0132667
  },


  {
    name: "MAROC DIABETE PLUS",
    specialite: "Importation, Distribution,",
    address: "94 RUE ABOU ASSALT AL ANDALOUSSI MAARIF CASABLANCA",
    tel: "0522237676",
    email: "marocdiabete@menara.ma",
    province:"Marrakech", lat: 33.5737774,
    lng: -7.6218933
  },


  {
    name: "MAROC MEDICAL SERVICES SARL",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "78 BOULEVARD LA RESIDENCE APPARTEMENT 66 SIDI BALYOUT CASABLANCA",
    tel: "0522520615",
    email: "mmsATmarocmedical.ma",
    province:"Marrakech", lat: 33.5737951,
    lng: -7.6218933
  },


  {
    name: "MAROC ORTHODONTIE ALIGNEUR Distribution, (MOAD)",
    specialite: "Exportation,",
    address: "214 BOULEVARD IBNOU SINA HAY HASSANI 20210 CASABLANCA",
    province:"Marrakech", lat: 33.5738128,
    lng: -7.6218933
  },


  {
    name: "MAROCCO DIALYSIS",
    specialite: "Fabrication Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "LOTISSEMENT AMSERNAT I 34 AGADIR MAROC",
    tel: "0528292977",
    province:"Marrakech", lat: 30.4145816,
    lng: -9.5782399
  },


  {
    name: "MASTORAL DIFFUSION",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "ANG RUE IBN ZAIDOUNE ET TUNIS N 1 HAY EL FARAHMOHAMMADIA",
    tel: "0522241851",
    province:"Marrakech", lat: 33.6885305,
    lng: -7.3970741
  },


  {
    name: "MATIN MEDICAL SARL ",
    specialite: "Distribution, DM steriles DM steriles",
    address: "165 BOUMESMAR DAOUDIATE MARRAKECH",
    tel: "0524291752",
    province:"Marrakech", lat: 33.617601,
    lng: -7.5053358
  },


  {
    name: "MAXIMED SARL",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "BOULEVARD D`ANDROMEDE ARRONDISSEMENT MAARIF CASABLANCA",
    province:"Marrakech", lat: 46.5972558,
    lng: -6.6088409
  },


  {
    name: "MC PHARMA",
    specialite: "Importation, DM non steriles",
    address: "LOTISSEMENT BACHKOU LOTISSEMENT 10 RUE 7 CASABLANCA",
    tel: "0522853518",
    province:"Marrakech", lat: 33.5128189,
    lng: -7.7087209
  },


  {
    name: "MCDOMAR GROUP",
    specialite: "Importation, Distribution, Maintenance, DM non steriles",
    address: "18 RUE 66 HAY MOULAY ABDELLAH CASABLANCA",
    tel: "0522823889",
    email: "info@mcdomar.ma",
    province:"Marrakech", lat: 33.5519592,
    lng: -7.6153447
  },


  {
    name: "MDV MEDICAL DEVICE",
    specialite: "Importation, DM steriles",
    address: "17 PLACE PASTEUR RESIDENCE PASTEUR BUILD 7 EME ETAGE CASABLANCA",
    province:"Marrakech", lat: 33.564842,
    lng: 7.6394389
  },


  {
    name: "MECA -MEDIC SARL ",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "51.NEZHA 1 FQUIH BEN SALAH",
    province:"Marrakech", lat: 32.503424,
    lng: -6.6912245
  },


  {
    name: "MED RAYS SARL",
    specialite: "Importation, Distribution, DM non steriles",
    address: "3354 AL WIFAQ TEMARA 12040",
    tel: "0537564862",
    province:"Marrakech", lat: 33.9603727,
    lng: -6.8802925,
  },


  {
    name: "MED SOLUTIONS",
    specialite: "Importation, DM steriles",
    address: "8 RUE EL WAHA EL MENZEH MEKNES",
    tel: "0676890543",
    email: "Contact@medsolutions.ma",
    province:"Marrakech", lat: 33.9831103,
    lng: -6.8298392
  },


  {
    name: "MEDECHOMAT SARL",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "16.N 3 AVENUE SIDI MOHAMMED BELHASSAN SALA AL JADIDA SALE",
    tel: "0537530717",
    province:"Marrakech", lat: 34.0375896,
    lng: -6.8324024
  },


  {
    name: "MEDEST",
    specialite: "Distribution, DM steriles DM non steriles",
    address: "81 RUE DAOUD IBN AICHA ANGLE RUE DAKHLA OUJDA",
    province:"Marrakech", lat: 29.109288,
    lng: -13.4404271
  },


  {
    name: "MEDEXEL",
    specialite: "Importation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "23 PLACE LOUIS PASTEUR QUARTIER DES HOPITAUX 20360 CASABLANCA",
    tel: "0522222225",
    province:"Marrakech", lat: 33.5738482,
    lng: -7.6267822
  },


  {
    name: "MEDI BIO",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "131 BOULEVARD ABDELMOUMEN N 17 4 EME ETAGE CASABLANCA",
    province:"Marrakech", lat: 33.5577214,
    lng: -7.6229634
  },


  {
    name: "MEDI CENTRE",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "71,RUE DES ECOLES 2 EME ETAGE APPARTEMENT N?6 QUARTIER BURGER - BEAUSEJOUR CASABLANCA",
    tel: "0522981437",
    province:"Marrakech", lat: 33.571497,
    lng: -7.6456027
  },


  {
    name: "MEDI MAK SARL",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "28 AVENUE DES FAR 1 ER ETAGE APPARTEMENT 2 MEKNES",
    province:"Marrakech", lat: 33.5305897,
    lng: -7.6653128
  },


  {
    name: "MEDI ORTHOPEDIE SARL",
    specialite: "Importation, Distribution, DM non steriles",
    address: "CHEIKH MAKOUDI N?30 RUE 16 TANGER",
    tel: "0539957155",
    province:"Marrakech", lat: 33.5579181,
    lng: -7.6633428
  },


  {
    name: "MEDI SOINS SARL",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "OUM DHAR HAY YASMINA N 18 BENI MELLAL",
    tel: "0523488081",
    province:"Marrakech", lat: 33.5577849,
    lng: -7.6633428
  },


  {
    name: "MEDI-ATLAS",
    specialite: "Exportation, Distribution, DM steriles DM non steriles",
    address: "N 17 BLOC 5 LOTISSEMENT CADI AYAD ASSIF MARRAKECH",
    province:"Marrakech", lat: 33.5576517,
    lng: -7.6633427
  },


  {
    name: "MEDIC HEALT",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles",
    address: "N173 MAGASIEN 2 HAY AIT SKATO ROUTE IMMOUZER FES",
    province:"Marrakech", lat: 33.5575185,
    lng: -7.6633427
  },


  {
    name: "MEDIC`UP SARL",
    specialite: "Distribution, DM non steriles",
    address: "IMMEUBLE ANTI 8 RUE IMAM GHAZALI N A6 TANGER",
    province:"Marrakech", lat: 33.8256761,
    lng: -7.4823143
  },


  {
    name: "MEDICA MAROC SARL",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "IMMEUBLE 14 RUE JABEL TAZEKA 1ER ETAGE APPARTEMENT 4 AGDAL RABAT",
    tel: "0522976464",
    email: "contact@medicar.ma",
    province:"Marrakech", lat: 33.8251687,
    lng: -7.482313
  },


  {
    name: "MEDICAL 10000",
    specialite: "Distribution, DM steriles DM non steriles",
    address: "N 80 LOTISSEMENT YASSMINA1 2EME TRANCHE KHOURIBGA",
    province:"Marrakech", lat: 33.5849188,
    lng: -7.6962369
  },


  {
    name: "MEDICAL 2000 SARL",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "13 ANGLE RUE IBNOU KHATIMA ET GROS DUBREUIL QUARTIER DES H?PITAUX CASABLANCA",
    tel: "0522298233",
    province:"Marrakech", lat: 33.5846723,
    lng: -7.6962368
  },


  {
    name: "MEDICAL ADAM SARL",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "IMMEUBLE 1-5 MAGASIEN 8 PROJET HASSAN 2 HAY MOHAMMADI CASABLANCA",
    tel: "0522635494",
    province:"Marrakech", lat: 33.587961,
    lng: -7.5544632
  },


  {
    name: "MEDICAL AFRICAIN SYSTEM (MEDAF SARL )",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "APPARTEMENT 11 IMMEUBLE 3 AVENUE IBN SINA ISMAILIA 2 MEKNES",
    tel: "0535513607",
    province:"Marrakech", lat: 33.8993031,
    lng: -5.5346665
  },


  {
    name: "MEDICAL COMPUTING TECHNOLOGY & ULTASOUND SOCIETY - MCTUS-",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "AVENUE TARIK IBN ZIAD RESIDENCE AL OSRA IMMOUBLE 3 APPARTEMENT 7 TEMARA",
    province:"Marrakech", lat: 39.04153,
    lng: -77.113949
  },


  {
    name: "MEDICAL DISTRIB",
    specialite: "Importation, Exportation,",
    address: "IMMEUBLE B ANGLE BD BIR ANZARANE ET ABOU ISHAK CHIRAZI APPARTEMENT 13 MAARIF EXTENSION CASABLANCA",
    tel: "0522996779",
    province:"Marrakech", lat: 33.5790434,
    lng: -7.6318699
  },


  {
    name: "MEDICAL ESTHETIC CENTER",
    specialite: "Importation, Exportation, Distribution, DM non steriles",
    address: "8 RUE DU POINT DU JOUR QUARTIER RACINE CASABLANCA",
    tel: "0522268293",
    province:"Marrakech", lat: 33.5789732,
    lng: -7.6318699
  },


  {
    name: "MEDICAL EXPERT",
    specialite: "Fabrication Importation, Distribution, Maintenance, DM steriles",
    address: "28 RUE LONGUEDOC QUARTIER DES HOPITAUX CASABLANCA",
    tel: "0522262860",
    email: "Commercial@medicalexpert.ma",
    province:"Marrakech", lat: 33.5789511,
    lng: -7.6253038
  },


  {
    name: "MEDICAL EXTRA",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "384 DAR LAMANE BLOC R N 7 HAY MOHAMMADI CASABLANCA",
    tel: "0691655311",
    province:"Marrakech", lat: 33.5332956,
    lng: -7.6009389
  },


  {
    name: "MEDICAL HOUSE SARL",
    specialite: "Distribution, DM steriles DM non steriles",
    address: "CITE AL QUOS RUE IMAM CHAFI N 2 AGADIR",
    tel: "0528231289",
    province:"Marrakech", lat: 35.7786223,
    lng: -5.8272367
  },


  {
    name: "MEDICAL LABORATOIRE SERVICE SARL",
    specialite: "Fabrication Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "IMMEUBLE MEDICAL BUSINES CENTER 540 AVENUE DES FAR TETOUAN",
    tel: "0539995757",
    email: "commercial@mlslabo.ma",
    province:"Marrakech", lat: 35.5888613,
    lng: -5.3474997
  },


  {
    name: "MEDICAL MARKET SARL",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "62. AVENUE AL MAGHREB AL ARABI RDC RABAT",
    tel: "0537720772",
    province:"Marrakech", lat: 34.0062508,
    lng: -6.8508148
  },


  {
    name: "MEDICAL SUPPORT",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "LOTISSEMENT 79 OUAFAE 5 ROUTE DE SEFROU EN FACE FACULTE DE MEDCINE FES",
    tel: "0535614635",
    province:"Marrakech", lat: 34.0062332,
    lng: -6.8508148
  },


  {
    name: "MEDICAL SYSTEMS",
    specialite: "Importation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "3 RUE OUM ERRABIA ANGLE AVENUE OQBAH AGDAL RABAT",
    tel: "0537773239",
    province:"Marrakech", lat: 34.0062156,
    lng: -6.8508148
  },


  {
    name: "MEDICAL TECH SARL",
    specialite: "Importation, Distribution, DM steriles",
    address: "6 RUE 6 OCTOBRE BOULEVARD AL MASSIRA AL KHADRA ETG3 CASABLANCA",
    tel: "0522256847",
    province:"Marrakech", lat: 33.5245813,
    lng: -7.7344806
  },


  {
    name: "MEDICAL TECHNIQUE SARL",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM non steriles",
    address: "INARA 1 RUE 13 N 16 ET RESIDENCE AHFAD C APPARTEMENT 11 AL QODS",
    tel: "0528215138",
    province:"Marrakech", lat: 33.5243685,
    lng: -7.7344806
  },


  {
    name: "MEDICALEX SARL",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "86.BOULEVARD MLY DRISS 1 ER QUARTIER DES HOPITEUX .20100 CASABLANCA",
    tel: "0661902578",
    email: "assist.import@medicalex.ma",
    province:"Marrakech", lat: 33.5721544,
    lng: -7.6214115
  },


  {
    name: "MEDICALS INTERNATIONAL NORTH AFRICA",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "12 RUE SABRI BOUJEMAA 1 ER ETAG APPARTEMENT N 6 SIDI BELYOUT CASABLANCA",
    tel: "0522994949",
    province:"Marrakech", lat: 33.5907156,
    lng: -7.6082605
  },


  {
    name: "MEDICALYKIT SARL",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "ANGLE BOULEVARD ZIRAOUI ET RUE MOHAMED SIDKI 1 ER ETAGE BOURGOGNE CASABLANCA",
    province:"Marrakech", lat: 33.5936171,
    lng: -7.6373618
  },


  {
    name: "MEDICAMIN",
    specialite: "Importation, DM steriles",
    address: "DOUAR OLD BOUBERKAT SKHIRAT",
    province:"Marrakech", lat: 33.8670751,
    lng: -7.0519253
  },


  {
    name: "MEDICAR SARL",
    specialite: "Importation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "21/23 RUE ABOU MOUSSA EL JAZOULI MAARIF CASABLANCA MAROC",
    tel: "0522976464",
    email: "contact@medicar.ma",
    province:"Marrakech", lat: 33.5848121,
    lng: -7.6408023
  },


  {
    name: "MEDICATRONIC",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "241 BOULEVARD EMILE ZOLA QUARTIER BELVEDERE 2 EME ETAGE APPARTEMENT N 4 CASABLANCA",
    tel: "0522245350",
    province:"Marrakech", lat: 33.5949713,
    lng: -7.595256
  },


  {
    name: "MEDICHOIX SARL ",
    specialite: "Distribution, DM steriles DM non steriles",
    address: "3 ET 5 RUE OMAR EL KINDY BOURGOGNE CASABLANCA",
    tel: "0663009689",
    province:"Marrakech", lat: 33.595402,
    lng: -7.6430628
  },


  {
    name: "MEDICODE SARL",
    specialite: "Importation,",
    address: "AVENUE SIDI ABDELHAMID QUARTIER ADMINISTRATIF CHEFCHAOUEN",
    tel: "0524435656",
    province:"Marrakech", lat: 35.1730708,
    lng: -5.2738836
  },


  {
    name: "MEDICODENTAIRE",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "22 RUE AHMED AL MAQRI .RACINE 20050 CASABLANCA",
    tel: "0522393433",
    province:"Marrakech", lat: 33.5923531,
    lng: -7.6392892
  },


  {
    name: "MEDICODEX SARL",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "N2 IMMEUBLE ATLASI RESIDENCE DAR EL HAMRA AVENUE ALLAL EL FASSI MARRAKECH",
    tel: "0524449690",
    province:"Marrakech", lat: 31.65376,
    lng: -8.018441
  },


  {
    name: "MEDICOR",
    specialite: "Importation,",
    address: "17 RUE EL KADI AISS MAARIF CASABLANCA",
    tel: "0522250388",
    province:"Marrakech", lat: 33.580262,
    lng: -7.630217
  },


  {
    name: "MEDICORP MAGHREB SARL",
    specialite: "Importation, Distribution,",
    address: "RESIDENCE NADIA IMMEUBLE 4 APPARTEMENT 8 BOULEVARD BRAHIM ROUDANI CASABLANCA",
    tel: "0522250438",
    province:"Marrakech", lat: 33.5768296,
    lng: -7.6381322
  },


  {
    name: "MEDICPRO",
    specialite: "Fabrication Importation,",
    address: "38 RUE DES HOPITAUX CASABLANCA",
    tel: "0522270355",
    email: "contact@medicpro.ma",
    province:"Marrakech", lat: 33.580698,
    lng: -7.6217958
  },


  {
    name: "MEDILOU SARL",
    specialite: "Importation, DM steriles DM non steriles",
    address: "HAY HANA-RUE 14 N 11 CASABLANCA",
    tel: "0522946277",
    province:"Marrakech", lat: 33.533333,
    lng: -7.583333
  },


  {
    name: "MEDIMED",
    specialite: "Fabrication Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "50 RUE DES HOPITAUX QUARTIER DES HOPITAUX CASABLANCA MAROC 50RUE DES HOPITAUX QUARTIER DES HOPITAUX CASA MAROC",
    tel: "0522296802",
    email: "contact@medimed.ma",
    province:"Marrakech", lat: 33.5786218,
    lng: -7.6213933
  },


  {
    name: "MEDINFAR MAROC",
    specialite: "Importation, Distribution, DM steriles DM non steriles",
    address: "322.RESIDENCE BOISSY 4 BOULEVARD ZERKTOUNI CASABLANCA",
    tel: "0522266990",
    province:"Marrakech", lat: 33.5830241,
    lng: -7.6192261
  },


  {
    name: "MEDINORM SARL",
    specialite: "Importation, Exportation, Distribution, DM steriles DM non steriles",
    address: "AVENUE ABDELKRIM ELKHATABI LOTISSEMENT N8 MARRAKECH",
    tel: "0524436160",
    province:"Marrakech", lat: 31.6600497,
    lng: -8.0410829
  },


  {
    name: "MEDINOVE",
    specialite: "Distribution, DM steriles DM non steriles",
    address: "RUE 324 N 2 LA CIGOGNE.KENITRA",
    province:"Marrakech", lat: 45.7357486,
    lng: 4.8399013
  },


  {
    name: "MEDIOR SARL",
    specialite: "Fabrication Importation, Distribution, DM non steriles",
    address: "330 BOULEVARD EDAOURA JAJ FATEH OULFA CASABLANCA",
    tel: "0522930685",
    province:"Marrakech", lat: 33.5450262,
    lng: -7.679268
  },


  {
    name: "MEDIPRO SARL",
    specialite: "Importation, Distribution,",
    address: "BORJ EL YACOUT BOULEVARD RAHAL EL MESKINI 3 EME ETAGE 20120 CASABLANCA",
    tel: "0770078989",
    province:"Marrakech", lat: 33.555769,
    lng: -7.6347262
  },


  {
    name: "MEDIPROMO",
    specialite: "Distribution, DM steriles DM non steriles",
    address: "RUE 9 N 17 JIRARI TENGER",
    tel: "0539363915",
    province:"Marrakech", lat: 35.7527764,
    lng: -5.8316951
  },


  {
    name: "MEDISAM SARL",
    specialite: "Importation, Distribution, Maintenance, DM steriles",
    address: "BOULEVARD ABDELMOUMEN ET RUE SOUMIYA N 22 CASABLANCA",
    tel: "0673380070",
    email: "amjidila@gmail.com",
    province:"Marrakech", lat: 33.5771604,
    lng: -7.6282122
  },


  {
    name: "MEDISUT SARL",
    specialite: "Importation, Distribution,",
    address: "LOTISSEMENT 1337 ZONE INDUSTRIELLE OULAL SALEH HAY EL HASSANI CASABLANCA",
    tel: "0522860127",
    province:"Marrakech", lat: 33.9862184,
    lng: -6.8590553
  },


  {
    name: "MEDITEC SARL EQUIPEMENT MEDICAL",
    specialite: "Importation, Distribution, Maintenance, DM steriles",
    address: "15.RUE EL HOUDHOUD CASABLANCA",
    tel: "0522200318",
    lat: 33.5839444,
    lng: -7.6162005
  },


  {
    name: "MEDITEN",
    specialite: "Importation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "LOTISSEMENT AL MASSIRA AL HASSANIA RUE 4 N 25 R?SIDENCE OUAFFA CASABLANCA",
    tel: "0522902377",
    lat: 33.5500554,
    lng: -7.6769226
  },


  {
    name: "MEDIXO",
    specialite: "Importation, Exportation, Distribution, Maintenance, DM steriles DM non steriles",
    address: "29 RUE ZIAD IBNOU RIFAA 1ER ETAGE MAARIF CASABLANCA",
    lat: 33.585794,
    lng: -7.630059
  },


  {
    name: "MEDOXYGENE SARL",
    specialite: "Importation, Exportation, Distribution, DM non steriles",
    address: "BOULEVARD BOURGOGNE RESIDENCE EL MACHRIK 2 CASABLANCA",
    tel: "0522276031",
    email: "info@medoxygene.ma",
    lat: 33.5786061,
    lng: -7.6241325
  }

]
/* module.exports = {
    healthCenters,
}; */


