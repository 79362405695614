<script>
import L from "leaflet";
import {
  LMap,
  //LPolyline,
  LMarker,
  LIcon,
  LTooltip,
  LTileLayer,
  LLayerGroup,
  //LControlLayers,
  LControl,
} from "vue2-leaflet";
//import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
/**
 * Map component
 */

export default {
  props: [
    "centers",
    "labsPharma",
    "labsBio",
    "orderAndCorp",
    "steSavantes",
    "dispoMed",
    "ctKech",
    "loader"
  ],
  mounted() {
    
    //this.centersPositions = this.centers;
    this.ctKech.map((obj)=>{
          this.ctKechF.push({...obj, status: false});
    })
    this.centers.map((obj) => {
      if(obj.lat > 31.006946 && obj.lat < 32.228391 && obj.lng < -7.458575 && obj.lng > -8.504928)switch (obj.type) {
        case 1:
          this.centersPositionsType1.push({...obj, status: false});
          break;
        case 2:
          this.centersPositionsType2.push({...obj, status: false});
          break;
      }
    });
    this.labsPharma.map((obj) => {
      if(obj.lat > 30.006946 && obj.lat < 32.938391 && obj.lng < -7.458575 && obj.lng > -8.504928)
          this.labsPharmaF.push({...obj, status: false});
    });
    this.labsBio.map((obj) => {
      if(obj.lat > 30.006946 && obj.lat < 32.938391 && obj.lng < -7.458575 && obj.lng > -8.504928)
          this.labsBioF.push({...obj, status: false});
    });
    this.orderAndCorp.map((obj) => {
      if(obj.lat > 30.006946 && obj.lat < 32.938391 && obj.lng < -7.458575 && obj.lng > -8.504928)
          this.orderAndCorpF.push({...obj, status: false});
    });
    this.steSavantes.map((obj) => {
      if(obj.lat > 30.006946 && obj.lat < 32.938391 && obj.lng < -7.458575 && obj.lng > -8.504928)
          this.steSavantesF.push({...obj, status: false});
    });
    this.dispoMed.map((obj) => {
      if(obj.lat > 30.006946 && obj.lat < 32.938391 && obj.lng < -7.458575 && obj.lng > -8.504928)
          this.dispoMedF.push({...obj, status: false});
    });
  },
  components: {
    LMap,
    LMarker,
    LIcon,
    LTooltip,
    LTileLayer,
    LLayerGroup,
    //LControlLayers,
    LControl,
    //"l-marker-cluster": Vue2LeafletMarkerCluster,
  },
  data() {
    return {
      filterType: 1,
      noFilter: null,
      serialFilter: null,
      filterStatus: false,
      mapZoom: 6,
      mapCenter: [27.105314, -7.684968],

      centersPositionsType1: [],
      centersPositionsType2: [],

      ctKechF: [],
      labsPharmaF: [],
      labsBioF: [],
      orderAndCorpF: [],
      steSavantesF: [],
      dispoMedF: [],

      icon: L.icon({
        iconUrl: require("@/assets/images/iconDevice.svg"),
        iconSize: [41, 41],
        iconAnchor: [16, 37],
      }),

      clusterOptions: {
        showCoverageOnHover: false,
      },

      imageDevice: require("@/assets/images/iconDevice.svg"),

      imageHospitalGreen: require("@/assets/images/hospitalGreen.svg"),
      imageHospitalRed: require("@/assets/images/hospitalRed.svg"),
      imageLaboOrange: require("@/assets/images/laboOrange.svg"),
      imageLaboCiel: require("@/assets/images/laboCiel.svg"),
      imageLaboGreen: require("@/assets/images/laboGreen.svg"),
      imageLaboBrown: require("@/assets/images/laboBrown.svg"),
      imageLaboBlue: require("@/assets/images/laboBlue.svg"),
      
      imageHospitalGray: require("@/assets/images/hospitalGray.svg"),
      imageLaboGray: require("@/assets/images/laboGrey.svg"),

      /* imageInsuline: require("@/assets/images/Insuline.svg"),
      imageSerum: require("@/assets/images/Serum.svg"),
      imagePNI: require("@/assets/images/PNI.svg"),
      imageCovid: require("@/assets/images/VaccinCovid19.svg"),

      imageInsulinegray: require("@/assets/images/Insulinegray.svg"),
      imageSerumgray: require("@/assets/images/Serumgray.svg"),
      imagePNIgray: require("@/assets/images/PNIgray.svg"),
      imageCovidgray: require("@/assets/images/VaccinCovid19gray.svg"), */

      imageProvince: [
        require("@/assets/images/iconProvince1.svg"),
        require("@/assets/images/iconProvince2.svg"),
        require("@/assets/images/iconProvince3.svg"),
        require("@/assets/images/iconProvince4.svg"),
        require("@/assets/images/iconProvince5.svg"),
      ],

      imageClear: require("@/assets/images/clearmap.svg"),
      imageReset: require("@/assets/images/resetzoom.svg"),
      zoomIn: require("@/assets/images/zoomin.svg"),
      zoomOut: require("@/assets/images/zoomout.svg"),

      //url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
      url: "http://{s}.google.com/vt/lyrs=m&gl=ma&x={x}&y={y}&z={z}",
      //url: "http://maps.google.com/maps?file=api&amp;v=2&amp;hl=en&amp;sensor=true",
      staticAnchor: [16, 10],
      iconAnchor: [20, 40],
      iconSize: [40, 40],
      tooltipAnchor: [0, -30],

      loaderDevice: false,

      statusPNI: true,
      statusCovid: true,
      statusInsuline: true,
      statusSerum: true,

      statusHos1: true,
      statusHos2: true,
      statusLabBio: true,
      statusLabPharm: true,
      statusOrderAndCorp: true,
      statusSteSavantes: true,
      statusDispoMed: true,

      provinces: [
        {id: 1, name: "Marrakech", lat: 31.611695, lng: -7.946353, status: false},
        {id: 2, name: "Chichaoua", lat: 31.528683, lng: -8.757234, status: false},
        {id: 2, name: "Al Haouz", lat: 31.309513, lng: -7.857903, status: false},
        {id: 2, name: "El Kelaâ des Sraghna", lat: 32.059195, lng: -7.400597, status: false},
        {id: 2, name: "Essaouira", lat: 31.505806, lng:  -9.742327, status: false},
        {id: 2, name: "Rehamna", lat: 32.416912, lng: -7.879500, status: false},
        {id: 2, name: "Youssoufia", lat: 32.242667, lng: -8.529402, status: false},
        {id: 2, name: "Safi", lat: 32.297714, lng: -9.220729, status: false}
      ]
    };
  },
  computed: {
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15];
    },
  },
  methods: {
    setZoomIn() {
      this.mapZoom = this.mapZoom + 1;
    },
    setZoomOut() {
      this.mapZoom = this.mapZoom - 1;
    },
    setResetZoom() {
      let center = [27.105314, -7.684968];
      const mapComponent = this.$refs.map;
      let start = center;
      let end = center;
      mapComponent.fitBounds([start, end], { maxZoom: 6 });
    },
    updateZoom(event) {
      this.mapZoom = event;
    },
    setClearMap() {
      this.clearDevices();
      this.provincesPositions = this.provinces;
      this.setResetZoom();
    },
    clear() {
      this.filterStatus = false;
      this.centersPositions = this.centers;
    },
    changeFilterType(type) {
      this.serialFilter = null;
      this.provinceFilter = null;
      this.filterType = type;
    },
    changeStatusPNI() {
      this.statusPNI == true ? this.statusPNI = false : this.statusPNI = true
    },
    changeStatusCovid() {
      this.statusCovid == true ? this.statusCovid = false : this.statusCovid = true
    },
    changeStatusSerum() {
      this.statusSerum == true ? this.statusSerum = false : this.statusSerum = true
    },
    changeStatusInsuline() {
      this.statusInsuline == true ? this.statusInsuline = false : this.statusInsuline = true
    },

    changeStatusHos1() {
      this.statusHos1 == true ? this.statusHos1 = false : this.statusHos1 = true
    },
    changeStatusHos2() {
      this.statusHos2 == true ? this.statusHos2 = false : this.statusHos2 = true
    },
    changeStatusLabBio() {
      this.statusLabBio == true ? this.statusLabBio = false : this.statusLabBio = true
    },
    changeStatusLabPharm() {
      this.statusLabPharm == true ? this.statusLabPharm = false : this.statusLabPharm = true
    },
    changeStatusOrderAndCorp() {
      this.statusOrderAndCorp == true ? this.statusOrderAndCorp = false : this.statusOrderAndCorp = true
    },
    changeStatusSteSavantes() {
      this.statusSteSavantes == true ? this.statusSteSavantes = false : this.statusSteSavantes = true
    },
    changeStatusDispoMed() {
      this.statusDispoMed == true ? this.statusDispoMed = false : this.statusDispoMed = true
    },
    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    showCenters(province){
      this.ctKechF = this.ctKechF.map(obj=>{
        if(obj.province == province) { 
          return {...obj, status: true}
        }
        else return {...obj, status: false}
      })
      this.centersPositionsType2 = this.centersPositionsType2.map(obj=>{
        if(obj.province == province) { 
          return {...obj, status: true}
        }
        else return {...obj, status: false}
      })
      this.labsPharmaF = this.labsPharmaF.map(obj=>{
        if(obj.province == province) { 
          return {...obj, status: true}
        }
        else return {...obj, status: false}
      })
      this.labsBioF = this.labsBioF.map(obj=>{
        if(obj.province == province) { 
          return {...obj, status: true}
        }
        else return {...obj, status: false}
      })
      this.orderAndCorpF = this.orderAndCorpF.map(obj=>{
        if(obj.province == province) { 
          return {...obj, status: true}
        }
        else return {...obj, status: false}
      })
      this.steSavantesF = this.steSavantesF.map(obj=>{
        if(obj.province == province) { 
          return {...obj, status: true}
        }
        else return {...obj, status: false}
      })
      this.dispoMedF = this.dispoMedF.map(obj=>{
        if(obj.province == province) { 
          return {...obj, status: true}
        }
        else return {...obj, status: false}
      })
      
    }
  },
  watch: {
    /* centers(newVal){
    newVal.map((obj) => {
      switch (obj.type) {
        case 1:
          this.centersPositionsType1.push(obj);
          break;
        case 2:
          this.centersPositionsType2.push(obj);
          return;
      }
    });
  }, */
  },
  
  
  beforeDestroy() {
    //this.$refs.map.removeLayer(this.$refs.markerCluster)
  },
};
</script>

<template>
  <div>
    <div
      v-if="loader == true"
      class="loaderDiv d-flex justify-content-center align-items-center"
    >
      <b-spinner
        label="Loading..."
        variant="primary"
        style="width: 3rem; height: 3rem"
      ></b-spinner>
    </div>

    <div style="height: 100%">

      <l-map
        style="height: 120vh"
        ref="map"
        :zoom="mapZoom"
        v-on:update:zoom="updateZoom($event)"
        :min-zoom="5"
        :options="{ zoomControl: false }"
        :center="mapCenter"
      >
        <l-tile-layer :url="url" :subdomains="['mt0', 'mt1', 'mt2', 'mt3']" />
        
        <l-layer-group layerType="overlay" name="PNI">
          <l-marker
            :zIndexOffset="100"
            v-for="(marker, index) in provinces"
            :key="index"
            :draggable="false"
            :lat-lng="{ lat: marker.lat, lng: marker.lng }"
            :icon="icon"
            @click="showCenters(marker.name)"
          >
            <l-icon
              :icon-anchor="iconAnchor"
              :icon-size="iconSize"
              :tooltipAnchor="tooltipAnchor"
              :icon-url="imageDevice"
            >
              <img :src="imageProvince[1]" />
            </l-icon>
            <l-tooltip
              class="rounded text-secondary"
              :options="{
                interactive: 'true',
                direction: 'top',
              }"
            >
              <span style="font-weight: 550"
                >{{ $t("tooltips.name.text") }} :
              </span>
              {{ marker.name }}
            </l-tooltip>
          </l-marker>
        </l-layer-group>

        <l-layer-group :visible="statusHos1" layerType="overlay" name="PNI">
          <l-marker
            :zIndexOffset="100"
            v-for="(marker, index) in ctKechF.filter((obj)=> obj.status == true)"
            :key="index"
            :draggable="false"
            :lat-lng="{ lat: marker.lat, lng: marker.lng }"
            :icon="icon"
          >
            <l-icon
              :icon-anchor="iconAnchor"
              :icon-size="iconSize"
              :tooltipAnchor="tooltipAnchor"
              :icon-url="imageDevice"
            >
              <img :src="imageHospitalGreen" />
            </l-icon>
            <l-tooltip
              class="rounded text-secondary"
              :options="{
                interactive: 'true',
                direction: 'top',
              }"
            >
              <span style="font-weight: 550"
                >{{ $t("tooltips.name.text") }} :
              </span>
              {{ marker.name }}<br />
              <span style="font-weight: 550"
                >{{ $t("tooltips.phone.text") }} :
              </span>
              {{ marker.tel ? marker.tel : null }}<br />
              <span v-if="marker.etat" style="font-weight: 550"
                >{{ $t("tooltips.state.text") }} :
              </span>
              {{ marker.etat ? marker.etat : null }}<br />
              <span v-if="marker.address" style="font-weight: 550"
                >{{ $t("tooltips.address.text") }} :
              </span>
              {{ marker.address ? marker.address : null }}
            </l-tooltip>
          </l-marker>
        </l-layer-group>

        <l-layer-group :visible="statusHos2" layerType="overlay" name="Serum">
          <l-marker
            :zIndexOffset="100"
            v-for="(marker, index) in centersPositionsType2.filter((obj)=> obj.status == true)"
            :key="index"
            :draggable="false"
            :lat-lng="{ lat: marker.lat, lng: marker.lng }"
            :icon="icon"
          >
            <l-icon
              :icon-anchor="iconAnchor"
              :icon-size="iconSize"
              :tooltipAnchor="tooltipAnchor"
              :icon-url="imageDevice"
            >
              <img :src="imageHospitalRed" />
            </l-icon>
            <l-tooltip
              class="rounded text-secondary"
              :options="{
                interactive: 'true',
                direction: 'top',
              }"
            >
              <div>
                <span style="font-weight: 550"
                  >{{ $t("tooltips.name.text") }} :
                </span>
                {{ marker.name }}<br />
                <span v-if="marker.tel" style="font-weight: 550"
                  >{{ $t("tooltips.phone.text") }} :
                </span>
                {{ marker.tel ? marker.tel : null }}<br v-if="marker.tel" />
                <span v-if="marker.etat" style="font-weight: 550"
                  >{{ $t("tooltips.state.text") }} :
                </span>
                {{ marker.etat ? marker.etat : null }}<br v-if="marker.etat" />
                <span v-if="marker.address" style="font-weight: 550"
                  >{{ $t("tooltips.address.text") }} :
                </span>
                {{ marker.address ? marker.address : null }}
              </div>
            </l-tooltip>
          </l-marker>
        </l-layer-group>

         <l-layer-group
          :visible="statusLabPharm"
          layerType="overlay"
          name="Vaccin Covid 19"
        >
          <l-marker
            :zIndexOffset="100"
            v-for="(marker, index) in labsPharmaF.filter((obj)=> obj.status == true)"
            :key="index"
            :draggable="false"
            :lat-lng="{ lat: marker.lat, lng: marker.lng }"
            :icon="icon"
          >
            <l-icon
              :icon-anchor="iconAnchor"
              :icon-size="iconSize"
              :tooltipAnchor="tooltipAnchor"
              :icon-url="imageDevice"
            >
              <img :src="imageLaboOrange" />
            </l-icon>
            <l-tooltip
              class="rounded text-secondary"
              :options="{
                interactive: 'true',
                direction: 'top',
              }"
            >
              <div>
                <span style="font-weight: 550"
                  >{{ $t("tooltips.name.text") }} :
                </span>
                {{ marker.name }}<br />
                <span v-if="marker.tel" style="font-weight: 550"
                  >{{ $t("tooltips.phone.text") }} :
                </span>
                {{ marker.tel ? marker.tel : null }}<br v-if="marker.tel" />
                <span v-if="marker.etat" style="font-weight: 550"
                  >{{ $t("tooltips.state.text") }} :
                </span>
                {{ marker.etat ? marker.etat : null }}<br v-if="marker.etat" />
                <span v-if="marker.address" style="font-weight: 550"
                  >{{ $t("tooltips.address.text") }} :
                </span>
                {{ marker.address ? marker.address : null }}
              </div>
            </l-tooltip>
          </l-marker>
        </l-layer-group>

        <l-layer-group
          :visible="statusLabBio"
          layerType="overlay"
          name="Insuline"
        >
          <l-marker
            :zIndexOffset="100"
            v-for="(marker, index) in labsBioF.filter((obj)=> obj.status == true)"
            :key="index"
            :draggable="false"
            :lat-lng="{ lat: marker.lat, lng: marker.lng }"
            :icon="icon"
          >
            <l-icon
              :icon-anchor="iconAnchor"
              :icon-size="iconSize"
              :tooltipAnchor="tooltipAnchor"
              :icon-url="imageDevice"
            >
              <img :src="imageLaboCiel" />
            </l-icon>
            <l-tooltip
              class="rounded text-secondary"
              :options="{
                interactive: 'true',
                direction: 'top',
              }"
            >
              <div>
                <span style="font-weight: 550"
                  >{{ $t("tooltips.name.text") }} :
                </span>
                {{ marker.name }}<br />
                <span v-if="marker.tel" style="font-weight: 550"
                  >{{ $t("tooltips.phone.text") }} :
                </span>
                {{ marker.tel ? marker.tel : null }}<br v-if="marker.tel" />
                <span v-if="marker.etat" style="font-weight: 550"
                  >{{ $t("tooltips.state.text") }} :
                </span>
                {{ marker.etat ? marker.etat : null }}<br v-if="marker.etat" />
                <span v-if="marker.address" style="font-weight: 550"
                  >{{ $t("tooltips.address.text") }} :
                </span>
                {{ marker.address ? marker.address : null }}
              </div>
            </l-tooltip>
          </l-marker>
        </l-layer-group>

        <l-layer-group
          :visible="statusOrderAndCorp"
          layerType="overlay"
          name="Insuline"
        >
          <l-marker
            :zIndexOffset="100"
            v-for="(marker, index) in orderAndCorpF.filter((obj)=> obj.status == true)"
            :key="index"
            :draggable="false"
            :lat-lng="{ lat: marker.lat, lng: marker.lng }"
            :icon="icon"
          >
            <l-icon
              :icon-anchor="iconAnchor"
              :icon-size="iconSize"
              :tooltipAnchor="tooltipAnchor"
              :icon-url="imageDevice"
            >
              <img :src="imageLaboGreen" />
            </l-icon>
            <l-tooltip
              class="rounded text-secondary"
              :options="{
                interactive: 'true',
                direction: 'top',
              }"
            >
              <div>
                <span style="font-weight: 550"
                  >{{ $t("tooltips.name.text") }} :
                </span>
                {{ marker.name }}<br />
                <span v-if="marker.tel" style="font-weight: 550"
                  >{{ $t("tooltips.phone.text") }} :
                </span>
                {{ marker.tel ? marker.tel : null }}<br v-if="marker.tel" />
                <span v-if="marker.title" style="font-weight: 550"
                  >Sigle :
                </span>
                {{ marker.title ? marker.title : null }}<br v-if="marker.title" />
                <span v-if="marker.city" style="font-weight: 550"
                  >Ville :
                </span>
                {{ marker.city ? marker.city : null }}<br v-if="marker.city" />
                <span v-if="marker.address" style="font-weight: 550"
                  >{{ $t("tooltips.address.text") }} :
                </span>
                {{ marker.address ? marker.address : null }}
              </div>
            </l-tooltip>
          </l-marker>
        </l-layer-group>

        <l-layer-group
          :visible="statusSteSavantes"
          layerType="overlay"
          name="Insuline"
        >
          <l-marker
            :zIndexOffset="100"
            v-for="(marker, index) in steSavantesF.filter((obj)=> obj.status == true)"
            :key="index"
            :draggable="false"
            :lat-lng="{ lat: marker.lat, lng: marker.lng }"
            :icon="icon"
          >
            <l-icon
              :icon-anchor="iconAnchor"
              :icon-size="iconSize"
              :tooltipAnchor="tooltipAnchor"
              :icon-url="imageDevice"
            >
              <img :src="imageLaboBrown" />
            </l-icon>
            <l-tooltip
              class="rounded text-secondary"
              :options="{
                interactive: 'true',
                direction: 'top',
              }"
            >
              <div>
                <span style="font-weight: 550"
                  >{{ $t("tooltips.name.text") }} :
                </span>
                {{ marker.name }}<br />
                <span v-if="marker.specialite" style="font-weight: 550"
                  >Spécialité
                </span>
                {{ marker.specialite ? marker.specialite : null }}<br v-if="marker.specialite" />
                <span v-if="marker.tel" style="font-weight: 550"
                  >{{ $t("tooltips.phone.text") }} :
                </span>
                {{ marker.tel ? marker.tel : null }}<br v-if="marker.tel" />
                <span v-if="marker.email" style="font-weight: 550"
                  >Email :
                </span>
                {{ marker.email ? marker.email : null }}<br v-if="marker.email" />
                <span v-if="marker.title" style="font-weight: 550"
                  >Sigle :
                </span>
                {{ marker.title ? marker.title : null }}<br v-if="marker.title" />
                <span v-if="marker.city" style="font-weight: 550"
                  >Ville :
                </span>
                {{ marker.city ? marker.city : null }}<br v-if="marker.city" />
                <span v-if="marker.address" style="font-weight: 550"
                  >{{ $t("tooltips.address.text") }} :
                </span>
                {{ marker.address ? marker.address : null }}
              </div>
            </l-tooltip>
          </l-marker>
        </l-layer-group>

        <l-layer-group
          :visible="statusDispoMed"
          layerType="overlay"
          name="Insuline"
        >
          <l-marker
            :zIndexOffset="100"
            v-for="(marker, index) in dispoMedF.filter((obj)=> obj.status == true)"
            :key="index"
            :draggable="false"
            :lat-lng="{ lat: marker.lat, lng: marker.lng }"
            :icon="icon"
          >
            <l-icon
              :icon-anchor="iconAnchor"
              :icon-size="iconSize"
              :tooltipAnchor="tooltipAnchor"
              :icon-url="imageDevice"
            >
              <img :src="imageLaboBlue" />
            </l-icon>
            <l-tooltip
              class="rounded text-secondary"
              :options="{
                interactive: 'true',
                direction: 'top',
              }"
            >
              <div>
                <span style="font-weight: 550"
                  >{{ $t("tooltips.name.text") }} :
                </span>
                {{ marker.name }}<br />
                <span v-if="marker.specialite" style="font-weight: 550"
                  >Spécialité
                </span>
                {{ marker.specialite ? marker.specialite : null }}<br v-if="marker.specialite" />
                <span v-if="marker.tel" style="font-weight: 550"
                  >{{ $t("tooltips.phone.text") }} :
                </span>
                {{ marker.tel ? marker.tel : null }}<br v-if="marker.tel" />
                <span v-if="marker.email" style="font-weight: 550"
                  >Email :
                </span>
                {{ marker.email ? marker.email : null }}<br v-if="marker.email" />
                <span v-if="marker.address" style="font-weight: 550"
                  >{{ $t("tooltips.address.text") }} :
                </span>
                {{ marker.address ? marker.address : null }}
              </div>
            </l-tooltip>
          </l-marker>
        </l-layer-group>

        <l-control>
          <div style="background: rgba(255, 255, 255, 0.4)">
            <span
              class="font-weight-bold"
              style="cursor: pointer"
              @click="changeStatusHos1"
              ><img v-if="statusHos1" :src="imageHospitalGreen" width="40" />
              <img v-if="!statusHos1" :src="imageHospitalGray" width="40" />Hopitaux publics</span
            ><br />
            <span
              class="font-weight-bold"
              style="cursor: pointer"
              @click="changeStatusHos2"
              ><img v-if="statusHos2" :src="imageHospitalRed" width="40" />
              <img v-if="!statusHos2" :src="imageHospitalGray" width="40" />Cliniques privées</span
            ><br />
            <span
              class="font-weight-bold"
              style="cursor: pointer"
              @click="changeStatusLabPharm"
              ><img v-if="statusLabPharm" :src="imageLaboOrange" width="40" />
              <img v-if="!statusLabPharm" :src="imageLaboGray" width="40" />Laboratoires Biologiques</span
            ><br />
            <span
              class="font-weight-bold"
              style="cursor: pointer"
              @click="changeStatusLabBio"
              ><img v-if="statusLabBio" :src="imageLaboCiel" width="40" />
              <img v-if="!statusLabBio" :src="imageLaboGray" width="40" />Laboratoires Pharma</span
            ><br />
            <span
              class="font-weight-bold"
              style="cursor: pointer"
              @click="changeStatusOrderAndCorp"
              ><img v-if="statusOrderAndCorp" :src="imageLaboGreen" width="40" />
              <img v-if="!statusOrderAndCorp" :src="imageLaboGray" width="40" />Conseil Ordre et Corporation</span
            ><br />
            <span
              class="font-weight-bold"
              style="cursor: pointer"
              @click="changeStatusSteSavantes"
              ><img v-if="statusSteSavantes" :src="imageLaboBrown" width="40" />
              <img v-if="!statusSteSavantes" :src="imageLaboGray" width="40" />Sociétés Savantes</span
            ><br />
            <span
              class="font-weight-bold"
              style="cursor: pointer"
              @click="changeStatusDispoMed"
              ><img v-if="statusDispoMed" :src="imageLaboBlue" width="40" />
              <img v-if="!statusDispoMed" :src="imageLaboGray" width="40" />Dispositifs médicaux</span
            ><br />
          </div>
        </l-control>
      </l-map>
    </div>
  </div>
</template>
<style scoped>
.loaderDiv {
  position: absolute;
  z-index: 5000;
  width: 100%;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background: rgba(255, 255, 255, 0.4);
}
@media (max-width: 760px) {
  .searchInput {
    top: 60px;
  }
}
</style>