<script>
import Layout from "../../layouts/main";

import HorizontalMenu from "../../../components/horizontal-navbar-2.vue";
import CentersMap from "./centers-map";

import {
  healthCenters,
  labsBiologiques,
  labsPharma,
  orderAndCorp,
  steSavantes,
  dispoMed,
  centersMarrakech,
} from "./cash";

import {
  layoutComputed,
  authComputed,
  layoutMethods,
  devicesMethods,
  devicesComputed,
} from "@/state/helpers";
/**
 * Devices component
 */
export default {
  page: {
    title: "Health-Centers",
    meta: [{ name: "Health-Centers" }],
  },
  components: {
    Layout,
    HorizontalMenu,
    CentersMap,
  },
  mounted() {
    var root = document.getElementsByTagName("html")[0];
    root.style.overflow = "hidden";
    this.changeMenuType();
  },
  created() {
    /* this.retrieveDevices(1);
    this.retrieveDevices(2);
    this.retrieveDevices(3);
    this.retrieveDevices(4); */
  },
  data() {
    return {
      title: "Health-Centers",
      items: [
        {
          text: "Nextrack",
        },
        {
          text: "Health-Centers",
          active: true,
        },
      ],

      loaderStatus: false,

      centersData: healthCenters,
      labsBiologiquesData: labsBiologiques,
      labsPharmaData: labsPharma,
      orderAndCorpData: orderAndCorp,
      steSavantesData: steSavantes,
      dispoMedData: dispoMed,

      centersMarrakechData: centersMarrakech,

      route: "/health-centers",
    };
  },
  computed: {
    ...layoutComputed,
    ...authComputed,
    ...devicesComputed,
    userRole() {
      return this.loggedIn.user.role;
    },
  },
  methods: {
    ...layoutMethods,
    ...devicesMethods,
    changeMenuType() {
      this.changeLeftSidebarType({ leftSidebarType: "icon" });
    },
    retrieveDevices(page) {
      this.getDevicesBy({
        region: "5fc768178531e643a466146d",
        limit: 10,
        page: page,
      }).then((devices) => {
        if (page == 1) {
          devices.results.map((obj) => {
            this.centersData.push({
              ...obj,
              type: 1,
            });
          });
        } else if (page == 2) {
          devices.results.map((obj) => {
            this.centersData.push({
              ...obj,
              type: 2,
            });
          });
        } else if (page == 3) this.labsBiologiquesData = devices.results;
        else if (page == 4) this.labsPharmaData = devices.results;
      });
    },
  },
  watch: {},
  beforeDestroy() {
    var root = document.getElementsByTagName("html")[0];
    root.style.overflow = "visible";
    this.changeLeftSidebarType({ leftSidebarType: "light" });
  },
};
</script>

<template>
  <Layout>
    <Horizontal-menu class="horiMenu" v-bind:page="route" />
    <CentersMap
      v-bind:centers="centersData"
      :labsBio="labsBiologiquesData"
      :labsPharma="labsPharmaData"
      :orderAndCorp="orderAndCorpData"
      :steSavantes="steSavantesData"
      :dispoMed="dispoMedData"
      :ctKech="centersMarrakechData"
      :loader="loaderStatus"
    />
  </Layout>
</template>
<style scoped>
@media (min-width: 761px) {
  div /deep/ .footer {
    display: none;
  }
  div /deep/ #page-topbar {
    display: none;
  }
  div /deep/ .vertical-menu {
    display: none;
  }
}

@media (max-width: 760px) {
  .horiMenu {
    display: none;
  }
}

div /deep/ .container-fluid {
  padding: 0px;
}
div /deep/ .page-content {
  min-height: 1320px;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
}
div /deep/ .main-content {
  margin-left: 0px;
}
</style>